import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import VuiDateRangePicker from "../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker";
import MultipleCheckboxes from "../../../../components/MultipleCheckboxes";
import { useState } from "@hookstate/core/dist";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import clsx from "clsx";
import VuiLoader from "../../../../vodea/@vodea-ui/components/VuiLoader";
import { Line } from "react-chartjs-2";
import { colorChart } from "../../../../data";
import { Link } from "react-router-dom";
import SalesRepository from "../../../../repositories/Widget/SalesRepository";
import SalesVendorRepository from "../../../../repositories/Widget/Vendor/SalesRepository";
import useRoleService from "../../../../vodea/utilities/hooks/useRoleService";
import useIsMounted from "../../../../vodea/utilities/hooks/useIsMounted";
import ChannelBrandRepositories from "../../../../repositories/Brand/ChannelRepository";
import BrandVendorRepositories from "../../../../repositories/Brand/VendorRepository";
import BrandCategoryRepositories from "../../../../repositories/Brand/CategoryRepository";
import {
  abbreviateNumber,
  convertToLocaleNumber,
  convertToType,
  getInvoiceStatusConfig,
  getOrderStatusConfig,
} from "../../../../vodea/utilities/helpers/global";
import VendorCategoryRepository from "../../../../repositories/Vendor/CategoryRepository";
import VuiDataTable from "../../../../vodea/@vodea-ui/components/VuiDataTable";
import { $clone } from "../../../../vodea/utilities";
import _ from "lodash";
import { TableChangeHandler } from "react-bootstrap-table-next";
import VuiNumberFormat from "../../../../vodea/@vodea-ui/components/VuiNumberFormat";
import { cardDefaultValues, CardInterface } from "./interface";
import { AxiosResponse } from "axios";
import VuiCard from "../../../../vodea/@vodea-ui/components/VuiCard";
import DateTime from "../../../../components/DateTime";
import { VuiButton } from "../../../../vodea/@vodea-ui/components/VuiButton";
import * as htmlToImage from "html-to-image";
import { htmlToPdf } from "../../../../vodea/utilities/helpers/pdf.helper";
import { useTranslation } from "react-i18next";

const DashboardPenjualan: React.FC<any> = () => {
  const isMounted = useIsMounted();
  const chartSelection = useState<string>("gmv");

  const [gmvCardLoading, setGmvCardLoading] = React.useState<boolean>(false);
  const [gmvCard, setGmvCard] =
    React.useState<CardInterface>(cardDefaultValues);

  const [incomingOrderCard, setIncomingOrderCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [incomingOrderCardLoading, setIncomingOrderCardLoading] =
    React.useState<boolean>(false);

  const [newOrderCard, setNewOrderCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [newOrderCardLoading, setNewOrderCardLoading] =
    React.useState<boolean>(false);

  const [soldUnit, setSoldUnit] =
    React.useState<CardInterface>(cardDefaultValues);
  const [soldUnitLoading, setSoldUnitLoading] = React.useState<boolean>(false);

  const [avgSalesPriceCard, setAvgSalesPriceCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [avgSalesPriceCardLoading, setAvgSalesPriceCardLoading] =
    React.useState<boolean>(false);

  const [totalCategoryOption, setTotalCategoryOption] =
    React.useState<number>(0);
  const [totalVendorOption, setTotalVendorOption] = React.useState<number>(0);

  const [tableData, setTableData] = React.useState<any[]>([]);
  const [colorIndex, setColorIndex] = React.useState<number>(0);
  const [chartLoading, setChartLoading] = React.useState<boolean>(false);
  const chartColor = useState<string>(colorChart[0]);

  const [chartLabel, setChartLabel] = React.useState<any>([]);
  const [chartLabelCompare, setChartLabelCompare] = React.useState<any>([]);
  const { t } = useTranslation();
  const [daysDifference, setDaysDifference] = React.useState(0);
  const [chartReal, setChartReal] = React.useState<any>([]);
  const [chartCompare, setChartCompare] = React.useState<any>([]);
  const [channelOptions, setChannelOptions] = React.useState<any>([]);
  const [selectedChannel, setSelectedChannel] = React.useState<any[]>([]);
  const [categoryOptions, setCategoryOptions] = React.useState<any>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<any>([]);
  const [vendorOptions, setVendorOptions] = React.useState<any>([]);
  const [selectedVendor, setSelectedVendor] = React.useState<any>([]);
  const [isFirstMount, setIsFirstMount] = React.useState<boolean>(false);
  const { isVendor } = useRoleService();
  const [loadingExport, setLoadingExport] = React.useState<boolean>(false);
  const defaultImageSrc = (ev: any) => {
    ev.target.src =
      "https://png.pngtree.com/png-clipart/20191120/original/pngtree-error-file-icon-vectors-png-image_5053766.jpg";
  };

  const filterConfig = useState({
    date_from: moment().subtract(6, "days").format("YYYY-MM-DD"),
    date_to: moment().format("YYYY-MM-DD"),
  });

  const tableConfig = useState({
    per_page: 5,
    page: 1,
    loading: false,
    total: 0,
    sorted_by: "desc",
    order_by: "created_at",
    search: "",
  });

  const { brand } = useSelector((state: RootState) => state.brand);
  const { vendor } = useSelector((state: RootState) => state.vendor);

  const getChannelOptions = async () => {
    await ChannelBrandRepositories.all(brand.id)
      .then((response: any) => {
        const temp: any[] = [];
        response.data.data.forEach((item: any) => {
          temp.push(item.id);
        });
        if (isMounted.current) {
          setChannelOptions(response.data.data);
          setSelectedChannel(temp);
        }
      })
      .catch((err: any) => {});
  };

  const getCategoryOptions = async () => {
    let params: any = {};
    Object.assign(params, {
      with: "children",
      only: "parent",
      per_page: 1000,
    });

    await (isVendor()
      ? VendorCategoryRepository.all(vendor.id, params)
      : BrandCategoryRepositories.all(brand.id, params)
    ).then((response: any) => {
      if (isMounted.current) {
        let total = 0;
        const temp: any = [];
        response.data.data.forEach((item: any) => {
          if (item.children.length > 0) {
            item.children.forEach((child: any, index: number) => {
              temp.push(child.id);
              total += 1;
            });
          }
          temp.push(item.id);
          total += 1;
        });
        setTotalCategoryOption(total);
        setCategoryOptions(response.data.data);
        setSelectedCategory(temp);
      }
    });
  };

  const getVendorOptions = async () => {
    await BrandVendorRepositories.all(brand.id, {
      per_page: 1000,
    }).then((response: any) => {
      if (isMounted.current) {
        const data = response.data.data;
        const temp: any = [];
        data.forEach((item: any) => {
          temp.push(item.id);
        });
        setVendorOptions(data);
        setTotalVendorOption(data.length);
        setSelectedVendor(temp);
      }
    });
  };

  useEffect(() => {
    getCategoryOptions();
    if (!isVendor()) {
      getVendorOptions();
      getChannelOptions();
    }
  }, []);

  const handleChangeDate = (start: any, end: any) => {
    filterConfig.date_from.set(start.format("YYYY-MM-DD"));
    filterConfig.date_to.set(end.format("YYYY-MM-DD"));

    getAllData(true);
  };

  const onTableChange: TableChangeHandler<any> = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    tableConfig.page.set(page);
    tableConfig.per_page.set(sizePerPage);
    tableConfig.order_by.set(sortField);
    tableConfig.sorted_by.set(sortOrder);

    getTableData();
  };

  const getTableData = (reset: boolean = false) => {
    if (isMounted.current) {
      if (reset) tableConfig.page.set(1);
      tableConfig.loading.set(true);
      setTableData([]);
    }

    let params: any = {};
    const conf = _.omit($clone(tableConfig.value), "loading");
    Object.keys(conf).forEach((prop) => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    Object.assign(params, {
      status: "Pesanan Baru",
      "date-from": filterConfig.date_from.get(),
      "date-to": filterConfig.date_to.get(),
    });

    Object.assign(params, {
      channel: selectedChannel,
    });

    if (isVendor()) {
      if (selectedCategory.length !== totalCategoryOption) {
        Object.assign(params, {
          category: selectedCategory,
        });
      }
      SalesVendorRepository.getTableOrderData(vendor.id, params)
        .then((res: any) => {
          const data = res.data.data;
          if (isMounted.current) {
            tableConfig.loading.set(false);
            setTableData(data);
            tableConfig.total.set(res.data.meta.total);
          }
        })
        .catch((error: any) => {
          if (isMounted.current) {
            tableConfig.loading.set(false);
          }
        });
    } else {
      if (selectedVendor.length !== totalVendorOption) {
        Object.assign(params, {
          vendor: selectedVendor,
        });
      }

      if (selectedCategory.length !== totalCategoryOption) {
        Object.assign(params, {
          category: selectedCategory,
        });
      }

      SalesRepository.getTableInvoiceData(brand.id, params)
        .then((response: any) => {
          const data = response.data.data;
          if (isMounted.current) {
            tableConfig.loading.set(false);
            tableConfig.total.set(response.data.meta.total);
            setTableData(data);
          }
        })
        .catch((error: any) => {
          if (isMounted.current) {
            tableConfig.loading.set(false);
          }
        });
    }
  };

  const getGmvData = (params: any) => {
    if (isMounted.current) {
      setGmvCardLoading(true);
    }

    if (isVendor()) {
      SalesVendorRepository.getGrossMargin(vendor.id, params)
        .then((response: AxiosResponse) => {
          const temp: CardInterface = {
            title: "GMV",
            description:
              "Didapat dari pesanan yang masuk diluar pesanan yang dibatalkan.",
            value: response.data.data.current_period,
            compare: convertToLocaleNumber(
              response.data.data.last_period,
              response.data.data.current_period
            ),
            type: convertToType(
              response.data.data.last_period,
              response.data.data.current_period
            ),
          };
          if (isMounted.current) {
            setGmvCardLoading(false);
            setGmvCard(temp);
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setGmvCardLoading(false);
          }
        });
    } else {
      SalesRepository.getGrossMargin(brand.id, params)
        .then((response: AxiosResponse) => {
          const temp: CardInterface = {
            title: "GMV",
            description:
              "Didapat dari pesanan yang masuk diluar pesanan yang dibatalkan.",
            value: response.data.data.current_period,
            compare: convertToLocaleNumber(
              response.data.data.last_period,
              response.data.data.current_period
            ),
            type: convertToType(
              response.data.data.last_period,
              response.data.data.current_period
            ),
          };
          if (isMounted.current) {
            setGmvCardLoading(false);
            setGmvCard(temp);
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setGmvCardLoading(false);
          }
        });
    }
  };

  const getAvgSalesPriceData = (params: any) => {
    if (isMounted.current) {
      setAvgSalesPriceCardLoading(true);
    }

    if (isVendor()) {
      SalesVendorRepository.getAverageSalePrice(vendor.id, params)
        .then((response: AxiosResponse) => {
          const temp: CardInterface = {
            title: "ASP",
            description:
              "Berdasarkan Total GMV dibagi dengan Total Unit yang tersedia.",
            value: response.data.data.current_period,
            compare: convertToLocaleNumber(
              response.data.data.last_period,
              response.data.data.current_period
            ),
            type: convertToType(
              response.data.data.last_period,
              response.data.data.current_period
            ),
          };
          if (isMounted.current) {
            setAvgSalesPriceCardLoading(false);
            setAvgSalesPriceCard(temp);
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setAvgSalesPriceCardLoading(false);
          }
        });
    } else {
      SalesRepository.getAverageSalePrice(brand.id, params)
        .then((response: AxiosResponse) => {
          const temp: CardInterface = {
            title: "ASP",
            description:
              "Berdasarkan Total GMV dibagi dengan Total Unit yang tersedia.",
            value: response.data.data.current_period,
            compare: convertToLocaleNumber(
              response.data.data.last_period,
              response.data.data.current_period
            ),
            type: convertToType(
              response.data.data.last_period,
              response.data.data.current_period
            ),
          };
          if (isMounted.current) {
            setAvgSalesPriceCardLoading(false);
            setAvgSalesPriceCard(temp);
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setAvgSalesPriceCardLoading(false);
          }
        });
    }
  };

  const getNewOrder = (params: any) => {
    if (isMounted.current) {
      setNewOrderCardLoading(true);
    }

    if (isVendor()) {
      SalesVendorRepository.getNewOrderCard(vendor.id, {
        ...params,
        status: "Pesanan Baru",
      })
        .then((response: AxiosResponse) => {
          const temp: CardInterface = {
            title: "Pesanan baru",
            description:
              "Jumlah pesanan yang baru masuk dan sudah dibayar oleh pembeli.",
            value: response.data.data?.current_period,
            compare: convertToLocaleNumber(
              response.data.data?.last_period,
              response.data.data?.current_period
            ),
            type: convertToType(
              response.data.data?.last_period,
              response.data.data?.current_period
            ),
          };

          if (isMounted.current) {
            setNewOrderCardLoading(false);
            setNewOrderCard(temp);
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setNewOrderCardLoading(false);
          }
        });
    } else {
      SalesRepository.getInvoiceCard(brand.id, {
        ...params,
        status: "Pesanan Baru",
      })
        .then((response: AxiosResponse) => {
          const temp: CardInterface = {
            title: "Pesanan baru",
            description:
              "Jumlah pesanan yang baru masuk dan sudah dibayar oleh pembeli.",
            value: response.data.data?.current_period,
            compare: convertToLocaleNumber(
              response.data.data?.last_period,
              response.data.data?.current_period
            ),
            type: convertToType(
              response.data.data?.last_period,
              response.data.data?.current_period
            ),
          };

          if (isMounted.current) {
            setNewOrderCardLoading(false);
            setNewOrderCard(temp);
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setNewOrderCardLoading(false);
          }
        });
    }
  };

  const getInvoiceIncomingCard = (params: any) => {
    if (isMounted.current) setIncomingOrderCardLoading(true);

    if (isVendor()) {
      SalesVendorRepository.getIncomingOrderCard(vendor.id, {
        ...params,
        status: [
          "Selesai",
          "Diproses Vendor",
          "Dalam Pengiriman",
          "Pesanan Sampai",
        ],
      })
        .then((response: AxiosResponse) => {
          const temp: CardInterface = {
            title: "Pesanan masuk",
            description:
              "Jumlah pesanan masuk yang diambil dari invoice dimulai dari status pesanan diproses vendor sampai pesanan selesai.",
            value: response.data.data?.current_period,
            compare: convertToLocaleNumber(
              response.data.data?.last_period,
              response.data.data?.current_period
            ),
            type: convertToType(
              response.data.data?.last_period,
              response.data.data?.current_period
            ),
          };

          if (isMounted.current) {
            setIncomingOrderCardLoading(false);
            setIncomingOrderCard(temp);
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setIncomingOrderCardLoading(false);
          }
        });
    } else {
      SalesRepository.getIncomingInvoiceCard(brand.id, {
        ...params,
        status: [
          "Selesai",
          "Diproses Vendor",
          "Dalam Pengiriman",
          "Pesanan Sampai",
        ],
      })
        .then((response: AxiosResponse) => {
          const temp: CardInterface = {
            title: "Pesanan masuk",
            description:
              "Jumlah pesanan masuk yang diambil dari invoice dimulai dari status pesanan diproses vendor sampai pesanan selesai.",
            value: response.data.data?.current_period,
            compare: convertToLocaleNumber(
              response.data.data?.last_period,
              response.data.data?.current_period
            ),
            type: convertToType(
              response.data.data?.last_period,
              response.data.data?.current_period
            ),
          };

          if (isMounted.current) {
            setIncomingOrderCardLoading(false);
            setIncomingOrderCard(temp);
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setIncomingOrderCardLoading(false);
          }
        });
    }
  };

  const getSoldUnit = (params: any) => {
    if (isMounted.current) {
      setSoldUnitLoading(true);
    }

    if (isVendor()) {
      SalesVendorRepository.getOrderProduct(vendor.id, params)
        .then((response: AxiosResponse) => {
          const temp: CardInterface = {
            title: "Unit terjual",
            description:
              "Jumlah pesanan yang baru masuk dan sudah dibayar oleh pembeli.",
            value: response.data.data.current_period,
            compare: convertToLocaleNumber(
              response.data.data.last_period,
              response.data.data.current_period
            ),
            type: convertToType(
              response.data.data.last_period,
              response.data.data.current_period
            ),
          };

          if (isMounted.current) {
            setSoldUnitLoading(false);
            setSoldUnit(temp);
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setSoldUnitLoading(false);
          }
        });
    } else {
      SalesRepository.getOrderProductCard(brand.id, params)
        .then((response: AxiosResponse) => {
          const temp: CardInterface = {
            title: "Unit terjual",
            description:
              "Jumlah pesanan yang baru masuk dan sudah dibayar oleh pembeli.",
            value: response.data.data.current_period,
            compare: convertToLocaleNumber(
              response.data.data.last_period,
              response.data.data.current_period
            ),
            type: convertToType(
              response.data.data.last_period,
              response.data.data.current_period
            ),
          };

          if (isMounted.current) {
            setSoldUnitLoading(false);
            setSoldUnit(temp);
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setSoldUnitLoading(false);
          }
        });
    }
  };

  const getDifferentDays = () => {
    const diff = moment(filterConfig.date_to.get()).diff(
      moment(filterConfig.date_from.get()),
      "days"
    );
    if (diff == 0 || diff == 1) {
      setDaysDifference(1);
    } else {
      setDaysDifference(diff + 1);
    }
  };

  const getAvgSalesPriceChartData = (difference: any, params: any) => {
    if (isVendor()) {
      SalesVendorRepository.getAverageSalePriceChart(vendor.id, params)
        .then((response: AxiosResponse) => {
          const responseData = response.data.data;
          const tempCurrentPeriod = [];
          const tempLastPeriod = [];
          for (let i = 0; i <= difference; i++) {
            tempCurrentPeriod.push(0);
            tempLastPeriod.push(0);
          }

          const startDate = moment(filterConfig.date_from.get());
          responseData?.current_period.forEach((item: any) => {
            const tempDate: any = moment(item?.date).diff(startDate, "days");
            tempCurrentPeriod[tempDate] = item?.grand_total;
          });

          responseData?.last_period.forEach((item: any) => {
            const tempDate: any = moment(item?.date)
              .add(difference + 1, "days")
              .diff(startDate, "days");

            tempLastPeriod[tempDate] = item?.grand_total;
          });

          if (isMounted.current) {
            setChartReal(tempCurrentPeriod);
            setChartCompare(tempLastPeriod);
            setChartLoading(false);
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setChartLoading(false);
          }
        });
    } else {
      SalesRepository.getAverageSalePriceChart(brand.id, params)
        .then((response: AxiosResponse) => {
          const responseData = response.data.data;
          const tempCurrentPeriod = [];
          const tempLastPeriod = [];
          for (let i = 0; i <= difference; i++) {
            tempCurrentPeriod.push(0);
            tempLastPeriod.push(0);
          }

          const startDate = moment(filterConfig.date_from.get());
          responseData?.current_period.forEach((item: any) => {
            const tempDate: any = moment(item?.date).diff(startDate, "days");
            tempCurrentPeriod[tempDate] = item?.grand_total;
          });

          responseData?.last_period.forEach((item: any) => {
            const tempDate: any = moment(item?.date)
              .add(difference + 1, "days")
              .diff(startDate, "days");

            tempLastPeriod[tempDate] = item?.grand_total;
          });

          if (isMounted.current) {
            setChartReal(tempCurrentPeriod);
            setChartCompare(tempLastPeriod);
            setChartLoading(false);
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setChartLoading(false);
          }
        });
    }
  };

  const getSoldUnitChartData = (difference: any, params: any) => {
    SalesRepository.getOrderProductChart(brand.id, params)
      .then((response: AxiosResponse) => {
        const responseData = response.data.data;
        const tempCurrentPeriod = [];
        const tempLastPeriod = [];
        for (let i = 0; i <= difference; i++) {
          tempCurrentPeriod.push(0);
          tempLastPeriod.push(0);
        }

        const startDate = moment(filterConfig.date_from.get());
        responseData?.current_period.forEach((item: any) => {
          const tempDate: any = moment(item?.date).diff(startDate, "days");
          tempCurrentPeriod[tempDate] = item?.total_order_product;
        });

        responseData?.last_period.forEach((item: any) => {
          const tempDate: any = moment(item?.date)
            .add(difference + 1, "days")
            .diff(startDate, "days");

          tempLastPeriod[tempDate] = item?.total_order_product;
        });

        if (isMounted.current) {
          setChartReal(tempCurrentPeriod);
          setChartCompare(tempLastPeriod);
          setChartLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setChartLoading(false);
        }
      });
  };

  const getGmvChartData = (difference: any, params: any) => {
    if (isVendor()) {
      SalesVendorRepository.getGrossMarginChart(vendor.id, params)
        .then((response: AxiosResponse) => {
          const responseData = response.data.data;
          const tempCurrentPeriod = [];
          const tempLastPeriod = [];
          for (let i = 0; i <= difference; i++) {
            tempCurrentPeriod.push(0);
            tempLastPeriod.push(0);
          }

          const startDate = moment(filterConfig.date_from.get());
          responseData?.current_period.forEach((item: any) => {
            const tempDate: any = moment(item?.date).diff(startDate, "days");
            tempCurrentPeriod[tempDate] = item?.grand_total;
          });

          responseData?.last_period.forEach((item: any) => {
            const tempDate: any = moment(item?.date)
              .add(difference + 1, "days")
              .diff(startDate, "days");
            tempLastPeriod[tempDate] = item?.grand_total;
          });

          if (isMounted.current) {
            setChartReal(tempCurrentPeriod);
            setChartCompare(tempLastPeriod);
            setChartLoading(false);
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setChartLoading(false);
          }
        });
    } else {
      SalesRepository.getGrossMarginChart(brand.id, params)
        .then((response: AxiosResponse) => {
          const responseData = response.data.data;
          const tempCurrentPeriod = [];
          const tempLastPeriod = [];
          for (let i = 0; i <= difference; i++) {
            tempCurrentPeriod.push(0);
            tempLastPeriod.push(0);
          }

          const startDate = moment(filterConfig.date_from.get());
          responseData?.current_period.forEach((item: any) => {
            const tempDate: any = moment(item?.date).diff(startDate, "days");
            tempCurrentPeriod[tempDate] = item?.grand_total;
          });

          responseData?.last_period.forEach((item: any) => {
            const tempDate: any = moment(item?.date)
              .add(difference + 1, "days")
              .diff(startDate, "days");
            tempLastPeriod[tempDate] = item?.grand_total;
          });

          if (isMounted.current) {
            setChartReal(tempCurrentPeriod);
            setChartCompare(tempLastPeriod);
            setChartLoading(false);
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setChartLoading(false);
          }
        });
    }
  };

  const getNewOrderChartData = (difference: any, params: any) => {
    if (isVendor()) {
      SalesVendorRepository.getOrderChart(vendor.id, {
        ...params,
        status: "Pesanan Baru",
      })
        .then((response: AxiosResponse) => {
          const responseData = response.data.data;
          const tempCurrentPeriod = [];
          const tempLastPeriod = [];
          for (let i = 0; i <= difference; i++) {
            tempCurrentPeriod.push(0);
            tempLastPeriod.push(0);
          }

          const startDate = moment(filterConfig.date_from.get());
          responseData?.current_period.forEach((item: any) => {
            const tempDate: any = moment(item?.date).diff(startDate, "days");
            tempCurrentPeriod[tempDate] = item?.total_invoice;
          });

          responseData?.last_period.forEach((item: any) => {
            const tempDate: any = moment(item?.date)
              .add(difference + 1, "days")
              .diff(startDate, "days");

            tempLastPeriod[tempDate] = item?.total_invoice;
          });

          if (isMounted.current) {
            setChartReal(tempCurrentPeriod);
            setChartCompare(tempLastPeriod);
            setChartLoading(false);
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setChartLoading(false);
          }
        });
    } else {
      SalesRepository.getInvoiceChart(brand.id, {
        ...params,
        status: "Pesanan Baru",
      })
        .then((response: AxiosResponse) => {
          const responseData = response.data.data;
          const tempCurrentPeriod = [];
          const tempLastPeriod = [];
          for (let i = 0; i <= difference; i++) {
            tempCurrentPeriod.push(0);
            tempLastPeriod.push(0);
          }

          const startDate = moment(filterConfig.date_from.get());
          responseData?.current_period.forEach((item: any) => {
            const tempDate: any = moment(item?.date).diff(startDate, "days");
            tempCurrentPeriod[tempDate] = item?.total_invoice;
          });

          responseData?.last_period.forEach((item: any) => {
            const tempDate: any = moment(item?.date)
              .add(difference + 1, "days")
              .diff(startDate, "days");

            tempLastPeriod[tempDate] = item?.total_invoice;
          });

          if (isMounted.current) {
            setChartReal(tempCurrentPeriod);
            setChartCompare(tempLastPeriod);
            setChartLoading(false);
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setChartLoading(false);
          }
        });
    }
  };

  const getIncomingOrderChartData = (difference: any, params: any) => {
    if (isVendor()) {
      SalesVendorRepository.getOrderChart(vendor.id, {
        ...params,
        status: [
          "Selesai",
          "Diproses Vendor",
          "Dalam Pengiriman",
          "Pesanan Sampai",
        ],
      })
        .then((response: AxiosResponse) => {
          const responseData = response.data.data;
          const tempCurrentPeriod = [];
          const tempLastPeriod = [];
          for (let i = 0; i <= difference; i++) {
            tempCurrentPeriod.push(0);
            tempLastPeriod.push(0);
          }

          const startDate = moment(filterConfig.date_from.get());
          responseData?.current_period.forEach((item: any) => {
            const tempDate: any = moment(item?.date).diff(startDate, "days");
            tempCurrentPeriod[tempDate] = item?.total_invoice;
          });

          responseData?.last_period.forEach((item: any) => {
            const tempDate: any = moment(item?.date)
              .add(difference + 1, "days")
              .diff(startDate, "days");

            tempLastPeriod[tempDate] = item?.total_invoice;
          });

          if (isMounted.current) {
            setChartReal(tempCurrentPeriod);
            setChartCompare(tempLastPeriod);
            setChartLoading(false);
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setChartLoading(false);
          }
        });
    } else {
      SalesRepository.getIncomingInvoiceChart(brand.id, {
        ...params,
        status: [
          "Selesai",
          "Diproses Vendor",
          "Dalam Pengiriman",
          "Pesanan Sampai",
        ],
      })
        .then((response: AxiosResponse) => {
          const responseData = response.data.data;
          const tempCurrentPeriod = [];
          const tempLastPeriod = [];
          for (let i = 0; i <= difference; i++) {
            tempCurrentPeriod.push(0);
            tempLastPeriod.push(0);
          }

          const startDate = moment(filterConfig.date_from.get());
          responseData?.current_period.forEach((item: any) => {
            const tempDate: any = moment(item?.date).diff(startDate, "days");
            tempCurrentPeriod[tempDate] = item?.total_invoice;
          });

          responseData?.last_period.forEach((item: any) => {
            const tempDate: any = moment(item?.date)
              .add(difference + 1, "days")
              .diff(startDate, "days");

            tempLastPeriod[tempDate] = item?.total_invoice;
          });

          if (isMounted.current) {
            setChartReal(tempCurrentPeriod);
            setChartCompare(tempLastPeriod);
            setChartLoading(false);
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setChartLoading(false);
          }
        });
    }
  };

  const changeChart = (key: string, index: number) => {
    if (isMounted.current) {
      chartSelection.set(key);
      chartColor.set(colorChart[index]);
      setColorIndex(index);
      setChartLoading(true);
    }

    const difference = moment(filterConfig.date_to.get()).diff(
      moment(filterConfig.date_from.get()),
      "days"
    );

    const params = {
      "date-from": filterConfig.date_from.get(),
      "date-to": filterConfig.date_to.get(),
      channel: selectedChannel,
    };

    if (selectedCategory.length !== totalCategoryOption) {
      Object.assign(params, {
        category: selectedCategory,
      });
    }

    if (selectedVendor.length !== totalVendorOption) {
      Object.assign(params, {
        vendor: selectedVendor,
      });
    }

    const tempChartLabel: string[] = [];
    const tempChartLabelCompare: string[] = [];

    for (let i = difference; i >= 0; i--) {
      tempChartLabel.push(
        moment(filterConfig.date_to.get())
          .subtract(i, "days")
          .format("DD MMMM YYYY")
      );
    }

    for (let i = difference; i >= 0; i--) {
      tempChartLabelCompare.push(
        moment(filterConfig.date_to.get())
          .subtract(i + difference + 1, "days")
          .format("DD MMMM YYYY")
      );
    }

    if (isMounted.current) {
      setChartLabel(tempChartLabel);
      setChartLabelCompare(tempChartLabelCompare);
    }

    if (key === "gmv") {
      getGmvChartData(difference, params);
    } else if (key === "new-order") {
      getNewOrderChartData(difference, params);
    } else if (key === "sold-unit") {
      getSoldUnitChartData(difference, params);
    } else if (key === "incoming-order") {
      getIncomingOrderChartData(difference, params);
    } else if (key === "avg-sales-price") {
      getAvgSalesPriceChartData(difference, params);
    }
  };

  const getAllData = (reset: boolean = false) => {
    let params: any = {
      "date-from": filterConfig.date_from.get(),
      "date-to": filterConfig.date_to.get(),
    };

    if (isVendor()) {
      if (selectedCategory.length !== totalCategoryOption) {
        Object.assign(params, {
          category: selectedCategory,
        });
      }
    } else {
      if (selectedCategory.length !== totalCategoryOption) {
        Object.assign(params, {
          category: selectedCategory,
        });
      }

      if (selectedVendor.length !== totalVendorOption) {
        Object.assign(params, {
          vendor: selectedVendor,
        });
      }
    }

    Object.assign(params, {
      channel: selectedChannel,
    });

    getDifferentDays();
    if (!isVendor()) {
      getSoldUnit(params);
    }
    getGmvData(params);
    getNewOrder(params);
    getInvoiceIncomingCard(params);
    getAvgSalesPriceData(params);
    getTableData(reset);
    changeChart(chartSelection.get(), colorIndex);
  };

  useEffect(() => {
    if (isFirstMount) {
      getAllData(true);
    }
    if (isVendor()) {
      if (selectedCategory.length > 0) {
        setIsFirstMount(true);
      }
    } else {
      if (
        selectedChannel.length > 0 &&
        selectedVendor.length > 0 &&
        selectedCategory.length > 0
      ) {
        setIsFirstMount(true);
      }
    }
  }, [selectedChannel, selectedVendor, selectedCategory, isFirstMount]);

  const tableBrandColumns = [
    {
      dataField: "external_number",
      text: "Nomor Invoice",
      sort: true,
      formatter: (cell: any, row: any) => (
        <Link to={`/invoice/${row.id}`}>
          <div>{cell}</div>
        </Link>
      ),
    },
    {
      dataField: "created_at",
      text: "Tanggal Dibuat",
      sort: true,
      formatter: (cell: any) => <DateTime data={cell} />,
    },
    {
      dataField: "customer_name",
      text: "Pelanggan",
      sort: true,
    },
    {
      dataField: "grand_total",
      text: "Total",
      sort: true,
      formatter: (cell: any) => <VuiNumberFormat data={cell} prefix={"Rp "} />,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell: any) => (
        <span
          className={clsx([
            "badge",
            isVendor()
              ? getOrderStatusConfig(cell)?.badge
              : getInvoiceStatusConfig(cell)?.badge,
          ])}
        >
          {cell}
        </span>
      ),
      sort: true,
    },
    {
      dataField: "channel_id",
      text: "Channel",
      sort: true,
      formatter: (cell: any) => (
        <img
          className={"channel-icon"}
          src={
            cell == 1
              ? "/images/logo-tokopedia-simple.png"
              : "@/public/logo.png"
          }
          alt={"logo-tokopedia-simple.png"}
          onError={defaultImageSrc}
        />
      ),
    },
    {
      dataField: "updated_at",
      text: "Terakhir Diperbaharui",
      sort: true,
      formatter: (cell: any) => <DateTime data={cell} />,
    },
  ];

  const tableVendorColumns = [
    {
      dataField: "invoice.external_number",
      text: "Nomor Invoice",
      sort: true,
      formatter: (cell: any, row: any) => (
        <Link to={`/order/${row.id}`}>
          <div>{cell}</div>
        </Link>
      ),
    },
    {
      dataField: "created_at",
      text: "Tanggal Dibuat",
      sort: true,
      formatter: (cell: any) => <DateTime data={cell} />,
    },
    {
      dataField: "grand_total",
      text: "Total",
      sort: true,
      formatter: (cell: any) => <VuiNumberFormat data={cell} prefix={"Rp "} />,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell: any, row: any) => (
        <span
          className={clsx([
            "badge",
            isVendor()
              ? getOrderStatusConfig(cell)?.badge
              : getInvoiceStatusConfig(cell)?.badge,
          ])}
        >
          {row.status}
        </span>
      ),
      sort: true,
    },
    {
      dataField: "updated_at",
      text: "Terakhir Diperbaharui",
      sort: true,
      formatter: (cell: any) => <DateTime data={cell} />,
    },
    {
      dataField: "invoice.channel_id",
      text: "Channel",
      sort: true,
      formatter: (cell: any) => (
        <img
          className={"channel-icon"}
          src={
            cell == 1
              ? "/images/logo-tokopedia-simple.png"
              : "@/public/logo.png"
          }
          alt={cell}
          onError={defaultImageSrc}
        />
      ),
    },
  ];

  const handleTableSearch = _.debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      tableConfig.search.set(e.target.value);
      tableConfig.page.set(1);

      getTableData();
    },
    300
  );

  const customTitle = (tooltipItem: any) => {
    const real = chartLabel[_.get(tooltipItem, "[0].index", 0)];
    const compare = chartLabelCompare[_.get(tooltipItem, "[0].index", 0)];
    return [real, compare];
  };

  const handleExport = useCallback(() => {
    if (isMounted.current) {
      setLoadingExport(true);
      const body: any = document.querySelector(".app-container");
      if (body) {
        htmlToImage
          .toCanvas(body)
          .then(function (dataUrl) {
            const finish = htmlToPdf(dataUrl, "dashboard-penjualan");
            if (finish) setLoadingExport(false);
          })
          .catch(function (error) {
            setLoadingExport(false);
          });
      }
    }
  }, [isMounted]);

  return (
    <>
      <Helmet>
        <title>Dashboard Penjualan</title>
      </Helmet>

      <div className={"page-header-component"}>
        <h3 className={"title"}>
          Dashboard | <strong>Penjualan</strong>
        </h3>
        <VuiButton
          onClick={handleExport}
          variant="success"
          label={t("button.export")}
          loading={loadingExport}
        />
      </div>

      <div className="card-paper mb-4">
        <div className="card-content">
          <div className="card-filter-section">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label" style={{ display: "flex" }}>
                    Rentang Tanggal
                  </label>
                  <VuiDateRangePicker
                    startDate={filterConfig.date_from.get()}
                    endDate={filterConfig.date_to.get()}
                    callback={handleChangeDate}
                    useRanges={true}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">Kategori</label>
                  <MultipleCheckboxes
                    name={"kategori"}
                    options={categoryOptions}
                    labelOption="name"
                    valueOption="id"
                    values={selectedCategory}
                    onChange={(values) => {
                      setSelectedCategory(values);
                    }}
                    children={true}
                  />
                </div>
              </div>
              <>
                {!isVendor() ? (
                  <>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Channel</label>
                        <MultipleCheckboxes
                          name={"channel"}
                          labelOption="name"
                          options={channelOptions}
                          valueOption="id"
                          values={selectedChannel}
                          onChange={(values) => {
                            setSelectedChannel(values);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Vendor</label>
                        <MultipleCheckboxes
                          name={"vendor"}
                          options={vendorOptions}
                          labelOption="name"
                          valueOption="id"
                          values={selectedVendor}
                          onChange={(values) => {
                            setSelectedVendor(values);
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </>
            </div>
          </div>
        </div>
      </div>

      <div className="card-paper mb-4">
        <div className="card-content">
          <div className="card-chart-wrapper mb-4">
            <VuiCard
              isActive={chartSelection.get() === "gmv"}
              title={gmvCard.title}
              description={gmvCard.description}
              loading={gmvCardLoading}
              type={gmvCard.type}
              compare={gmvCard.compare}
              value={gmvCard.value}
              days={daysDifference}
              isCurrency
              hasComparison
              onClick={() => changeChart("gmv", 0)}
            />

            <VuiCard
              isActive={chartSelection.get() === "new-order"}
              title={newOrderCard.title}
              description={newOrderCard.description}
              loading={newOrderCardLoading}
              type={newOrderCard.type}
              compare={newOrderCard.compare}
              value={newOrderCard.value}
              days={daysDifference}
              hasComparison
              onClick={() => changeChart("new-order", 1)}
            />

            <VuiCard
              isActive={chartSelection.get() === "incoming-order"}
              title={incomingOrderCard.title}
              description={incomingOrderCard.description}
              loading={incomingOrderCardLoading}
              type={incomingOrderCard.type}
              compare={incomingOrderCard.compare}
              value={incomingOrderCard.value}
              days={daysDifference}
              hasComparison
              onClick={() => changeChart("incoming-order", 2)}
            />

            {!isVendor() && (
              <VuiCard
                isActive={chartSelection.get() === "sold-unit"}
                title={soldUnit.title}
                description={soldUnit.description}
                loading={soldUnitLoading}
                type={soldUnit.type}
                compare={soldUnit.compare}
                value={soldUnit.value}
                hasComparison
                days={daysDifference}
                onClick={() => changeChart("sold-unit", 3)}
              />
            )}

            <VuiCard
              isActive={chartSelection.get() === "avg-sales-price"}
              title={avgSalesPriceCard.title}
              description={avgSalesPriceCard.description}
              loading={avgSalesPriceCardLoading}
              type={avgSalesPriceCard.type}
              compare={avgSalesPriceCard.compare}
              value={avgSalesPriceCard.value}
              days={daysDifference}
              isCurrency
              hasComparison
              onClick={() => changeChart("avg-sales-price", 4)}
            />
          </div>

          {chartLoading ? (
            <VuiLoader />
          ) : (
            <div className="line-chart-wrapper">
              <Line
                height={350}
                data={{
                  labels: chartLabel,
                  datasets: [
                    {
                      label: "Periode saat ini",
                      data: chartReal,
                      fill: false,
                      backgroundColor: chartColor.get(),
                      borderColor: chartColor.get(),
                      tension: 0,
                    },
                    {
                      label: "Periode sebelumnya",
                      data: chartCompare,
                      fill: false,
                      backgroundColor: chartColor.get(),
                      borderColor: chartColor.get(),
                      borderDash: [5, 5],
                      tension: 0,
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  legend: {
                    display: true,
                  },
                  tooltips: {
                    callbacks: {
                      title: customTitle,
                    },
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          callback: function (label: any) {
                            return abbreviateNumber(label, 0);
                          },
                        },
                        scaleLabel: {
                          display: true,
                        },
                      },
                    ],
                  },
                }}
              />
            </div>
          )}
        </div>
      </div>

      <div className="card-paper mb-4">
        <div className="card-header">
          <h6 className="card-header-title">Pesanan Baru</h6>
        </div>

        <div className="card-content">
          <div className={"default-filter-section"}>
            <div className={"filter-item filter-special type-search"}>
              <div className="input-group prefix-group">
                <span className="input-group-text">
                  <FontAwesomeIcon
                    className={"icon icon-prefix"}
                    icon={faSearch}
                  />
                </span>
                <input
                  defaultValue={tableConfig.search.get()}
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={handleTableSearch}
                />
              </div>
            </div>
          </div>

          <VuiDataTable
            loading={tableConfig.loading.get()}
            columns={isVendor() ? tableVendorColumns : tableBrandColumns}
            data={tableData}
            page={tableConfig.page.get()}
            sizePerPage={tableConfig.per_page.get()}
            totalSize={tableConfig.total.get()}
            onTableChange={onTableChange}
          />
        </div>
      </div>
    </>
  );
};

export default DashboardPenjualan;
