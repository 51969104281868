import {faFileExcel, faSearch} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import VuiBreadcrumb from "../../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import { VuiButton } from "../../../../vodea/@vodea-ui/components/VuiButton";

function ProductFormatter(cell: any, row: any) {
  return (
      <div className="product-wrapper">
        <div className="product-image">
          <img src={row.product.image} alt={"image-product"} />
        </div>
        <Link to={`/stok/${row.id}`} className="product-name">{row.product.name}</Link>
      </div>
  );
}

const tableColumns = [
  {
    dataField: "product",
    text: "Produk",
    sort: true,
    formatter: ProductFormatter,
  },
  {
    dataField: "category",
    text: "Kategori",
    sort: false,
  },
  {
    dataField: "sku_stok",
    text: "SKU Stok",
    sort: true,
    classes: "td-custom",
  },
  {
    dataField: "ketersediaan",
    text: "Ketersediaan",
    sort: true,
    classes: "td-custom",
  },
  {
    dataField: "updated_at",
    text: "Terakhir diperbaharui",
    sort: true,
    classes: "td-custom",
  },
];

const tableData = [
  {
    id: 1,
    product: {
      name: "TV 32 Inch",
      image:
          "https://www.jing.fm/clipimg/full/125-1258427_telefunken-smart-tv-finlux-tv-32-inch.png",
    },
    category: 'TV',
    sku_stok: "SKU01241240001",
    sku_product: "SKU01241240002",
    ketersediaan: "112",
    in_stock_rate: "62.24%",
    updated_at: '20 Mei 2021 10:00 AM'
  },
  {
    id: 2,
    product: {
      name: "Kulkas 2 Pintu",
      image:
          "https://images.samsung.com/is/image/samsung/id-top-mount-freezer-rt38k5030b1-rt38k5030b1-se-rperspectiveblack-240668699?$720_576_PNG$",
    },
    category: 'Kulkas',
    sku_stok: "SKU0124124521",
    sku_product: "SKU0002412421412",
    ketersediaan: "97",
    in_stock_rate: "77.87%",
    updated_at: '20 Mei 2021 10:00 AM'
  },
  {
    id: 3,
    product: {
      name: "LED 32 Inch",
      image:
          "https://www.jing.fm/clipimg/full/125-1258427_telefunken-smart-tv-finlux-tv-32-inch.png",
    },
    category: 'TV',
    sku_stok: "SKU0124683124",
    sku_product: "SKU0951354125",
    ketersediaan: "82",
    in_stock_rate: "72.52%",
    updated_at: '20 Mei 2021 10:00 AM'
  },
  {
    id: 4,
    product: {
      name: "Kulkas Side by Side",
      image:
          "https://images.samsung.com/is/image/samsung/id-top-mount-freezer-rt38k5030b1-rt38k5030b1-se-rperspectiveblack-240668699?$720_576_PNG$",
    },
    category: 'Kulkas',
    sku_stok: "SKU01242412555",
    sku_product: "SKU245522555",
    ketersediaan: "155",
    in_stock_rate: "80.02%",
    updated_at: '20 Mei 2021 10:00 AM'
  },
  {
    id: 5,
    product: {
      name: "TV 50 Inch",
      image:
          "https://www.jing.fm/clipimg/full/125-1258427_telefunken-smart-tv-finlux-tv-32-inch.png",
    },
    category: 'TV',
    sku_stok: "SKU52567827335",
    sku_product: "SKU0786457436",
    ketersediaan: "52",
    in_stock_rate: "79.98%",
    updated_at: '20 Mei 2021 10:00 AM'
  },
];

const ManagementStock: React.FC<any> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = React.useState<any[]>([]);

  const breadcrumbList = [
    {
      label: t("Stok"),
      link: "/stok",
    },
    {
      label: t("Manjemen"),
      link: "/manajemen",
    },
  ];

  const handleOnSelect = (row: any, isSelect: boolean) => {
    if (isSelect) {
      const selected = [...selectedRow, row.id];
      setSelectedRow(selected);
    } else {
      setSelectedRow(selectedRow.filter((x: any) => x !== row.id));
    }
  };

  const handleOnSelectAll = (isSelect: boolean, rows: any) => {
    const ids = rows.map((r: any) => r.id);
    if (isSelect) {
      setSelectedRow(ids);
    } else {
      setSelectedRow([]);
    }
  };

  const selectRow: any = {
    mode: "checkbox",
    clickToSelect: true,
    selected: selectedRow,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  const handleAdjustStock = () => {
    navigate("adjust", { state: selectedRow });
  };

  return (
    <>
      <Helmet>
        <title>{t("Management")}</title>
      </Helmet>
      <VuiBreadcrumb list={breadcrumbList} />
      <div className={"page-header-component"}>
        <h3 className={"title"}>{t("Manajemen Stok")}</h3>

        <div className="btn-wrapper">
          <button type="button" className="btn btn-info">
            <FontAwesomeIcon icon={faFileExcel} className="icon icon-prefix"/>
            Import
          </button>
        </div>
      </div>

      <div className="card-paper mb-4">
        <div className="card-content">
          <div className={"default-filter-section"}>
            <div className={"filter-item filter-special type-search"}>
              <div className="input-group prefix-group">
                <span className="input-group-text">
                  <FontAwesomeIcon
                    className={"icon icon-prefix"}
                    icon={faSearch}
                  />
                </span>
                <input
                  defaultValue={""}
                  type="text"
                  className="form-control"
                  placeholder="Search"
                />
              </div>
            </div>
          </div>

          <BootstrapTable
            wrapperClasses={"table-responsive"}
            classes={"table-custom"}
            keyField={"id"}
            data={tableData}
            columns={tableColumns}
            filter={filterFactory()}
            pagination={paginationFactory({
              page: 1,
              sizePerPage: 5,
              totalSize: 5,
              showTotal: true,
            })}
          />
        </div>
      </div>

      {selectedRow.length > 0 && (
        <div className="card-paper">
          <div className="card-content">
            <VuiButton
              forSubmit={false}
              label={"Lakukan Penyesuaian Stok"}
              loading={false}
              onClick={handleAdjustStock}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ManagementStock;
