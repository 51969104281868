import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useState } from "@hookstate/core/dist";
import { TableChangeHandler } from "react-bootstrap-table-next";
import VuiBreadcrumb from "../../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import { Link, Navigate, useLocation } from "react-router-dom";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import NumberFormat from "react-number-format";
import {
  getChannelConfig,
  getInvoiceStatusConfig,
  showToast,
} from "../../../../vodea/utilities/helpers/global";
import { AxiosError, AxiosResponse } from "axios";
import { $clone } from "../../../../vodea/utilities";
import BrandInvoiceRepository from "../../../../repositories/Brand/InvoiceRepository";
import useIsMounted from "../../../../vodea/utilities/hooks/useIsMounted";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import VuiDataTable from "../../../../vodea/@vodea-ui/components/VuiDataTable";

const CustomerShow: React.FC<any> = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const isMounted = useIsMounted();
  const { brand } = useSelector((state: RootState) => state.brand);

  const tableConfig = useState({
    search: "",
    page: 1,
    per_page: 10,
    sorted_by: "asc",
    order_by: "",
    total: 0,
    loading: false,
  });

  const tableData = useState<any[]>([]);

  const tableColumns = [
    {
      dataField: "external_number",
      text: t("table.invoiceNumber.label"),
      formatter: (cell: any, row: any) => (
        <Link to={`/invoice/${row.id}`}>
          <div className="">{row.external_number}</div>
        </Link>
      ),
      sort: true,
    },
    {
      dataField: "payment_date",
      text: t("table.paymentDate.label"),
      sort: true,
      formatter: (cell: any) => {
        return moment(cell).format("D MMMM YYYY h:mm A");
      },
    },
    {
      dataField: "total",
      text: t("table.total.label"),
      sort: true,
      formatter: (cell: any) => {
        return (
          <NumberFormat
            defaultValue={cell}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            decimalScale={0}
            prefix={"IDR "}
          />
        );
      },
    },
    {
      dataField: "status",
      text: t("table.status.label"),
      formatter: (cell: any, row: any) => (
        <span className={clsx(["badge", getInvoiceStatusConfig(cell)?.badge])}>
          {row.status}
        </span>
      ),
      sort: true,
    },
    {
      dataField: "channel_name",
      text: t("table.channel.label"),
      formatter: (cell: any, row: any) => (
        <img
          className={"channel-icon"}
          src={getChannelConfig(cell)?.simpleLogo}
        ></img>
      ),
      sort: true,
    },
  ];

  useEffect(() => {
    if (state) {
      getTableData();
    }
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeTableLoading = (val: boolean) => {
    if (isMounted.current) {
      tableConfig.loading.set(val);
    }
  };

  const onTableChange: TableChangeHandler<any> = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    tableConfig.page.set(page);
    tableConfig.per_page.set(sizePerPage);
    tableConfig.order_by.set(sortField);
    tableConfig.sorted_by.set(sortOrder);

    getTableData();
  };

  const getTableData = (reset: boolean = false) => {
    if (reset) tableConfig.page.set(1);

    changeTableLoading(true);

    const conf = _.omit($clone(tableConfig.value), "loading");

    let params: any = {};
    Object.keys(conf).forEach((prop) => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    Object.assign(params, {
      with: ["channel"],
      "customer-name": encodeURI(_.get(state, "customer_name")),
    });

    tableData.set([]);

    BrandInvoiceRepository.all(brand.id, params)
      .then((response: AxiosResponse) => {
        remapTableData(response.data.data).then(() => {
          tableConfig.total.set(response.data.meta.total);
          changeTableLoading(false);
        });
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, "error");

        changeTableLoading(false);
      });
  };

  const remapTableData = async (data: any[]) => {
    const returnData = data.map((item) => {
      return {
        id: item.id,
        external_number: _.get(item, "external_number", ""),
        payment_date: _.get(item, "payment_date", ""),
        customer_name: _.get(item, "customer_name", ""),
        total: _.get(item, "grand_total", ""),
        status: _.get(item, "status", ""),
        channel_name: _.get(item, "channel.name", ""),
      };
    });

    tableData.set(returnData);
  };

  const breadcrumbList = [
    {
      label: t("Customer"),
      link: "/customer",
    },
    {
      label: _.get(state, "customer_name"),
      link: `/customer/${_.get(state, "customer_name")}`,
    },
  ];

  if (!state) {
    return <Navigate to={"/customer"} replace />;
  }

  return (
    <>
      <Helmet>
        <title>{_.get(state, "customer_name")}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={"page-header-component"}>
        <h3 className={"title"}>{t("customer.detailTitle")}</h3>
      </div>

      <div className="page-content">
        <div className="row">
          <div className="col-4">
            <div className="card-paper mb-4">
              <div className="card-header">
                <div className="card-header-title">
                  <h5>{t("general.information")}</h5>
                </div>
              </div>

              <div className="card-content">
                <div className="mb-4">
                  <div className="text-muted">{t("form.name.label")}</div>
                  <div>{_.get(state, "customer_name", "-")}</div>
                </div>

                <div className="mb-4">
                  <div className="text-muted">
                    {t("form.mobilePhone.label")}
                  </div>
                  <div>{_.get(state, "customer_phone", "-")}</div>
                </div>

                <div className="mb-4">
                  <div className="text-muted">{t("form.email.label")}</div>
                  <div>{_.get(state, "customer_email", "-")}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-8">
            <div className="card-paper mb-4">
              <div className="card-header">
                <div className="card-header-title">
                  <h5>{t("general.order")}</h5>
                </div>
              </div>

              <div className="card-content">
                <VuiDataTable
                  customClasses={"table-invoice"}
                  loading={tableConfig.loading.get()}
                  columns={tableColumns}
                  data={tableData.get()}
                  page={tableConfig.page.get()}
                  sizePerPage={tableConfig.per_page.get()}
                  totalSize={tableConfig.total.get()}
                  onTableChange={onTableChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerShow;
