import React, { useEffect } from "react";
import {
  faDownload,
  faPlus,
  faUpload,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import VuiBreadcrumb from "../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import { useState } from "@hookstate/core/dist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { TableChangeHandler } from "react-bootstrap-table-next";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { $clone } from "../../../vodea/utilities";
import useIsMounted from "../../../vodea/utilities/hooks/useIsMounted";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";
import BrandCustomerRepository from "../../../repositories/Brand/CustomerRepository";
import { AxiosError, AxiosResponse } from "axios";
import { showToast } from "../../../vodea/utilities/helpers/global";
import _ from "lodash";
import VuiDataTable from "../../../vodea/@vodea-ui/components/VuiDataTable";
import ExportBrandRepository from "../../../repositories/Export/Brand/ExportBrandRepositories";
import fileDownload from "js-file-download";
import moment from "moment";

const Customer: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { brand } = useSelector((state: RootState) => state.brand);
  const navigate = useNavigate();

  const tableConfig = useState({
    search: "",
    page: 1,
    per_page: 10,
    sorted_by: "asc",
    order_by: "",
    total: 0,
    loading: false,
  });

  const tableData = useState([]);

  const tableColumns = [
    {
      dataField: "customer_name",
      text: t("table.name.label"),
      sort: true,
      formatter: (cell: any, row: any) => (
        <label
          className="table-link"
          onClick={() => {
            navigate(`${cell}`, {
              state: $clone(row),
            });
          }}
        >
          {cell}
        </label>
      ),
    },
    {
      dataField: "customer_email",
      text: t("table.email.label"),
      sort: false,
    },
    {
      dataField: "customer_phone",
      text: t("table.mobilePhone.label"),
      sort: false,
    },
    {
      dataField: "total_invoice",
      text: t("table.totalInvoice.label"),
      sort: false,
    },
  ];

  useEffect(() => {
    getTableData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTableSearch = _.debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      tableConfig.search.set(e.target.value);
      tableConfig.page.set(1);

      getTableData();
    },
    300
  );

  const handleExport = () => {
    ExportBrandRepository.exportCustomer(brand.id).then((response: any) => {
      const fileName = `${moment().format(
        "YYYY-MM-DD HH:mm:ss"
      )} customer.xlsx`;

      fileDownload(response.data, fileName);
    });
  };

  const onTableChange: TableChangeHandler<any> = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    tableConfig.page.set(page);
    tableConfig.per_page.set(sizePerPage);
    tableConfig.order_by.set(sortField);
    tableConfig.sorted_by.set(sortOrder);

    getTableData();
  };

  const changeTableLoading = (val: boolean) => {
    if (isMounted.current) {
      tableConfig.loading.set(val);
    }
  };

  const getTableData = (reset: boolean = false) => {
    if (reset) tableConfig.page.set(1);

    changeTableLoading(true);

    const conf = _.omit($clone(tableConfig.value), "loading");

    let params: any = {};
    Object.keys(conf).forEach((prop) => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    tableData.set([]);

    BrandCustomerRepository.all(brand.id, params)
      .then((response: AxiosResponse) => {
        tableData.set(response.data.data);
        tableConfig.total.set(response.data.meta.total);

        changeTableLoading(false);
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e?.response?.data.message, "error");

        changeTableLoading(false);
      });
  };

  const breadcrumbList = [
    {
      label: t("customer.title"),
      link: "/customer",
    },
  ];

  if (!_.get(brand, "id", null)) {
    return <Navigate to={"/"} replace />;
  }

  return (
    <>
      <Helmet>
        <title>{t("customer.title")}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={"page-header-component"}>
        <h3 className={"title"}>{t("customer.title")}</h3>
        <div className="btn-wrapper d-grid">
          <button
            type="button"
            className="btn btn-success"
            onClick={handleExport}
          >
            <FontAwesomeIcon icon={faFileExcel} className="icon icon-prefix" />
            {t("button.export")}
          </button>
        </div>
      </div>

      <div className={"card-paper"}>
        <div className={"card-content"}>
          <div className={"default-filter-section"}>
            <div className={"filter-item filter-special type-search"}>
              <div className="input-group prefix-group">
                <span className="input-group-text">
                  <FontAwesomeIcon
                    className={"icon icon-prefix"}
                    icon={faSearch}
                  />
                </span>
                <input
                  defaultValue={tableConfig.search.get()}
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={handleTableSearch}
                />
              </div>
            </div>
          </div>

          <VuiDataTable
            loading={tableConfig.loading.get()}
            columns={tableColumns}
            data={tableData.get()}
            page={tableConfig.page.get()}
            sizePerPage={tableConfig.per_page.get()}
            totalSize={tableConfig.total.get()}
            onTableChange={onTableChange}
          />
        </div>
      </div>
    </>
  );
};

export default Customer;
