import { Helmet } from 'react-helmet-async';
import { HeaderAuth } from '../../../layouts/Auth';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { VuiButton } from '../../../vodea/@vodea-ui/components/VuiButton';
import { useState } from '@hookstate/core/dist';
import useIsMounted from '../../../vodea/utilities/hooks/useIsMounted';
import { useForm } from 'react-hook-form';
import { Inputs, schema } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';
import AuthenticationRepository from '../../../repositories/AuthenticationRepository';
import { useNavigate } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { mapHookErrors, showToast, useQuery } from '../../../vodea/utilities/helpers/global';

const ResetPassword: React.FC<any> = () => {
  const { t } = useTranslation();
  const loading = useState(false);
  const isMounted = useIsMounted();
  const query = useQuery();
  const navigate = useNavigate();

  const { register, handleSubmit, errors, setError } = useForm<Inputs>({
    resolver: yupResolver(schema)
  });

  const onSubmit = handleSubmit(data => {
    loading.set(true);

    AuthenticationRepository.resetPassword({
      token: query.get('token'),
      email: query.get('email'),
      password: data.new_password,
      password_confirmation: data.confirm_new_password
    })
      .then((response: AxiosResponse) => {
        showToast('Success, your password has been successfully changed', 'success');

        loading.set(false);

        navigate('/auth/login');
      })
      .catch((e: AxiosError) => {
        if (isMounted.current && e?.response?.data?.errors) {
          const errors = mapHookErrors(e.response.data.errors);
          Object.keys(errors).forEach((key: any) => setError(key, errors[key]));

          showToast(e.response.data.message, 'error');

          loading.set(false);
        }
      });
  });

  return (
    <>
      <Helmet>
        <title>{t('resetPassword.title')}</title>
      </Helmet>
      <HeaderAuth title={t('resetPassword.header.0')} subtitle={t('resetPassword.header.1')} />

      <form className={'form-wrapper'} onSubmit={onSubmit}>
        <div
          className={clsx({
            'form-group': true,
            error: errors.new_password
          })}
        >
          <label className={'form-label'}>{t('form.newPassword.label')}</label>
          <input type='password' name={'new_password'} className='form-control' ref={register} />
          <label className={'label-help'}>{errors.new_password?.message}</label>
        </div>

        <div
          className={clsx({
            'form-group mb-4': true,
            error: errors.confirm_new_password
          })}
        >
          <label className={'form-label'}>{t('form.confirmNewPassword.label')}</label>
          <input type='password' name={'confirm_new_password'} className='form-control' ref={register} />
          <label className={'label-help'}>{errors.confirm_new_password?.message}</label>
        </div>

        <VuiButton label={t('button.reset')} loading={loading.get()} forSubmit={true} fullWidth={true} />
      </form>
    </>
  );
};

export default ResetPassword;
