import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import VuiBreadcrumb from "../../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import { VuiButton } from "../../../../vodea/@vodea-ui/components/VuiButton";
import { useState } from "@hookstate/core/dist";
import moment from "moment";
import VuiDateRangePicker from "../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker";
import Select from "react-select";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faChevronLeft,
  faKey,
  faSearch,
  faSuitcase,
  faTruck,
  faUser,
  faUserCircle,
  faUserFriends,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";

const statusOptions = [
  {
    key: 1,
    label: "Semua",
  },
  {
    key: 2,
    label: "Menunggu SendPayment",
  },
  {
    key: 3,
    label: "Lunas",
  },
  {
    key: 4,
    label: "Diproses",
  },
  {
    key: 5,
    label: "Dikirim",
  },
  {
    key: 6,
    label: "Sampai",
  },
  {
    key: 7,
    label: "Dibatalkan",
  },
  {
    key: 8,
    label: "Dikembalikan",
  },
  {
    key: 9,
    label: "Gagal",
  },
];

const tableColumns = [
  {
    dataField: "access",
    text: "",
  },
  {
    dataField: "read",
    text: "READ",
    formatter: (cell: any, row: any) => (
      <div>
        <input
          type="checkbox"
          name={row.access + row.read}
          id={row.access + row.read}
        ></input>
      </div>
    ),
  },
  {
    dataField: "create",
    text: "CREATE",
    formatter: (cell: any, row: any) => (
      <div>
        <input
          type="checkbox"
          name={row.access + row.id}
          id={row.access + row.id}
        ></input>
      </div>
    ),
  },
  {
    dataField: "update",
    text: "UPDATE",
    formatter: (cell: any, row: any) => (
      <div>
        <input
          type="checkbox"
          name={row.access + row.id}
          id={row.access + row.id}
        ></input>
      </div>
    ),
  },
  {
    dataField: "delete",
    text: "DELETE",
    formatter: (cell: any, row: any) => (
      <div>
        <input
          type="checkbox"
          name={row.access + row.id}
          id={row.access + row.id}
        ></input>
      </div>
    ),
  },
];

const tableData = [
  {
    id: 1,
    access: "Pesanan",
    read: "read",
    update: "update",
    create: "create",
    delete: "delete",
  },
  {
    id: 2,
    access: "Manajemen Stok",
    read: "read",
    update: "update",
    create: "create",
    delete: "delete",
  },
  {
    id: 3,
    access: "Mutasi Stok",
    read: "read",
    update: "update",
    create: "create",
    delete: "delete",
  },
  {
    id: 4,
    access: "Promosi",
    read: "read",
    update: "update",
    create: "create",
    delete: "delete",
  },
];

const TambahHakAkses: React.FC<any> = () => {
  const { t } = useTranslation();
  const statusSelected = useState(1);

  const filterConfig = useState({
    loading: false,

    date_from: moment().subtract(6, "days").format("YYYY-MM-DD"),
    date_to: moment().format("YYYY-MM-DD"),

    date_compare_from: moment().subtract(14, "days").format("YYYY-MM-DD"),
    date_compare_to: moment().subtract(7, "days").format("YYYY-MM-DD"),
  });

  const handleChangeDate = (start: any, end: any) => {
    filterConfig.date_from.set(start.format("YYYY-MM-DD"));
    filterConfig.date_to.set(end.format("YYYY-MM-DD"));
  };

  const handleChangeCompareDate = (start: any, end: any) => {
    filterConfig.date_compare_from.set(start.format("YYYY-MM-DD"));
    filterConfig.date_compare_to.set(end.format("YYYY-MM-DD"));
  };

  const getData = () => {
    filterConfig.loading.set(true);

    setTimeout(() => {
      filterConfig.loading.set(false);
    }, 1000);
  };

  const breadcrumbList = [
    {
      label: t("Order"),
      link: "/order",
    },
  ];

  const selectedOption = useState("");
  const checkedOption = useState("");

  function onValueChange(e: any) {
    selectedOption.set(e.target.value);
  }

  function onCheckedChange(e: any) {
    checkedOption.set(e.target.value);
  }

  return (
    <>
      <Helmet>
        <title>{t("Order")}</title>
      </Helmet>

      <div className="page-header type-form mb-4">
        <div className="d-flex justify-content-start align-items-center">
          <Link to={"/setting/hakakses"}>
            <div className={"icon-wrapper"}>
              <FontAwesomeIcon
                icon={faChevronLeft}
                className={"icon icon-prefix"}
              />
            </div>
          </Link>

          <div className="">
            <h4 className="title">Tambah Hak Akses</h4>
          </div>
        </div>
      </div>

      <div className="page-content">
        <div className="row">
          <div className="col-4">
            <h5>Informasi</h5>
          </div>
          <div className="col-8">
            <div className="card-paper mb-4">
              <div className="card-content">
                <div className="text-muted mb-2">Terapkan hak akses pada: </div>
                <div className="d-flex justify-content-start align-items-center mb-4">
                  <div className="radio">
                    <label>
                      <input
                        type="radio"
                        value="User"
                        checked={selectedOption.get() === "User"}
                        onChange={onValueChange}
                      />
                      {"           "}User
                    </label>
                  </div>
                  <div className="radio ms-4">
                    <label>
                      <input
                        type="radio"
                        value="Peran"
                        checked={selectedOption.get() === "Peran"}
                        onChange={onValueChange}
                      />
                      {"    "}Peran
                    </label>
                  </div>
                </div>

                <div className={"form-group"}>
                  <label className={"form-label"}>Nama Brand</label>
                  <Select
                    name="vendor"
                    defaultValue={{
                      value: 1,
                      label: "Samsung",
                    }}
                    options={[
                      {
                        value: 1,
                        label: "Samsung",
                      },
                      {
                        value: 2,
                        label: "Apple",
                      },
                    ]}
                    // onChange={changeDiv}
                  />

                  <label className={"label-help"}></label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-4">
            <h5>Hak Akses Peran</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <div className="text-muted">
              <h6>Beranda</h6>
            </div>
          </div>
          <div className="col-8">
            <div className="card-paper mb-4">
              <div className="card-header">
                <div className="col-6">
                  <div className="d-flex justify-content-end">
                    <h6>READ</h6>
                  </div>
                </div>
              </div>
              <div className="card-content">
                <div className="col-6 mb-4 text-muted">
                  <div className="d-flex justify-content-between align-items-center ">
                    <h6>Performa Fullfilment</h6>

                    <input
                      type="checkbox"
                      value="performa_fullfilment"
                      checked={checkedOption.get() === "performa_fullfilment"}
                      onChange={onCheckedChange}
                    />
                  </div>
                </div>

                <div className="col-6 text-muted">
                  <div className="d-flex justify-content-between">
                    <h6>Performa Produk</h6>

                    <input
                      type="checkbox"
                      value="performa_produk"
                      checked={checkedOption.get() === "performa_produk"}
                      onChange={onCheckedChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <h6 className="text-muted">Data Master dan Transaksi</h6>
          </div>
          <div className="col-8">
            <div className="card-paper mb-4">
              <div className="card-content">
                <BootstrapTable
                  wrapperClasses={"table-responsive"}
                  keyField={"id"}
                  data={tableData}
                  columns={tableColumns}
                  filter={filterFactory()}
                  //   pagination={paginationFactory({
                  //     page: 1,
                  //     sizePerPage: 5,
                  //     totalSize: 5,
                  //     showTotal: true,
                  //   })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TambahHakAkses;
