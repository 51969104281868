import * as yup from 'yup';

export type Inputs = {
  new_password: string;
  confirm_new_password: string;
};

export const schema = yup.object().shape({
  new_password: yup.string().min(8).required().label('new password'),
  confirm_new_password: yup
    .string()
    .oneOf([yup.ref('new_password')])
    .required()
    .label('confirm new password')
});
