import { Helmet } from "react-helmet-async";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faPlus,
  faSearch,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { TableChangeHandler } from "react-bootstrap-table-next";
import moment from "moment";
import ReactSwitch from "react-switch";
import { $clone } from "../../../vodea/utilities";
import { useState } from "@hookstate/core/dist";
import VuiBreadcrumb from "../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import { Link, useNavigate } from "react-router-dom";
import VuiActionModal from "../../../vodea/@vodea-ui/components/Modal/VuiActionModal";
import { AxiosError, AxiosResponse } from "axios";
import { showToast } from "../../../vodea/utilities/helpers/global";
import _ from "lodash";
import useIsMounted from "../../../vodea/utilities/hooks/useIsMounted";
import VuiNumberFormat from "../../../vodea/@vodea-ui/components/VuiNumberFormat";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";
import useRoleService from "../../../vodea/utilities/hooks/useRoleService";
import ProductCommerceRepository from "../../../repositories/Brand/Tokopedia/ProductCommerceRepository";
import VuiDataTable from "../../../vodea/@vodea-ui/components/VuiDataTable";
import fileDownload from "js-file-download";

const Product: React.FC<any> = () => {
  const { t } = useTranslation();
  const showActionModal = useState(false);
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const tableDeleteId = useState(0);

  const tableData = useState<any[]>([]);

  const { brand } = useSelector((state: RootState) => state.brand);

  const { isAdministrator, isKeyAccount } = useRoleService();

  const tableConfig = useState({
    search: "",
    page: 1,
    per_page: 10,
    sorted_by: "asc",
    order_by: "created_at",
    total: 0,
    loading: false,
  });

  const tableHandleOnContinue = (e: React.MouseEvent) => {
    ProductCommerceRepository.delete(tableDeleteId.get(), brand?.id)
      .then((response: AxiosResponse) => {
        showToast(
          response.data.message,
          response.data.success ? "success" : "error"
        );
        showActionModal.set(false);
        getTableData();
      })
      .catch((e: AxiosError) => {
        showToast(e?.response?.data.message, "error");
      });
  };

  const changeTableLoading = (val: boolean) => {
    if (isMounted.current) {
      tableConfig.loading.set(val);
    }
  };

  const getTableData = (reset: boolean = false) => {
    if (reset) tableConfig.page.set(1);

    changeTableLoading(true);

    const conf = _.omit($clone(tableConfig.value), "loading");

    let params: any = {};
    Object.keys(conf).forEach((prop) => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    if (isMounted.current) tableData.set([]);

    ProductCommerceRepository.all(params, brand?.id)
      .then((response: any) => {
        if (isMounted.current) {
          tableData.set(response.data.data);
          tableConfig.total.set(response.data.meta.total);
        }
        setTimeout(() => {
          changeTableLoading(false);
        }, 300);
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e?.response?.data.message, "error");

        setTimeout(() => {
          changeTableLoading(false);
        }, 300);
      });
  };

  const tableOnClickDelete = (id: number) => {
    tableDeleteId.set(id);
    showActionModal.set(true);
  };

  const handleChangeStatus = async (row: any) => {
    if (row.is_inactive == "0") {
      tableData
        ?.find((item: any) => item.value.id == row.id)
        ?.set((s: any) => {
          s.is_inactive = "1";
          return s;
        });
      await ProductCommerceRepository.setStatusInActive(brand?.id, row.id);
    } else {
      tableData
        ?.find((item: any) => item.value.id == row.id)
        ?.set((s: any) => {
          s.is_inactive = "0";
          return s;
        });
      await ProductCommerceRepository.setStatusActive(brand?.id, row.id);
    }
  };

  const tableColumns = [
    {
      dataField: "id",
      text: "Produk",
      classes: "valign-middle",
      formatter: (cell: any, row: any) => {
        return (
          <div className="product-wrapper">
            <div className="product-image">
              <img src={row.photo_url} alt={"image-product"} />
            </div>
            <div className="product-name">{row.name}</div>
          </div>
        );
      },
      sort: false,
    },
    {
      dataField: "sku",
      text: "SKU Product",
      classes: "valign-middle",
      formatter: (cell: any) => {
        return <div>{cell ?? "-"}</div>;
      },
    },
    {
      dataField: "price",
      text: "Harga",
      classes: "valign-middle",
      formatter: (cell: any) => {
        return <VuiNumberFormat data={cell} prefix={"Rp. "} />;
      },
    },
    {
      dataField: "updated_at",
      text: "Terakhir Diperbaharui",
      classes: "valign-middle",
      formatter: (cell: any) => {
        return moment(cell).format("D MMMM YYYY h:m A");
      },
    },
    {
      dataField: "is_inactive",
      text: "Status",
      classes: "valign-middle",
      formatter: (cell: any, row: any) => {
        return (
          <div className="switch-wrapper">
            <ReactSwitch
              checked={cell == "0"}
              onColor={"#101C51"}
              onChange={() => handleChangeStatus(row)}
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </div>
        );
      },
    },
    {
      dataField: "action",
      text: "Action",
      headerAlign: "center",
      isDummyField: true,
      align: "center",
      classes: "valign-middle",
      formatter: (cell: any, row: any) => {
        return (
          <div className={"table-btn-wrapper"}>
            <button
              className={"btn btn-primary btn-small"}
              // onClick={() => {
              //   navigate(`${cell.id}`, {
              //     state: $clone(cell),
              //   });
              // }}
              onClick={() => {
                navigate(row.id);
              }}
            >
              View
            </button>
            {isAdministrator() || isKeyAccount() ? (
              <button
                className={"btn btn-danger btn-small"}
                onClick={() => tableOnClickDelete(row.id)}
              >
                Delete
              </button>
            ) : null}
          </div>
        );
      },
    },
  ];

  const onTableChange: TableChangeHandler<any> = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    tableConfig.page.set(page);
    tableConfig.per_page.set(sizePerPage);
    tableConfig.order_by.set(sortField);
    tableConfig.sorted_by.set(sortOrder);

    getTableData();
  };

  const handleTableSearch = _.debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      tableConfig.search.set(e.target.value);
      tableConfig.page.set(1);

      setTimeout(() => {
        changeTableLoading(true);
      }, 200);

      getTableData();
    },
    300
  );

  const handleDownload = () => {
    ProductCommerceRepository.download(brand.id).then((response: any) => {
      const fileName = `${moment().format("YYYY-MM-DD HH:mm:ss")} product.xlsx`;

      fileDownload(response.data, fileName);
    });
  };

  const handleFile = async (event: any) => {
    const file = event.target.files[0];

    const form = new FormData();

    form.append("file", file);

    ProductCommerceRepository.import(brand.id, form)
      .then((res: any) => {
        if (isMounted.current) {
          showToast(t("response.successUpdateData"), "success");
          getTableData();
        }
      })
      .catch((err: any) => {
        if (
          isMounted.current &&
          err.isAxiosError &&
          err.response.data.errors.length > 0
        ) {
          showToast(err.message, "error");
        }
      });
  };

  useEffect(() => {
    getTableData();
  }, []);

  const breadcrumbList = [
    {
      label: t("Produk"),
      link: "/product",
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("Produk")}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={"page-header-component"}>
        <div className="title-action-wrapper">
          <h3 className={"title"}>Produk</h3>
        </div>

        <div className="action-wrapper">
          <div className="btn-wrapper d-grid">
            {tableData.length > 0 ? (
              <button
                type={"button"}
                className={"btn btn-primary"}
                onClick={handleDownload}
              >
                <FontAwesomeIcon
                  className={"icon icon-prefix"}
                  icon={faDownload}
                />
                {t("stock.index.vendor.button.download")}
              </button>
            ) : null}
          </div>

          <div className="btn-wrapper d-grid me-2 ms-2">
            <button
              type={"button"}
              className={"btn btn-info"}
              onClick={() => {
                if (hiddenFileInput.current) {
                  hiddenFileInput.current.click();
                }
              }}
            >
              <FontAwesomeIcon className={"icon icon-prefix"} icon={faUpload} />
              {t("stock.index.vendor.button.upload")}
            </button>

            <input
              ref={hiddenFileInput}
              style={{ display: "none" }}
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={handleFile}
            />
          </div>
          {isAdministrator() || isKeyAccount() ? (
            <Link
              to={"/product/create"}
              type={"button"}
              className={"btn btn-primary action-item"}
            >
              <FontAwesomeIcon className={"icon icon-prefix"} icon={faPlus} />
              Tambah Produk
            </Link>
          ) : null}
          {/* <DropdownButton id="dropdown-basic-button" title="Action">
            <Dropdown.Item onClick = {handleDownload} >Unduh</Dropdown.Item>
            <Dropdown.Item onClick = {handleFile} >Unggah</Dropdown.Item>
          </DropdownButton> */}
        </div>
      </div>

      <div className="card-paper mb-4">
        <div className="card-content">
          <div className={"default-filter-section"}>
            <div className={"filter-item filter-special type-search"}>
              <div className="input-group prefix-group">
                <span className="input-group-text">
                  <FontAwesomeIcon
                    className={"icon icon-prefix"}
                    icon={faSearch}
                  />
                </span>
                <input
                  defaultValue={tableConfig.search.get()}
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={handleTableSearch}
                />
              </div>
            </div>
          </div>

          <VuiDataTable
            loading={tableConfig.loading.get()}
            columns={tableColumns}
            data={tableData.get()}
            page={tableConfig.page.get()}
            sizePerPage={tableConfig.per_page.get()}
            totalSize={tableConfig.total.get()}
            onTableChange={onTableChange}
          />
        </div>
      </div>
      <VuiActionModal
        show={showActionModal.get()}
        onHide={() => showActionModal.set(false)}
        onContinue={tableHandleOnContinue}
      />
    </>
  );
};

export default Product;
