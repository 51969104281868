import { api } from "../vodea/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (brandId: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/brand/${brandId}/order-product`, {
      params,
    });
  },
  show: function (
    brandId: number | string,
    id: number | string,
    params: any = null
  ) {
    return api.get(`${endPoint()}/api/brand/${brandId}/order/${id}`, {
      params,
    });
  },
  changeVendor: function (
    brandId: number | string,
    orderId: number | string,
    payload: any,
    params: any = null
  ) {
    return api.put(
      `${endPoint()}/api/brand/${brandId}/order/${orderId}/change-vendor`,
      payload,
      {
        params,
      }
    );
  },
};

export default Repository;
