import { api } from "../vodea/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  update: function (data: any) {
    return api.post(`${endPoint()}/api/account`, {
      ...data,
    });
  },
  all: function (data: any = null) {
    return api.get(`${endPoint()}/api/account`, {
      ...data,
    });
  },
  updatePassword: function (data: any) {
    return api.post(`${endPoint()}/api/account/change-password`, {
      ...data,
    });
  },
  changeBrand: function (data: any) {
    return api.post(`${endPoint()}/api/account/change-brand`, {
      ...data,
    });
  },
};

export default Repository;
