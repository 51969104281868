import {useSelector} from "react-redux";
import {RootState} from "../../../stores";

const roles = {
    ADMINISTRATOR: 'Administrator',
    KEY_ACCOUNT: 'Key Account',
    BRAND: 'Brand',
    VENDOR: 'Vendor'
}

const useRoleService = () => {
    const user = useSelector((state: RootState) => {
        return state.system.user;
    });

    const isAdministrator = () => {
        return user.role_name === roles.ADMINISTRATOR
    }

    const isKeyAccount = () => {
        return user.role_name === roles.KEY_ACCOUNT
    }

    const isBrand = () => {
        return user.role_name === roles.BRAND
    }

    const isVendor = () => {
        return user.role_name === roles.VENDOR
    }

    return {
        isAdministrator,
        isKeyAccount,
        isBrand,
        isVendor
    }
}

export default useRoleService
