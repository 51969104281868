import LayoutAuth from '../layouts/Auth';
import Login from '../pages/Auth/Login';
import ForgotPassword from '../pages/Auth/ForgotPassword';
import ResetPassword from '../pages/Auth/ResetPassword';

const authRoutes = [
  {
    path: '/auth',
    element: <LayoutAuth />,
    children: [
      { path: '/login', element: <Login /> },
      { path: '/forgot-password', element: <ForgotPassword /> },
      { path: '/reset-password', element: <ResetPassword /> }
    ]
  }
];

export default authRoutes;
