import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import VuiBreadcrumb from "../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import { VuiButton } from "../../../vodea/@vodea-ui/components/VuiButton";
import { useState } from "@hookstate/core/dist";
import moment from "moment";
import VuiDateRangePicker from "../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker";
import Select from "react-select";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";

const statusOptions = [
  {
    key: 1,
    label: "Semua",
  },
  {
    key: 2,
    label: "Belum Selesai",
  },
  {
    key: 3,
    label: "Sedang Berjalan",
  },
  {
    key: 4,
    label: "Selesai",
  },

];

const tableColumns = [
  {
    dataField: "name",
    text: "Nama",
    formatter: (cell: any, row: any) => (
      <Link to={"/promosi/" + row.id}>{row.name}</Link>
    ),
    sort: false,
  },
  {
    dataField: "jenis",
    text: "Jenis",
  },
  {
    dataField: "valid_date",
    text: "Tanggal Berlaku",
    sort: true,
  },
  {
    dataField: "valid_hours",
    text: "Jam Berlaku",
    sort: true,
  },
  {
    dataField: "channel",
    text: "Channel",
    formatter: (cell: any, row: any) => (
      <Link to={"/order/" + row.id}>
        <img
          src={
            row.channel == "Tokopedia"
              ? "./logo-tokopedia.png"
              : "@/public/logo.png"
          }
          style={{ maxWidth: "100px" }}
        ></img>
      </Link>
    ),
    sort: true,
  },
  {
    dataField: "status",
    text: "Status",
    formatter: (cell: any, row: any) => (
      <div
        className={
          row.status == "Lunas" || row.status == "Diproses"
            ? "status-wrapper type-yellow"
            : row.status == "Belum Dibayar"
            ? "status-wrapper type-red"
            : row.status == "Dikirim"
            ? "status-wrapper type-blue"
            : "status-wrapper type-green"
        }
      >
        {row.status}
      </div>
    ),
    sort: true,
  },
];

const tableData = [
  {
    id: 1,
    name: "Lebaran 50%",
    valid_date: "29 Apr 2021, 10:21 ",
    valid_hours: "18:00 - 20:00",
    status: "Diproses",
    jenis: "Toko A",
    channel: "Tokopedia",
  },
  {
    id: 2,
    name: "Lebaran 50%",
    valid_date: "29 Apr 2021, 10:21 ",
    valid_hours: "18:00 - 20:00",
    status: "Belum Dibayar",
    jenis: "Toko A",
    channel: "Tokopedia",
  },
  {
    id: 3,
    name: "Lebaran 50%",
    valid_date: "29 Apr 2021, 10:21 ",
    valid_hours: "18:00 - 20:00",
    status: "Dikirim",
    jenis: "Toko A",
    channel: "Tokopedia",
  },
  {
    id: 4,
    name: "Lebaran 50%",
    valid_date: "29 Apr 2021, 10:21 ",
    valid_hours: "18:00 - 20:00",
    status: "Dikirim",
    jenis: "Toko A",
    channel: "Tokopedia",
  },
  {
    id: 5,
    name: "Lebaran 50%",
    valid_date: "29 Apr 2021, 10:21 ",
    valid_hours: "18:00 - 20:00",
    status: "Dikirim",
    jenis: "Toko A",
    channel: "Tokopedia",
  },
];

const Promosi: React.FC<any> = () => {
  const { t } = useTranslation();
  const statusSelected = useState(1);

  const filterConfig = useState({
    loading: false,

    date_from: moment().subtract(6, "days").format("YYYY-MM-DD"),
    date_to: moment().format("YYYY-MM-DD"),

    date_compare_from: moment().subtract(14, "days").format("YYYY-MM-DD"),
    date_compare_to: moment().subtract(7, "days").format("YYYY-MM-DD"),
  });

  const handleChangeDate = (start: any, end: any) => {
    filterConfig.date_from.set(start.format("YYYY-MM-DD"));
    filterConfig.date_to.set(end.format("YYYY-MM-DD"));
  };

  const handleChangeCompareDate = (start: any, end: any) => {
    filterConfig.date_compare_from.set(start.format("YYYY-MM-DD"));
    filterConfig.date_compare_to.set(end.format("YYYY-MM-DD"));
  };

  const getData = () => {
    filterConfig.loading.set(true);

    setTimeout(() => {
      filterConfig.loading.set(false);
    }, 1000);
  };

  const breadcrumbList = [
    {
      label: t("Order"),
      link: "/order",
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("Order")}</title>
      </Helmet>

      <div className="d-flex justify-content-between align-items-center mb-4 ">
        <h3 className={"title"}>Promosi</h3>
        <Link to={"/promosi/create"}>
          <button className="btn btn-primary">Tambah Promosi</button>
        </Link>
      </div>

      <div className="card-paper mb-4">
        <div className="card-content">
          <div className="card-filter-section">
            <div className="row">
            <div className="col-3 col-md-3">
                <div className="form-group">
                  <label className="form-label">Rentang Tanggal</label>
                  <VuiDateRangePicker
                    startDate={filterConfig.date_from.get()}
                    endDate={filterConfig.date_to.get()}
                    callback={handleChangeDate}
                    useRanges={true}
                  />
                </div>
              </div>
              <div className="col-3 col-md-3">
                <div className="form-group">
                  <label className="form-label">Nama</label>
                  <Select
                    name="vendor"
                    defaultValue={{
                      value: 1,
                      label: "12 produk dipilih",
                    }}
                    options={[
                      {
                        value: 1,
                        label: "12 produk dipilih",
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="col-3 col-md-3">
                <div className="form-group">
                  <label className="form-label">Jenis</label>
                  <Select
                    name="vendor"
                    defaultValue={{
                      value: 1,
                      label: "2 jenis dipilih",
                    }}
                    options={[
                      {
                        value: 1,
                        label: "2 jenis dipilih",
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="col-3 col-md-3">
                <div className="form-group">
                  <label className="form-label">Channel</label>
                  <Select
                    name="channel"
                    defaultValue={{
                      value: 1,
                      label: "4 channel dipilih",
                    }}
                    options={[
                      {
                        value: 1,
                        label: "12 channel dipilih",
                      },
                    ]}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="card-footer">
          <div className="default-btn-action-wrapper">
            <button type={"button"} className="btn btn-secondary">
              Reset
            </button>
            <VuiButton
              forSubmit={false}
              label={"Terapkan"}
              loading={filterConfig.loading.get()}
              onClick={getData}
            />
          </div>
        </div>
      </div>

      <div className="card-paper">
        <ul className="status-options">
          {statusOptions.map((item, key) => {
            return (
              <li
                key={key}
                className={clsx("status-item", {
                  active: statusSelected.get() === item.key,
                })}
                onClick={() => statusSelected.set(item.key)}
              >
                {item.label}
              </li>
            );
          })}
        </ul>
        <div className="card-content">
          <div className={"default-filter-section"}>
            <div className={"filter-item filter-special type-search"}>
              <div className="input-group prefix-group">
                <span className="input-group-text">
                  <FontAwesomeIcon
                    className={"icon icon-prefix"}
                    icon={faSearch}
                  />
                </span>
                <input
                  defaultValue={""}
                  type="text"
                  className="form-control"
                  placeholder="Search"
                />
              </div>
            </div>
          </div>

          <BootstrapTable
            wrapperClasses={"table-responsive"}
            keyField={"id"}
            data={tableData}
            columns={tableColumns}
            filter={filterFactory()}
            pagination={paginationFactory({
              page: 1,
              sizePerPage: 5,
              totalSize: 5,
              showTotal: true,
            })}
          />
        </div>
      </div>
    </>
  );
};

export default Promosi;
