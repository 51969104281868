import _ from "lodash";
import { defaultStatusOptions } from "../../../constants";
import moment from "moment";
import { instanceOfMedia } from "../../interfaces/media";
import { AxiosError } from "axios";
import { mapHookErrors } from "./global";

export const formatSetValueForm = (model: any, data: any) => {
  let returnData: any = {};

  Object.keys(model).forEach(function (key) {
    if (key === "is_visible") {
      returnData[key] = _.find(defaultStatusOptions, (option) => {
        return Number(option.id) === Number(data[key]);
      });
    } else if (key.substr(key.length - 3) === "_id") {
      returnData[key] = data[key.substr(0, key.length - 3)];
    } else if (key.substr(key.length - 4) === "_ids") {
      returnData[key] = data[key.substr(0, key.length - 4) + "s"];
    } else {
      returnData[key] = data[key];
    }
  });

  return returnData;
};

export const formatFormData = (data: any) => {
  let returnData: any = {};

  Object.keys(data).forEach(function (key) {
    returnData[key] = data[key];

    if (data[key] !== null) {
      if (key === "is_visible") {
        returnData[key] = Number(_.get(data[key], "id", 0));
      } else if (key.substr(key.length - 3) === "_id") {
        returnData[key] = _.get(data[key], "id", data[key]);
      } else if (key.substr(key.length - 4) === "_ids") {
        returnData[key] = _.map(data[key], "id");
      } else if (moment.isMoment(data[key])) {
        returnData[key] = data[key].format("YYYY-MM-DD");
      } else if (key.substr(key.length - 6) === "_range") {
        let fromKey = key.substr(0, key.length - 6) + "_from";
        let toKey = key.substr(0, key.length - 6) + "_to";

        returnData[fromKey] = data[key]["start"];
        returnData[toKey] = data[key]["end"];

        if (moment.isMoment(data[key]["start"])) {
          returnData[fromKey] = data[key]["start"].format("YYYY-MM-DD");
        }

        if (moment.isMoment(data[key]["end"])) {
          returnData[toKey] = data[key]["end"].format("YYYY-MM-DD");
        }
      } else if (instanceOfMedia(data[key])) {
        returnData[key] = _.get(data[key], "id", null);
      }
    }
  });

  return returnData;
};

export const handleSaveResponseError = (
  error: AxiosError,
  setError: any,
  showToast: any
) => {
  const tempErrors: any[] = error?.response?.data?.errors;
  if (tempErrors && !_.isEmpty(tempErrors)) {
    const errors = mapHookErrors(tempErrors);
    const flattenError: any[] = Object.values(tempErrors).flat();
    Object.keys(errors).forEach((key: any) => setError(key, errors[key]));
    if (flattenError.length > 0) showToast(flattenError[0], "error");
  } else {
    showToast(error?.response?.data.message, "error");
  }
};

// export const handleSaveResponseSuccess = (
//     response: AxiosError,
//     setError: any,
//     showToast: any
// ) => {
//   const tempErrors: any[] = error?.response?.data?.errors;
//   if (tempErrors && !_.isEmpty(tempErrors)) {
//     const errors = mapHookErrors(tempErrors);
//     const flattenError: any[] = Object.values(tempErrors).flat();
//     Object.keys(errors).forEach((key: any) => setError(key, errors[key]));
//     if (flattenError.length > 0) showToast(flattenError[0], "error");
//   } else {
//     showToast(error?.response?.data.message, "error");
//   }
// };
