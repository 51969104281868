import _ from "lodash";
import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import VuiNumberFormat from "../../../vodea/@vodea-ui/components/VuiNumberFormat";

interface ModalProps {
  show: boolean;
  setShow: any;
  data: any;
  handlePrint: any;
  productData: any;
  totalPrice: string | number;
}

const SendPaymentModal: React.FC<ModalProps> = ({
  show,
  setShow,
  data,
  handlePrint,
  productData,
  totalPrice = 0,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton id="printSendPayment" />
      <Modal.Body id="printable" className="p-0">
        {data && (
          <div id={"payment-modal"}>
            <div className={"text-center text-uppercase"}>
              <h5>Bank Payment Voucher</h5>
            </div>

            <div className={"payment-modal-wrapper"}>
              <h6>PT. Growin Digital Solusi</h6>
              <div className={"payment-modal-border"}>
                <div className={"payment-modal-header"}>
                  <div className={"display-flex mr-16"}>
                    <div className={"mr-4"}>
                      <div>Voucher No.</div>
                      <div>Voucher Date</div>
                      <div>Pay To</div>
                      <div>Due Date</div>
                    </div>
                    <div>
                      <div>: {_.get(data, "number", "")}</div>
                      <div>
                        : {moment(_.get(data, "date")).format("DD-MM-YYYY")}
                      </div>
                      <div>: {_.get(data, "vendor.name", "")}</div>
                      <div>
                        : {moment(_.get(data, "due_date")).format("DD-MM-YYYY")}
                      </div>
                    </div>
                  </div>

                  <div className={"display-flex"}>
                    <div className={"mr-4"}>
                      <div>Bank's ACC. No.</div>
                      <div>Bank's Name</div>
                      <div>Check's/BG No.</div>
                      <div>Order Number</div>
                    </div>
                    <div>
                      <div>
                        :{" "}
                        {`${_.get(data, "account_number", "")} (${_.get(
                          data,
                          "bank.name",
                          ""
                        )})`}
                      </div>
                      <div>: {_.get(data, "account_holder_name", "")}</div>
                      <div>
                        : {_.get(data, "payment_method_description", "")}
                      </div>
                      <div>{}</div>
                    </div>
                  </div>
                </div>
                <div className={"payment-modal-body mt-4"}>
                  <div className="payment-modal-body-header mb-4">
                    <div>Invoice No.</div>
                    <div>Description</div>
                    <div>Quantity</div>
                    <div>Price (Rp.)</div>
                    <div>Total (Rp.)</div>
                  </div>
                  {productData.map((product: any, index: number) => {
                    return (
                      <div className={"product-item"}>
                        {`${index + 1}. ${
                          product?.invoice_product?.product?.name
                        } (${
                          product?.invoice_product?.invoice?.external_number
                        }) - ${product.order_number} - ${
                          product?.quantity - product?.quantity_return
                        } - `}
                        <NumberFormat
                          value={product?.price}
                          defaultValue={product?.price}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"Rp. "}
                          decimalScale={0}
                        />
                        {" - "}
                        <NumberFormat
                          value={
                            product.total_price - product?.total_price_return
                          }
                          defaultValue={
                            product.total_price - product?.total_price_return
                          }
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"Rp. "}
                          decimalScale={0}
                        />
                      </div>
                    );
                  })}

                  <div className={"row my-2"}>
                    <div className={"col-md-8"} />
                    <div
                      className={
                        "col-md-4 display-flex justify-content-around align-items-center"
                      }
                    >
                      <div>Total</div>
                      <VuiNumberFormat
                        data={totalPrice}
                        value={totalPrice}
                        prefix={"Rp. "}
                      />
                    </div>
                  </div>
                </div>
                <div className={"payment-modal-footer"}>
                  <div className={"signature-body"}>
                    <div className={"sign-item"}>
                      <div className={"sign-item-title"}>Prepare</div>
                      <div className={"sign-item-body"}>
                        {_.get(data, "prepares.name", "")}
                      </div>
                    </div>
                    <div className={"sign-item"}>
                      <div className={"sign-item-title"}>Applied By</div>
                      <div className={"sign-item-body"} />
                    </div>
                    <div className={"sign-item"}>
                      <div className={"sign-item-title"}>Checked</div>
                      <div className={"sign-item-body"} />
                    </div>
                    <div className={"sign-item"}>
                      <div className={"sign-item-title"}>Apporoved</div>
                      <div className={"sign-item-body"} />
                    </div>
                    <div className={"sign-item"}>
                      <div className={"sign-item-title"}>Received</div>
                      <div className={"sign-item-body"} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/*{data.invoice && (*/}
        {/*  <div className="d-flex flex-column">*/}
        {/*    <div className="row p-4">*/}
        {/*      <div className="col-6">*/}
        {/*        <div className="mb-2 mt-2">*/}
        {/*          <strong className="fs-6 ">Nomor Invoice</strong> :{" "}*/}
        {/*          {data.invoice.external_number}*/}
        {/*        </div>*/}

        {/*        <div className="d-flex flex-column">*/}
        {/*          /!* <div className="fw-bold fs-5">*/}
        {/*            {data.invoice.external_number}*/}
        {/*          </div> *!/*/}

        {/*          <div className="mb-2">*/}
        {/*            <strong>Diterbitkan atas nama :</strong>{" "}*/}
        {/*          </div>*/}
        {/*          <div className="row">*/}
        {/*            <div className="col-6">*/}
        {/*              <div>*/}
        {/*                <strong>Penjual</strong>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <div className="col-6">*/}
        {/*              <div className="fw-bold">{data.brand.name}</div>*/}
        {/*            </div>*/}
        {/*            <div className="col-6">*/}
        {/*              <div>*/}
        {/*                <strong>Tanggal</strong>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <div className="col-6">*/}
        {/*              <div className="fw-bold">*/}
        {/*                {moment(data.date).format("DD MMMM YYYY")}*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*          </div>*/}

        {/*          /!* <div className="mt-4 mb-3">*/}
        {/*            <div className="row">*/}
        {/*              <div className="col-6 col-md-4 fw-bold">*/}
        {/*                {t("printOrder.table.date")}*/}
        {/*              </div>*/}
        {/*              <div className="col-6 col-md-4">*/}
        {/*                {moment(data.date).format("DD MMMM YYYY")}*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*          </div> *!/*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="col-6">*/}
        {/*        <div className="d-flex flex-column">*/}
        {/*          <div className="mb-2 fw-bold fs-5">Tujuan Pengiriman : </div>*/}
        {/*          <div className="d-flex flex-column">*/}
        {/*            <div className="fw-bold">*/}
        {/*              <>{data.invoice.recipient_name}</>*/}
        {/*            </div>*/}
        {/*            <div className="fw-bold">*/}
        {/*              {data.invoice.recipient_address_full}*/}
        {/*            </div>*/}
        {/*            <div className="fw-bold">*/}
        {/*              {data.invoice.recipient_mobile_phone}*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}

        {/*    <div className="row p-4">*/}
        {/*      {data.invoice.recipient_name ? (*/}
        {/*        <div className="col-12 col-md-6"></div>*/}
        {/*      ) : (*/}
        {/*        ""*/}
        {/*      )}*/}
        {/*      <div className="col-12 col-md-6"></div>*/}
        {/*    </div>*/}

        {/*    <hr />*/}

        {/*    <div className="p-4">*/}
        {/*      <div className="table-responsive w-100">*/}
        {/*        <table className="table table-bordered vendor-table">*/}
        {/*          <thead>*/}
        {/*            <tr>*/}
        {/*              <th>{t("printOrder.table.productName")}</th>*/}
        {/*              <th>{t("printOrder.table.quantity")}</th>*/}

        {/*              <th>{t("printOrder.table.weight")}</th>*/}
        {/*              <th>{t("printOrder.table.price")}</th>*/}

        {/*              <th>{t("printOrder.table.subtotal")}</th>*/}
        {/*            </tr>*/}
        {/*          </thead>*/}
        {/*          <tbody>*/}
        {/*            /!*{products &&*!/*/}
        {/*            /!*  products.map((product: any, productKey: number) => {*!/*/}
        {/*            /!*    return (*!/*/}
        {/*            /!*      <tr key={productKey}>*!/*/}
        {/*            /!*        <td>*!/*/}
        {/*            /!*          <div className="product-info">*!/*/}
        {/*            /!*            <p className="name">{product.name}</p>*!/*/}
        {/*            /!*            <p className="text-muted">*!/*/}
        {/*            /!*              SKU - {product.sku}*!/*/}
        {/*            /!*            </p>*!/*/}
        {/*            /!*          </div>*!/*/}
        {/*            /!*        </td>*!/*/}
        {/*            /!*        <td>{`${product.quantity} pcs`}</td>*!/*/}
        {/*            /!*        <td>{`${product.total_weight} kg `} </td>*!/*/}
        {/*            /!*        /!* <td>Variant Example</td> *!/*!/*/}
        {/*            /!*        <td>*!/*/}
        {/*            /!*          <NumberFormat*!/*/}
        {/*            /!*            value={product.price}*!/*/}
        {/*            /!*            displayType={"text"}*!/*/}
        {/*            /!*            thousandSeparator={"."}*!/*/}
        {/*            /!*            decimalSeparator={","}*!/*/}
        {/*            /!*            decimalScale={0}*!/*/}
        {/*            /!*            prefix={`${t("printOrder.table.currency")} `}*!/*/}
        {/*            /!*          />*!/*/}
        {/*            /!*        </td>{" "}*!/*/}
        {/*            /!*        <td>*!/*/}
        {/*            /!*          <NumberFormat*!/*/}
        {/*            /!*            value={product.total_price}*!/*/}
        {/*            /!*            displayType={"text"}*!/*/}
        {/*            /!*            thousandSeparator={"."}*!/*/}
        {/*            /!*            decimalSeparator={","}*!/*/}
        {/*            /!*            decimalScale={0}*!/*/}
        {/*            /!*            prefix={`${t("printOrder.table.currency")} `}*!/*/}
        {/*            /!*          />*!/*/}
        {/*            /!*        </td>*!/*/}
        {/*            /!*      </tr>*!/*/}
        {/*            /!*    );*!/*/}
        {/*            /!*  })}*!/*/}

        {/*            <tr*/}
        {/*              style={{*/}
        {/*                verticalAlign: "middle",*/}
        {/*              }}*/}
        {/*            >*/}
        {/*              <td colSpan={4}>*/}
        {/*                <div className="total me-5">*/}
        {/*                  {t("printOrder.table.subtotal2")}*/}
        {/*                </div>*/}
        {/*              </td>*/}
        {/*              <td>*/}
        {/*                <div className="fs-6">*/}
        {/*                  <strong>*/}
        {/*                    <NumberFormat*/}
        {/*                      value={_.sumBy(products, "total_price")}*/}
        {/*                      displayType={"text"}*/}
        {/*                      thousandSeparator={"."}*/}
        {/*                      decimalSeparator={","}*/}
        {/*                      decimalScale={0}*/}
        {/*                      prefix={`${t("printOrder.table.currency")} `}*/}
        {/*                    />*/}
        {/*                  </strong>*/}
        {/*                </div>*/}
        {/*              </td>*/}
        {/*            </tr>*/}
        {/*          </tbody>*/}
        {/*        </table>*/}
        {/*      </div>*/}
        {/*    </div>*/}

        {/*    <div className="row p-4">*/}
        {/*      <div className="col-4"></div>*/}
        {/*      <div className="col-12 col-md-8 border border-2 p-3">*/}
        {/*        <div className="row">*/}
        {/*          <div className="col-8 col-md-8">*/}
        {/*            {data.invoice.delivery_type} (Berat {data.total_weight} kg)*/}
        {/*          </div>*/}
        {/*          <div className="col-4 col-md-4">*/}
        {/*            {`${t("printOrder.table.currency")} `}{" "}*/}
        {/*            {data.invoice.shipping_cost.toLocaleString()}*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*        <hr />*/}
        {/*        <div className="row">*/}
        {/*          <div className="col-8 col-md-8 fw-bold fs-6">*/}
        {/*            {t("printOrder.table.subtotal_delivery")}*/}
        {/*          </div>*/}
        {/*          <div className="col-4 col-md-4 fw-bold fs-6">*/}
        {/*            {`${t("printOrder.table.currency")} `}{" "}*/}
        {/*            {data.invoice.shipping_cost.toLocaleString()}*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div className="row p-4">*/}
        {/*      <div className="col-4"></div>*/}
        {/*      <div className="col-12 col-md-8 border border-2 p-3">*/}
        {/*        <div className="row">*/}
        {/*          <div className="col-8 col-md-8 fw-bold fs-5">*/}
        {/*            {t("printOrder.table.total")}*/}
        {/*          </div>*/}
        {/*          <div className="col-4 col-md-4 fw-bold fs-5">*/}
        {/*            {`${t("printOrder.table.currency")} `}{" "}*/}
        {/*            {data.invoice.grand_total.toLocaleString()}*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
      </Modal.Body>

      <Modal.Footer id="printInvoice">
        <div className="m-auto">
          <button className="btn btn-primary" onClick={handlePrint}>
            {t("printOrder.button.print")}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(SendPaymentModal);
