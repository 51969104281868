import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import VuiBreadcrumb from "../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import { useState } from "@hookstate/core/dist";
import moment from "moment";
import VuiDateRangePicker from "../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faFileExcel,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { TableChangeHandler } from "react-bootstrap-table-next";
import { Link, Navigate, useLocation } from "react-router-dom";
import MultipleCheckboxes from "../../../components/MultipleCheckboxes";
import { channelOptions, invoiceStatusConfig } from "../../../constants";
import { $clone } from "../../../vodea/utilities";
import _ from "lodash";
import BrandInvoiceRepository from "../../../repositories/Brand/InvoiceRepository";
import axios, { AxiosError, AxiosResponse } from "axios";
import {
  getChannelConfig,
  getInvoiceStatusConfig,
  showToast,
} from "../../../vodea/utilities/helpers/global";
import useIsMounted from "../../../vodea/utilities/hooks/useIsMounted";
import VuiDataTable from "../../../vodea/@vodea-ui/components/VuiDataTable";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";
import NumberFormat from "react-number-format";
import BrandChannelRepository from "../../../repositories/Brand/ChannelRepository";
import fileDownload from "js-file-download";
import ExportBrandRepository from "../../../repositories/Export/Brand/ExportBrandRepositories";
import Pusher from "pusher-js";
import { passportService } from "../../../vodea/services";
import ExportVendorRepository from "../../../repositories/Export/Vendor/ExportVendorRepositories";
import VuiLoader from "../../../vodea/@vodea-ui/components/VuiLoader";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import VuiNumberFormat from "../../../vodea/@vodea-ui/components/VuiNumberFormat";
import SalesRepository from "../../../repositories/Widget/SalesRepository";
import DateTime from "../../../components/DateTime";

const Invoice: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { brand } = useSelector((state: RootState) => state.brand);
  const tableData = useState<any[]>([]);

  const statusSelected = useState<string>("");

  const selectedChannels = useState<any[]>([]);
  const totalOrder = useState(0);
  const filterConfigLoading = useState<boolean>(false);
  const channelOptions = useState<any[]>([]);
  const [echo, setEcho] = React.useState<any>();

  const handleChangeDate = (start: any, end: any) => {
    tableConfig.date_from.set(start.format("YYYY-MM-DD"));
    tableConfig.date_to.set(end.format("YYYY-MM-DD"));

    getTableData();
  };

  const tableConfig = useState({
    search: "",
    page: 1,
    per_page: 10,
    sorted_by: "asc",
    order_by: "",
    total: 0,
    loading: false,

    // additional
    channel: [],
    date_from: moment().format("YYYY-MM-DD"),
    date_to: moment().format("YYYY-MM-DD"),
  });

  const defaultImageSrc = (ev: any) => {
    ev.target.src =
      "https://png.pngtree.com/png-clipart/20191120/original/pngtree-error-file-icon-vectors-png-image_5053766.jpg";
  };

  const tableColumns = [
    {
      dataField: "external_number",
      text: t("table.invoiceNumber.label"),
      formatter: (cell: any, row: any) => (
        <Link to={`/invoice/${row.id}`}>
          <div className="">{row.external_number}</div>
        </Link>
      ),
      sort: true,
    },
    {
      dataField: "date",
      text: t("table.createdAt.label"),
      sort: true,
      formatter: (cell: any) => {
        return <DateTime data={cell} />;
      },
    },
    {
      dataField: "customer_name",
      text: t("table.customer.label"),
      sort: true,
    },
    {
      dataField: "total",
      text: t("table.total.label"),
      sort: true,
      formatter: (cell: any) => {
        return (
          <NumberFormat
            defaultValue={cell}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            decimalScale={0}
            prefix={"IDR "}
          />
        );
      },
    },
    {
      dataField: "status",
      text: t("table.status.label"),
      formatter: (cell: any, row: any) => (
        <span className={clsx(["badge", getInvoiceStatusConfig(cell)?.badge])}>
          {row.status}
        </span>
      ),
      sort: true,
    },
    {
      dataField: "channel_name",
      text: t("table.channel.label"),
      formatter: (cell: any, row: any) => (
        <img
          className={"channel-icon"}
          // src={getChannelConfig(cell)?.simpleLogo}
          src={
            cell == "Tokopedia"
              ? "/images/logo-tokopedia-simple.png"
              : "@/public/logo.png"
          }
          onError={defaultImageSrc}
        ></img>
      ),
      sort: true,
    },
    {
      dataField: "preorder_deadline",
      text: t("table.preorderDeadline.label"),
      formatter: (cell: any, row: any) => {
        return (
          <div className="display-flex align-items-center">
            {row.preorder_process_day ? (
              <>
                <DateTime data={cell} />
                <div className="status-wrapper for-status-po-detail type-grey ms-lg-3">
                  {`PO: ${row.preorder_process_day} Hari`}
                </div>
              </>
            ) : (
              "-"
            )}
          </div>
        );
      },
      sort: true,
    },
    {
      dataField: "updated_at",
      text: t("table.lastUpdated.label"),
      sort: true,
      formatter: (cell: any) => {
        return cell ? <DateTime data={cell} /> : "-";
      },
    },
  ];

  useEffect(() => {
    // @ts-ignore
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || "", {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      authorizer: (channel: any, options: any) => {
        return {
          authorize: (socketId: any, callback: any) => {
            axios
              .post(
                `${process.env.REACT_APP_PASSPORT_END_POINT}/api/broadcasting/auth`,
                {
                  socket_id: socketId,
                  channel_name: channel.name,
                },
                {
                  headers: {
                    Authorization: `Bearer ${passportService.getAccessToken()}`,
                  },
                }
              )
              .then((response: any) => {
                callback(false, response.data);
              })
              .catch((error: any) => {
                callback(true, error);
              });
          },
        };
      },
    });

    const channel = pusher.subscribe(`Invoice.Status`);

    setEcho(channel);

    return () => {
      channel.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (echo) {
      echo.bind("App\\Events\\InvoiceStatusChanged", (channelData: any) => {
        // tableData
        //   ?.find((data: any) => data.value.id === channelData.invoiceId)
        //   ?.set((s: any) => {
        //     s.status = channelData.status;
        //     return s;
        //   });
        getTableData();
      });
    }
  }, [echo]);

  useEffect(() => {
    (async () => {
      await Promise.all([getChannelOptions()]).then(() => {
        getTableData();
      });
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getTotalOrder = (params: any) => {
    delete params.with;
    delete params.total;
    SalesRepository.getInvoiceCard(brand?.id, {
      ...params,
      status: params.status && encodeURI(params.status),
    }).then((resp: any) => {
      if (isMounted.current) {
        totalOrder.set(resp.data.data.current_period_value);
      }
    });
  };

  const handleExport = () => {
    let params: any = {};
    Object.assign(params, {
      channel: $clone(selectedChannels.get()),
      "date-from": $clone(_.get(tableConfig.get(), "date_from")),
      "date-to": $clone(_.get(tableConfig.get(), "date_to")),
    });
    ExportBrandRepository.exportInvoice(brand.id, params).then(
      (response: any) => {
        const fileName = `${moment().format(
          "YYYY-MM-DD HH:mm:ss"
        )} invoice.xlsx`;

        fileDownload(response.data, fileName);
      }
    );
  };

  const getChannelOptions = async () => {
    await BrandChannelRepository.all(brand.id, {
      per_page: 1000,
    }).then((response: AxiosResponse) => {
      const options = response.data.data;
      if (isMounted.current) {
        channelOptions.set(options);

        selectedChannels.set(
          options.map((item: any) => {
            return item.id;
          })
        );
      }
    });
  };

  const handleTableSearch = _.debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isMounted.current) {
        tableConfig.search.set(e.target.value);
        tableConfig.page.set(1);
      }

      getTableData();
    },
    300
  );

  const handleStatusTabChange = (val: string) => {
    if (isMounted.current) {
      statusSelected.set(val);
    }

    getTableData();
  };

  const onTableChange: TableChangeHandler<any> = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    tableConfig.page.set(page);
    tableConfig.per_page.set(sizePerPage);
    tableConfig.order_by.set(sortField);
    tableConfig.sorted_by.set(sortOrder);

    getTableData();
  };

  const changeTableLoading = (val: boolean) => {
    if (isMounted.current) {
      tableConfig.loading.set(val);
    }
  };

  const pathname = useLocation();
  const [notificationState, setNotificationState] = React.useState<any>(
    pathname?.state
  );

  const getTableData = (reset: boolean = false) => {
    if (reset) tableConfig.page.set(1);

    changeTableLoading(true);

    const conf = _.omit($clone(tableConfig.value), "loading");

    let params: any = {};
    Object.keys(conf).forEach((prop) => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    Object.assign(params, {
      with: ["channel"],
      "date-from": params.date_from,
      "date-to": params.date_to,
      channel: $clone(selectedChannels.get()),
    });

    if (statusSelected.get() === "" || statusSelected.get() === "Dibatalkan") {
      Object.assign(params, {
        "with-canceled": 1,
      });
    }

    if (notificationState) {
      Object.assign(params, {
        notif: notificationState.notificationId,
      });
    }

    if (statusSelected.get()) {
      Object.assign(params, {
        status: statusSelected.get(),
      });
    }

    delete params.date_from;
    delete params.date_to;

    getTotalOrder(params);

    if (isMounted.current) tableData.set([]);

    BrandInvoiceRepository.all(brand.id, params)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          remapTableData(response.data.data).then(() => {
            tableConfig.total.set(response.data.meta.total);
            changeTableLoading(false);
          });
        }
      })
      .catch((e: AxiosError) => {
        if (isMounted.current) {
          if (e.isAxiosError) showToast(e?.response?.data.message, "error");

          changeTableLoading(false);
        }
      });
  };

  const remapTableData = async (data: any[]) => {
    const returnData = data.map((item) => {
      return {
        id: item.id,
        external_number: _.get(item, "external_number", ""),
        date: _.get(item, "date", ""),
        customer_name: _.get(item, "customer_name", ""),
        total: _.get(item, "grand_total", ""),
        status: _.get(item, "status", ""),
        channel_name: _.get(item, "channel_name", ""),
        preorder_deadline: _.get(item, "preorder_deadline", null),
        preorder_process_day: _.get(item, "preorder_process_day", null),
        preorder_process_start: _.get(item, "preorder_process_start", null),
        updated_at: _.get(item, "updated_at", null),
      };
    });

    if (isMounted.current) tableData.set(returnData);
  };

  const breadcrumbList = [
    {
      label: t("invoice.title"),
      link: "/invoice",
    },
  ];

  if (!_.get(brand, "id", null)) {
    return <Navigate to={"/"} replace />;
  }

  return (
    <>
      <Helmet>
        <title>{t("invoice.title")}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={"page-header-component"}>
        <h3 className={"title"}>{t("invoice.title")}</h3>
        <div className="btn-wrapper d-grid">
          <button
            type="button"
            className="btn btn-success"
            onClick={handleExport}
          >
            <FontAwesomeIcon icon={faFileExcel} className="icon icon-prefix" />
            {t("button.export")}
          </button>
        </div>
      </div>

      <div className="card-paper mb-4">
        <div className="card-content">
          <div className="card-filter-section">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">
                    {t("form.dateRange.label")}
                  </label>
                  <VuiDateRangePicker
                    startDate={tableConfig.date_from.get()}
                    endDate={tableConfig.date_to.get()}
                    callback={handleChangeDate}
                    useRanges={true}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">
                    {t("form.channel.label")}
                  </label>
                  <MultipleCheckboxes
                    name={"channels"}
                    options={$clone(channelOptions.get())}
                    values={$clone(selectedChannels.get())}
                    labelOption={"name"}
                    valueOption={"id"}
                    onChange={(values) => {
                      selectedChannels.set(values);
                      getTableData(true);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={"card-chart-wrapper"}>
        <div className={"card-chart mb-4"}>
          {filterConfigLoading.get() ? (
            <VuiLoader />
          ) : (
            <>
              <label className="card-title">
                Total Penjualan
                <OverlayTrigger
                  key={`tooltip-overlay-gmv`}
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-gmv`}>
                      <i>
                        Hasil dari total penjualan sesuai dengan invoice yang
                        ada
                      </i>
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon
                    className={"icon"}
                    icon={faExclamationCircle}
                  />
                </OverlayTrigger>
              </label>

              <label className="card-note">
                <span className={"percentage-stock"}>
                  <VuiNumberFormat
                    data={totalOrder.get()}
                    value={totalOrder.get()}
                    prefix={"IDR "}
                  />
                </span>
              </label>
            </>
          )}
        </div>
      </div>

      <div className="card-paper">
        <ul className="status-options">
          <li
            key={"all"}
            className={clsx("status-item", {
              active: statusSelected.get() === "",
            })}
            onClick={() => handleStatusTabChange("")}
          >
            {t("general.all")}
          </li>
          {invoiceStatusConfig.map((item, key) => {
            return (
              <li
                key={key}
                className={clsx("status-item", {
                  active: $clone(statusSelected.get()) === item.key,
                })}
                onClick={() => handleStatusTabChange(item.key)}
              >
                {item.label}
              </li>
            );
          })}
        </ul>
        <div className="card-content">
          <div className={"default-filter-section"}>
            <div className={"filter-item filter-special type-search"}>
              <div className="input-group prefix-group">
                <span className="input-group-text">
                  <FontAwesomeIcon
                    className={"icon icon-prefix"}
                    icon={faSearch}
                  />
                </span>
                <input
                  defaultValue={tableConfig.search.get()}
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={handleTableSearch}
                />
              </div>
            </div>
          </div>

          <VuiDataTable
            customClasses={"table-invoice"}
            loading={tableConfig.loading.get()}
            columns={tableColumns}
            data={tableData.get()}
            page={tableConfig.page.get()}
            sizePerPage={tableConfig.per_page.get()}
            totalSize={tableConfig.total.get()}
            onTableChange={onTableChange}
          />
        </div>
      </div>
    </>
  );
};

export default Invoice;
