import Geocode from "react-geocode";

const googleApiKey = () => process.env.REACT_APP_GOOGLE_API_KEY;

Geocode.setApiKey(`${googleApiKey()}`);

const useGeocode = () => {
    const fromLatLng = async (lat: any, lng: any) => {
        let returnData = {
            address: '',
            city: '',
            district: '',
            country: '',
            postalCode: ''
        }

        await Geocode.fromLatLng(lat, lng).then(
            (response: any) => {
                returnData.address = response.results[0].formatted_address
                for (let i = 0; i < response.results[0].address_components.length; i++) {
                    for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                        switch (response.results[0].address_components[i].types[j]) {
                            case "administrative_area_level_2":
                                returnData.city = response.results[0].address_components[i].long_name;
                                break;
                            case "administrative_area_level_3":
                                returnData.district = response.results[0].address_components[i].long_name;
                                break;
                            case "country":
                                returnData.country = response.results[0].address_components[i].long_name;
                                break;
                            case "postal_code":
                                returnData.postalCode = response.results[0].address_components[i].long_name;
                                break;
                        }
                    }
                }
            },
            (error: any) => {

            }
        );

        return returnData
    }


    return {
        fromLatLng
    }
}

export default useGeocode
