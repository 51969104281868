import BootstrapTable, {
  ColumnDescription,
  TableChangeType,
  TableChangeState,
} from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import React from "react";
import clsx from "clsx";

// @ts-ignore
import overlayFactory from "react-bootstrap-table2-overlay";

interface VuiDataTableProps {
  customClasses?: string;
  keyField?: string;
  loading: boolean;
  columns: ColumnDescription[];
  data: Array<any>;
  page?: number;
  sizePerPage?: number;
  totalSize?: number;
  onTableChange?: (
    type: TableChangeType,
    newState: TableChangeState<any>
  ) => void;
  withPagination?: boolean;
}

const NoDataIndication = () => (
  <div className="table-indication-section">No data available in table</div>
);

const LoadingIndication = () => (
  <div className="table-loading-section">
    <div className="spinner-border text-secondary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);

const VuiDataTable: React.FC<VuiDataTableProps> = ({
  customClasses = "",
  keyField = "id",
  loading,
  columns,
  data,
  page,
  sizePerPage = 10,
  totalSize,
  onTableChange,
  withPagination = true,
}) => {
  return (
    <>
      {withPagination ? (
        <BootstrapTable
          wrapperClasses={clsx(["table-responsive", customClasses])}
          keyField={keyField}
          remote
          data={data}
          columns={columns}
          overlay={overlayFactory()}
          filter={filterFactory()}
          pagination={paginationFactory({
            page,
            sizePerPage,
            totalSize,
            showTotal: true,
          })}
          onTableChange={onTableChange}
          bodyClasses={clsx({
            loading: loading,
          })}
          noDataIndication={() =>
            loading ? <LoadingIndication /> : <NoDataIndication />
          }
        />
      ) : (
        <BootstrapTable
          wrapperClasses={clsx(["table-responsive", customClasses])}
          keyField={keyField}
          remote
          data={data}
          columns={columns}
          overlay={overlayFactory()}
          filter={filterFactory()}
          onTableChange={onTableChange}
          bodyClasses={clsx({
            loading: loading,
          })}
          noDataIndication={() =>
            loading ? <LoadingIndication /> : <NoDataIndication />
          }
        />
      )}
    </>
  );
};

export default VuiDataTable;
