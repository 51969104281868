import { api, createCancelTokenHandler } from "../vodea/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (params: any = null) {
    return api.get(`${endPoint()}/api/send-payment`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  show: function (id: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/send-payment/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.show.name].handleRequestCancellation()
          .token,
    });
  },
  select: function (params: any = null) {
    return api.get(`${endPoint()}/api/select/send-payment/order`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
          .token,
    });
  },
  create: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/send-payment`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
  update: function (id: number | string, payload: any, params: any = null) {
    return api.put(`${endPoint()}/api/send-payment/${id}`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
          .token,
    });
  },
  delete: function (id: number | string, params: any = null) {
    return api.delete(`${endPoint()}/api/send-payment/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.delete.name].handleRequestCancellation()
          .token,
    });
  },
  getHistoryAccountName: function (params: any = null) {
    return api.get(
      `${endPoint()}/api/select/send-payment/history-account-name`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getHistoryAccountName.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getHistoryAccountNumber: function (params: any = null) {
    return api.get(
      `${endPoint()}/api/select/send-payment/history-account-number`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getHistoryAccountNumber.name
          ].handleRequestCancellation().token,
      }
    );
  },
  export: function (params: any = null) {
    return api.get(`${endPoint()}/api/export/send-payment`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.export.name].handleRequestCancellation()
          .token,
      responseType: "blob",
    });
  },
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);
