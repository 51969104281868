import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useState } from "@hookstate/core";
import moment from "moment";
import VuiBreadcrumb from "../../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import { VuiButton } from "../../../../vodea/@vodea-ui/components/VuiButton";
import VuiDateRangePicker from "../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker";
import { defaultProductFormatter } from "../components/CustomCell";

const tableColumns = [
  {
    dataField: "updated_at",
    text: "Tanggal",
    sort: true,
    classes: "td-custom",
  },
  {
    dataField: "product",
    text: "Produk",
    sort: true,
    formatter: defaultProductFormatter,
  },
  {
    dataField: "SKU",
    text: "SKU Stok",
    sort: true,
    classes: "td-custom",
  },
  {
    dataField: "stock",
    text: "Ketersediaan",
    sort: true,
    classes: "td-custom",
  },
  {
    dataField: "adjust_stock",
    text: "Perubahan",
    sort: true,
    classes: "td-custom",
  },
  {
    dataField: "last_stock",
    isDummyField: true,
    text: "Stok Akhir",
    classes: "td-custom",
    formatter: (cell: any, row: any, rowIndex: number) => {
      const lastStock = Number(row.stock) + Number(row.adjust_stock);
      return <div>{lastStock}</div>;
    },
    editable: false,
  },
  {
    dataField: "remark",
    text: "Alasan",
    sort: true,
    classes: "td-custom",
  },
];

const tableData = [
  {
    id: 1,
    product: "TV 32 inch",
    image:
      "https://www.jing.fm/clipimg/full/125-1258427_telefunken-smart-tv-finlux-tv-32-inch.png",
    SKU: "A01001",
    stock: 201,
    adjust_stock: 10,
    updated_at: "29 Apr 2021, 10:21",
    remark: "Mengedit Stock",
  },
  {
    id: 2,
    product: "Kulkas 2 Pintu",
    image:
      "https://images.samsung.com/is/image/samsung/id-top-mount-freezer-rt38k5030b1-rt38k5030b1-se-rperspectiveblack-240668699?$720_576_PNG$",
    SKU: "A03001",
    stock: 41,
    adjust_stock: 10,
    updated_at: "29 Apr 2021, 10:21",
    remark: "Mengedit Stock",
  },
  {
    id: 3,
    product: "TV 40 inch",
    image:
      "https://www.jing.fm/clipimg/full/125-1258427_telefunken-smart-tv-finlux-tv-32-inch.png",
    SKU: "A03001",
    stock: 20,
    adjust_stock: 10,
    updated_at: "29 Apr 2021, 10:21",
    remark: "Mengedit Stock",
  },
  {
    id: 4,
    product: "Kulkas side by side",
    image:
      "https://images.samsung.com/is/image/samsung/id-top-mount-freezer-rt38k5030b1-rt38k5030b1-se-rperspectiveblack-240668699?$720_576_PNG$",
    SKU: "A04001",
    stock: 301,
    adjust_stock: 10,
    updated_at: "29 Apr 2021, 10:21",
    remark: "Mengedit Stock",
  },
  {
    id: 5,
    product: "TV 50 inch",
    image:
      "https://www.jing.fm/clipimg/full/125-1258427_telefunken-smart-tv-finlux-tv-32-inch.png",
    SKU: "A05001",
    stock: 101,
    adjust_stock: 10,
    updated_at: "29 Apr 2021, 10:21",
    remark: "Mengedit Stock",
  },
];

const MutationStock: React.FC<any> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const filterConfig = useState({
    loading: false,

    date_from: moment().subtract(6, "days").format("YYYY-MM-DD"),
    date_to: moment().format("YYYY-MM-DD"),
  });

  const handleChangeDate = (start: any, end: any) => {
    filterConfig.date_from.set(start.format("YYYY-MM-DD"));
    filterConfig.date_to.set(end.format("YYYY-MM-DD"));
  };

  const breadcrumbList = [
    {
      label: t("Mutation"),
      link: "/mutation",
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("Mutation")}</title>
      </Helmet>
      <VuiBreadcrumb list={breadcrumbList} />
      <div className={"page-header-component"}>
        <h3 className={"title"}>{t("Mutasi Stok")}</h3>
      </div>

      <div className="card-paper mb-4">
        <div className="card-content">
          <div className="card-filter-section">
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label">SKU Stok</label>
                  <Select
                    name="vendor"
                    defaultValue={{
                      value: 1,
                      label: "Stok SKU dipilh",
                    }}
                    options={[
                      {
                        value: 1,
                        label: "Stok SKU dipilh",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">Rentang Tanggal</label>
                  <VuiDateRangePicker
                    startDate={filterConfig.date_from.get()}
                    endDate={filterConfig.date_to.get()}
                    callback={handleChangeDate}
                    useRanges={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card-footer">
          <div className="default-btn-action-wrapper">
            <button type={"button"} className="btn btn-secondary">
              Reset
            </button>
            <VuiButton forSubmit={false} label={"Terapkan"} loading={false} />
          </div>
        </div>
      </div>

      <div className="card-paper mb-4">
        <div className="card-content">
          <div className={"default-filter-section"}>
            <div className={"filter-item filter-special type-search"}>
              <div className="input-group prefix-group">
                <span className="input-group-text">
                  <FontAwesomeIcon
                    className={"icon icon-prefix"}
                    icon={faSearch}
                  />
                </span>
                <input
                  defaultValue={""}
                  type="text"
                  className="form-control"
                  placeholder="Search"
                />
              </div>
            </div>
          </div>

          <BootstrapTable
            wrapperClasses={"table-responsive"}
            classes={"table-custom"}
            keyField={"id"}
            data={tableData}
            columns={tableColumns}
            filter={filterFactory()}
            pagination={paginationFactory({
              page: 1,
              sizePerPage: 5,
              totalSize: 5,
              showTotal: true,
            })}
          />
        </div>
      </div>
    </>
  );
};

export default MutationStock;
