import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";
import { Navigate } from "react-router-dom";
import useRoleService from "../../../vodea/utilities/hooks/useRoleService";

const Dashboard: React.FC<any> = () => {
  const { user } = useSelector((state: RootState) => {
    return state.system;
  });
  const { isAdministrator, isKeyAccount, isVendor } = useRoleService();

  if (isAdministrator() || isKeyAccount()) {
    return <Navigate to={"/dashboard/ringkasan"} replace />;
  } else if (isVendor()) {
    return <Navigate to={"/dashboard/penjualan"} replace />;
  } else {
    return <Navigate to={"/dashboard/ringkasan"} replace />;
  }
};

export default Dashboard;
