import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";

const loadAsyncOptions = async (
  search: string,
  prevOptions: any,
  page: number,
  selectRepository: any,
  selectParams = {},
  selectParentId: string | number
) => {
  let data: any = {};

  if (selectRepository !== null) {
    const params = {
      ...selectParams,
      per_page: 10,
      search: search !== "" ? search : null,
      page: page,
    };

    await (selectParentId
      ? selectRepository.all(selectParentId, params)
      : selectRepository.all(params)
    )
      .then((response: AxiosResponse) => {
        data = response.data;
      })
      .catch((e: AxiosError) => {});
  }

  return {
    options: _.get(data, "data", []),
    hasMore: _.get(data, "meta.current_page") !== _.get(data, "meta.last_page"),
    additional: {
      page: page + 1,
    },
  };
};

export default loadAsyncOptions;
