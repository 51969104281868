import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import VuiBreadcrumb from "../../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import { useState } from "@hookstate/core/dist";
import moment from "moment";
import VuiDateRangePicker from "../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faSearch } from "@fortawesome/free-solid-svg-icons";
import { TableChangeHandler } from "react-bootstrap-table-next";
import { Link, Navigate } from "react-router-dom";
import { orderStatusConfig } from "../../../../constants";
import { $clone } from "../../../../vodea/utilities";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import _ from "lodash";
import useIsMounted from "../../../../vodea/utilities/hooks/useIsMounted";
import {
  getOrderStatusConfig,
  showToast,
} from "../../../../vodea/utilities/helpers/global";
import VendorOrderRepository from "../../../../repositories/Vendor/OrderRepository";
import axios, { AxiosError, AxiosResponse } from "axios";
import VuiDataTable from "../../../../vodea/@vodea-ui/components/VuiDataTable";
import fileDownload from "js-file-download";
import ExportVendorRepository from "../../../../repositories/Export/Vendor/ExportVendorRepositories";
import Pusher from "pusher-js";
import { passportService } from "../../../../vodea/services";
import DateTime from "../../../../components/DateTime";

const VendorOrder: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { vendor } = useSelector((state: RootState) => state.vendor);

  const statusSelected = useState<string>("");

  const handleChangeDate = (start: any, end: any) => {
    tableConfig.date_from.set(start.format("YYYY-MM-DD"));
    tableConfig.date_to.set(end.format("YYYY-MM-DD"));

    getTableData();
  };

  const tableConfig = useState({
    search: "",
    page: 1,
    per_page: 10,
    sorted_by: "asc",
    order_by: "number",
    total: 0,
    loading: false,

    // additional
    channel: [],
    date_from: moment().format("YYYY-MM-DD"),
    date_to: moment().format("YYYY-MM-DD"),
  });

  const tableData = useState<any[]>([]);

  const [echo, setEcho] = React.useState<any>();

  useEffect(() => {
    // @ts-ignore
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || "", {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      authorizer: (channel: any, options: any) => {
        return {
          authorize: (socketId: any, callback: any) => {
            axios
              .post(
                `${process.env.REACT_APP_PASSPORT_END_POINT}/api/broadcasting/auth`,
                {
                  socket_id: socketId,
                  channel_name: channel.name,
                },
                {
                  headers: {
                    Authorization: `Bearer ${passportService.getAccessToken()}`,
                  },
                }
              )
              .then((response: any) => {
                callback(false, response.data);
              })
              .catch((error: any) => {
                callback(true, error);
              });
          },
        };
      },
    });

    const channel = pusher.subscribe(`Order.Status`);

    setEcho(channel);

    return () => {
      channel.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (echo) {
      echo.bind("App\\Events\\OrderStatusChanged", (channelData: any) => {
        getTableData();
      });
    }
  }, [echo]);

  const tableColumns = [
    {
      dataField: "number",
      text: t("table.orderNumber.label"),
      formatter: (cell: any, row: any) => (
        <Link to={`/order/${row.id}`}>
          <div className="">{cell}</div>
        </Link>
      ),
      sort: true,
    },

    {
      dataField: "created_at",
      text: t("table.createdAt.label"),
      sort: true,
      formatter: (cell: any) => {
        return moment(cell).format("D MMMM YYYY h:mm A");
      },
    },
    {
      dataField: "status",
      text: t("table.status.label"),
      formatter: (cell: any, row: any) => (
        <span className={clsx(["badge", getOrderStatusConfig(cell)?.badge])}>
          {row.status}
        </span>
      ),
      sort: true,
    },
    {
      dataField: "invoice_preorder_deadline",
      text: t("table.preorderDeadline.label"),
      formatter: (cell: any, row: any) => {
        return (
          <div className="display-flex align-items-center">
            {row?.invoice_preorder_process_day ? (
              <>
                <DateTime data={cell} />
                <div className="status-wrapper for-status-po-detail type-grey ms-lg-3">
                  {`PO: ${row?.invoice_preorder_process_day} Hari`}
                </div>
              </>
            ) : (
              "-"
            )}
          </div>
        );
      },
      sort: true,
    },
    {
      dataField: "updated_at",
      text: t("table.lastUpdated.label"),
      sort: true,
      formatter: (cell: any) => {
        return moment(cell).format("D MMMM YYYY h:mm A");
      },
    },
  ];

  useEffect(() => {
    getTableData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTableSearch = _.debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      tableConfig.search.set(e.target.value);
      tableConfig.page.set(1);

      getTableData();
    },
    300
  );

  const handleStatusTabChange = (val: string) => {
    statusSelected.set(val);

    getTableData();
  };

  const onTableChange: TableChangeHandler<any> = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    tableConfig.page.set(page);
    tableConfig.per_page.set(sizePerPage);
    tableConfig.order_by.set(sortField);
    tableConfig.sorted_by.set(sortOrder);

    getTableData();
  };

  const changeTableLoading = (val: boolean) => {
    if (isMounted.current) {
      tableConfig.loading.set(val);
    }
  };

  const getTableData = (reset: boolean = false) => {
    if (reset) tableConfig.page.set(1);

    changeTableLoading(true);

    const conf = _.omit($clone(tableConfig.value), "loading");

    let params: any = {};
    Object.keys(conf).forEach((prop) => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    Object.assign(params, {
      "date-from": params.date_from,
      "date-to": params.date_to,
      with: ["vendor", "invoice.channel"],
    });

    if (
      statusSelected.get() === "" ||
      statusSelected.get() === "Dibatalkan" ||
      statusSelected.get() === "Dialihkan"
    ) {
      Object.assign(params, {
        "with-canceled": 1,
        "with-canceled-invoice": 1,
      });
    }

    if (statusSelected.get()) {
      Object.assign(params, {
        status: statusSelected.get(),
      });
    }
    delete params.date_from;
    delete params.date_to;

    if (isMounted.current) tableData.set([]);

    VendorOrderRepository.all(vendor.id, params)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          tableData.set(response.data.data);
          tableConfig.total.set(response.data.meta.total);
        }
        changeTableLoading(false);
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, "error");

        changeTableLoading(false);
      });
  };

  const handleExport = () => {
    let params: any = {};
    Object.assign(params, {
      "date-from": $clone(_.get(tableConfig.get(), "date_from")),
      "date-to": $clone(_.get(tableConfig.get(), "date_to")),
    });
    ExportVendorRepository.exportOrder(vendor.id, params).then(
      (response: any) => {
        const fileName = `${moment().format("YYYY-MM-DD HH:mm:ss")} order.xlsx`;

        fileDownload(response.data, fileName);
      }
    );
  };

  const breadcrumbList = [
    {
      label: t("order.title"),
      link: "/order",
    },
  ];

  if (!_.get(vendor, "id", null)) {
    return <Navigate to={"/"} replace />;
  }

  return (
    <>
      <Helmet>
        <title>{t("order.title")}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={"page-header-component"}>
        <h3 className={"title"}>{t("order.title")}</h3>
        <div className="btn-wrapper d-grid">
          <button
            type="button"
            className="btn btn-success"
            onClick={handleExport}
          >
            <FontAwesomeIcon icon={faFileExcel} className="icon icon-prefix" />
            {t("button.export")}
          </button>
        </div>
      </div>

      <div className="card-paper mb-4">
        <div className="card-content">
          <div className="card-filter-section">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">
                    {t("form.dateRange.label")}
                  </label>
                  <VuiDateRangePicker
                    startDate={tableConfig.date_from.get()}
                    endDate={tableConfig.date_to.get()}
                    callback={handleChangeDate}
                    useRanges={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-paper">
        <ul className="status-options">
          <li
            key={"all"}
            className={clsx("status-item", {
              active: statusSelected.get() === "",
            })}
            onClick={() => handleStatusTabChange("")}
          >
            {t("general.all")}
          </li>
          {orderStatusConfig.map((item, key) => {
            return (
              <li
                key={key}
                className={clsx("status-item", {
                  active: $clone(statusSelected.get()) === item.key,
                })}
                onClick={() => handleStatusTabChange(item.key)}
              >
                {item.label}
              </li>
            );
          })}
        </ul>
        <div className="card-content">
          <div className={"default-filter-section"}>
            <div className={"filter-item filter-special type-search"}>
              <div className="input-group prefix-group">
                <span className="input-group-text">
                  <FontAwesomeIcon
                    className={"icon icon-prefix"}
                    icon={faSearch}
                  />
                </span>
                <input
                  defaultValue={""}
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={handleTableSearch}
                />
              </div>
            </div>
          </div>

          <VuiDataTable
            customClasses={"table-invoice"}
            loading={tableConfig.loading.get()}
            columns={tableColumns}
            data={tableData.get()}
            page={tableConfig.page.get()}
            sizePerPage={tableConfig.per_page.get()}
            totalSize={tableConfig.total.get()}
            onTableChange={onTableChange}
          />
        </div>
      </div>
    </>
  );
};

export default VendorOrder;
