import Modal from "react-bootstrap/Modal";
import { VuiButton } from "../../vodea/@vodea-ui/components/VuiButton";
import React, { useEffect } from "react";
import { IModalReplaceProduct } from "./interface";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import _ from "lodash";
import { IOrder } from "../../interfaces/Order";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import OrderRepository from "../../repositories/Brand/OrderRepository";
import { AxiosError, AxiosResponse } from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../stores";
import { handleSaveResponseError } from "../../vodea/utilities/helpers/form";
import { showToast } from "../../vodea/utilities/helpers/global";
import useIsMounted from "../../vodea/utilities/hooks/useIsMounted";
import VuiNumberFormat from "../../vodea/@vodea-ui/components/VuiNumberFormat";

const orderDefaultValues: IOrder = {
  brand_id: "",
  can_change_vendor: false,
  can_replace_product: false,
  completed_at: null,
  created_at: "",
  date: "",
  deleted_at: null,
  delivered_at: null,
  delivery_type: "",
  grand_total: 0,
  grand_total_return: 0,
  id: "",
  invoice: {
    external_number: "-",
  },
  invoice_id: "",
  number: "",
  order_products: [],
  receiver_name: null,
  ref_order_id: null,
  ref_order_number: null,
  shipping_number: null,
  status: "",
  total_weight: 0,
  updated_at: "",
  vendor: {
    id: "",
    name: "-",
  },
  vendor_id: "",
};

interface IOrderProduct {
  id?: number | string;
  note: string;
  quantity: number | string;
  max_quantity?: number;
  canInput?: boolean;
  name?: string;
}

interface IReplaceOrder {
  order_products: Array<IOrderProduct>;
}

const ModalReplaceProduct: React.FC<IModalReplaceProduct> = ({
  data,
  open,
  onClose,
  productId = null,
}) => {
  const { t } = useTranslation();
  const { brand } = useSelector((state: RootState) => state.brand);
  const [scopedData, setScopedData] =
    React.useState<IOrder>(orderDefaultValues);
  const isMounted = useIsMounted();
  const [loading, setLoading] = React.useState<boolean>(false);

  const { handleSubmit, setValue, watch, control, setError } =
    useForm<IReplaceOrder>({
      mode: "onChange",
      shouldUnregister: true,
      defaultValues: {
        order_products: [],
      },
    });

  const {
    fields: orderProductFields,
    remove: orderProductRemove,
    append: orderProductAppend,
  } = useFieldArray({
    control,
    name: "order_products",
    keyName: "key",
  });

  const watchOrderProduct = watch("order_products");

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setScopedData(data);
      if (productId) {
        const mappingOrderProduct = data.order_products.map((item: any) => {
          return {
            id: item.id,
            name: item.invoice_product.product.name,
            max_quantity: item.quantity,
            quantity: 0,
            note: "",
            canInput: productId === item.invoice_product.product.id,
          };
        });
        setValue("order_products", mappingOrderProduct);
      } else {
        const mappingOrderProduct = data.order_products.map((item: any) => {
          return {
            id: item.id,
            name: item.invoice_product.product.name,
            max_quantity: item.quantity,
            quantity: 0,
            note: "",
            canInput: false,
          };
        });
        setValue("order_products", mappingOrderProduct);
      }
    }
  }, [data]);

  const onSubmit = handleSubmit((formData) => {
    if (isMounted.current) setLoading(true);
    const filterOrderProductData: IOrderProduct[] =
      formData.order_products.filter((data: IOrderProduct) => data.canInput);

    if (filterOrderProductData.length > 0) {
      const orderProducts: IOrderProduct[] = filterOrderProductData.map(
        (item: IOrderProduct) => {
          return {
            id: item.id,
            note: item.note,
            quantity: item.quantity,
          };
        }
      );

      OrderRepository.replaceProduct(brand?.id, scopedData.id, {
        order_products: orderProducts,
      })
        .then((response: AxiosResponse) => {
          if (isMounted.current) setLoading(false);
          showToast(response.data.message, "success");
          onClose(true);
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) setLoading(false);
          handleSaveResponseError(error, setError, showToast);
        });
    }
  });

  return (
    <Modal id="replace-modal" show={open} onHide={() => onClose(false)} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Retur Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={4} sm={12}>
            <div className="form-group mb-4">
              <label className="form-label mb-unset">No Order</label>
              <div className="fw-bold">{scopedData.number}</div>
            </div>
          </Col>
          <Col md={8} sm={12} />
          <Col md={4} sm={12}>
            <div className="form-group mb-4">
              <label className="form-label mb-unset">Vendor</label>
              <div className="fw-bold">{scopedData.vendor?.name}</div>
            </div>
          </Col>
          <Col md={8} sm={12}>
            <div className="form-group mb-4">
              <label className="form-label mb-unset">Invoice</label>
              <div className="fw-bold">
                {scopedData.invoice?.external_number}
              </div>
            </div>
          </Col>
        </Row>

        <div className="react-bootstrap-table table-responsive">
          <table className="table table-bordered table-custom">
            <thead>
              <tr>
                <th />
                <th>Name</th>
                <th>Quantity</th>
                <th>Note</th>
                <th style={{ width: "10vw" }}>Quantity Return</th>
              </tr>
            </thead>
            <tbody>
              {orderProductFields.map((field: any, index: number) => {
                return (
                  <tr key={field.key}>
                    <td>
                      <Controller
                        name={`order_products[${index}].id`}
                        control={control}
                        defaultValue={field.id}
                        render={() => <input type="hidden" />}
                      />
                      <Controller
                        control={control}
                        name={`order_products[${index}].canInput`}
                        defaultValue={field.canInput}
                        render={({ value, onChange }) => {
                          return (
                            <Form.Check
                              id={field.id}
                              type="checkbox"
                              onChange={(event) => {
                                onChange(event.target.checked);
                              }}
                              checked={value}
                            />
                          );
                        }}
                      />
                    </td>
                    <td>
                      <div>{field.name}</div>
                    </td>
                    <td>
                      <VuiNumberFormat
                        data={field.max_quantity}
                        value={field.max_quantity}
                      />
                    </td>
                    <td>
                      <Controller
                        name={`order_products[${index}].note`}
                        control={control}
                        defaultValue={field.note}
                        render={({ name, value, onChange }) => {
                          return (
                            <input
                              disabled={!watchOrderProduct[index].canInput}
                              type="text"
                              name={name}
                              onChange={onChange}
                              value={value}
                              className="form-control"
                            />
                          );
                        }}
                      />
                    </td>
                    <td>
                      <Controller
                        name={`order_products[${index}].quantity`}
                        control={control}
                        defaultValue={field.quantity}
                        render={({ value, onChange }) => {
                          return (
                            <NumberFormat
                              className={"form-control"}
                              allowNegative={false}
                              thousandSeparator={","}
                              decimalSeparator={"."}
                              decimalScale={0}
                              placeholder={"0"}
                              disabled={!watchOrderProduct[index].canInput}
                              defaultValue={value}
                              isAllowed={({ floatValue }: any) => {
                                if (floatValue) {
                                  return (
                                    floatValue <= Number(field?.max_quantity)
                                  );
                                } else {
                                  return true;
                                }
                              }}
                              onValueChange={({ value }) => {
                                onChange(parseFloat(value));
                              }}
                            />
                          );
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm" onClick={() => onClose(false)}>
          {t("button.cancel")}
        </button>
        <VuiButton
          label={t("button.submit")}
          loading={loading}
          onClick={onSubmit}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalReplaceProduct;
