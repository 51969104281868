import { Helmet } from 'react-helmet-async';
import VuiBreadcrumb from '../../../../vodea/@vodea-ui/components/VuiBreadcrumb';
import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { VuiButton } from '../../../../vodea/@vodea-ui/components/VuiButton';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from '@hookstate/core/dist';
import VuiUploadImage from '../../../../vodea/@vodea-ui/components/Forms/VuiUploadImage';
import { informationSchema, passwordSchema, PasswordInputs, InformationInputs } from './validation';
import AccountRepository from '../../../../repositories/AccountRepository';
import { AxiosError, AxiosResponse } from 'axios';
import { mapHookErrors, showToast } from '../../../../vodea/utilities/helpers/global';
import useIsMounted from '../../../../vodea/utilities/hooks/useIsMounted';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../stores';
import _ from 'lodash';
import { updateSystem } from '../../../../stores/system/actions';
import {formatFormData} from "../../../../vodea/utilities/helpers/form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp, faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const AccountProfile: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const { loggedIn, user } = useSelector((state: RootState) => {
    return state.system;
  });

  const informationLoading = useState(false);
  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    errors: informationErrors,
    setError: informationSetError,
    control: informationControl
  } = useForm<InformationInputs>({
    resolver: yupResolver(informationSchema),
    defaultValues: {
      name: user.name,
      email: user.email,
      mobile_phone: user.mobile_phone,
    }
  });
  const onInformationSubmit = informationHandleSubmit(data => {
    informationLoading.set(true);
    const photo = data.photo_id;
    const formData = formatFormData(data);
    formData.email = user.email

    AccountRepository.update(formData)
      .then((response: AxiosResponse) => {
        showToast(response.data.message, 'success');

        dispatch(
          updateSystem({
            loggedIn: loggedIn,
            user: {
              ...user,
              ...formData,
              photo: photo
            }
          })
        );

        informationLoading.set(false);
      })
      .catch((e: AxiosError) => {
        if (isMounted.current && e?.response?.data?.errors) {
          const errors = mapHookErrors(e.response.data.errors);
          Object.keys(errors).forEach((key: any) => {
            informationSetError(key, errors[key]);
          });

          showToast(e.response.data.message, 'error');
        }

        informationLoading.set(false);
      });
  });

  const passwordLoading = useState(false);
  const {
    register: passwordRegister,
    handleSubmit: passwordHandleSubmit,
    errors: passwordErrors,
    setError: passwordSetError,
    reset: passwordReset
  } = useForm<PasswordInputs>({
    resolver: yupResolver(passwordSchema)
  });
  const onPasswordSubmit = passwordHandleSubmit(data => {
    passwordLoading.set(true);

    AccountRepository.updatePassword(data)
      .then((response: AxiosResponse) => {
        showToast(response.data.message, 'success');

        passwordLoading.set(false);
        passwordReset();
      })
      .catch((e: AxiosError) => {
        if (isMounted.current && e?.response?.data?.errors) {
          const errors = mapHookErrors(e.response.data.errors);
          Object.keys(errors).forEach((key: any) => {
            passwordSetError(key, errors[key]);
          });

          showToast(e.response.data.message, 'error');
        }

        passwordLoading.set(false);
      });
  });

  const performanceData = [
    {
      name: 'SLA',
      info: 'Ketepatan terima pesanan dan pengiriman.',
      value: '85.35%',
      compare: '1.75%',
      type: 1
    },
    {
      name: 'In-Stock Rate',
      info: 'Jumlah rata-rata ketersediaan stok.',
      value: '81.5',
      compare: '2.12%',
      type: 2
    },
    {
      name: 'Vendor Performance',
      info: 'Rating rata-rata yang diberikan GROWIN.',
      value: '4.7',
      compare: '0.5%',
      type: 1
    },
  ]

  const breadcrumbList = [
    {
      label: t('Account'),
      link: '/account'
    },
    {
      label: t('Profile'),
      link: '/account/profile'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{t('Profile')}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={'page-header-component'}>
        <h3 className={'title'}>{t('Profile')}</h3>
      </div>

      <div className={'default-page-layout type-profile'}>
        <div className={'information-section'}>

          {
            (user.role_id === 2) ? (
                <div className="card-paper mb-4">
                  <div className="card-header">
                    <h6 className="card-header-title">Performa</h6>
                  </div>
                  <div className="card-content">
                    <div className="card-chart-wrapper type-compact">
                      {
                        performanceData.map((item, key) => {
                          return (
                              <div className={clsx('card-chart')} key={key}>
                                <label className="card-title">
                                  {item.name}
                                  <OverlayTrigger
                                      key={`tooltip-overlay-${item.name}`}
                                      placement={'top'}
                                      overlay={
                                        <Tooltip id={`tooltip-${item.name}`}>
                                          <i>{item.info}</i>
                                        </Tooltip>
                                      }
                                  >
                                    <FontAwesomeIcon className={'icon'} icon={faExclamationCircle}/>
                                  </OverlayTrigger>
                                </label>
                                <h3 className="card-value">
                                  {item.value}
                                </h3>

                                <label className="card-note">
                                                        <span className={clsx('percentage', {
                                                          'positive': item.type === 1,
                                                          'negative': item.type === 2,
                                                        })}><FontAwesomeIcon className={'icon'}
                                                                             icon={(item.type === 1) ? faArrowUp : faArrowDown}/> {item.compare}</span> vs
                                  bulan lalu
                                </label>
                              </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
            ) : ''
          }

          <div className={'card-paper'}>
            <div className={'card-header'}>
              <h6 className={'card-header-title'}>{t('Information')}</h6>
            </div>
            <div className={'card-content'}>
              <form className={'form-wrapper'} onSubmit={onInformationSubmit}>
                <div className={'row'}>
                  <div className={'col-md-12'}>
                    <div
                      className={clsx({
                        'form-group': true,
                        error: informationErrors.name
                      })}
                    >
                      <label className={'form-label'}>Name</label>
                      <input type='text' name='name' className='form-control' ref={informationRegister} />
                      <label className={'label-help'}>{informationErrors.name?.message}</label>
                    </div>
                  </div>
                  <div className={'col-md-6'}>
                    <div
                        className={clsx({
                          'form-group': true,
                        })}
                    >
                      <label className={'form-label'}>Email</label>
                      <input type='text' className='form-control' defaultValue={user.email} disabled/>
                    </div>
                  </div>
                  <div className={'col-md-6'}>
                    <div
                        className={clsx({
                          'form-group': true,
                          error: informationErrors.mobile_phone
                        })}
                    >
                      <label className={'form-label'}>Phone Number</label>
                      <input type='text' name='mobile_phone' className='form-control' ref={informationRegister} />
                      <label className={'label-help'}>{informationErrors.mobile_phone?.message}</label>
                    </div>
                  </div>
                  <div className={'col-md-6'}>
                    <div
                      className={clsx({
                        'form-group': true
                      })}
                    >
                      <label className={'form-label'}>Role</label>
                      <input type='text' name='role' className='form-control' defaultValue={user.role_name} disabled />
                    </div>
                  </div>
                </div>

                <div className={'form-btn-wrapper'}>
                  <VuiButton forSubmit={true} label={'Save'} loading={informationLoading.get()} />
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className={'profile-picture-section'}>
          <div className={'card-paper floating-content'}>
            <div className={'card-header'}>
              <h6 className={'card-header-title'}>{t('Profile Picture')}</h6>
            </div>
            <div className={'card-content'}>
              <div
                className={clsx({
                  'form-group': true,
                  error: informationErrors.photo_id
                })}
              >
                <Controller
                  name='photo_id'
                  control={informationControl}
                  defaultValue={_.get(user, 'photo', null)}
                  render={({ value, onChange }) => <VuiUploadImage defaultValue={value} onChange={onChange} />}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={'password-section'}>
          <div className={'card-paper'}>
            <div className={'card-header'}>
              <h6 className={'card-header-title'}>{t('Password')}</h6>
            </div>
            <div className={'card-content'}>
              <form className={'form-wrapper'} onSubmit={onPasswordSubmit}>
                <div className={'row'}>
                  <div className={'col-md-12'}>
                    <div className={'row'}>
                      <div className={'col-md-6'}>
                        <div
                          className={clsx({
                            'form-group': true,
                            error: passwordErrors.old_password
                          })}
                        >
                          <label className={'form-label'}>Current Password</label>
                          <input type='password' name='old_password' className='form-control' ref={passwordRegister} />
                          <label className={'label-help'}>{passwordErrors.old_password?.message}</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={'col-md-12'}>
                    <div className={'row'}>
                      <div className={'col-md-6'}>
                        <div
                          className={clsx({
                            'form-group': true,
                            error: passwordErrors.password
                          })}
                        >
                          <label className={'form-label'}>New Password</label>
                          <input type='password' name='password' className='form-control' ref={passwordRegister} />
                          <label className={'label-help'}>{passwordErrors.password?.message}</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={'col-md-12'}>
                    <div className={'row'}>
                      <div className={'col-md-6'}>
                        <div
                          className={clsx({
                            'form-group': true,
                            error: passwordErrors.password_confirmation
                          })}
                        >
                          <label className={'form-label'}>Confirm New Password</label>
                          <input type='password' name='password_confirmation' className='form-control' ref={passwordRegister} />
                          <label className={'label-help'}>{passwordErrors.password_confirmation?.message}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={'form-btn-wrapper'}>
                  <VuiButton forSubmit={true} label={'Save'} loading={passwordLoading.get()} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountProfile;
