import * as yup from "yup";
import "yup-phone";

export type InformationInputs = {
  product_id: string | number;
  quantity: number;
};

export const informationSchema = yup.object().shape({
  product_id: yup.string().required(),
  quantity: yup.number().required(),
});

export const InformationBaseModel: InformationInputs = {
  product_id: "",
  quantity: 0,
};
