import { api, createCancelTokenHandler } from "../../../vodea/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  exportOrder: function (vendorId: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/vendor/${vendorId}/export/order`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.exportOrder.name
        ].handleRequestCancellation().token,
      responseType: "blob",
    });
  },
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);
