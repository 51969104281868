import i18n from 'i18next';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import translationEN from './en/translation.json';
import translationID from './id/translation.json';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: translationEN
    },
    id: {
        translation: translationID
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(Backend)
    .init({
        resources,
        lng: "id", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        backend: {
            backends: [
                LocalStorageBackend,
            ],
            backendOptions: [
                {
                    expirationTime: 7 * 24 * 60 * 60 * 1000 // 7 days
                },
            ]
        }
    });

export default i18n;
