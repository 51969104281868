import React from "react";
import { ColumnFormatter } from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import { State } from "@hookstate/core/dist";

export const defaultActionFormatter: ColumnFormatter<any> = (
  cell,
  row,
  rowIndex,
  formatExtraData
) => {
  const formatPermission = formatExtraData.permission || "";
  const handleDelete = (e: React.MouseEvent<HTMLElement>) => {
    if (typeof formatExtraData?.actionDelete == "function") {
      formatExtraData?.actionDelete(row.id);
    }
  };

  return (
    <div className={"table-btn-wrapper"}>
      {formatExtraData.permissions?.includes(`${formatPermission}.update`) ||
      formatExtraData.permission === "any" ? (
        <Link
          className={"btn btn-primary btn-small"}
          to={`${formatExtraData?.location}/${row.id}`}
        >
          View
        </Link>
      ) : null}

      {formatExtraData.permissions?.includes(`${formatPermission}.destroy`) ||
      formatExtraData.permission === "any" ? (
        <button
          type={"button"}
          className={"btn btn-danger btn-small"}
          onClick={handleDelete}
        >
          Delete
        </button>
      ) : null}
    </div>
  );
};

export const defaultInputFormatter: ColumnFormatter<any> = (
  cell,
  row,
  rowIndex,
  formatExtraData
) => {
  return (
    <div className={"form-group"}>
      <input
        type="text"
        name="number"
        className="form-control"
        ref={`${formatExtraData?.location}`}
      />
    </div>
  );
};

export const $clone = (value: any) => {
  if (!value) {
    return [];
  }

  return JSON.parse(JSON.stringify(value));
};

export const $cloneState = (value: State<any>) => {
  return $clone(value.value);
};
