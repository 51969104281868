import { api, createCancelTokenHandler } from "../../vodea/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (vendorId: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/vendor/${vendorId}/order`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  show: function (
    vendorId: number | string,
    id: number | string,
    params: any = null
  ) {
    return api.get(`${endPoint()}/api/vendor/${vendorId}/order/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.show.name].handleRequestCancellation()
          .token,
    });
  },
  update: function (
    vendorId: number | string,
    orderId: string | number,
    status: string,
    payload: any = null,
    params: any = null
  ) {
    return api.put(
      `${endPoint()}/api/vendor/${vendorId}/order/${orderId}/status/${status}`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
            .token,
      }
    );
  },
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);
