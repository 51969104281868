import * as yup from 'yup';
import 'yup-phone';

export type InformationInputs = {
  name: string;
  parent_id: string;
};

export const informationSchema = yup.object().shape({
  name: yup.string().required(),
  parent_id: yup.mixed(),
});

export const InformationBaseModel: InformationInputs = {
  name: '',
  parent_id: ''
};

