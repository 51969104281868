import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, Navigate, useLocation } from "react-router-dom";
import useIsMounted from "../../../../vodea/utilities/hooks/useIsMounted";
import { useState } from "@hookstate/core";
import BrandRoleRepository from "../../../../repositories/Brand/RoleRepository";
import VendorRoleRepository from "../../../../repositories/Vendor/RoleRepository";
import { AxiosError, AxiosResponse } from "axios";
import { showToast } from "../../../../vodea/utilities/helpers/global";
import moment from "moment";
import { $clone, defaultActionFormatter } from "../../../../vodea/utilities";
import _ from "lodash";
import { TableChangeHandler } from "react-bootstrap-table-next";
import { Helmet } from "react-helmet-async";
import VuiBreadcrumb from "../../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import VuiDataTable from "../../../../vodea/@vodea-ui/components/VuiDataTable";
import VuiActionModal from "../../../../vodea/@vodea-ui/components/Modal/VuiActionModal";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import useRoleService from "../../../../vodea/utilities/hooks/useRoleService";
import RoleRepository from "../../../../repositories/RoleRepository";

const SettingRole: React.FC<any> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isMounted = useIsMounted();
  const tableDeleteId = useState(0);
  const showActionModal = useState(false);
  const { brand } = useSelector((state: RootState) => state.brand);
  const { vendor } = useSelector((state: RootState) => state.vendor);
  const { isAdministrator, isBrand, isVendor } = useRoleService();

  const tableOnClickDelete = (id: number) => {
    tableDeleteId.set(id);
    showActionModal.set(true);
  };

  const tableHandleOnContinue = (e: React.MouseEvent) => {
    (isVendor()
      ? VendorRoleRepository.delete(vendor.id, tableDeleteId.get())
      : isBrand()
      ? BrandRoleRepository.delete(brand.id, tableDeleteId.get())
      : RoleRepository.delete(tableDeleteId.get())
    )
      .then((response: AxiosResponse) => {
        showToast(
          response.data.message,
          response.data.success ? "success" : "error"
        );
        showActionModal.set(false);
        getTableData(true);
      })
      .catch((e: AxiosError) => {
        showToast(e?.response?.data.message, "error");
      });
  };

  const tableConfig = useState({
    search: "",
    page: 1,
    per_page: 10,
    sorted_by: "asc",
    order_by: "",
    total: 0,
    loading: false,
  });

  const tableData = useState([]);

  const tableColumns = [
    {
      dataField: "name",
      text: t("table.name.label"),
      sort: true,
    },
    {
      dataField: "updated_at",
      text: t("table.lastUpdated.label"),
      sort: true,
      formatter: (cell: any) => {
        return moment(cell).format("D MMMM YYYY h:mm A");
      },
    },
    {
      dataField: "action",
      text: "Action",
      headerAlign: "center",
      isDummyField: true,
      align: "center",
      formatter: defaultActionFormatter,
      formatExtraData: {
        location: location.pathname,
        actionDelete: tableOnClickDelete,
        permission: "any",
      },
    },
  ];

  useEffect(() => {
    getTableData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTableSearch = _.debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      tableConfig.search.set(e.target.value);
      tableConfig.page.set(1);

      getTableData();
    },
    300
  );

  const onTableChange: TableChangeHandler<any> = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    tableConfig.page.set(page);
    tableConfig.per_page.set(sizePerPage);
    tableConfig.order_by.set(sortField);
    tableConfig.sorted_by.set(sortOrder);

    getTableData();
  };

  const changeTableLoading = (val: boolean) => {
    if (isMounted.current) {
      tableConfig.loading.set(val);
    }
  };

  const getTableData = (reset: boolean = false) => {
    if (reset) tableConfig.page.set(1);

    changeTableLoading(true);

    const conf = _.omit($clone(tableConfig.value), "loading");

    let params: any = {};
    Object.keys(conf).forEach((prop) => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    if (isMounted.current) tableData.set([]);

    (isVendor()
      ? VendorRoleRepository.all(vendor.id, params)
      : isBrand()
      ? BrandRoleRepository.all(brand.id, params)
      : RoleRepository.all(params)
    )
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          tableData.set(response.data.data);
          tableConfig.total.set(response.data.meta.total);
        }

        changeTableLoading(false);
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e?.response?.data.message, "error");

        changeTableLoading(false);
      });
  };

  const breadcrumbList = [
    {
      label: t("settings.title"),
      link: "/settings",
    },
    {
      label: t("settings.role.title"),
      link: "/settings/role",
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          {t("settings.title")} {t("settings.role.title")}
        </title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={"page-header-component"}>
        <h3 className={"title"}>
          {t("settings.title")} | <strong>{t("settings.role.title")}</strong>
        </h3>
        <div className={"btn-wrapper"}>
          <Link to={"/settings/role/create"}>
            <button type={"button"} className={"btn btn-primary"}>
              <FontAwesomeIcon className={"icon icon-prefix"} icon={faPlus} />
              {t("settings.role.button.create")}
            </button>
          </Link>
        </div>
      </div>

      <div className={"card-paper"}>
        <div className={"card-content"}>
          <div className={"default-filter-section"}>
            <div className={"filter-item filter-special type-search"}>
              <div className="input-group prefix-group">
                <span className="input-group-text">
                  <FontAwesomeIcon
                    className={"icon icon-prefix"}
                    icon={faSearch}
                  />
                </span>
                <input
                  defaultValue={tableConfig.search.get()}
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={handleTableSearch}
                />
              </div>
            </div>
          </div>

          <VuiDataTable
            loading={tableConfig.loading.get()}
            columns={tableColumns}
            data={tableData.get()}
            page={tableConfig.page.get()}
            sizePerPage={tableConfig.per_page.get()}
            totalSize={tableConfig.total.get()}
            onTableChange={onTableChange}
          />
        </div>
      </div>

      <VuiActionModal
        show={showActionModal.get()}
        onHide={() => showActionModal.set(false)}
        onContinue={tableHandleOnContinue}
      />
    </>
  );
};

export default SettingRole;
