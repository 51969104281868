import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import VuiBreadcrumb from "../../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import { useState } from "@hookstate/core";
import BrandRepository from "../../../../repositories/BrandRepository";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  InformationInputs,
  informationSchema,
  InformationBaseModel,
} from "./validation";
import {
  formatFormData,
  formatSetValueForm,
} from "../../../../vodea/utilities/helpers/form";
import { AxiosError, AxiosResponse } from "axios";
import {
  mapHookErrors,
  showToast,
} from "../../../../vodea/utilities/helpers/global";
import useIsMounted from "../../../../vodea/utilities/hooks/useIsMounted";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import VuiActionForm from "../../../../vodea/@vodea-ui/components/Forms/VuiActionForm";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import _ from "lodash";
import { Navigate } from "react-router-dom";
import PinAddress from "../../../../components/PinAddress";
import { Form, Tab, Tabs } from "react-bootstrap";
import TabUser from "./tabUser";

const SettingBrand: React.FC<any> = () => {
  const { t } = useTranslation();
  const { brand } = useSelector((state: RootState) => state.brand);
  const isMounted = useIsMounted();

  useEffect(() => {
    if (brand.id) {
      (async () => {
        await Promise.all([getData()]).catch();
      })();
    }
  }, []); // eslint-disable-line

  const getData = async () => {
    const params = {
      with: ["channels"],
    };

    BrandRepository.show(brand.id, params)
      .then((res: AxiosResponse) => {
        const data = res.data.data;
        const formattedData = formatSetValueForm(InformationBaseModel, data);

        _.forEach(formattedData, (value, name: any) => {
          informationSetValue(name, value);
        });

        if (data.channels.length) {
          const channels = _.map(data.channels, (channel) => {
            return {
              channel_id: channel.pivot.channel_id,
              tokopedia_shop_id: channel.pivot.tokopedia_shop_id,
              channel_meta: channel.pivot.channel_meta,
            };
          });

          informationSetValue("channels", channels);
        }
      })
      .catch((e: AxiosError) => {
        showToast(e?.response?.data.message, "error");
      });
  };

  const informationLoading = useState(false);
  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    errors: informationErrors,
    setValue: informationSetValue,
    setError: informationSetError,
    watch: informationWatch,
    control: informationControl,
  } = useForm<InformationInputs>({
    mode: "onChange",
    resolver: yupResolver(informationSchema),
  });

  const watchLatLng = informationWatch(["lat", "lng"]);

  const handleChangePinAddress = (position: any) => {
    informationSetValue("lat", position.lat);
    informationSetValue("lng", position.lng);
    informationSetValue("city", `${position.city}, ${position.district}`);
    informationSetValue("address", position.address);
    informationSetValue("postal_code", position.postal_code);
  };

  const onInformationSubmit = informationHandleSubmit((data) => {
    if (isMounted.current) informationLoading.set(true);
    const formData = formatFormData(data);

    BrandRepository.update(brand.id, formData)
      .then((response: AxiosResponse) => {
        showToast(t("response.successUpdateData"), "success");

        if (isMounted.current) informationLoading.set(false);
      })
      .catch((e: AxiosError) => {
        if (isMounted.current && e?.response?.data?.errors) {
          const errors = mapHookErrors(e.response.data.errors);
          Object.keys(errors).forEach((key: any) => {
            informationSetError(key, errors[key]);
          });

          showToast(e.response.data.message, "error");
        }

        if (isMounted.current) informationLoading.set(false);
      });
  });

  const breadcrumbList = [
    {
      label: t("settings.title"),
      link: "/settings",
    },
    {
      label: t("settings.brand.title"),
      link: "/settings/brand",
    },
  ];

  if (!_.get(brand, "id", null)) {
    return <Navigate to={"/settings"} replace />;
  }

  return (
    <>
      <Helmet>
        <title>
          {t("settings.title")} {t("settings.brand.title")}
        </title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div id={"setting-section"}>
        <div className={"page-header-component"}>
          <h3 className={"title"}>
            {t("settings.title")} | <strong>{t("settings.brand.title")}</strong>
          </h3>
        </div>

        <form className={"form-wrapper"} onSubmit={onInformationSubmit}>
          <Tabs defaultActiveKey="general" variant={"pills"} className="mb-3">
            <Tab eventKey="general" title={t("general.general")}>
              <div className={"default-page-layout"}>
                <div>
                  <div className={"card-setting"}>
                    <div className={"inner-content"}>
                      <FontAwesomeIcon
                        className={"icon icon-prefix type-setting"}
                        icon={faBookmark}
                      />
                      <h3 className={"title"}>{t("settings.brand.title")}</h3>
                    </div>
                  </div>
                </div>

                <div className="information-section">
                  <div className={"card-paper"}>
                    <div className={"card-header"}>
                      <h6 className={"card-header-title"}>
                        {t("general.information")}
                      </h6>
                    </div>

                    <div className={"card-content"}>
                      <div className={"col-md-12"}>
                        <div
                          className={clsx({
                            "form-group": true,
                            error: informationErrors.name,
                          })}
                        >
                          <label className={"form-label"}>
                            {t("form.name.label")}
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            ref={informationRegister}
                          />
                          <label className={"label-help"}>
                            {informationErrors.name?.message}
                          </label>
                        </div>
                      </div>

                      <div className={"row"}>
                        <div className={"col-md-6"}>
                          <div
                            className={clsx({
                              "form-group": true,
                              error: informationErrors.telephone,
                            })}
                          >
                            <label className={"form-label"}>
                              {t("form.telephone.label")}
                            </label>
                            <input
                              type="text"
                              name="telephone"
                              className="form-control"
                              ref={informationRegister}
                            />
                            <label className={"label-help"}>
                              {informationErrors.telephone?.message}
                            </label>
                          </div>
                        </div>
                        <div className={"col-md-6"}>
                          <div
                            className={clsx({
                              "form-group": true,
                              error: informationErrors.fax,
                            })}
                          >
                            <label className={"form-label"}>
                              {t("form.fax.label")}
                            </label>
                            <input
                              type="text"
                              name="fax"
                              className="form-control"
                              ref={informationRegister}
                            />
                            <label className={"label-help"}>
                              {informationErrors.fax?.message}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div
                            className={clsx({
                              "form-group": true,
                              error: informationErrors.city,
                            })}
                          >
                            <label className={"form-label"}>
                              {t("form.city.label")}
                            </label>
                            <input
                              type="text"
                              name="city"
                              className="form-control"
                              ref={informationRegister}
                            />
                            <label className={"label-help"}>
                              {informationErrors.city?.message}
                            </label>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div
                            className={clsx({
                              "form-group": true,
                              error: informationErrors.postal_code,
                            })}
                          >
                            <label className={"form-label"}>
                              {t("form.postalCode.label")}
                            </label>
                            <input
                              type="text"
                              name="postal_code"
                              className="form-control"
                              ref={informationRegister}
                            />
                            <label className={"label-help"}>
                              {informationErrors.postal_code?.message}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div
                        className={clsx({
                          "form-group": true,
                          error: informationErrors.address,
                        })}
                      >
                        <label className={"form-label"}>
                          {t("form.address.label")}
                        </label>
                        <textarea
                          name="address"
                          className="form-control"
                          rows={4}
                          ref={informationRegister}
                        ></textarea>
                        <label className={"label-help"}>
                          {informationErrors.address?.message}
                        </label>
                      </div>

                      <div
                        className={clsx({
                          "form-group": true,
                          error: informationErrors.lat || informationErrors.lng,
                        })}
                      >
                        <label className={"form-label"}>
                          {t("form.pinAddress.label")}
                        </label>
                        <input
                          type="hidden"
                          name="lat"
                          className="form-control"
                          ref={informationRegister}
                        />
                        <input
                          type="hidden"
                          name="lng"
                          className="form-control"
                          ref={informationRegister}
                        />
                        <PinAddress
                          lat={watchLatLng.lat}
                          lng={watchLatLng.lng}
                          callBack={handleChangePinAddress}
                        />
                        {informationErrors.lat || informationErrors.lng ? (
                          <label className={"label-help"}>
                            {t("errors.pinAddress")}
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <VuiActionForm
                loading={informationLoading.get()}
                cancelLink={"/settings"}
              />
            </Tab>

            <Tab eventKey="integration" title={t("general.integration")}>
              <div className={"default-page-layout"}>
                <div>
                  <div className={"card-setting"}>
                    <div className={"inner-content type-channel"}>
                      <img
                        className="logo"
                        src={"/images/logo-tokopedia.png"}
                        alt="logo tokopedia"
                      />
                    </div>
                  </div>
                </div>

                <div className="information-section">
                  <div className={"card-paper"}>
                    <div className={"card-header"}>
                      <h6 className={"card-header-title"}>
                        Tokopedia {t("general.credentials")}
                      </h6>
                    </div>

                    <div className={"card-content"}>
                      <input
                        type="hidden"
                        name="channels[0].channel_id"
                        defaultValue={1}
                        className="form-control"
                        ref={informationRegister}
                      />

                      <div
                        className={clsx({
                          "form-group": true,
                          error: _.get(
                            informationErrors,
                            "channels[0].tokopedia_shop_id",
                            false
                          ),
                        })}
                      >
                        <label className={"form-label"}>Shop ID</label>
                        <input
                          type="text"
                          name="channels[0].tokopedia_shop_id"
                          className="form-control"
                          ref={informationRegister}
                        />
                        <label className={"label-help"}>
                          {_.get(
                            informationErrors,
                            "channels[0].tokopedia_shop_id",
                            ""
                          )}
                        </label>
                      </div>

                      <div className={"form-group"}>
                        <Controller
                          name={"channels[0].channel_meta.is_tkpd_official"}
                          control={informationControl}
                          defaultValue={false}
                          render={(props) => {
                            return (
                              <Form.Check
                                id="is_official"
                                type="checkbox"
                                label={t("products.form.officialStore.label")}
                                checked={props.value}
                                onChange={(e) =>
                                  props.onChange(e.target.checked)
                                }
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <VuiActionForm
                loading={informationLoading.get()}
                cancelLink={"/settings"}
              />
            </Tab>

            <Tab eventKey="users" title={t("general.users")}>
              <TabUser />
            </Tab>
          </Tabs>
        </form>
      </div>
    </>
  );
};

export default SettingBrand;
