import { Helmet } from "react-helmet-async";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import VuiDateRangePicker from "../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker";
import moment from "moment";
import { useState } from "@hookstate/core/dist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { Line } from "react-chartjs-2";
import VuiLoader from "../../../../vodea/@vodea-ui/components/VuiLoader";
import { TableChangeHandler } from "react-bootstrap-table-next";
import MultipleCheckboxes from "../../../../components/MultipleCheckboxes";
import { colorChart } from "../../../../data";
import VendorRepositories from "../../../../repositories/Widget/VendorRepositories";

import ChannelBrandRepositories from "../../../../repositories/Brand/ChannelRepository";
import BrandVendorRepositories from "../../../../repositories/Brand/VendorRepository";
import BrandCategoryRepositories from "../../../../repositories/Brand/CategoryRepository";
import useIsMounted from "../../../../vodea/utilities/hooks/useIsMounted";
import {
  abbreviateNumber,
  convertToLocaleNumber,
  convertToType,
} from "../../../../vodea/utilities/helpers/global";
import VuiDataTable from "../../../../vodea/@vodea-ui/components/VuiDataTable";
import VuiNumberFormat from "../../../../vodea/@vodea-ui/components/VuiNumberFormat";
import _ from "lodash";
import { $clone } from "../../../../vodea/utilities";
import { cardDefaultValues, CardInterface } from "../Penjualan/interface";
import { AxiosResponse } from "axios";
import VuiCard from "../../../../vodea/@vodea-ui/components/VuiCard";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import clsx from "clsx";
import * as htmlToImage from "html-to-image";
import { htmlToPdf } from "../../../../vodea/utilities/helpers/pdf.helper";
import { VuiButton } from "../../../../vodea/@vodea-ui/components/VuiButton";
import { useTranslation } from "react-i18next";

const tableColumns = [
  {
    dataField: "name",
    text: "Vendor",
    sort: true,
  },
  {
    dataField: "city",
    text: "Area",
    sort: true,
  },
  {
    dataField: "in_stock_rate",
    text: "In-Stock Rate",
    sort: true,
  },
  {
    dataField: "sla",
    text: "SLA",
    sort: true,
    formatter: (cell: any) => <VuiNumberFormat data={cell} value={cell} />,
  },
  {
    dataField: "grand_total",
    text: "GMV",
    sort: true,
    formatter: (cell: any) => (
      <VuiNumberFormat data={cell} value={cell} prefix={"Rp "} />
    ),
  },
  {
    dataField: "total_order",
    text: "Unit Terjual",
    sort: true,
    formatter: (cell: any) => <VuiNumberFormat data={cell} value={cell} />,
  },
];

const DashboardVendor: React.FC<any> = () => {
  const isMounted = useIsMounted();
  const { brand } = useSelector((state: RootState) => state.brand);
  const filterConfig = useState({
    date_from: moment().subtract(6, "days").format("YYYY-MM-DD"),
    date_to: moment().format("YYYY-MM-DD"),
  });
  const { t } = useTranslation();
  const [loadingExport, setLoadingExport] = React.useState<boolean>(false);
  const [isFirstMount, setIsFirstMount] = React.useState<boolean>(false);
  const [chartLabelCompare, setChartLabelCompare] = React.useState<any>([]);
  const tableConfig = useState({
    loading: false,
    page: 1,
    per_page: 5,
    total: 0,
    sorted_by: "desc",
    order_by: "created_at",
    search: "",
  });

  const [gmvCard, setGmvCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [gmvCardLoading, setGmvCardLoading] = React.useState<boolean>(false);

  const [soldProductCard, setSoldProductCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [soldProductCardLoading, setSoldProductCardLoading] =
    React.useState<boolean>(false);

  const [inStockRateCard, setInStockRateCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [inStockRateCardLoading, setInStockRateCardLoading] =
    React.useState<boolean>(false);

  const [serviceLevelAgreementCard, setServiceLevelAgreementCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [
    serviceLevelAgreementCardLoading,
    setServiceLevelAgreementCardLoading,
  ] = React.useState<boolean>(false);

  const [totalCategoryOption, setTotalCategoryOption] =
    React.useState<number>(0);
  const [totalVendorOption, setTotalVendorOption] = React.useState<number>(0);

  const [colorIndex, setColorIndex] = React.useState<number>(0);
  const [chartReal, setChartReal] = React.useState<any>([]);
  const [chartCompare, setChartCompare] = React.useState<any>([]);
  const [chartLabel, setChartLabel] = React.useState<any>([]);
  const [tableData, setTableData] = React.useState<any>([]);
  const [daysDifference, setDaysDifference] = React.useState(0);
  const [chartLoading, setChartLoading] = React.useState<boolean>(false);
  const [channelOptions, setChannelOptions] = React.useState<any>([]);
  const [selectedChannel, setSelectedChannel] = React.useState<any>([]);
  const [categoryOptions, setCategoryOptions] = React.useState<any>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<any>([]);
  const [vendorOptions, setVendorOptions] = React.useState<any>([]);
  const [selectedVendor, setSelectedVendor] = React.useState<any>([]);
  const chartSelection = useState<string>("gmv");
  const chartColor = useState<string>(colorChart[0]);

  const getChannelOptions = () => {
    ChannelBrandRepositories.all(brand.id).then((response: any) => {
      const temp: any[] = [];
      response.data.data.forEach((item: any) => {
        temp.push(item.id);
      });
      if (isMounted.current) {
        setChannelOptions(response.data.data);
        setSelectedChannel(temp);
      }
    });
  };

  const getCategoryOptions = () => {
    BrandCategoryRepositories.all(brand.id, {
      with: "children",
      only: "parent",
      per_page: 1000,
    }).then((response: any) => {
      let total = 0;
      const temp: any = [];
      response.data.data.forEach((item: any) => {
        if (item.children.length > 0) {
          item.children.forEach((child: any, index: number) => {
            temp.push(child.id);
            total += 1;
          });
        }
        temp.push(item.id);
        total += 1;
      });
      if (isMounted.current) {
        setTotalCategoryOption(total);
        setCategoryOptions(response.data.data);
        setSelectedCategory(temp);
      }
    });
  };

  const getVendorOptions = () => {
    BrandVendorRepositories.all(brand.id, {
      per_page: 1000,
    }).then((response: any) => {
      if (isMounted.current) {
        const data = response.data.data;
        const temp: any = [];
        data.forEach((item: any) => {
          temp.push(item.id);
        });
        setVendorOptions(data);
        setTotalVendorOption(data.length);
        setSelectedVendor(temp);
      }
    });
  };

  useEffect(() => {
    getChannelOptions();
    getVendorOptions();
    getCategoryOptions();
  }, []);

  const getGmvChartData = (difference: any, params: any) => {
    VendorRepositories.getGrossMarginChart(brand.id, params)
      .then((response: AxiosResponse) => {
        const responseData = response.data.data;
        const tempCurrentPeriod = [];
        const tempLastPeriod = [];
        for (let i = 0; i <= difference; i++) {
          tempCurrentPeriod.push(0);
          tempLastPeriod.push(0);
        }

        const startDate = moment(filterConfig.date_from.get());
        responseData?.current_period.forEach((item: any) => {
          const tempDate: any = moment(item?.date).diff(startDate, "days");
          tempCurrentPeriod[tempDate] = item?.grand_total;
        });

        responseData?.last_period.forEach((item: any) => {
          const tempDate: any = moment(item?.date)
            .add(difference + 1, "days")
            .diff(startDate, "days");

          tempLastPeriod[tempDate] = item?.grand_total;
        });

        if (isMounted.current) {
          setChartReal(tempCurrentPeriod);
          setChartCompare(tempLastPeriod);
          setChartLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setChartLoading(false);
        }
      });
  };

  const getSoldProductChartData = (difference: any, params: any) => {
    VendorRepositories.getSoldProductChart(brand.id, params)
      .then((response: AxiosResponse) => {
        const responseData = response.data.data;
        const tempCurrentPeriod = [];
        const tempLastPeriod = [];
        for (let i = 0; i <= difference; i++) {
          tempCurrentPeriod.push(0);
          tempLastPeriod.push(0);
        }

        const startDate = moment(filterConfig.date_from.get());
        responseData?.current_period.forEach((item: any) => {
          const tempDate: any = moment(item?.date).diff(startDate, "days");
          tempCurrentPeriod[tempDate] = item?.total_order_product;
        });

        responseData?.last_period.forEach((item: any) => {
          const tempDate: any = moment(item?.date)
            .add(difference + 1, "days")
            .diff(startDate, "days");

          tempLastPeriod[tempDate] = item?.total_order_product;
        });

        if (isMounted.current) {
          setChartReal(tempCurrentPeriod);
          setChartCompare(tempLastPeriod);
          setChartLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setChartLoading(false);
        }
      });
  };

  const handleTableSearch = _.debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      tableConfig.search.set(e.target.value);
      tableConfig.page.set(1);

      getData();
    },
    300
  );

  const getDifferentDays = () => {
    const difference = moment(filterConfig.date_to.get()).diff(
      moment(filterConfig.date_from.get()),
      "days"
    );
    if (difference == 0 || difference == 1) {
      setDaysDifference(1);
    } else {
      setDaysDifference(difference + 1);
    }
  };

  const getGmvData = (params: any) => {
    if (isMounted.current) {
      setGmvCardLoading(true);
    }

    VendorRepositories.getGrossMargin(brand.id, params)
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const temp: CardInterface = {
          title: "GMV",
          description:
            "Didapat dari pesanan yang masuk diluar dari pesanan yang dibatalkan.",
          value: data.current_period,
          compare: convertToLocaleNumber(data.last_period, data.current_period),
          type: convertToType(data.last_period, data.current_period),
        };
        if (isMounted.current) {
          setGmvCardLoading(false);
          setGmvCard(temp);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setGmvCardLoading(false);
        }
      });
  };

  const getSoldProductData = (params: any) => {
    if (isMounted.current) setSoldProductCardLoading(true);

    VendorRepositories.getSoldProduct(brand.id, params)
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const temp: CardInterface = {
          title: "Produk terjual",
          description: "Jumlah produk terjual",
          value: data.current_period,
          compare: convertToLocaleNumber(data.last_period, data.current_period),
          type: convertToType(data.last_period, data.current_period),
        };
        if (isMounted.current) {
          setSoldProductCardLoading(false);
          setSoldProductCard(temp);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setSoldProductCardLoading(false);
        }
      });
  };

  const getInStockRateData = (params: any) => {
    if (isMounted.current) setInStockRateCardLoading(true);
    VendorRepositories.getInStockRate(brand.id, params)
      .then((response: AxiosResponse) => {
        const temp: CardInterface = {
          title: "Average In Stock Rate",
          description: "Persentase ketersediaan stok.",
          value: response.data.data,
        };
        if (isMounted.current) {
          setInStockRateCard(temp);
          setInStockRateCardLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setInStockRateCardLoading(false);
        }
      });
  };

  const getServiceLevelAgreementData = (params: any) => {
    if (isMounted.current) setServiceLevelAgreementCardLoading(true);
    VendorRepositories.getServiceLevelAgreement(brand.id, params)
      .then((response: AxiosResponse) => {
        const temp: CardInterface = {
          title: "SLA",
          description:
            "Average dari semua transaksi awal saat vendor terima order s.d barang di terima customer.",
          value: response.data.data.current_period,
        };
        if (isMounted.current) {
          setServiceLevelAgreementCard(temp);
          setServiceLevelAgreementCardLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setServiceLevelAgreementCardLoading(false);
        }
      });
  };

  const getAllData = (reset: boolean = false) => {
    const params = {
      "date-from": filterConfig.date_from.get(),
      "date-to": filterConfig.date_to.get(),
      channel: selectedChannel,
    };

    if (selectedCategory.length !== totalCategoryOption) {
      Object.assign(params, {
        category: selectedCategory,
      });
    }

    if (selectedVendor.length !== totalVendorOption) {
      Object.assign(params, {
        vendor: selectedVendor,
      });
    }

    getDifferentDays();
    getInStockRateData(params);
    getGmvData(params);
    getServiceLevelAgreementData(params);
    getSoldProductData(params);
    changeChart(chartSelection.get(), colorIndex);
    getData(reset);
  };

  useEffect(() => {
    if (isFirstMount) {
      getAllData(true);
    }
    if (
      selectedChannel.length > 0 &&
      selectedVendor.length > 0 &&
      selectedCategory.length > 0
    ) {
      setIsFirstMount(true);
    }
  }, [selectedCategory, selectedChannel, selectedVendor, isFirstMount]);

  const changeChart = (key: string, index: number) => {
    if (isMounted.current) {
      chartSelection.set(key);
      chartColor.set(colorChart[index]);
      setColorIndex(index);
      setChartLoading(true);
    }

    const difference = moment(filterConfig.date_to.get()).diff(
      moment(filterConfig.date_from.get()),
      "days"
    );

    const params = {
      "date-from": filterConfig.date_from.get(),
      "date-to": filterConfig.date_to.get(),
      channel: selectedChannel,
    };

    if (selectedCategory.length !== totalCategoryOption) {
      Object.assign(params, {
        category: selectedCategory,
      });
    }

    if (selectedVendor.length !== totalVendorOption) {
      Object.assign(params, {
        vendor: selectedVendor,
      });
    }

    const tempChartLabel: string[] = [];

    for (let i = difference; i >= 0; i--) {
      tempChartLabel.push(
        moment(filterConfig.date_to.get())
          .subtract(i, "days")
          .format("DD MMMM YYYY")
      );
    }

    if (isMounted.current) setChartLabel(tempChartLabel);

    const tempChartLabelCompare: string[] = [];
    for (let i = difference; i >= 0; i--) {
      tempChartLabelCompare.push(
        moment(filterConfig.date_to.get())
          .subtract(i + difference + 1, "days")
          .format("DD MMMM YYYY")
      );
    }
    if (isMounted.current) setChartLabelCompare(tempChartLabelCompare);

    if (key === "gmv") {
      getGmvChartData(difference, params);
    } else if (key === "sold-product") {
      getSoldProductChartData(difference, params);
    }
  };

  const getData = (reset: boolean = false) => {
    if (isMounted.current) {
      if (reset) tableConfig.page.set(1);
      setTableData([]);
      tableConfig.loading.set(true);
    }
    const conf = _.omit($clone(tableConfig.value), "loading");
    let params: any = {};
    if (conf) {
      Object.keys(conf).forEach((prop) => {
        if (conf[prop]) {
          params[prop] = conf[prop];
        }
      });
    }

    Object.assign(params, {
      "date-from": filterConfig.date_from.get(),
      "date-to": filterConfig.date_to.get(),
      channel: selectedChannel,
    });

    if (selectedCategory.length !== totalCategoryOption) {
      Object.assign(params, {
        category: selectedCategory,
      });
    }

    if (selectedVendor.length !== totalVendorOption) {
      Object.assign(params, {
        vendor: selectedVendor,
      });
    }

    VendorRepositories.getTableData(brand.id, params)
      .then((res: any) => {
        const data = res.data.data;
        if (isMounted.current) {
          tableConfig.total.set(res.data.meta.total);
          tableConfig.loading.set(false);
          setTableData(data);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          tableConfig.loading.set(false);
        }
      });
  };

  const handleChangeDate = (start: any, end: any) => {
    filterConfig.date_from.set(start.format("YYYY-MM-DD"));
    filterConfig.date_to.set(end.format("YYYY-MM-DD"));

    getAllData(true);
  };

  const onTableChange: TableChangeHandler<any> = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    tableConfig.page.set(page);
    tableConfig.per_page.set(sizePerPage);
    tableConfig.order_by.set(sortField);
    tableConfig.sorted_by.set(sortOrder);

    getData();
  };

  const customTitle = (tooltipItem: any) => {
    const real = chartLabel[_.get(tooltipItem, "[0].index", 0)];
    const compare = chartLabelCompare[_.get(tooltipItem, "[0].index", 0)];
    return [real, compare];
  };

  const handleExport = useCallback(() => {
    if (isMounted.current) {
      setLoadingExport(true);
      const body: any = document.querySelector(".app-container");
      if (body) {
        htmlToImage
          .toCanvas(body)
          .then(function (dataUrl) {
            const finish = htmlToPdf(dataUrl, "dashboard-pembeli");
            if (finish) setLoadingExport(false);
          })
          .catch(function (error) {
            setLoadingExport(false);
          });
      }
    }
  }, [isMounted]);

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      <div className={"page-header-component"}>
        <h3 className={"title"}>
          Dashboard | <strong>Vendor</strong>
        </h3>
        <VuiButton
          onClick={handleExport}
          variant="success"
          label={t("button.export")}
          loading={loadingExport}
        />
      </div>

      <>
        <div className="card-paper mb-4">
          <div className="card-content">
            <div className="card-filter-section">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label" style={{ display: "flex" }}>
                      Rentang Tanggal
                    </label>
                    <VuiDateRangePicker
                      startDate={filterConfig.date_from.get()}
                      endDate={filterConfig.date_to.get()}
                      callback={handleChangeDate}
                      useRanges={true}
                    />
                  </div>
                </div>

                <>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">Kategori</label>
                      <MultipleCheckboxes
                        name={"kategori"}
                        options={categoryOptions}
                        labelOption="name"
                        valueOption="id"
                        values={selectedCategory}
                        onChange={(values) => {
                          setSelectedCategory(values);
                        }}
                        children={true}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">Channel</label>
                      <MultipleCheckboxes
                        name={"channel"}
                        labelOption="name"
                        options={channelOptions}
                        valueOption="id"
                        values={selectedChannel}
                        onChange={(values) => {
                          setSelectedChannel(values);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">Vendor</label>
                      <MultipleCheckboxes
                        name={"vendor"}
                        options={vendorOptions}
                        labelOption="name"
                        valueOption="id"
                        values={selectedVendor}
                        onChange={(values) => {
                          setSelectedVendor(values);
                        }}
                      />
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>

        <div className="card-paper mb-4">
          <div className="card-content">
            <div className="card-chart-wrapper mb-4">
              <VuiCard
                isActive={chartSelection.get() === "gmv"}
                title={gmvCard.title}
                description={gmvCard.description}
                loading={gmvCardLoading}
                type={gmvCard.type}
                compare={gmvCard.compare}
                value={gmvCard.value}
                days={daysDifference}
                hasComparison
                onClick={() => changeChart("gmv", 0)}
              />

              <VuiCard
                isActive={chartSelection.get() === "sold-product"}
                title={soldProductCard.title}
                description={soldProductCard.description}
                loading={soldProductCardLoading}
                type={soldProductCard.type}
                compare={soldProductCard.compare}
                value={soldProductCard.value}
                days={daysDifference}
                hasComparison
                onClick={() => changeChart("sold-product", 1)}
              />

              <VuiCard
                isActive={chartSelection.get() === "in-stock-rate"}
                title={inStockRateCard.title}
                description={inStockRateCard.description}
                loading={inStockRateCardLoading}
                value={inStockRateCard.value}
                isPercentage
                days={daysDifference}
              />

              <div className={"card-chart"}>
                {serviceLevelAgreementCardLoading ? (
                  <VuiLoader />
                ) : (
                  <>
                    <label className="card-title">
                      {serviceLevelAgreementCard.title}
                      <OverlayTrigger
                        key={`tooltip-overlay-gmv`}
                        placement={"top"}
                        overlay={
                          <Tooltip id={`tooltip-gmv`}>
                            <i>{serviceLevelAgreementCard.description}</i>
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          className={"icon"}
                          icon={faExclamationCircle}
                        />
                      </OverlayTrigger>
                    </label>
                    <h3
                      className={clsx({
                        "card-value": true,
                        positive:
                          serviceLevelAgreementCard.value <= 24 &&
                          serviceLevelAgreementCard.value >= 1,
                        negative: serviceLevelAgreementCard.value > 24,
                      })}
                    >
                      <VuiNumberFormat
                        data={serviceLevelAgreementCard.value}
                        value={serviceLevelAgreementCard.value}
                      />
                    </h3>
                  </>
                )}
              </div>
            </div>

            {chartLoading ? (
              <VuiLoader />
            ) : (
              <div className="line-chart-wrapper">
                <Line
                  height={350}
                  data={{
                    labels: chartLabel,
                    datasets: [
                      {
                        label: "Periode saat ini",
                        data: chartReal,
                        fill: false,
                        backgroundColor: chartColor.get(),
                        borderColor: chartColor.get(),
                        tension: 0,
                      },
                      {
                        label: "Periode sebelumnya",
                        data: chartCompare,
                        fill: false,
                        backgroundColor: chartColor.get(),
                        borderColor: chartColor.get(),
                        borderDash: [5, 5],
                        tension: 0,
                      },
                    ],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    legend: {
                      display: true,
                    },
                    tooltips: {
                      callbacks: {
                        title: customTitle,
                      },
                    },
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            callback: function (label: any) {
                              return abbreviateNumber(label, 0);
                            },
                          },
                          scaleLabel: {
                            display: true,
                          },
                        },
                      ],
                    },
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div className="card-paper mb-4">
          <div className="card-header">
            <h6 className="card-header-title">Performa Vendor</h6>
          </div>

          <div className="card-content">
            <div className={"default-filter-section"}>
              <div className={"filter-item filter-special type-search"}>
                <div className="input-group prefix-group">
                  <span className="input-group-text">
                    <FontAwesomeIcon
                      className={"icon icon-prefix"}
                      icon={faSearch}
                    />
                  </span>
                  <input
                    defaultValue={tableConfig.search.get()}
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    onChange={handleTableSearch}
                  />
                </div>
              </div>
            </div>

            <VuiDataTable
              loading={tableConfig.loading.get()}
              columns={tableColumns}
              data={tableData}
              page={tableConfig.page.get()}
              sizePerPage={tableConfig.per_page.get()}
              totalSize={tableConfig.total.get()}
              onTableChange={onTableChange}
            />
          </div>
        </div>
      </>
    </>
  );
};

export default DashboardVendor;
