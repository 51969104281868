import clsx from "clsx";
import _ from "lodash";
import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
//@ts-ignore
import cellEditFactory from "react-bootstrap-table2-editor";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import VuiActionForm from "../../../../../vodea/@vodea-ui/components/Forms/VuiActionForm";
import VuiBreadcrumb from "../../../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import { $clone } from "../../../../../vodea/utilities";
import { defaultProductFormatter } from "../../components/CustomCell";

const tableColumns = [
  {
    dataField: "product",
    text: "Produk",
    sort: true,
    formatter: defaultProductFormatter,
    editable: false,
  },
  {
    dataField: "SKU",
    text: "SKU Stok",
    sort: true,
    classes: "td-custom",
    editable: false,
  },
  {
    dataField: "stock",
    text: "Stok Awal",
    sort: true,
    classes: "td-custom",
    editable: false,
  },
  {
    dataField: "adjustment",
    text: "Penyesuaian",
    sort: true,
    classes: "td-custom",
  },
  {
    dataField: "last_stock",
    isDummyField: true,
    text: "Stok Akhir",
    classes: "td-custom",
    formatter: (cell: any, row: any, rowIndex: number) => {
      const lastStock = Number(row.stock) + Number(row.adjustment);
      return <div>{lastStock}</div>;
    },
    editable: false,
  },
  {
    dataField: "updated_at",
    text: "Terakhir diperbaharui",
    sort: true,
    classes: "td-custom",
    editable: false,
  },
];

const tableData = [
  {
    id: 1,
    product: "TV 32 inch",
    image:
      "https://www.jing.fm/clipimg/full/125-1258427_telefunken-smart-tv-finlux-tv-32-inch.png",
    SKU: "A01001",
    stock: 201,
    updated_at: "29 Apr 2021, 10:21",
  },
  {
    id: 2,
    product: "Kulkas 2 Pintu",
    image:
      "https://images.samsung.com/is/image/samsung/id-top-mount-freezer-rt38k5030b1-rt38k5030b1-se-rperspectiveblack-240668699?$720_576_PNG$",
    SKU: "A03001",
    stock: 41,
    updated_at: "29 Apr 2021, 10:21",
  },
  {
    id: 3,
    product: "TV 40 inch",
    image:
      "https://www.jing.fm/clipimg/full/125-1258427_telefunken-smart-tv-finlux-tv-32-inch.png",
    SKU: "A03001",
    stock: 20,
    updated_at: "29 Apr 2021, 10:21",
  },
  {
    id: 4,
    product: "Kulkas side by side",
    image:
      "https://images.samsung.com/is/image/samsung/id-top-mount-freezer-rt38k5030b1-rt38k5030b1-se-rperspectiveblack-240668699?$720_576_PNG$",
    SKU: "A04001",
    stock: 301,
    updated_at: "29 Apr 2021, 10:21",
  },
  {
    id: 5,
    product: "TV 50 inch",
    image:
      "https://www.jing.fm/clipimg/full/125-1258427_telefunken-smart-tv-finlux-tv-32-inch.png",
    SKU: "A05001",
    stock: 101,
    updated_at: "29 Apr 2021, 10:21",
  },
];

const AdjustStock: React.FC<any> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [data, setData] = React.useState<any[]>([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const selectedData: any[] = tableData
      .filter((item: any) => {
        const selectedIds: any = _.get(location, "state", []);

        return selectedIds?.includes(item.id);
      })
      .map((item: any) => {
        item.adjustment = 0;

        return item;
      });

    setData(selectedData);
  };

  const tableBeforeSaveCell = (
    oldValue: any,
    newValue: any,
    row: any,
    column: any
  ) => {
    let tempTableData = $clone(data);

    const name = column.dataField;
    const index = _.findIndex(tempTableData, ["id", row.id]);

    tempTableData[index][name] = newValue;

    setData(tempTableData);
  };

  const breadcrumbList = [
    {
      label: t("Adjust Stock"),
      link: "/adjust",
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("Adjust Stock")}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={"page-header-component"}>
        <h3 className={"title"}>{t("Penyesuaian Stok")}</h3>
      </div>

      <div className="card-paper mb-4">
        <div className="card-content">
          <div className="row">
            <div className={"col-md-6"}>
              <div
                className={clsx({
                  "form-group": true,
                  error: false,
                })}
              >
                <label className={"form-label"}>
                  {t("Catatan Penyesuaian")}
                </label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  // ref={informationRegister}
                />
                {/* <label className={'label-help'}>{informationErrors.name?.message}</label> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-paper mb-4">
        <div className="card-content">
          <BootstrapTable
            wrapperClasses={"table-responsive"}
            classes={"table-custom"}
            keyField={"id"}
            data={data}
            columns={tableColumns}
            filter={filterFactory()}
            pagination={paginationFactory({
              page: 1,
              sizePerPage: 5,
              totalSize: data.length,
              showTotal: true,
            })}
            cellEdit={cellEditFactory({
              mode: "click",
              blurToSave: true,
              afterSaveCell: tableBeforeSaveCell,
            })}
          />
        </div>
      </div>

      <VuiActionForm loading={false} cancelLink={"/stock/management"} />
    </>
  );
};

export default AdjustStock;
