import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../stores';
import { usePassportService } from '../../vodea/utilities/hooks';
import VuiLoadingScreen from '../../vodea/@vodea-ui/components/VuiLoadingScreen';

interface HeaderAuthState {
  title: string;
  subtitle: string;
}

export const HeaderAuth: React.FC<HeaderAuthState> = ({ title, subtitle }) => {
  return (
    <div className={'auth-header'}>
      <div>
        <h4 className={'title'}>{title}</h4>
        <h6 className={'subtitle'}>{subtitle}</h6>
      </div>
      <div>
        <img className={'logo'} src={'/logo.png'} alt={'logo'} />
      </div>
    </div>
  );
};

const LayoutAuth: React.FC<any> = () => {
  const { isOnFetchingUser } = usePassportService();
  const loggedIn = useSelector((state: RootState) => state.system.loggedIn);

  if (isOnFetchingUser) return <VuiLoadingScreen />;

  if (loggedIn) {
    return <Navigate to={'/dashboard'} replace />;
  }

  return (
    <section id='auth-layout'>
      <div className={'container'}>
        <div className={'main-wrapper'}>
          <div className={'card-paper'}>
            <div className={'card-content'}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LayoutAuth;
