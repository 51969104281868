import React from "react";
import clsx from "clsx";
import VuiLoader from "../VuiLoader";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import VuiNumberFormat from "../VuiNumberFormat";

interface VuiCardInterface {
  isActive?: boolean;
  onClick?: () => void;
  title: string;
  description: string;
  value: string | number;
  isCurrency?: boolean;
  isPercentage?: boolean;
  days?: string | number;
  type?: string | number;
  loading: boolean;
  compare?: string | number;
  hasComparison?: boolean;
}

const VuiCard: React.FC<VuiCardInterface> = ({
  isActive = false,
  onClick,
  title = "",
  description = "",
  value = "",
  days = "",
  type = 0,
  isCurrency = false,
  compare,
  loading = false,
  isPercentage,
  hasComparison = false,
}) => {
  return (
    <div
      className={clsx("card-chart", {
        active: isActive,
      })}
      onClick={onClick}
    >
      {loading ? (
        <VuiLoader />
      ) : (
        <>
          <label className="card-title">
            {title}
            <OverlayTrigger
              key={`tooltip-overlay-gmv`}
              placement={"top"}
              overlay={
                <Tooltip id={`tooltip-gmv`}>
                  <i>{description}</i>
                </Tooltip>
              }
            >
              <FontAwesomeIcon className={"icon"} icon={faExclamationCircle} />
            </OverlayTrigger>
          </label>
          <h3 className="card-value">
            {isCurrency ? (
              <VuiNumberFormat data={value} value={value} prefix={"Rp "} />
            ) : isPercentage ? (
              <VuiNumberFormat
                data={value}
                value={value}
                suffix={"%"}
                decimalScale={2}
              />
            ) : (
              <VuiNumberFormat data={value} value={value} />
            )}
          </h3>

          <label className="card-note">
            {hasComparison && (
              <>
                <span
                  className={clsx({
                    percentage: true,
                    positive: type === 1,
                    negative: type === 2,
                  })}
                >
                  {type !== 0 && (
                    <FontAwesomeIcon
                      className={"icon"}
                      icon={type === 1 ? faArrowUp : faArrowDown}
                    />
                  )}{" "}
                  {compare}
                </span>{" "}
                vs {days} hari lalu
              </>
            )}
          </label>
        </>
      )}
    </div>
  );
};

export default VuiCard;
