import { api, createCancelTokenHandler } from "../../../vodea/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  exportInvoice: function (brandId: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/brand/${brandId}/export/invoice`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.exportInvoice.name
        ].handleRequestCancellation().token,
      responseType: "blob",
    });
  },
  exportOrder: function (brandId: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/brand/${brandId}/export/order`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.exportOrder.name
        ].handleRequestCancellation().token,
      responseType: "blob",
    });
  },
  exportProductStock: function (brandId: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/brand/${brandId}/export/product-stock`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.exportProductStock.name
        ].handleRequestCancellation().token,
      responseType: "blob",
    });
  },
  exportCustomer: function (brandId: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/brand/${brandId}/export/customer`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.exportCustomer.name
        ].handleRequestCancellation().token,
      responseType: "blob",
    });
  },
  downloadProductPrice: function (brand_id: any, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brand_id}/import-template/product-price`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.downloadProductPrice.name
          ].handleRequestCancellation().token,
        responseType: "blob",
      }
    );
  },
  importProductPrice: function (
    brand_id: number | string,
    payload: any,
    params: any = null
  ) {
    return api.post(
      `${endPoint()}/api/brand/${brand_id}/import/product-price`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.importProductPrice.name
          ].handleRequestCancellation().token,
      }
    );
  },
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);
