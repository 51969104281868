import React, { useMemo } from "react";
import passportService from "../../services/passport.service";
import { useDispatch, useSelector } from "react-redux";
import BrandRepository from "../../../repositories/BrandRepository";
import { updateSystem } from "../../../stores/system/actions";
import { RootState } from "../../../stores";
import useIsMounted from "./useIsMounted";
import { updateVendor } from "../../../stores/vendor/actions";
import { AxiosResponse } from "axios";
import { updateBrand } from "../../../stores/brand/actions";

const usePassportService = (disableAutoFetchUser = false) => {
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state: RootState) => {
    return {
      loggedIn: state.system.loggedIn,
    };
  });

  const [isOnSignIn, setIsOnSignIn] = React.useState(false);
  const [isOnFetchingUser, setIsOnFetchingUser] = React.useState(
    !disableAutoFetchUser
  );

  const fetchUser = async () => {
    const accessToken = passportService.getAccessToken();
    let selectedBrandId = "";
    let roleName = "";
    let vendorId = "";

    if (accessToken && !loggedIn) {
      setIsOnFetchingUser(true);
      await passportService
        .fetchUser()
        .then((response: any) => {
          roleName = response.data.data.role_name;
          vendorId = response.data.data.vendor_id;

          if (roleName === "Brand") {
            selectedBrandId = response.data.data.brand_id;
          } else {
            selectedBrandId = response.data.data.current_brand_id;
          }

          dispatch(
            updateSystem({
              loggedIn: !!response.data.data,
              user: response.data.data,
            })
          );

          if (selectedBrandId) {
            dispatch(
              updateBrand({
                brand: {
                  id: selectedBrandId,
                  name: response.data.data.current_brand_name,
                },
              })
            );
          }

          if (vendorId) {
            dispatch(
              updateVendor({
                vendor: {
                  id: vendorId,
                  name: response.data.data.vendor_name,
                },
              })
            );
          }
        })
        .catch(() => {});

      if (
        selectedBrandId &&
        (roleName === "Administrator" || roleName === "Key Account")
      ) {
        await BrandRepository.show(selectedBrandId)
          .then((response: AxiosResponse) => {
            dispatch(
              updateBrand({
                brand: response.data.data,
              })
            );
          })
          .catch(() => {});
      }

      setIsOnFetchingUser(false);
    }
  };

  const login = async (
    username: string,
    password: string,
    scope: string = "*"
  ) => {
    return new Promise(async (resolve, reject) => {
      setIsOnSignIn(true);

      await passportService
        .login(username, password, scope)
        .then((response) => {
          setIsOnFetchingUser(true);
          resolve(response);
        })
        .catch((error) => reject(error));

      setIsOnSignIn(false);
    });
  };

  useMemo(() => {
    (async () => {
      if (isOnFetchingUser) {
        await fetchUser()
          .then(() => {})
          .catch(() => {});
      }

      setIsOnFetchingUser(false);
    })();
  }, [isOnFetchingUser]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    isOnSignIn,
    isOnFetchingUser,
    login,
    fetchUser,
  };
};

export default usePassportService;
