import { Helmet } from "react-helmet-async";
import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import { useTranslation } from "react-i18next";
import VuiDateRangePicker from "../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker";
import moment from "moment";
import { useState } from "@hookstate/core/dist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Line } from "react-chartjs-2";
import VuiLoader from "../../../../vodea/@vodea-ui/components/VuiLoader";
import { TableChangeHandler } from "react-bootstrap-table-next";
import MultipleCheckboxes from "../../../../components/MultipleCheckboxes";
import { colorChart } from "../../../../data";
import ProductRepositories from "../../../../repositories/Widget/ProductRepositories";

import ChannelBrandRepositories from "../../../../repositories/Brand/ChannelRepository";
import BrandVendorRepositories from "../../../../repositories/Brand/VendorRepository";
import BrandCategoryRepositories from "../../../../repositories/Brand/CategoryRepository";
import useIsMounted from "../../../../vodea/utilities/hooks/useIsMounted";
import {
  abbreviateNumber,
  convertToLocaleNumber,
  convertToType,
} from "../../../../vodea/utilities/helpers/global";
import VuiNumberFormat from "../../../../vodea/@vodea-ui/components/VuiNumberFormat";
import VuiDataTable from "../../../../vodea/@vodea-ui/components/VuiDataTable";
import _ from "lodash";
import { $clone } from "../../../../vodea/utilities";
import { cardDefaultValues, CardInterface } from "../Penjualan/interface";
import { AxiosResponse } from "axios";
import VuiCard from "../../../../vodea/@vodea-ui/components/VuiCard";
import * as htmlToImage from "html-to-image";
import { htmlToPdf } from "../../../../vodea/utilities/helpers/pdf.helper";
import { VuiButton } from "../../../../vodea/@vodea-ui/components/VuiButton";

const tableColumns = [
  {
    dataField: "name",
    text: "Nama Produk",
    sort: true,
  },
  {
    dataField: "category_name",
    text: "Kategori",
    sort: true,
  },
  {
    dataField: "sku",
    text: "SKU",
    sort: true,
  },
  {
    dataField: "total_price",
    text: "GMV",
    sort: true,
    formatter: (cell: any) => <VuiNumberFormat data={cell} prefix={"Rp "} />,
  },
  {
    dataField: "total_sold",
    text: "Terjual",
    sort: true,
    formatter: (cell: any) => <VuiNumberFormat data={cell} />,
  },
  {
    dataField: "total_order",
    text: "Total Order",
    sort: true,
    formatter: (cell: any) => <VuiNumberFormat data={cell} />,
  },
];

const DashboardProduk: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [loadingExport, setLoadingExport] = React.useState<boolean>(false);
  const [daysDifference, setDaysDifference] = React.useState(0);
  const [gmvCard, setGMVCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [gmvCardLoading, setGmvCardLoading] = React.useState<boolean>(false);
  const chartColor = useState<string>(colorChart[0]);
  const [soldProductCard, setSoldProductCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [soldProductCardLoading, setSoldProductCardLoading] =
    React.useState(false);

  const [runRateCard, setRunRateCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [runRateCardLoading, setRunRateCardLoading] =
    React.useState<boolean>(false);

  const [weeksOfSupplyCard, setWeeksOfSupplyCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [weeksOfSupplyCardLoading, setWeeksOfSupplyCardLoading] =
    React.useState<boolean>(false);

  const [totalCategoryOption, setTotalCategoryOption] =
    React.useState<number>(0);
  const [totalVendorOption, setTotalVendorOption] = React.useState<number>(0);

  const [chartLoading, setChartLoading] = React.useState<boolean>(false);
  const [chartReal, setChartReal] = React.useState<any>([]);
  const [chartCompare, setChartCompare] = React.useState<any>([]);
  const chartSelection = useState<string>("gmv");
  const [colorIndex, setColorIndex] = React.useState<number>(0);
  const [chartLabel, setChartLabel] = React.useState<any>([]);
  const [tableData, setTableData] = React.useState<any>([]);
  const [channelOptions, setChannelOptions] = React.useState<any>([]);
  const [selectedChannel, setSelectedChannel] = React.useState<any>([]);
  const [categoryOptions, setCategoryOptions] = React.useState<any>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<any>([]);
  const [vendorOptions, setVendorOptions] = React.useState<any>([]);
  const [selectedVendor, setSelectedVendor] = React.useState<any>([]);
  const [isFirstMount, setIsFirstMount] = React.useState<boolean>(false);
  const filterConfig = useState({
    date_from: moment().subtract(6, "days").format("YYYY-MM-DD"),
    date_to: moment().format("YYYY-MM-DD"),
  });
  const [chartLabelCompare, setChartLabelCompare] = React.useState<any>([]);

  const tableConfig = useState({
    per_page: 5,
    page: 1,
    loading: false,
    total: 0,
    sorted_by: "desc",
    order_by: "created_at",
    search: "",
  });

  const { brand } = useSelector((state: RootState) => state.brand);

  const getChannelOptions = async () => {
    await ChannelBrandRepositories.all(brand.id)
      .then((response: any) => {
        const temp: any[] = [];
        response.data.data.forEach((item: any) => {
          temp.push(item.id);
        });
        if (isMounted.current) {
          setChannelOptions(response.data.data);
          setSelectedChannel(temp);
        }
      })
      .catch((err: any) => {});
  };

  const getCategoryOptions = async () => {
    BrandCategoryRepositories.all(brand.id, {
      with: "children",
      only: "parent",
      per_page: 1000,
    }).then((response: any) => {
      let total = 0;
      const temp: any = [];
      response.data.data.forEach((item: any) => {
        if (item.children.length > 0) {
          item.children.forEach((child: any, index: number) => {
            temp.push(child.id);
            total += 1;
          });
        }
        temp.push(item.id);
        total += 1;
      });
      if (isMounted.current) {
        setTotalCategoryOption(total);
        setCategoryOptions(response.data.data);
        setSelectedCategory(temp);
      }
    });
  };

  const getVendorOptions = async () => {
    await BrandVendorRepositories.all(brand.id, {
      per_page: 1000,
    }).then((response: any) => {
      if (isMounted.current) {
        const data = response.data.data;
        const temp: any = [];
        data.forEach((item: any) => {
          temp.push(item.id);
        });
        setVendorOptions(data);
        setTotalVendorOption(data.length);
        setSelectedVendor(temp);
      }
    });
  };

  useMemo(() => {
    getChannelOptions();
    getVendorOptions();
    getCategoryOptions();
  }, []);

  const getDifferentDays = () => {
    const difference = moment(filterConfig.date_to.get()).diff(
      moment(filterConfig.date_from.get()),
      "days"
    );
    if (difference == 0 || difference == 1) {
      setDaysDifference(1);
    } else {
      setDaysDifference(difference + 1);
    }
  };

  const getAllData = (reset: boolean = false) => {
    const params = {
      "date-from": filterConfig.date_from.get(),
      "date-to": filterConfig.date_to.get(),
      channel: selectedChannel,
    };

    if (selectedCategory.length !== totalCategoryOption) {
      Object.assign(params, {
        category: selectedCategory,
      });
    }

    if (selectedVendor.length !== totalVendorOption) {
      Object.assign(params, {
        vendor: selectedVendor,
      });
    }

    getDifferentDays();
    getGmvCardData(params);
    getSoldProductCardData(params);
    getRunRateCardData(params);
    getWeeksOfSupplyCardData(params);
    changeChart(chartSelection.get(), colorIndex);
    getData(reset);
  };

  useEffect(() => {
    if (isFirstMount) {
      getAllData(true);
    }
    if (
      selectedChannel.length > 0 &&
      selectedVendor.length > 0 &&
      selectedCategory.length > 0
    ) {
      setIsFirstMount(true);
    }
  }, [selectedCategory, selectedChannel, selectedVendor, isFirstMount]);

  const getGmvCardData = (params: any) => {
    if (isMounted.current) {
      setGmvCardLoading(true);
    }
    ProductRepositories.getGrossMargin(brand.id, params)
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const temp: CardInterface = {
          title: "GMV",
          description:
            "Didapat dari pesanan yang masuk diluar dari pesanan yang dibatalkan.",
          value: data.current_period,
          compare: convertToLocaleNumber(data.last_period, data.current_period),
          type: convertToType(data.last_period, data.current_period),
        };

        if (isMounted.current) {
          setGmvCardLoading(false);
          setGMVCard(temp);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setGmvCardLoading(false);
        }
      });
  };

  const getSoldProductCardData = (params: any) => {
    if (isMounted.current) {
      setSoldProductCardLoading(true);
    }
    ProductRepositories.getSoldProduct(brand.id, params)
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const temp: CardInterface = {
          title: "Produk terjual",
          description: "Jumlah produk terjual",
          value: data.current_period,
          compare: convertToLocaleNumber(data.last_period, data.current_period),
          type: convertToType(data.last_period, data.current_period),
        };

        if (isMounted.current) {
          setSoldProductCard(temp);
          setSoldProductCardLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setSoldProductCardLoading(false);
        }
      });
  };

  const getRunRateCardData = (params: any) => {
    if (isMounted.current) {
      setRunRateCardLoading(true);
    }

    ProductRepositories.getRunRate(brand.id, params)
      .then((response: any) => {
        const temp: CardInterface = {
          title: "Average Run Rate",
          description: "Unit terjual dari 7 hari kebelakang",
          value: response.data.data,
        };
        if (isMounted.current) {
          setRunRateCard(temp);
          setRunRateCardLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setRunRateCardLoading(false);
        }
      });
  };

  const getWeeksOfSupplyCardData = (params: any) => {
    if (isMounted.current) {
      setWeeksOfSupplyCardLoading(true);
    }

    ProductRepositories.getWOS(brand.id, params)
      .then((response: any) => {
        const temp: CardInterface = {
          title: "Average Weeks Of Supply",
          description: "Nilai total rata-rata dari item yang ada.",
          value: response.data.data,
        };
        if (isMounted.current) {
          setWeeksOfSupplyCardLoading(false);
          setWeeksOfSupplyCard(temp);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setWeeksOfSupplyCardLoading(false);
        }
      });
  };

  const getData = (reset: boolean = false) => {
    if (isMounted.current) {
      if (reset) tableConfig.page.set(1);
      tableConfig.loading.set(true);
      setTableData([]);
    }

    let params: any = {};
    const conf = _.omit($clone(tableConfig.value), "loading");
    Object.keys(conf).forEach((prop) => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    Object.assign(params, {
      "date-from": filterConfig.date_from.get(),
      "date-to": filterConfig.date_to.get(),
      channel: selectedChannel,
    });

    if (selectedCategory.length !== totalCategoryOption) {
      Object.assign(params, {
        category: selectedCategory,
      });
    }

    if (selectedVendor.length !== totalVendorOption) {
      Object.assign(params, {
        vendor: selectedVendor,
      });
    }

    ProductRepositories.getTableData(brand.id, params)
      .then((res: any) => {
        if (isMounted.current) {
          tableConfig.total.set(res.data.meta.total);
          tableConfig.loading.set(false);
          setTableData(res.data.data);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          tableConfig.loading.set(false);
        }
      });
  };

  const handleChangeDate = (start: any, end: any) => {
    filterConfig.date_from.set(start.format("YYYY-MM-DD"));
    filterConfig.date_to.set(end.format("YYYY-MM-DD"));

    getAllData(true);
  };

  const getGmvChartData = (difference: any, params: any) => {
    ProductRepositories.getGrossMarginChart(brand.id, params)
      .then((response: AxiosResponse) => {
        const responseData = response.data.data;
        const tempCurrentPeriod = [];
        const tempLastPeriod = [];
        for (let i = 0; i <= difference; i++) {
          tempCurrentPeriod.push(0);
          tempLastPeriod.push(0);
        }

        const startDate = moment(filterConfig.date_from.get());
        responseData?.current_period.forEach((item: any) => {
          const tempDate: any = moment(item?.date).diff(startDate, "days");
          tempCurrentPeriod[tempDate] = item?.grand_total;
        });

        responseData?.last_period.forEach((item: any) => {
          const tempDate: any = moment(item?.date)
            .add(difference + 1, "days")
            .diff(startDate, "days");

          tempLastPeriod[tempDate] = item?.grand_total;
        });

        if (isMounted.current) {
          setChartReal(tempCurrentPeriod);
          setChartCompare(tempLastPeriod);
          setChartLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setChartLoading(false);
        }
      });
  };

  const getProductChartData = (difference: any, params: any) => {
    ProductRepositories.getSoldProductChart(brand.id, params)
      .then((response: AxiosResponse) => {
        const responseData = response.data.data;
        const tempCurrentPeriod = [];
        const tempLastPeriod = [];
        for (let i = 0; i <= difference; i++) {
          tempCurrentPeriod.push(0);
          tempLastPeriod.push(0);
        }

        const startDate = moment(filterConfig.date_from.get());
        responseData?.current_period.forEach((item: any) => {
          const tempDate: any = moment(item?.date).diff(startDate, "days");
          tempCurrentPeriod[tempDate] = item?.total_sold_product;
        });

        responseData?.last_period.forEach((item: any) => {
          const tempDate: any = moment(item?.date)
            .add(difference + 1, "days")
            .diff(startDate, "days");

          tempLastPeriod[tempDate] = item?.total_sold_product;
        });

        if (isMounted.current) {
          setChartReal(tempCurrentPeriod);
          setChartCompare(tempLastPeriod);
          setChartLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setChartLoading(false);
        }
      });
  };

  const onTableChange: TableChangeHandler<any> = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    tableConfig.page.set(page);
    tableConfig.per_page.set(sizePerPage);
    tableConfig.order_by.set(sortField);
    tableConfig.sorted_by.set(sortOrder);

    getData();
  };

  const changeChart = (key: string, index: number) => {
    if (isMounted.current) {
      setColorIndex(index);
      setChartLoading(true);
      chartSelection.set(key);
      chartColor.set(colorChart[index]);
    }

    const difference = moment(filterConfig.date_to.get()).diff(
      moment(filterConfig.date_from.get()),
      "days"
    );

    const tempChartLabel: string[] = [];

    for (let i = difference; i >= 0; i--) {
      tempChartLabel.push(
        moment(filterConfig.date_to.get())
          .subtract(i, "days")
          .format("DD MMMM YYYY")
      );
    }

    if (isMounted.current) setChartLabel(tempChartLabel);
    const tempChartLabelCompare: string[] = [];
    for (let i = difference; i >= 0; i--) {
      tempChartLabelCompare.push(
        moment(filterConfig.date_to.get())
          .subtract(i + difference + 1, "days")
          .format("DD MMMM YYYY")
      );
    }
    if (isMounted.current) setChartLabelCompare(tempChartLabelCompare);

    const params = {
      "date-from": filterConfig.date_from.get(),
      "date-to": filterConfig.date_to.get(),
      channel: selectedChannel,
    };

    if (selectedCategory.length !== totalCategoryOption) {
      Object.assign(params, {
        category: selectedCategory,
      });
    }

    if (selectedVendor.length !== totalVendorOption) {
      Object.assign(params, {
        vendor: selectedVendor,
      });
    }

    if (key === "gmv") {
      getGmvChartData(difference, params);
    } else if (key === "sold-product") {
      getProductChartData(difference, params);
    }
  };

  const handleTableSearch = _.debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      tableConfig.search.set(e.target.value);
      tableConfig.page.set(1);

      getData();
    },
    300
  );

  const customTitle = (tooltipItem: any) => {
    const real = chartLabel[_.get(tooltipItem, "[0].index", 0)];
    const compare = chartLabelCompare[_.get(tooltipItem, "[0].index", 0)];
    return [real, compare];
  };

  const handleExport = useCallback(() => {
    if (isMounted.current) {
      setLoadingExport(true);
      const body: any = document.querySelector(".app-container");
      if (body) {
        htmlToImage
          .toCanvas(body)
          .then(function (dataUrl) {
            const finish = htmlToPdf(dataUrl, "dashboard-produk");
            if (finish) setLoadingExport(false);
          })
          .catch(function (error) {
            setLoadingExport(false);
          });
      }
    }
  }, [isMounted]);

  return (
    <>
      <Helmet>
        <title>{t("Dashboard")}</title>
      </Helmet>

      <div className={"page-header-component"}>
        <h3 className={"title"}>
          Dashboard | <strong>Produk</strong>
        </h3>
        <VuiButton
          onClick={handleExport}
          variant="success"
          label={t("button.export")}
          loading={loadingExport}
        />
      </div>

      <div className="card-paper mb-4">
        <div className="card-content">
          <div className="card-filter-section">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label" style={{ display: "flex" }}>
                    Rentang Tanggal
                  </label>
                  <VuiDateRangePicker
                    startDate={filterConfig.date_from.get()}
                    endDate={filterConfig.date_to.get()}
                    callback={handleChangeDate}
                    useRanges={true}
                  />
                </div>
              </div>

              <>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">Kategori</label>
                    <MultipleCheckboxes
                      name={"kategori"}
                      options={categoryOptions}
                      labelOption="name"
                      valueOption="id"
                      values={selectedCategory}
                      onChange={(values) => {
                        setSelectedCategory(values);
                        getData();
                      }}
                      children={true}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">Channel</label>
                    <MultipleCheckboxes
                      name={"channel"}
                      labelOption="name"
                      options={channelOptions}
                      valueOption="id"
                      values={selectedChannel}
                      onChange={(values) => {
                        setSelectedChannel(values);
                        getData();
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">Vendor</label>
                    <MultipleCheckboxes
                      name={"vendor"}
                      options={vendorOptions}
                      labelOption="name"
                      valueOption="id"
                      values={selectedVendor}
                      onChange={(values) => {
                        setSelectedVendor(values);
                        getData();
                      }}
                    />
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>

      <div className="card-paper mb-4">
        <div className="card-content">
          <div className="card-chart-wrapper mb-4">
            <VuiCard
              isActive={chartSelection.get() === "gmv"}
              title={gmvCard.title}
              description={gmvCard.description}
              loading={gmvCardLoading}
              type={gmvCard.type}
              compare={gmvCard.compare}
              value={gmvCard.value}
              days={daysDifference}
              isCurrency
              hasComparison
              onClick={() => changeChart("gmv", 0)}
            />

            <VuiCard
              isActive={chartSelection.get() === "sold-product"}
              title={soldProductCard.title}
              description={soldProductCard.description}
              loading={soldProductCardLoading}
              type={soldProductCard.type}
              compare={soldProductCard.compare}
              value={soldProductCard.value}
              days={daysDifference}
              hasComparison
              onClick={() => changeChart("sold-product", 1)}
            />

            <VuiCard
              isActive={chartSelection.get() === "avg-run-rate"}
              title={runRateCard.title}
              description={runRateCard.description}
              loading={runRateCardLoading}
              value={runRateCard.value}
              days={daysDifference}
            />

            <VuiCard
              isActive={chartSelection.get() === "wos"}
              title={weeksOfSupplyCard.title}
              description={weeksOfSupplyCard.description}
              loading={weeksOfSupplyCardLoading}
              value={weeksOfSupplyCard.value}
              days={daysDifference}
            />
          </div>

          {chartLoading ? (
            <VuiLoader />
          ) : (
            <div className="line-chart-wrapper">
              <Line
                height={350}
                data={{
                  labels: chartLabel,
                  datasets: [
                    {
                      label: "Periode saat ini",
                      data: chartReal,
                      fill: false,
                      backgroundColor: chartColor.get(),
                      borderColor: chartColor.get(),
                      tension: 0,
                    },
                    {
                      label: "Periode sebelumnya",
                      data: chartCompare,
                      fill: false,
                      backgroundColor: chartColor.get(),
                      borderColor: chartColor.get(),
                      borderDash: [5, 5],
                      tension: 0,
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  legend: {
                    display: true,
                  },
                  tooltips: {
                    callbacks: {
                      title: customTitle,
                    },
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          callback: function (label: any) {
                            return abbreviateNumber(label, 0);
                          },
                        },
                        scaleLabel: {
                          display: true,
                        },
                      },
                    ],
                  },
                }}
              />
            </div>
          )}
        </div>
      </div>

      <div className="card-paper mb-4">
        <div className="card-header">
          <h6 className="card-header-title">Performa Produk</h6>
        </div>

        <div className="card-content">
          <div className={"default-filter-section"}>
            <div className={"filter-item filter-special type-search"}>
              <div className="input-group prefix-group">
                <span className="input-group-text">
                  <FontAwesomeIcon
                    className={"icon icon-prefix"}
                    icon={faSearch}
                  />
                </span>
                <input
                  defaultValue={tableConfig.search.get()}
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={handleTableSearch}
                />
              </div>
            </div>
          </div>

          <VuiDataTable
            loading={tableConfig.loading.get()}
            columns={tableColumns}
            data={tableData}
            page={tableConfig.page.get()}
            sizePerPage={tableConfig.per_page.get()}
            totalSize={tableConfig.total.get()}
            onTableChange={onTableChange}
          />
        </div>
      </div>
    </>
  );
};

export default DashboardProduk;
