import { systemReducer } from "./system/reducers";
import { combineReducers } from "redux";
import { permissionReducer } from "./permission/reducers";
import { brandReducer } from "./brand/reducers";
import { vendorReducer } from "./vendor/reducers";
import { formatterReducer } from "./formatter/reducers";

export const rootReducer = combineReducers({
  system: systemReducer,
  permission: permissionReducer,
  brand: brandReducer,
  vendor: vendorReducer,
  formatter: formatterReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
