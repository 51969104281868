import * as yup from "yup";

export const validationSchema = () => {
  let shapes = {
    vendor_id: yup.mixed().required().label("vendor"),
    quantity: yup.string().required().label("quantity"),
  };

  return yup.object().shape(shapes);
};
