const defaultStatusOptions = [
  {
    id: 1,
    name: "Active",
  },
  {
    id: 0,
    name: "Inactive",
  },
];

const langOptions = [
  {
    value: "en",
    label: "EN",
  },
  {
    value: "id",
    label: "ID",
  },
];

const defaultRoleOptions = [
  {
    id: 1,
    name: "Admin",
  },
  {
    id: 2,
    name: "Cashier",
  },
  {
    id: 3,
    name: "Employee",
  },
  {
    id: 4,
    name: "Supplier",
  },
];

const categoryOptions = [
  {
    value: 1,
    label: "Kulkas",
  },
  {
    value: 2,
    label: "AC",
  },
  {
    value: 3,
    label: "TV",
  },
  {
    value: 4,
    label: "Mesin Cuci",
  },
];

const channelOptions = [
  {
    value: 1,
    label: "Tokopedia",
  },
];

const vendorOptions = [
  {
    value: 1,
    label: "Vendor 1",
  },
  {
    value: 2,
    label: "Vendor 2",
  },
  {
    value: 3,
    label: "Vendor 3",
  },
  {
    value: 4,
    label: "Vendor 4",
  },
  {
    value: 5,
    label: "Vendor 5",
  },
  {
    value: 6,
    label: "Vendor 7",
  },
  {
    value: 7,
    label: "Vendor 7",
  },
  {
    value: 8,
    label: "Vendor 8",
  },
  {
    value: 9,
    label: "Vendor 9",
  },
  {
    value: 10,
    label: "Vendor 10",
  },
];

const defaultWeightOptions = [
  {
    value: "GR",
    label: "Gram (g)",
  },
  {
    value: "KG",
    label: "Kilogram (kg)",
  },
];

const channelConfig = [
  {
    label: "Tokopedia",
    logo: "/images/logo-tokopedia.png",
    simpleLogo: "/images/logo-tokopedia-simple.png",
  },
];

const STATUS = {
  NEW_ORDER: "Pesanan Baru",
  PROCESS_BY_VENDOR: "Diproses Vendor",
  READY_TO_SHIP: "Siap Dikirim",
  ON_DELIVERY: "Dalam Pengiriman",
  ORDER_DELIVERED: "Pesanan Sampai",
  DELIVERED: "Sampai",
  COMPLETED: "Selesai",
  CANCELLED: "Dibatalkan",
  PAID: "Terbayar",
  DIVERTED: "Dialihkan",
};

const invoiceStatusConfig = [
  {
    key: STATUS.NEW_ORDER,
    label: "Pesanan Baru",
    badge: "bg-success",
  },
  {
    key: STATUS.PROCESS_BY_VENDOR,
    label: "Diproses Vendor",
    badge: "bg-info",
  },
  {
    key: STATUS.ON_DELIVERY,
    label: "Dalam Pengiriman",
    badge: "bg-warning",
  },
  {
    key: STATUS.ORDER_DELIVERED,
    label: "Pesanan Sampai",
    badge: "bg-orange",
  },
  {
    key: STATUS.COMPLETED,
    label: "Selesai",
    badge: "bg-secondary",
  },
  {
    key: STATUS.CANCELLED,
    label: "Dibatalkan",
    badge: "bg-danger",
  },
];

const orderStatusConfig = [
  {
    key: STATUS.NEW_ORDER,
    label: "Pesanan Baru",
    badge: "bg-success",
  },
  {
    key: STATUS.READY_TO_SHIP,
    label: "Siap Dikirim",
    badge: "bg-info",
  },
  {
    key: STATUS.ON_DELIVERY,
    label: "Dalam Pengiriman",
    badge: "bg-warning",
  },
  {
    key: STATUS.DELIVERED,
    label: "Sampai",
    badge: "bg-orange",
  },
  {
    key: STATUS.COMPLETED,
    label: "Selesai",
    badge: "bg-secondary",
  },
  {
    key: STATUS.DIVERTED,
    label: "Dialihkan",
    badge: "bg-danger",
  },
  {
    key: STATUS.CANCELLED,
    label: "Dibatalkan",
    badge: "bg-danger",
  },
  {
    key: STATUS.PAID,
    label: "Terbayar",
    badge: "bg-secondary",
  },
];

const bankOptions = [
  {
    id: "BCA",
    name: "BCA",
  },
  {
    id: "MANDIRI",
    name: "Mandiri",
  },
  {
    id: "BRI",
    name: "BRI",
  },
  {
    id: "Maybank",
    name: "Maybank",
  },
];

export {
  defaultStatusOptions,
  defaultRoleOptions,
  defaultWeightOptions,
  categoryOptions,
  channelOptions,
  vendorOptions,
  langOptions,
  invoiceStatusConfig,
  orderStatusConfig,
  channelConfig,
  STATUS,
  bankOptions,
};
