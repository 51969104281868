import React, { ChangeEvent, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import useIsMounted from "../../../../../vodea/utilities/hooks/useIsMounted";
import { Helmet } from "react-helmet-async";
import VuiBreadcrumb from "../../../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import VendorRepository from "../../../../../repositories/VendorRepository";
import {
  formatFormData,
  formatSetValueForm,
} from "../../../../../vodea/utilities/helpers/form";
import { AxiosError, AxiosResponse } from "axios";
import { Controller, useForm } from "react-hook-form";
import {
  InformationBaseModel,
  InformationInputs,
  informationSchema,
} from "./validation";
import {
  convertExcelToJson,
  mapHookErrors,
  showToast,
} from "../../../../../vodea/utilities/helpers/global";
import { useState } from "@hookstate/core";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import VuiActionForm from "../../../../../vodea/@vodea-ui/components/Forms/VuiActionForm";
import _ from "lodash";
import PinAddress from "../../../../../components/PinAddress";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../stores";
import { Form, Tab, Tabs } from "react-bootstrap";
import TabUser from "./tabUser";
import { CSVLink } from "react-csv";
import { $clone } from "../../../../../vodea/utilities";
import { faDownload, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImportTemplateRepository from "../../../../../repositories/ImportTemplateRepository";
import fileDownload from "js-file-download";

const SettingVendorForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { id } = useParams();
  const { brand } = useSelector((state: RootState) => state.brand);
  const selectedArea = useState<any[]>([]);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [fileUpload, setFileUpload] = React.useState<any>(null);
  const [progressUpload, setProgressUpload] = React.useState<number>(0);
  const [isProgress, setIsProgress] = React.useState<boolean>(false);

  const title = `${id ? t("general.edit") : t("general.create")} ${t(
    "settings.vendor.title"
  )}`;

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = () => {
    const params = {
      with: ["vendorAreas"],
    };

    VendorRepository.show(id, params)
      .then((response: AxiosResponse) => {
        const data = response.data.data;

        const formattedData = formatSetValueForm(InformationBaseModel, data);

        _.forEach(formattedData, (value, name: any) => {
          informationSetValue(name, value);
        });

        if (data.vendor_areas.length) {
          selectedArea.set(data.vendor_areas);
        }
      })
      .catch((e: AxiosError) => {
        showToast(e?.response?.data.message, "error");
      });
  };

  const informationLoading = useState(false);
  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    errors: informationErrors,
    setError: informationSetError,
    setValue: informationSetValue,
    watch: informationWatch,
    control: informationControl,
  } = useForm<InformationInputs>({
    resolver: yupResolver(informationSchema),
    defaultValues: {
      lat: "-6.20924990562099",
      lng: "106.84323982844344",
    },
  });

  const watchLatLng = informationWatch(["lat", "lng"]);

  const handleChangePinAddress = (position: any) => {
    informationSetValue("lat", position.lat);
    informationSetValue("lng", position.lng);
    informationSetValue("city", `${position.city}, ${position.district}`);
    informationSetValue("address", position.address);
    informationSetValue("postal_code", position.postal_code);
  };

  const onInformationSubmit = informationHandleSubmit((data) => {
    informationLoading.set(true);

    const formData = formatFormData(data);
    const form = new FormData();

    if (fileUpload) {
      form.append("vendor_area_file", fileUpload[0]);
    }

    _.forEach(formData, (value, name) => {
      form.append(name, value);
    });

    form.delete("is_priority");
    form.append("is_priority", Number(formData.is_priority).toString());
    form.append("brand_ids[]", brand.id);

    if (id) {
      form.append("_method", "PUT");
    }

    (id
      ? VendorRepository.update(id, form, (event: any) => {
          setIsProgress(true);
          setProgressUpload(Math.round((100 * event.loaded) / event.total));
        })
      : VendorRepository.create(form, (event: any) => {
          setIsProgress(true);
          setProgressUpload(Math.round((100 * event.loaded) / event.total));
        })
    )
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          if (id) showToast(t("response.successUpdateData"), "success");
          if (!id) showToast(t("response.successCreateData"), "success");
          informationLoading.set(false);
          setIsProgress(false);

          if (!id) navigate("/settings/vendor");
        }
      })
      .catch((e: AxiosError) => {
        if (isMounted.current && e?.response?.data?.errors) {
          const errors = mapHookErrors(e.response.data.errors);
          Object.keys(errors).forEach((key: any) => {
            informationSetError(key, errors[key]);
          });
          setIsProgress(false);

          showToast(e.response.data.message, "error");
        }

        informationLoading.set(false);
      });
  });

  const handleSelectedFile = (event: any) =>
    setFileUpload(event?.target?.files);

  const handleFileDownload = () => {
    ImportTemplateRepository.vendorArea({
      vendor: id,
    }).then((resp: any) => {
      fileDownload(resp.data, "vendor-area.csv");
    });
  };

  const handleClickUpload = () => {
    const domFile = document.getElementById("file");
    if (domFile) {
      domFile.click();
    }
  };

  const breadcrumbList = [
    {
      label: t("settings.title"),
      link: "/settings",
    },
    {
      label: t("settings.vendor.title"),
      link: "/settings/vendor",
    },
    {
      label: title,
      link: `/settings/vendor/${id ? id : "create"}`,
    },
  ];

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={"page-header-component"}>
        <h3 className={"title"}>{title}</h3>
      </div>

      <Tabs defaultActiveKey="general" variant={"pills"} className="mb-3">
        <Tab eventKey="general" title={t("general.general")}>
          <form className={"form-wrapper"} onSubmit={onInformationSubmit}>
            <div className={"default-page-layout"}>
              <div className={"information-section"}>
                <div className={"card-paper"}>
                  <div className={"card-header"}>
                    <h6 className={"card-header-title"}>
                      {t("general.information")}
                    </h6>
                  </div>
                  <div className={"card-content"}>
                    <div
                      className={clsx({
                        "form-group": true,
                        error: informationErrors.name,
                      })}
                    >
                      <label className={"form-label"}>
                        {t("form.name.label")}
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        ref={informationRegister}
                      />
                      <label className={"label-help"}>
                        {informationErrors.name?.message}
                      </label>
                    </div>

                    <div className={"row"}>
                      <div className={"col-md-6"}>
                        <div
                          className={clsx({
                            "form-group": true,
                            error: informationErrors.telephone,
                          })}
                        >
                          <label className={"form-label"}>
                            {t("form.telephone.label")}
                          </label>
                          <input
                            type="text"
                            name="telephone"
                            className="form-control"
                            ref={informationRegister}
                          />
                          <label className={"label-help"}>
                            {informationErrors.telephone?.message}
                          </label>
                        </div>
                      </div>
                      <div className={"col-md-6"}>
                        <div
                          className={clsx({
                            "form-group": true,
                            error: informationErrors.fax,
                          })}
                        >
                          <label className={"form-label"}>
                            {t("form.fax.label")}
                          </label>
                          <input
                            type="text"
                            name="fax"
                            className="form-control"
                            ref={informationRegister}
                          />
                          <label className={"label-help"}>
                            {informationErrors.fax?.message}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className={"row"}>
                      <div className={"col-md-6"}>
                        <div
                          className={clsx({
                            "form-group": true,
                            error: informationErrors.is_priority,
                          })}
                        >
                          <Controller
                            name={"is_priority"}
                            control={informationControl}
                            defaultValue={false}
                            render={(props) => (
                              <Form.Check
                                type={"checkbox"}
                                id={`checkbox-is-priority`}
                                checked={props.value}
                                label={`${t("form.mainVendor.label")}?`}
                                onChange={(e) =>
                                  props.onChange(e.target.checked)
                                }
                              />
                            )}
                          />

                          <label className={"label-help"}>
                            {informationErrors.is_priority?.message}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div
                          className={clsx({
                            "form-group": true,
                            error: informationErrors.city,
                          })}
                        >
                          <label className={"form-label"}>
                            {t("form.city.label")}
                          </label>
                          <input
                            type="text"
                            name="city"
                            className="form-control"
                            ref={informationRegister}
                          />
                          <label className={"label-help"}>
                            {informationErrors.city?.message}
                          </label>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div
                          className={clsx({
                            "form-group": true,
                            error: informationErrors.postal_code,
                          })}
                        >
                          <label className={"form-label"}>
                            {t("form.postalCode.label")}
                          </label>
                          <input
                            type="text"
                            name="postal_code"
                            className="form-control"
                            ref={informationRegister}
                          />
                          <label className={"label-help"}>
                            {informationErrors.postal_code?.message}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div
                      className={clsx({
                        "form-group": true,
                        error: informationErrors.address,
                      })}
                    >
                      <label className={"form-label"}>
                        {t("form.address.label")}
                      </label>
                      <textarea
                        name="address"
                        className="form-control"
                        rows={4}
                        ref={informationRegister}
                      />
                      <label className={"label-help"}>
                        {informationErrors.address?.message}
                      </label>
                    </div>

                    <div className="default-btn-action-wrapper type-start">
                      <button
                        type={"button"}
                        className={"btn btn-primary btn-small"}
                        onClick={handleFileDownload}
                      >
                        <FontAwesomeIcon
                          className={"icon icon-prefix"}
                          icon={faDownload}
                        />
                        {t("button.download")}
                      </button>

                      <button
                        type={"button"}
                        className={"btn btn-info btn-small"}
                        onClick={handleClickUpload}
                        disabled={isProgress && progressUpload != 100}
                      >
                        <div
                          className={clsx({
                            "loader-wrapper": true,
                            loading: isProgress && progressUpload != 100,
                          })}
                        >
                          <div
                            className="spinner-border text-light"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                          <FontAwesomeIcon
                            className={"icon icon-prefix"}
                            icon={faUpload}
                          />
                          {t("button.upload")}
                        </div>
                      </button>

                      {fileUpload && fileUpload.length > 0 && (
                        <strong>{_.get(fileUpload, "0.name")}</strong>
                      )}

                      <div className={"d-none"}>
                        <input
                          id={"file"}
                          type="file"
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          onChange={handleSelectedFile}
                        />
                      </div>
                    </div>

                    <div
                      className={clsx({
                        "form-group": true,
                        error: informationErrors.lat || informationErrors.lng,
                      })}
                    >
                      <label className={"form-label"}>
                        {t("form.pinAddress.label")}
                      </label>
                      <input
                        type="hidden"
                        name="lat"
                        className="form-control"
                        ref={informationRegister}
                      />
                      <input
                        type="hidden"
                        name="lng"
                        className="form-control"
                        ref={informationRegister}
                      />
                      <PinAddress
                        lat={watchLatLng.lat}
                        lng={watchLatLng.lng}
                        callBack={handleChangePinAddress}
                      />
                      {informationErrors.lat || informationErrors.lng ? (
                        <label className={"label-help"}>
                          {t("errors.pinAddress")}
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*<div className={"card-paper mt-3"}>*/}
            {/*  <div className={"card-header"}>*/}
            {/*    <h6 className={"card-header-title"}>*/}
            {/*      {t("general.vendorArea")}*/}
            {/*    </h6>*/}

            {/*    */}
            {/*  </div>*/}
            {/*</div>*/}

            <VuiActionForm
              loading={informationLoading.get()}
              cancelLink={"/settings/vendor"}
            />
          </form>
        </Tab>
        <Tab
          eventKey="users"
          title={t("general.users")}
          disabled={id === undefined}
        >
          <TabUser />
        </Tab>
      </Tabs>
    </>
  );
};

export default SettingVendorForm;
