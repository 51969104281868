import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import VuiBreadcrumb from "../../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import { VuiButton } from "../../../../vodea/@vodea-ui/components/VuiButton";
import { useState } from "@hookstate/core/dist";
import moment from "moment";
import VuiDateRangePicker from "../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker";
import Select from "react-select";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faChevronLeft,
  faKey,
  faSearch,
  faSuitcase,
  faTruck,
  faUser,
  faUserCircle,
  faUserFriends,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";

const statusOptions = [
  {
    key: 1,
    label: "Semua",
  },
  {
    key: 2,
    label: "Menunggu SendPayment",
  },
  {
    key: 3,
    label: "Lunas",
  },
  {
    key: 4,
    label: "Diproses",
  },
  {
    key: 5,
    label: "Dikirim",
  },
  {
    key: 6,
    label: "Sampai",
  },
  {
    key: 7,
    label: "Dibatalkan",
  },
  {
    key: 8,
    label: "Dikembalikan",
  },
  {
    key: 9,
    label: "Gagal",
  },
];

const tableColumns = [
  {
    dataField: "name",
    text: "Nama",
  },
  {
    dataField: "email",
    text: "Email",
  },
  {
    dataField: "no_hp",
    text: "Nomor Telpon",
  },
  {
    dataField: "peran",
    text: "Peran",
  },
];

const tableData = [
  {
    id: 1,
    name: "Admin",
    peran: "Administrator",
    email: "admin@gmail.com",
    no_hp: "0821212121141",
  },
  {
    id: 2,
    name: "Wiliam Dolan",
    peran: "Administrator",
    type: "Peran",
    email: "admin@gmail.com",
    no_hp: "0821212121141",
  },
  {
    id: 3,
    name: "Super Administrator",
    peran: "Administrator",
    type: "Peran",
    email: "admin@gmail.com",
    no_hp: "0821212121141",
  },
  {
    id: 4,
    name: "Tanudjaja",
    peran: "Administrator",
    type: "Admin",
    email: "admin@gmail.com",
    no_hp: "0821212121141",
  },
  {
    id: 5,
    name: "Kevin",
    peran: "Administrator",
    type: "Admin",
    email: "kevin@gmail.com",
    no_hp: "0821212121141",
  },
];

const PengaturanAkun: React.FC<any> = () => {
  const { t } = useTranslation();
  const statusSelected = useState(1);

  const filterConfig = useState({
    loading: false,

    date_from: moment().subtract(6, "days").format("YYYY-MM-DD"),
    date_to: moment().format("YYYY-MM-DD"),

    date_compare_from: moment().subtract(14, "days").format("YYYY-MM-DD"),
    date_compare_to: moment().subtract(7, "days").format("YYYY-MM-DD"),
  });

  const handleChangeDate = (start: any, end: any) => {
    filterConfig.date_from.set(start.format("YYYY-MM-DD"));
    filterConfig.date_to.set(end.format("YYYY-MM-DD"));
  };

  const handleChangeCompareDate = (start: any, end: any) => {
    filterConfig.date_compare_from.set(start.format("YYYY-MM-DD"));
    filterConfig.date_compare_to.set(end.format("YYYY-MM-DD"));
  };

  const getData = () => {
    filterConfig.loading.set(true);

    setTimeout(() => {
      filterConfig.loading.set(false);
    }, 1000);
  };

  const breadcrumbList = [
    {
      label: t("Order"),
      link: "/order",
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("Order")}</title>
      </Helmet>

      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="page-header type-form">
          <div className="d-flex justify-content-start align-items-center">
            <Link to={"/setting"}>
              <div className={"icon-wrapper"}>
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  className={"icon icon-prefix"}
                />
              </div>
            </Link>

            <div className="">
              <h4 className="title">Akun</h4>
            </div>
          </div>
        </div>

        <div>
          <Link to={"/setting/akun/create"}>
            <button type="button" className="btn btn-primary">
              Tambah Akun
            </button>
          </Link>
        </div>
      </div>

      <div className="page-content">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <div className="card-paper mb-4">
              <div className="card-content">
                <div className={"default-filter-section"}>
                  <div className={"filter-item filter-special type-search"}>
                    <div className="input-group prefix-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon
                          className={"icon icon-prefix"}
                          icon={faSearch}
                        />
                      </span>
                      <input
                        defaultValue={""}
                        type="text"
                        className="form-control"
                        placeholder="Search"
                      />
                    </div>
                  </div>
                </div>
                <BootstrapTable
                  wrapperClasses={"table-responsive"}
                  keyField={"id"}
                  data={tableData}
                  columns={tableColumns}
                  filter={filterFactory()}
                  pagination={paginationFactory({
                    page: 1,
                    sizePerPage: 5,
                    totalSize: 5,
                    showTotal: true,
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PengaturanAkun;
