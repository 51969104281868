import * as yup from "yup";
import "yup-phone";

export type InformationInputs = {
  name: string;
  email: string;
  mobile_phone: string;
  role_id: any;
  password: string | null;
};

export const informationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  mobile_phone: yup.string().phone("ID", true).required().label("phone number"),
  role_id: yup.mixed().required().label("role"),
  password: yup.string().min(8).required().label("password"),
});

export const informationAdminSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().min(8).required().label("password"),
  role_id: yup.mixed().required().label("role"),
});

export const informationAdminUpdateSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
});

export const informationSchemaUpdate = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  mobile_phone: yup.string().phone("ID", true).required().label("phone number"),
  role_id: yup.mixed().required().label("role"),
  password: yup.string().label("password").nullable(true),
});

export const InformationBaseModel: InformationInputs = {
  name: "",
  email: "",
  mobile_phone: "",
  role_id: null,
  password: null,
};
