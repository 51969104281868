import { api, createCancelTokenHandler } from "../../vodea/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  show: function (brandId: string, id: string, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/update-stock-history/${id}`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[this.show.name].handleRequestCancellation()
            .token,
      }
    );
  },
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);
