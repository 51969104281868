export interface PermissionState {
  permission: any;
}

export const LOAD_PERMISSION = 'LOAD_PERMISSION';

interface UpdatePermissionAction {
  type: typeof LOAD_PERMISSION;
  payload: PermissionState;
}

export type PermissionActionTypes = UpdatePermissionAction;
