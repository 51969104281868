import { api, createCancelTokenHandler } from "../vodea/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  vendorArea: function (params: any = null) {
    return api.get(`${endPoint()}/api/import-template/vendor-area`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.vendorArea.name
        ].handleRequestCancellation().token,
      responseType: "blob",
    });
  },
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);
