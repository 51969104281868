import React, { useEffect } from "react";
import { useState } from "@hookstate/core/dist";
import OutsideClickHandler from "react-outside-click-handler";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { $clone } from "../../vodea/utilities";
import _ from "lodash";

interface MultipleCheckboxesProps {
  name: string;
  options: Array<any>;
  values: Array<any>;
  onChange: (val: any) => void;
  labelOption?: string;
  valueOption?: string;
  children?: boolean;
}

const MultipleCheckboxes: React.FC<MultipleCheckboxesProps> = ({
  name = "",
  options,
  values,
  onChange,
  labelOption = "label",
  valueOption = "value",
  children,
}) => {
  const show = useState(false);
  const tempValues = useState<any[]>([]);

  const [categoryList, setCategory] = React.useState<any>([]);

  useEffect(() => {
    if (values) {
      tempValues.set(values);
    }
  }, [values]);

  useEffect(() => {
    const category: any = [];
    if (options) {
      options.map((option: any) => {
        const parentId = option.id;
        const arrayChild: any = [];
        if (option.children) {
          option.children.map((child: any) => {
            // const childId = child.id;
            arrayChild.push(child.id);
          });
          const dataa = {
            parent: parentId,
            child: arrayChild,
          };
          category.push(dataa);
        }
      });
      setCategory(category);
    }
  }, [options]);

  const handleClick = (value: any) => {
    const exists = tempValues.get().includes(value);
    let results = $clone(tempValues.get());

    let parent = false;

    const cat = [...categoryList];
    const arrayChild: any = [];
    arrayChild.push(value);

    for (var i = 0; i < cat.length; i++) {
      if (cat[i].parent == value) {
        parent = true;
      }
    }

    if (children) {
      if (parent) {
        if (exists) {
          arrayChild.push(value);
          for (var i = 0; i < cat.length; i++) {
            if (cat[i].parent == value) {
              for (var j = 0; j < cat[i].child.length; j++) {
                arrayChild.push(cat[i].child[j]);
              }
            }
          }

          const arrayNew: any = [];

          for (var k = 0; k < results.length; k++) {
            if (!arrayChild.includes(results[k])) {
              arrayNew.push(results[k]);
            }
          }

          tempValues.set(arrayNew);
          onChange(arrayNew);
        } else {
          const arrNew: any = [];
          for (var i = 0; i < cat.length; i++) {
            if (cat[i].parent == value) {
              arrNew.push(cat[i].parent);

              for (var j = 0; j < cat[i].child.length; j++) {
                if (!results.includes(cat[i].child[j])) {
                  arrNew.push(cat[i].child[j]);
                }
              }
            }
          }

          for (var k = 0; k < results.length; k++) {
            arrNew.push(results[k]);
          }

          tempValues.set(arrNew);
          onChange(arrNew);
        }
      } else {
        //children
        if (exists) {
          // uncheck child
          const arrToCut: any = [];
          for (var i = 0; i < cat.length; i++) {
            for (var j = 0; j < cat[i].child.length; j++) {
              if (cat[i].child[j] == value) {
                arrToCut.push(cat[i].child[j]);
                if (results.includes(cat[i].parent)) {
                  arrToCut.push(cat[i].parent);
                }
              }
            }
          }

          const arrayNew: any = [];

          for (var k = 0; k < results.length; k++) {
            if (!arrToCut.includes(results[k])) {
              // results.splice(k,1)
              arrayNew.push(results[k]);
            }
          }
          tempValues.set(arrayNew);
          onChange(arrayNew);
        } else {
          // check children
          results.push(value);
          tempValues.set(results);
          onChange(results);
        }
      }
    } else {
      // dont have children in props
      if (exists) {
        if (results.length > 1) {
          results = tempValues.get().filter((c) => {
            return c !== value;
          });
        }
      } else {
        results.push(value);
      }

      tempValues.set(results);
      onChange(results);
    }
  };

  const handleSelectAll = () => {
    if (children) {
      const arr: any = [];
      for (var i = 0; i < categoryList.length; i++) {
        arr.push(categoryList[i].parent);
        for (var j = 0; j < categoryList[i].child.length; j++) {
          arr.push(categoryList[i].child[j]);
        }
      }

      tempValues.set(arr);
      onChange(arr);
    } else {
      const selectedData = options.map((item) => {
        return _.get(item, valueOption);
      });

      tempValues.set(selectedData);
      onChange(selectedData);
    }
  };

  const handleClear = () => {
    tempValues.set([]);
    onChange([]);
  };

  return (
    <>
      <OutsideClickHandler
        onOutsideClick={() => {
          show.set(false);
        }}
      >
        <div className="dropdown-select-checkbox">
          <div
            className="dropdown-control"
            onClick={() => show.set(!show.value)}
          >
            {$clone(tempValues.get()).length} {name} dipilih{" "}
            <FontAwesomeIcon icon={faAngleDown} className={"dropdown-icon"} />
          </div>

          <div
            className={clsx("dropdown-content", {
              show: show.get(),
            })}
          >
            <div className="dropdown-header">
              <label className="link-action select" onClick={handleSelectAll}>
                Select all
              </label>
              <label className="link-action remove" onClick={handleClear}>
                Clear
              </label>
            </div>
            <div className="dropdown-option-wrapper">
              <div className="dropdown-options">
                {options &&
                  options.map((item, key: number) => {
                    return (
                      <div key={key}>
                        <div className="d-flex flex-column justify-content-start align-items-start">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id={`check-option-${name}-${key}`}
                              checked={tempValues
                                .get()
                                .includes(_.get(item, valueOption))}
                              onClick={() =>
                                handleClick(_.get(item, valueOption))
                              }
                              onChange={() => {}}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check-option-${name}-${key}`}
                            >
                              {_.get(item, labelOption)}
                            </label>
                          </div>
                          {item.children && item.children.length > 0 ? (
                            <div className="ms-2">Sub-Category</div>
                          ) : (
                            ""
                          )}
                          {children
                            ? item.children
                              ? item.children.map(
                                  (optionss: any, keyy: number) => {
                                    return (
                                      <div
                                        className="ms-2 form-check"
                                        key={keyy}
                                      >
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id={`check-option-children-${name}-${keyy}`}
                                          checked={tempValues
                                            .get()
                                            .includes(
                                              _.get(optionss, valueOption)
                                            )}
                                          onClick={() =>
                                            handleClick(
                                              _.get(optionss, valueOption)
                                            )
                                          }
                                          onChange={() => {}}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`check-option-children-${name}-${keyy}`}
                                        >
                                          {_.get(optionss, labelOption)}
                                        </label>
                                      </div>
                                    );
                                  }
                                )
                              : null
                            : null}
                        </div>

                        {/* {children ?} */}
                        {/* <div className = "ms-2">
                          Hi
                      </div> */}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default MultipleCheckboxes;
