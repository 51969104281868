import { Helmet } from "react-helmet-async";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import { useTranslation } from "react-i18next";
import VuiDateRangePicker from "../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker";
import moment from "moment";
import { useState } from "@hookstate/core/dist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useIsMounted from "../../../../vodea/utilities/hooks/useIsMounted";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Line } from "react-chartjs-2";
import VuiLoader from "../../../../vodea/@vodea-ui/components/VuiLoader";
import { TableChangeHandler } from "react-bootstrap-table-next";
import MultipleCheckboxes from "../../../../components/MultipleCheckboxes";
import CustomerRepository from "../../../../repositories/Widget/CustomerRepository";
import ChannelBrandRepositories from "../../../../repositories/Brand/ChannelRepository";
import BrandVendorRepositories from "../../../../repositories/Brand/VendorRepository";
import BrandCategoryRepositories from "../../../../repositories/Brand/CategoryRepository";
import VuiDataTable from "../../../../vodea/@vodea-ui/components/VuiDataTable";
import _ from "lodash";
import { $clone } from "../../../../vodea/utilities";
import { colorChart } from "../../../../data";
import { cardDefaultValues, CardInterface } from "../Penjualan/interface";
import { AxiosResponse } from "axios";
import VuiCard from "../../../../vodea/@vodea-ui/components/VuiCard";
import {
  convertToLocaleNumber,
  convertToType,
} from "../../../../vodea/utilities/helpers/global";
import VuiNumberFormat from "../../../../vodea/@vodea-ui/components/VuiNumberFormat";
import DoughnutCard from "../../../../components/DoughnutCard";
import * as htmlToImage from "html-to-image";
import { htmlToPdf } from "../../../../vodea/utilities/helpers/pdf.helper";
import { VuiButton } from "../../../../vodea/@vodea-ui/components/VuiButton";

const productTableColumns = [
  {
    dataField: "recipient_city",
    text: "Kota",
    sort: true,
  },
  {
    dataField: "total_customer",
    text: "Pembeli",
    sort: true,
    formatter: (cell: any) => <VuiNumberFormat data={cell} />,
  },
  {
    dataField: "total_order",
    text: "Pesanan",
    sort: true,
    formatter: (cell: any) => <VuiNumberFormat data={cell} />,
  },
  {
    dataField: "total_item",
    text: "Barang",
    sort: true,
    formatter: (cell: any) => <VuiNumberFormat data={cell} />,
  },
  {
    dataField: "grand_total",
    text: "Nilai Pesanan",
    sort: true,
    formatter: (cell: any) => <VuiNumberFormat data={cell} prefix={"Rp "} />,
  },
];

const DashboardPembeli: React.FC<any> = () => {
  const { t } = useTranslation();
  const { brand } = useSelector((state: RootState) => state.brand);
  const isMounted = useIsMounted();
  const chartSelection = useState<string>("new-customer");
  const [colorIndex, setColorIndex] = React.useState<number>(0);
  const [channelOptions, setChannelOptions] = React.useState<any>([]);
  const [selectedChannel, setSelectedChannel] = React.useState<any>([]);
  const [categoryOptions, setCategoryOptions] = React.useState<any>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<any>([]);
  const [vendorOptions, setVendorOptions] = React.useState<any>([]);
  const [selectedVendor, setSelectedVendor] = React.useState<any>([]);
  const [customerGenderChartLoading, setCustomerGenderChartLoading] =
    React.useState<boolean>(false);
  const [
    customerPaymentMethodChartLoading,
    setCustomerPaymentMethodChartLoading,
  ] = React.useState<boolean>(false);
  const [cityCustomerChartLoading, setCityCustomerChartLoading] =
    React.useState<boolean>(false);

  const [totalCategoryOption, setTotalCategoryOption] =
    React.useState<number>(0);
  const [totalVendorOption, setTotalVendorOption] = React.useState<number>(0);
  const [chartLoading, setChartLoading] = React.useState<boolean>(false);
  const [isFirstMount, setIsFirstMount] = React.useState<boolean>(false);
  const chartPembeliLabel = useState<any[]>([
    "Tidak diketahui",
    "Laki-laki",
    "Perempuan",
  ]);
  const chartColor = useState<string>(colorChart[0]);
  const [chartLabelCompare, setChartLabelCompare] = React.useState<any>([]);
  const [loadingExport, setLoadingExport] = React.useState<boolean>(false);

  const tableConfig = useState({
    loading: false,
    page: 1,
    per_page: 5,
    total: 0,
    sorted_by: "desc",
    order_by: "created_at",
    search: "",
  });

  const filterConfig = useState({
    date_from: moment().subtract(6, "days").format("YYYY-MM-DD"),
    date_to: moment().format("YYYY-MM-DD"),
  });

  const getChannelOptions = () => {
    ChannelBrandRepositories.all(brand.id).then((response: any) => {
      const temp: any[] = [];
      response.data.data.forEach((item: any) => {
        temp.push(item.id);
      });
      if (isMounted.current) {
        setChannelOptions(response.data.data);
        setSelectedChannel(temp);
      }
    });
  };

  const getCategoryOptions = () => {
    BrandCategoryRepositories.all(brand.id, {
      with: "children",
      only: "parent",
      per_page: 1000,
    }).then((response: any) => {
      let total = 0;
      const temp: any = [];
      response.data.data.forEach((item: any) => {
        if (item.children.length > 0) {
          item.children.forEach((child: any, index: number) => {
            temp.push(child.id);
            total += 1;
          });
        }
        temp.push(item.id);
        total += 1;
      });
      if (isMounted.current) {
        setTotalCategoryOption(total);
        setCategoryOptions(response.data.data);
        setSelectedCategory(temp);
      }
    });
  };

  const getVendorOptions = () => {
    BrandVendorRepositories.all(brand.id, {
      per_page: 1000,
    }).then((response: any) => {
      if (isMounted.current) {
        const data = response.data.data;
        const temp: any = [];
        data.forEach((item: any) => {
          temp.push(item.id);
        });
        setVendorOptions(data);
        setTotalVendorOption(data.length);
        setSelectedVendor(temp);
      }
    });
  };

  const handleChangeDate = (start: any, end: any) => {
    filterConfig.date_from.set(start.format("YYYY-MM-DD"));
    filterConfig.date_to.set(end.format("YYYY-MM-DD"));

    getAllData(true);
  };

  const [daysDifference, setDaysDifference] = React.useState(0);
  const [newCustomerCard, setNewCustomerCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [newCustomerCardLoading, setNewCustomerCardLoading] =
    React.useState<boolean>(false);

  const [regularCustomerCard, setRegularCustomerCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [regularCustomerCardLoading, setRegularCustomerCardLoading] =
    React.useState<boolean>(false);

  const [loyalCustomerCard, setLoyalCustomerCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [loyalCustomerCardLoading, setLoyalCustomerCardLoading] =
    React.useState<boolean>(false);

  const [chartReal, setChartReal] = React.useState<any>([]);
  const [chartCompare, setChartCompare] = React.useState<any>([]);
  const [chartLabel, setChartLabel] = React.useState<any>([]);
  const [tableData, setTableData] = React.useState<any>([]);
  const [totalPembeli, setTotalPembeli] = React.useState<any>(0);
  const [totalPembeliLast, setTotalPembeliLast] = React.useState<any>(0);
  const [dataChartGender, setDataChartGender] = React.useState<any>([]);
  const [paymentChart, setPaymentChart] = React.useState<any>([]);
  const [paymentChartLabel, setPaymentChartLabel] = React.useState<any>([]);

  const [cityChart, setCityChart] = React.useState<any>([]);
  const [cityChartLabel, setCityChartLabel] = React.useState<any>([]);

  const onTableChange: TableChangeHandler<any> = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    tableConfig.page.set(page);
    tableConfig.per_page.set(sizePerPage);
    tableConfig.order_by.set(sortField);
    tableConfig.sorted_by.set(sortOrder);

    getData();
  };

  useEffect(() => {
    getCategoryOptions();
    getChannelOptions();
    getVendorOptions();
  }, []);

  const getNewCustomerCardData = (params: any) => {
    if (isMounted.current) setNewCustomerCardLoading(true);
    CustomerRepository.getNewCustomer(brand.id, params)
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const temp: CardInterface = {
          title: "Pembeli baru",
          description: "Pembeli yang baru pertama kali berbelanja di tokomu.",
          value: data.current_period,
          compare: convertToLocaleNumber(data.last_period, data.current_period),
          type: convertToType(data.last_period, data.current_period),
        };

        if (isMounted.current) {
          setNewCustomerCard(temp);
          setNewCustomerCardLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setNewCustomerCardLoading(false);
        }
      });
  };

  const getRegularCustomerCardData = (params: any) => {
    if (isMounted.current) setRegularCustomerCardLoading(true);
    CustomerRepository.getCustomerRegular(brand.id, params)
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const temp: CardInterface = {
          title: "Pembeli Reguler",
          description:
            "Pembeli yang kembali berbelanja di tokomu setelah lebih dari 30 hari semenjak transaksi terakhir.",
          value: data.current_period,
          compare: convertToLocaleNumber(data.last_period, data.current_period),
          type: convertToType(data.last_period, data.current_period),
        };
        if (isMounted.current) {
          setRegularCustomerCard(temp);
          setRegularCustomerCardLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setRegularCustomerCardLoading(false);
        }
      });
  };

  const getLoyalCustomerCardData = (params: any) => {
    if (isMounted.current) setLoyalCustomerCardLoading(true);
    CustomerRepository.getLoyalCustomer(brand.id, params)
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const temp: CardInterface = {
          title: "Pembeli setia",
          description:
            "Pembeli yang kembali berbelanja di tokomu dalam kurang dari 30 hari semenjak transaksi terakhir.",
          value: data.current_period,
          compare: convertToLocaleNumber(data.last_period, data.current_period),
          type: convertToType(data.last_period, data.current_period),
        };
        if (isMounted.current) {
          setLoyalCustomerCard(temp);
          setLoyalCustomerCardLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setLoyalCustomerCardLoading(false);
        }
      });
  };

  const getCustomerGenderChartData = (params: any) => {
    if (isMounted.current) {
      setCustomerGenderChartLoading(true);
    }
    CustomerRepository.getCustomerGenderChart(brand.id, params)
      .then((res: any) => {
        const data: any[] = [];
        const dataLatest: any[] = [];
        data.push(res.data.data.current_period.others);
        data.push(res.data.data.current_period.male);
        data.push(res.data.data.current_period.female);
        dataLatest.push(res.data.data.last_period.others);
        dataLatest.push(res.data.data.last_period.male);
        dataLatest.push(res.data.data.last_period.female);
        let totalNow = 0;
        let totalLast = 0;
        data.forEach((item: any) => {
          totalNow += item;
        });
        dataLatest.forEach((item: any) => {
          totalLast += item;
        });
        if (isMounted.current) {
          setTotalPembeli(totalNow);
          setTotalPembeliLast(totalLast);
          setDataChartGender(data);
          setCustomerGenderChartLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setCustomerGenderChartLoading(false);
        }
      });
  };

  const getCustomerPaymentMethodChartData = (params: any) => {
    if (isMounted.current) {
      setCustomerPaymentMethodChartLoading(true);
    }
    CustomerRepository.getPaymentMethodChart(brand.id, params)
      .then((response: any) => {
        const arrayMethod: any[] = [];
        const arrayValue: any[] = [];
        response.data.data.forEach((item: any) => {
          arrayMethod.push(item.payment_method);
          arrayValue.push(item.total_payment);
        });
        if (isMounted.current) {
          setPaymentChartLabel(arrayMethod);
          setPaymentChart(arrayValue);
          setCustomerPaymentMethodChartLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setCustomerPaymentMethodChartLoading(false);
        }
      });
  };

  const getCustomerCityChartData = (params: any) => {
    if (isMounted.current) setCityCustomerChartLoading(true);
    CustomerRepository.getCustomerCityChart(brand.id, params)
      .then((response: AxiosResponse) => {
        const arrayMethod: any = [];
        const arrayValue: any = [];
        response.data.data.forEach((item: any) => {
          arrayMethod.push(item.city);
          arrayValue.push(item.total_order);
        });

        if (isMounted.current) {
          setCityChart(arrayValue);
          setCityChartLabel(arrayMethod);
          setCityCustomerChartLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setCityCustomerChartLoading(false);
        }
      });
  };

  const getAllData = (reset: boolean = false) => {
    const params = {
      "date-from": filterConfig.date_from.get(),
      "date-to": filterConfig.date_to.get(),
      channel: selectedChannel,
    };

    const difference = moment(filterConfig.date_to.get()).diff(
      moment(filterConfig.date_from.get()),
      "days"
    );

    if (difference == 0 || difference == 1) {
      setDaysDifference(1);
    } else {
      setDaysDifference(difference + 1);
    }

    if (selectedCategory.length !== totalCategoryOption) {
      Object.assign(params, {
        category: selectedCategory,
      });
    }

    if (selectedVendor.length !== totalVendorOption) {
      Object.assign(params, {
        vendor: selectedVendor,
      });
    }

    getNewCustomerCardData(params);
    getRegularCustomerCardData(params);
    getLoyalCustomerCardData(params);
    getCustomerGenderChartData(params);
    getCustomerPaymentMethodChartData(params);
    getCustomerCityChartData(params);
    getData(reset);
    changeChart(chartSelection.get(), colorIndex);
  };

  useEffect(() => {
    if (isFirstMount) {
      getAllData(true);
    }
    if (
      selectedChannel.length > 0 &&
      selectedVendor.length > 0 &&
      selectedCategory.length > 0
    ) {
      setIsFirstMount(true);
    }
  }, [selectedVendor, selectedChannel, selectedCategory, isFirstMount]);

  const getData = (reset: boolean = false) => {
    if (isMounted.current) {
      if (reset) tableConfig.page.set(1);
      setTableData([]);
      tableConfig.loading.set(true);
    }
    const conf = _.omit($clone(tableConfig.value), "loading");
    let params: any = {};
    if (conf) {
      Object.keys(conf).forEach((prop) => {
        if (conf[prop]) {
          params[prop] = conf[prop];
        }
      });
    }

    Object.assign(params, {
      "date-from": filterConfig.date_from.get(),
      "date-to": filterConfig.date_to.get(),
      channel: selectedChannel,
    });

    if (selectedCategory.length !== totalCategoryOption) {
      Object.assign(params, {
        category: selectedCategory,
      });
    }

    if (selectedVendor.length !== totalVendorOption) {
      Object.assign(params, {
        vendor: selectedVendor,
      });
    }

    CustomerRepository.getTableData(brand.id, params)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          setTableData(response.data.data);
          tableConfig.total.set(response.data.meta.total);
          tableConfig.loading.set(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          tableConfig.loading.set(false);
        }
      });
  };

  const getNewCustomerChartData = (difference: any, params: any) => {
    CustomerRepository.getNewCustomerChart(brand.id, params)
      .then((response: AxiosResponse) => {
        const responseData = response.data.data;
        const tempCurrentPeriod = [];
        const tempLastPeriod = [];
        for (let i = 0; i <= difference; i++) {
          tempCurrentPeriod.push(0);
          tempLastPeriod.push(0);
        }

        const startDate = moment(filterConfig.date_from.get());
        responseData?.current_period.forEach((item: any) => {
          const tempDate: any = moment(item?.date).diff(startDate, "days");
          tempCurrentPeriod[tempDate] = item?.grand_total;
        });

        responseData?.last_period.forEach((item: any) => {
          const tempDate: any = moment(item?.date)
            .add(difference + 1, "days")
            .diff(startDate, "days");

          tempLastPeriod[tempDate] = item?.grand_total;
        });

        if (isMounted.current) {
          setChartReal(tempCurrentPeriod);
          setChartCompare(tempLastPeriod);
          setChartLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setChartLoading(false);
        }
      });
  };

  const getRegularCustomerChartData = (difference: any, params: any) => {
    CustomerRepository.getRegularCustomerChart(brand.id, params)
      .then((response: AxiosResponse) => {
        const responseData = response.data.data;
        const tempCurrentPeriod = [];
        const tempLastPeriod = [];
        for (let i = 0; i <= difference; i++) {
          tempCurrentPeriod.push(0);
          tempLastPeriod.push(0);
        }

        const startDate = moment(filterConfig.date_from.get());
        responseData?.current_period.forEach((item: any) => {
          const tempDate: any = moment(item?.date).diff(startDate, "days");
          tempCurrentPeriod[tempDate] = item?.grand_total;
        });

        responseData?.last_period.forEach((item: any) => {
          const tempDate: any = moment(item?.date)
            .add(difference + 1, "days")
            .diff(startDate, "days");

          tempLastPeriod[tempDate] = item?.grand_total;
        });

        if (isMounted.current) {
          setChartReal(tempCurrentPeriod);
          setChartCompare(tempLastPeriod);
          setChartLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setChartLoading(false);
        }
      });
  };

  const getLoyalCustomerChartData = (difference: any, params: any) => {
    CustomerRepository.getLoyalCustomerChart(brand.id, params)
      .then((response: AxiosResponse) => {
        const responseData = response.data.data;
        const tempCurrentPeriod = [];
        const tempLastPeriod = [];
        for (let i = 0; i <= difference; i++) {
          tempCurrentPeriod.push(0);
          tempLastPeriod.push(0);
        }

        const startDate = moment(filterConfig.date_from.get());
        responseData?.current_period.forEach((item: any) => {
          const tempDate: any = moment(item?.date).diff(startDate, "days");
          tempCurrentPeriod[tempDate] = item?.grand_total;
        });

        responseData?.last_period.forEach((item: any) => {
          const tempDate: any = moment(item?.date)
            .add(difference + 1, "days")
            .diff(startDate, "days");

          tempLastPeriod[tempDate] = item?.grand_total;
        });

        if (isMounted.current) {
          setChartReal(tempCurrentPeriod);
          setChartCompare(tempLastPeriod);
          setChartLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setChartLoading(false);
        }
      });
  };

  const changeChart = (key: string, index: number = 0) => {
    if (isMounted.current) {
      setChartLoading(true);
      chartSelection.set(key);
      setColorIndex(index);
      chartColor.set(colorChart[index]);
    }

    const difference = moment(filterConfig.date_to.get()).diff(
      moment(filterConfig.date_from.get()),
      "days"
    );

    const params = {
      "date-from": filterConfig.date_from.get(),
      "date-to": filterConfig.date_to.get(),
      channel: selectedChannel,
    };

    if (selectedCategory.length !== totalCategoryOption) {
      Object.assign(params, {
        category: selectedCategory,
      });
    }

    if (selectedVendor.length !== totalVendorOption) {
      Object.assign(params, {
        vendor: selectedVendor,
      });
    }

    const tempChartLabel: string[] = [];

    for (let i = difference; i >= 0; i--) {
      tempChartLabel.push(
        moment(filterConfig.date_to.get())
          .subtract(i, "days")
          .format("DD MMMM YYYY")
      );
    }

    const tempChartLabelCompare: string[] = [];
    for (let i = difference; i >= 0; i--) {
      tempChartLabelCompare.push(
        moment(filterConfig.date_to.get())
          .subtract(i + difference + 1, "days")
          .format("DD MMMM YYYY")
      );
    }
    if (isMounted.current) {
      setChartLabelCompare(tempChartLabelCompare);
      setChartLabel(tempChartLabel);
    }

    if (key === "new-customer") {
      getNewCustomerChartData(difference, params);
    } else if (key === "regular-customer") {
      getRegularCustomerChartData(difference, params);
    } else if (key === "loyal-customer") {
      getLoyalCustomerChartData(difference, params);
    }
  };

  const handleTableSearch = _.debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      tableConfig.search.set(e.target.value);
      tableConfig.page.set(1);

      getData();
    },
    300
  );

  const customTitle = (tooltipItem: any) => {
    const real = chartLabel[_.get(tooltipItem, "[0].index", 0)];
    const compare = chartLabelCompare[_.get(tooltipItem, "[0].index", 0)];
    return [real, compare];
  };

  const handleExport = useCallback(() => {
    if (isMounted.current) {
      setLoadingExport(true);
      const body: any = document.querySelector(".app-container");
      if (body) {
        htmlToImage
          .toCanvas(body)
          .then(function (dataUrl) {
            const finish = htmlToPdf(dataUrl, "dashboard-pembeli");
            if (finish) setLoadingExport(false);
          })
          .catch(function (error) {
            setLoadingExport(false);
          });
      }
    }
  }, [isMounted]);

  return (
    <>
      <Helmet>
        <title>{t("Dashboard")}</title>
      </Helmet>

      <div className={"page-header-component"}>
        <h3 className={"title"}>
          Dashboard | <strong>Pembeli</strong>
        </h3>
        <VuiButton
          onClick={handleExport}
          variant="success"
          label={t("button.export")}
          loading={loadingExport}
        />
      </div>

      <>
        <div className="card-paper mb-4">
          <div className="card-content">
            <div className="card-filter-section">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label" style={{ display: "flex" }}>
                      Rentang Tanggal
                    </label>
                    <VuiDateRangePicker
                      startDate={filterConfig.date_from.get()}
                      endDate={filterConfig.date_to.get()}
                      callback={handleChangeDate}
                      useRanges={true}
                    />
                  </div>
                </div>

                <>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">Kategori</label>
                      <MultipleCheckboxes
                        name={"kategori"}
                        options={categoryOptions}
                        labelOption="name"
                        valueOption="id"
                        values={selectedCategory}
                        onChange={(values) => {
                          setSelectedCategory(values);
                          getData();
                        }}
                        children={true}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">Channel</label>
                      <MultipleCheckboxes
                        name={"channel"}
                        labelOption="name"
                        options={channelOptions}
                        valueOption="id"
                        values={selectedChannel}
                        onChange={(values) => {
                          setSelectedChannel(values);
                          getData();
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">Vendor</label>
                      <MultipleCheckboxes
                        name={"vendor"}
                        options={vendorOptions}
                        labelOption="name"
                        valueOption="id"
                        values={selectedVendor}
                        onChange={(values) => {
                          setSelectedVendor(values);
                          getData();
                        }}
                      />
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>

        <div className="card-paper mb-4">
          <div className="card-content">
            <div className="card-chart-wrapper mb-4">
              <VuiCard
                isActive={chartSelection.get() === "new-customer"}
                title={newCustomerCard.title}
                description={newCustomerCard.description}
                loading={newCustomerCardLoading}
                type={newCustomerCard.type}
                compare={newCustomerCard.compare}
                value={newCustomerCard.value}
                days={daysDifference}
                hasComparison
                onClick={() => changeChart("new-customer", 0)}
              />

              <VuiCard
                isActive={chartSelection.get() === "regular-customer"}
                title={regularCustomerCard.title}
                description={regularCustomerCard.description}
                loading={regularCustomerCardLoading}
                type={regularCustomerCard.type}
                compare={regularCustomerCard.compare}
                value={regularCustomerCard.value}
                days={daysDifference}
                hasComparison
                onClick={() => changeChart("regular-customer", 1)}
              />

              <VuiCard
                isActive={chartSelection.get() === "loyal-customer"}
                title={loyalCustomerCard.title}
                description={loyalCustomerCard.description}
                loading={loyalCustomerCardLoading}
                type={loyalCustomerCard.type}
                compare={loyalCustomerCard.compare}
                value={loyalCustomerCard.value}
                days={daysDifference}
                hasComparison
                onClick={() => changeChart("loyal-customer", 2)}
              />
            </div>

            {chartLoading ? (
              <VuiLoader />
            ) : (
              <div className="line-chart-wrapper">
                <Line
                  height={350}
                  data={{
                    labels: chartLabel,
                    datasets: [
                      {
                        label: "Periode saat ini",
                        data: chartReal,
                        fill: false,
                        backgroundColor: chartColor.get(),
                        borderColor: chartColor.get(),
                        tension: 0,
                      },
                      {
                        label: "Periode sebelumnya",
                        data: chartCompare,
                        fill: false,
                        backgroundColor: chartColor.get(),
                        borderColor: chartColor.get(),
                        borderDash: [5, 5],
                        tension: 0,
                      },
                    ],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    legend: {
                      display: true,
                    },
                    tooltips: {
                      callbacks: {
                        title: customTitle,
                      },
                    },
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div className="card-paper">
          <div className="card-header">
            <h6 className="card-header-title">Performa Pembeli</h6>
          </div>

          <div className="card-content">
            <div className={"default-filter-section"}>
              <div className={"filter-item filter-special type-search"}>
                <div className="input-group prefix-group">
                  <span className="input-group-text">
                    <FontAwesomeIcon
                      className={"icon icon-prefix"}
                      icon={faSearch}
                    />
                  </span>
                  <input
                    defaultValue={tableConfig.search.get()}
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    onChange={handleTableSearch}
                  />
                </div>
              </div>
            </div>

            <VuiDataTable
              loading={tableConfig.loading.get()}
              columns={productTableColumns}
              data={tableData}
              page={tableConfig.page.get()}
              sizePerPage={tableConfig.per_page.get()}
              totalSize={tableConfig.total.get()}
              onTableChange={onTableChange}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-4">
            <div className="card-paper">
              <div className="card-header">
                <h6 className="card-header-title">Jenis Kelamin Pembeli</h6>
              </div>

              <div className="card-content">
                {customerGenderChartLoading ? (
                  <VuiLoader />
                ) : (
                  <DoughnutCard
                    total={totalPembeli}
                    lastTotal={totalPembeliLast}
                    days={daysDifference}
                    label={$clone(chartPembeliLabel.get())}
                    data={dataChartGender}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card-paper">
              <div className="card-header">
                <h6 className="card-header-title">Metode Pembayaran</h6>
              </div>

              <div className="card-content">
                {customerPaymentMethodChartLoading ? (
                  <VuiLoader />
                ) : (
                  <DoughnutCard
                    total={totalPembeli}
                    lastTotal={totalPembeliLast}
                    days={daysDifference}
                    label={paymentChartLabel}
                    data={paymentChart}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card-paper">
              <div className="card-header">
                <h6 className="card-header-title">Berdasarkan Kota</h6>
              </div>

              <div className="card-content">
                {cityCustomerChartLoading ? (
                  <VuiLoader />
                ) : (
                  <DoughnutCard
                    total={totalPembeli}
                    lastTotal={totalPembeliLast}
                    days={daysDifference}
                    label={cityChartLabel}
                    data={cityChart}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default DashboardPembeli;
