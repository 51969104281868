import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import {
  faBoxes,
  faTachometerAlt,
  faCogs,
  faArchive,
  faUsers,
  faReceipt,
  faFileInvoice,
  faMoneyBillAlt,
} from "@fortawesome/free-solid-svg-icons";

export interface VuiSidebarProps {
  show: boolean;
  onClose: () => void;
}

export interface MenuChildrenProps {
  name: string;
  link: string;
  activeRoutes: Array<string>;
  badge?: number;
  permissions: Array<string> | string;
}

export interface MenuStructureProps {
  name: string;
  link: string;
  icon: IconDefinition;
  activeRoutes: Array<string>;
  children?: Array<MenuChildrenProps>;
  permissions: string[] | string;
}

const getBadgeMenu = () => {
  return 3;
};

export const MenuStructure: Array<MenuStructureProps> = [
  {
    name: "Dashboard",
    link: "/dashboard",
    icon: faTachometerAlt,
    activeRoutes: ["dashboard"],
    permissions: "any",
    children: [
      {
        name: "Ringkasan",
        link: "/dashboard/ringkasan",
        activeRoutes: ["ringkasan"],
        permissions: "any",
      },
      {
        name: "Penjualan",
        link: "/dashboard/penjualan",
        activeRoutes: ["penjualan"],
        permissions: "any",
      },
      {
        name: "Vendor",
        link: "/dashboard/vendor",
        activeRoutes: ["vendor"],
        permissions: "any",
      },
      {
        name: "Produk",
        link: "/dashboard/produk",
        activeRoutes: ["produk"],
        permissions: "any",
      },
      {
        name: "Pembeli",
        link: "/dashboard/pembeli",
        activeRoutes: ["pembeli"],
        permissions: "any",
      },
    ],
  },
  {
    name: "Invoice",
    link: "/invoice",
    icon: faFileInvoice,
    activeRoutes: ["invoice"],
    permissions: "any",
  },
  {
    name: "Penjualan",
    link: "/order",
    icon: faReceipt,
    activeRoutes: ["order"],
    permissions: "any",
  },
  {
    name: "Pembayaran",
    link: "/send-payment",
    icon: faMoneyBillAlt,
    activeRoutes: ["send-payment"],
    permissions: "any",
  },
  {
    name: "Produk",
    link: "/product",
    icon: faArchive,
    activeRoutes: ["product"],
    permissions: "any",
  },
  {
    name: "Stok",
    link: "/stock",
    icon: faBoxes,
    activeRoutes: ["stock"],
    permissions: "any",
    children: [
      {
        name: "Manajemen",
        link: "/stock/management",
        activeRoutes: ["management", "/adjust"],
        permissions: [],
      },
      {
        name: "Mutasi",
        link: "/stock/mutation",
        activeRoutes: ["mutation"],
        permissions: [],
      },
    ],
  },
  {
    name: "Pelanggan",
    link: "/customer",
    icon: faUsers,
    activeRoutes: ["customer"],
    permissions: "any",
  },
  {
    name: "Pengaturan",
    link: "/settings",
    icon: faCogs,
    activeRoutes: ["settings"],
    permissions: "any",
  },
];

export const BrandStructure: Array<MenuStructureProps> = [
  {
    name: "Dashboard",
    link: "/dashboard",
    icon: faTachometerAlt,
    activeRoutes: ["dashboard"],
    permissions: "any",
    children: [
      {
        name: "Ringkasan",
        link: "/dashboard/ringkasan",
        activeRoutes: ["ringkasan"],
        permissions: "any",
      },
      {
        name: "Penjualan",
        link: "/dashboard/penjualan",
        activeRoutes: ["penjualan"],
        permissions: "any",
      },
      {
        name: "Vendor",
        link: "/dashboard/vendor",
        activeRoutes: ["vendor"],
        permissions: "any",
      },
      {
        name: "Produk",
        link: "/dashboard/produk",
        activeRoutes: ["produk"],
        permissions: "any",
      },
      {
        name: "Pembeli",
        link: "/dashboard/pembeli",
        activeRoutes: ["pembeli"],
        permissions: "any",
      },
    ],
  },
  {
    name: "Invoice",
    link: "/invoice",
    icon: faFileInvoice,
    activeRoutes: ["invoice"],
    permissions: "any",
  },
  {
    name: "Penjualan",
    link: "/order",
    icon: faReceipt,
    activeRoutes: ["order"],
    permissions: "any",
  },
  {
    name: "Produk",
    link: "/product",
    icon: faArchive,
    activeRoutes: ["product"],
    permissions: "any",
  },
  {
    name: "Stok",
    link: "/stock",
    icon: faBoxes,
    activeRoutes: ["stock"],
    permissions: "any",
    children: [
      {
        name: "Manajemen",
        link: "/stock/management",
        activeRoutes: ["management", "/adjust"],
        permissions: [],
      },
      {
        name: "Mutasi",
        link: "/stock/mutation",
        activeRoutes: ["mutation"],
        permissions: [],
      },
    ],
  },
  {
    name: "Pelanggan",
    link: "/customer",
    icon: faUsers,
    activeRoutes: ["customer"],
    permissions: "any",
  },
];

export const VendorMenuStructure: Array<MenuStructureProps> = [
  {
    name: "Dashboard",
    link: "/dashboard",
    icon: faTachometerAlt,
    activeRoutes: ["dashboard"],
    permissions: [],
    children: [
      {
        name: "Penjualan",
        link: "/dashboard/penjualan",
        activeRoutes: ["penjualan"],
        permissions: "any",
      },
    ],
  },
  {
    name: "Penjualan",
    link: "/order",
    icon: faReceipt,
    activeRoutes: ["order"],
    permissions: "any",
  },
  {
    name: "Stok",
    link: "/stock",
    icon: faBoxes,
    activeRoutes: ["stock"],
    permissions: "any",
  },
  {
    name: "Pengaturan",
    link: "/settings",
    icon: faCogs,
    activeRoutes: ["settings"],
    permissions: [
      "vendor.user.index",
      "vendor.role.index",
      "vendor.gate-setting.index",
    ],
  },
];
