import { api, createCancelTokenHandler } from "../../vodea/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (brandId: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/brand/${brandId}/widget/sales/card/`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  getOrderCard: function (brandId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/sales/card/order`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getOrderCard.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getOrderProductCard: function (brandId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/sales/card/order-product`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getOrderProductCard.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getGrossMargin: function (brandId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/sales/card/gross-margin`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getGrossMargin.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getInvoiceCard: function (brandId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/sales/card/invoice`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getInvoiceCard.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getIncomingInvoiceCard: function (
    brandId: number | string,
    params: any = null
  ) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/sales/card/invoice`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getIncomingInvoiceCard.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getIncomingInvoiceChart: function (
    brandId: number | string,
    params: any = null
  ) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/sales/chart/invoice-period`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getIncomingInvoiceChart.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getGrossMarginChart: function (brandId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/sales/chart/gross-margin-period`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getGrossMarginChart.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getOrderProductChart: function (
    brandId: number | string,
    params: any = null
  ) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/sales/chart/order-product-period`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getOrderProductChart.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getInvoiceChart: function (brandId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/sales/chart/invoice-period`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getInvoiceChart.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getTableInvoiceData: function (brandId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/sales/table/invoice`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getTableInvoiceData.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getOrder: function (brandId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/sales/card/order`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getOrder.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getOrder2: function (brandId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/sales/card/order-product`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getOrder2.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getAverageSalePrice: function (brandId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/sales/card/average-sales-price`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getAverageSalePrice.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getAverageSalePriceChart: function (
    brandId: number | string,
    params: any = null
  ) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/sales/chart/average-sales-price`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getAverageSalePriceChart.name
          ].handleRequestCancellation().token,
      }
    );
  },
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);
