import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import useIsMounted from "../../../../../vodea/utilities/hooks/useIsMounted";
import { Helmet } from "react-helmet-async";
import VuiBreadcrumb from "../../../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import UserRepository from "../../../../../repositories/UserRepository";
import {
  formatFormData,
  formatSetValueForm,
} from "../../../../../vodea/utilities/helpers/form";
import { AxiosError, AxiosResponse } from "axios";
import { Controller, useForm } from "react-hook-form";
import {
  InformationBaseModel,
  InformationInputs,
  informationSchema,
  informationSchemaUpdate,
} from "./validation";
import {
  mapHookErrors,
  showToast,
} from "../../../../../vodea/utilities/helpers/global";
import { useState } from "@hookstate/core";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import VuiActionForm from "../../../../../vodea/@vodea-ui/components/Forms/VuiActionForm";
import _ from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../stores";
import useRoleService from "../../../../../vodea/utilities/hooks/useRoleService";

const SettingVendorUserForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { vendorId, id } = useParams();
  const { brand } = useSelector((state: RootState) => state.brand);
  const { vendor } = useSelector((state: RootState) => state.vendor);
  const { isAdministrator, isKeyAccount, isVendor, isBrand } = useRoleService();

  const vendorTitle = `${
    vendorId ? t("general.edit") : t("general.create")
  } ${t("settings.vendor.title")}`;
  const title = `${id ? t("general.edit") : t("general.create")} ${t(
    "settings.vendor.user.title"
  )}`;

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = () => {
    UserRepository.show(id)
      .then((response: AxiosResponse) => {
        const data = response.data.data;

        const formattedData = formatSetValueForm(InformationBaseModel, data);

        _.forEach(formattedData, (value, name: any) => {
          informationSetValue(name, value);
        });
      })
      .catch((e: AxiosError) => {
        showToast(e?.response?.data.message, "error");
      });
  };

  const informationLoading = useState(false);
  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    errors: informationErrors,
    setError: informationSetError,
    setValue: informationSetValue,
    control: informationControl,
  } = useForm<InformationInputs>({
    resolver: yupResolver(id ? informationSchemaUpdate : informationSchema),
  });

  const onInformationSubmit = informationHandleSubmit((data) => {
    informationLoading.set(true);

    const formData = formatFormData(data);

    Object.assign(formData, {
      role_name: "Vendor",
      vendor_id: vendorId,
    });

    (id ? UserRepository.update(id, formData) : UserRepository.create(formData))
      .then((response: AxiosResponse) => {
        showToast(t("response.successUpdateData"), "success");
        informationLoading.set(false);

        if (!id) navigate(`/settings/vendor/${vendorId}`);
      })
      .catch((e: AxiosError) => {
        if (isMounted.current && e?.response?.data?.errors) {
          const errors = mapHookErrors(e.response.data.errors);
          Object.keys(errors).forEach((key: any) => {
            informationSetError(key, errors[key]);
          });

          showToast(e.response.data.message, "error");
        }

        informationLoading.set(false);
      });
  });

  const breadcrumbList = [
    {
      label: t("settings.title"),
      link: "/settings",
    },
    {
      label: t("settings.vendor.title"),
      link: "/settings/vendor",
    },
    {
      label: vendorTitle,
      link: `/settings/vendor/${vendorId ? vendorId : "create"}`,
    },
    {
      label: title,
      link: `/settings/vendor/${vendorId ? vendorId : "create"}/user/${
        id ? id : "create"
      }`,
    },
  ];

  if (!_.get(brand, "id", null)) {
    return <Navigate to={"/settings"} replace />;
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={"page-header-component"}>
        <h3 className={"title"}>{title}</h3>
      </div>

      <form className={"form-wrapper"} onSubmit={onInformationSubmit}>
        <div className={"default-page-layout"}>
          <div className={"information-section"}>
            <div className={"card-paper"}>
              <div className={"card-header"}>
                <h6 className={"card-header-title"}>
                  {t("general.information")}
                </h6>
              </div>
              <div className={"card-content"}>
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className={clsx({
                        "form-group": true,
                        error: informationErrors.name,
                      })}
                    >
                      <label className={"form-label"}>
                        {t("form.name.label")}
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        ref={informationRegister}
                      />
                      <label className={"label-help"}>
                        {informationErrors.name?.message}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div
                      className={clsx({
                        "form-group": true,
                        error: informationErrors.email,
                      })}
                    >
                      <label className={"form-label"}>
                        {t("form.email.label")}
                      </label>
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        ref={informationRegister}
                      />
                      <label className={"label-help"}>
                        {informationErrors.email?.message}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className={clsx({
                        "form-group": true,
                        error: informationErrors.mobile_phone,
                      })}
                    >
                      <label className={"form-label"}>
                        {t("form.mobilePhone.label")}
                      </label>
                      <input
                        type="text"
                        name="mobile_phone"
                        className="form-control"
                        ref={informationRegister}
                      />
                      <label className={"label-help"}>
                        {informationErrors.mobile_phone?.message}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div
                      className={clsx({
                        "form-group": true,
                        error: informationErrors.password,
                      })}
                    >
                      <label className={"form-label"}>
                        {t("form.password.label")}
                      </label>
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        ref={informationRegister}
                      />
                      <label className={"label-help"}>
                        {informationErrors.password?.message}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <VuiActionForm
          loading={informationLoading.get()}
          cancelLink={`/settings/vendor/${vendorId}`}
        />
      </form>
    </>
  );
};

export default SettingVendorUserForm;
