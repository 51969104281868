import defaultLoader from './loader.svg';

interface VuiLoadingScreenProps {
  loader?: string;
}

const VuiLoadingScreen: React.FC<VuiLoadingScreenProps> = ({ loader = defaultLoader }) => {
  return (
    <div id='screen-loader'>
      <img className='loader' src={loader} alt={'test'} />
    </div>
  );
};

export default VuiLoadingScreen;
