/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faTimes } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useState } from "@hookstate/core/dist";
import NotificationRepository from "../../../../../repositories/NotificationRepository";
import moment from "moment";
import IncomingOrderModal from "../../../../../components/Modal/IncomingOrderModal";
import axios, { AxiosError, AxiosResponse } from "axios";
import { showToast } from "../../../../utilities/helpers/global";
import _ from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../stores";
import Pusher from "pusher-js";
import { passportService } from "../../../../services";
import { useNavigate } from "react-router-dom";
import AccountRepository from "../../../../../repositories/AccountRepository";
import { updateBrand } from "../../../../../stores/brand/actions";
import InfiniteScroll from "react-infinite-scroll-component";
import useIsMounted from "../../../../utilities/hooks/useIsMounted";

interface VuiHeaderNotificationProps {}

interface IQuery {
  page: number;
  per_page: number;
}

const VuiHeaderNotification: React.FC<VuiHeaderNotificationProps> = () => {
  const { t } = useTranslation();
  const show = useState(false);
  const showIncomingOrderModal = useState(false);
  const notifications = useState<any[]>([]);
  const isMounted = useIsMounted();
  const selectedInvoiceOfferLogId = useState<string | number>("");
  const selectedNotificationId = useState<string | number>("");
  const { brand } = useSelector((state: RootState) => state.brand);
  const { user } = useSelector((state: RootState) => state.system);
  const dispatch = useDispatch();
  const [echo, setEcho] = React.useState<any>();
  const navigate = useNavigate();
  const [query, setQuery] = React.useState<IQuery>({
    page: 1,
    per_page: 10,
  });
  const [hasMore, setHasMore] = React.useState<boolean>(true);

  useEffect(() => {
    // @ts-ignore
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || "", {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      authorizer: (channel: any, options: any) => {
        return {
          authorize: (socketId: any, callback: any) => {
            axios
              .post(
                `${process.env.REACT_APP_PASSPORT_END_POINT}/api/broadcasting/auth`,
                {
                  socket_id: socketId,
                  channel_name: channel.name,
                },
                {
                  headers: {
                    Authorization: `Bearer ${passportService.getAccessToken()}`,
                  },
                }
              )
              .then((response: any) => {
                callback(false, response.data);
              })
              .catch((error: any) => {
                callback(true, error);
              });
          },
        };
      },
    });

    const channel = pusher.subscribe(`private-App.Models.User.${user.id}`);

    setEcho(channel);

    return () => {
      channel.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (echo) {
      echo.bind(
        "Illuminate\\Notifications\\Events\\BroadcastNotificationCreated",
        (data: any) => {
          loadData().then();

          if (
            _.get(data, "type", "") ===
            "App\\Notifications\\Vendor\\InvoiceOfferNotification"
          ) {
            openIncomingOrderModal(
              _.get(data, "id"),
              _.get(data, "notification.invoice_offer_log_id")
            );
          }
        }
      );
    }
  }, [echo]);

  const closeNotification = () => {
    show.set(false);
  };

  const openIncomingOrderModal = (
    notificationId: any,
    invoiceOfferLogId: any
  ) => {
    selectedInvoiceOfferLogId.set(invoiceOfferLogId);
    selectedNotificationId.set(notificationId);
    showIncomingOrderModal.set(true);
  };

  const handleClickNotification = (item: any) => {
    if (item?.data?.web) {
      if (item.data.web.type === "dialog") {
        if (item.data.web.route === "invoice-offer-log") {
          openIncomingOrderModal(
            item.id,
            item.data.notification.invoice_offer_log_id
          );
        }
      } else {
        if (item.data.web.route === "order") {
          navigate(`/order/${item.data.notification.order_id}`, {
            state: {
              notificationId: item.id,
            },
          });
          notifications
            .find((notification: any) => notification.value.id === item.id)
            ?.set((prev: any) => {
              prev.read_at = moment();
              return prev;
            });
          show.set(!show.get());
        } else if (item.data.web.route === "invoice") {
          navigate(`/order/${item.data.notification.invoice_offer_log_id}`, {
            state: {
              notificationId: item.id,
            },
          });
          notifications
            .find((notification: any) => notification.value.id === item.id)
            ?.set((prev: any) => {
              prev.read_at = moment();
              return prev;
            });
          show.set(!show.get());
        } else if (item.data.web.route === "update-stock-history") {
          if (item.data.web.brand.id === brand?.id) {
            navigate(
              `/stock/update-stock-history/${item.data.notification.update_stock_history_id}/${item.id}`
            );
          } else {
            AccountRepository.changeBrand({
              brand_id: item.data.web.brand.id,
            })
              .then((res: AxiosResponse) => {
                dispatch(
                  updateBrand({
                    brand: brand,
                  })
                );
                window.location.href = `/stock/update-stock-history/${item.data.notification.update_stock_history_id}/${item.id}`;
              })
              .catch((err: AxiosError) => {});
          }

          notifications
            .find((notification: any) => notification.value.id === item.id)
            ?.set((prev: any) => {
              prev.read_at = moment();
              return prev;
            });
          show.set(!show.get());
        }
      }
    } else {
      notifications
        .find((notification: any) => notification.value.id === item.id)
        ?.set((prev: any) => {
          prev.read_at = moment();
          return prev;
        });
      return;
    }
  };

  const loadData = useCallback(
    async (next: boolean = false) => {
      if (isMounted.current) {
        if (next) {
          setQuery({
            ...query,
            page: query.page + 1,
          });
        } else {
          setQuery({
            ...query,
            page: 1,
          });
          notifications.set([]);
          setHasMore(true);
        }
      }

      await NotificationRepository.all(query)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            const { data: responseData } = response.data;
            notifications.merge(responseData);
            if (responseData.length < 10) {
              setHasMore(false);
            }
          }
        })
        .catch((e: AxiosError) => {
          if (e.isAxiosError) showToast(e?.response?.data.message, "error");
        });
    },
    [query]
  );

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  const handleNextData = () => {
    loadData(true).then();
  };

  return (
    <>
      <div
        className={"badge-notification"}
        onClick={() => {
          show.set(!show.get());
        }}
      >
        {user.total_unread_notification ? (
          <span className={"notification-sign"} />
        ) : null}

        <FontAwesomeIcon className={"icon"} icon={faBell} />
      </div>

      <div
        className={clsx([
          "app-backdrop",
          {
            show: show.get(),
          },
        ])}
        onClick={closeNotification}
      />

      <div
        className={clsx([
          "app-slide-popup",
          {
            show: show.get(),
          },
        ])}
      >
        <div className={"app-slide-header"}>
          <h4 className={"title"}>{t("Notification")}</h4>
          <FontAwesomeIcon
            className={"icon"}
            icon={faTimes}
            onClick={closeNotification}
          />
        </div>
        <div className={"app-slide-content"} id="scrollable-slide">
          <InfiniteScroll
            next={handleNextData}
            hasMore={hasMore}
            loader={<h6>Loading...</h6>}
            className={"slide-list"}
            scrollableTarget={"scrollable-slide"}
            dataLength={notifications.get().length}
            scrollThreshold={0.9}
          >
            {notifications.get().map((item: any, key: number) => {
              return (
                <div
                  className={"slide-item"}
                  key={key}
                  onClick={() => handleClickNotification(item)}
                >
                  <label className={"title"}>
                    {_.get(item, "data.notification.title", "")}

                    {_.get(item, "read_at", null) === null ? (
                      <span className="badge-new"></span>
                    ) : null}
                  </label>
                  <p className={"summary"}>
                    {_.get(item, "data.notification.description", "")}
                  </p>
                  <label className={"date"}>
                    {moment(
                      _.get(item, "data.notification.timestamp", "")
                    ).format("D MMMM YYYY, h:mm A")}
                  </label>
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      </div>

      <IncomingOrderModal
        invoiceLogId={selectedInvoiceOfferLogId.get()}
        notificationId={selectedNotificationId.get()}
        onContinue={() => {
          showIncomingOrderModal.set(false);
          closeNotification();
        }}
        show={showIncomingOrderModal.get()}
      />
    </>
  );
};

export default VuiHeaderNotification;
