import { api, createCancelTokenHandler } from "../../../vodea/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (params: any = null, brand_id: any) {
    return api.get(
      `${endPoint()}/api/brand/${brand_id}/tokopedia/product-commerce`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
            .token,
      }
    );
  },
  show: function (id: number | string, brand_id: any, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brand_id}/tokopedia/product-commerce/${id}`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[this.show.name].handleRequestCancellation()
            .token,
      }
    );
  },
  showAll: function (id: number | string, brand_id: any, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brand_id}/channel-request-log/1/${id}`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.showAll.name
          ].handleRequestCancellation().token,
      }
    );
  },
  select: function (brand_id: any, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brand_id}/tokopedia/product-commerce`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
            .token,
      }
    );
  },
  create: function (brand_id: any, payload: any, params: any = null) {
    return api.post(
      `${endPoint()}/api/brand/${brand_id}/tokopedia/product-commerce`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
            .token,
      }
    );
  },
  download: function (brand_id: any, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brand_id}/tokopedia/import-template/product-price`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.download.name
          ].handleRequestCancellation().token,
        responseType: "blob",
      }
    );
  },
  import: function (
    brand_id: number | string,
    payload: any,
    params: any = null
  ) {
    return api.post(
      `${endPoint()}/api/brand/${brand_id}/tokopedia/import/product-price`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[this.import.name].handleRequestCancellation()
            .token,
      }
    );
  },
  update: function (
    id: number | string,
    brand_id: any,
    payload: any,
    params: any = null
  ) {
    return api.put(
      `${endPoint()}/api/brand/${brand_id}/tokopedia/product-commerce/${id}`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
            .token,
      }
    );
  },

  delete: function (id: number | string, brand_id: any, params: any = null) {
    return api.delete(
      `${endPoint()}/api/brand/${brand_id}/tokopedia/product-commerce/${id}`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[this.delete.name].handleRequestCancellation()
            .token,
      }
    );
  },
  setStatusInActive: function (
    brand_id: any,
    id: number | string,
    params: any = null
  ) {
    return api.put(
      `${endPoint()}/api/brand/${brand_id}/tokopedia/product-commerce/${id}/set-inactive`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.setStatusInActive.name
          ].handleRequestCancellation().token,
      }
    );
  },
  setStatusActive: function (
    brand_id: any,
    id: number | string,
    params: any = null
  ) {
    return api.put(
      `${endPoint()}/api/brand/${brand_id}/tokopedia/product-commerce/${id}/set-active`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.setStatusActive.name
          ].handleRequestCancellation().token,
      }
    );
  },
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);
