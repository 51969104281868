import React from "react";
import { FieldError } from "react-hook-form";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import clsx from "clsx";

interface INumberTextField extends NumberFormatProps {
  isError?: FieldError;
  errorMessage?: string;
  isForm?: boolean;
}

const NumberTextField = (props: INumberTextField) => {
  const {
    isError,
    errorMessage,
    decimalSeparator = ",",
    thousandSeparator = ".",
    decimalScale = 0,
    placeholder = "0",
    isForm = true,
    ...other
  } = props;

  return (
    <>
      <NumberFormat
        {...other}
        className={clsx({
          "form-control": isForm,
        })}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        decimalScale={decimalScale}
        placeholder={placeholder}
      />
      {isError && <span className={"text-danger"}>{errorMessage}</span>}
    </>
  );
};

export default NumberTextField;
