import clsx from "clsx";
import { variantTypes } from "../../types";
import React from "react";

interface VuiButtonProps {
  label: string;
  loading: boolean;
  forSubmit?: boolean;
  fullWidth?: boolean;
  variant?: variantTypes;
  customClass?: string;
  onClick?: (e: React.MouseEvent<any>) => void;
}

export const VuiButton: React.FC<VuiButtonProps> = ({
  label,
  loading,
  forSubmit = false,
  fullWidth = false,
  variant = "primary",
  customClass,
  onClick,
}) => {
  const variantClass = `btn-${variant}`;

  return (
    <button
      type={forSubmit ? "submit" : "button"}
      className={clsx([
        "btn",
        variantClass,
        { "btn-full-width": fullWidth },
        customClass,
      ])}
      disabled={loading}
      onClick={onClick}
    >
      <div
        className={clsx({
          "loader-wrapper": true,
          loading: loading,
        })}
      >
        <span className={"btn-text"}>{label}</span>
        <div className="spinner-border text-light" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </button>
  );
};
