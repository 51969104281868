import { Navigate } from "react-router-dom";
import LayoutApp from "../layouts/App";
import Dashboard from "../pages/App/Dashboard";
import AccountProfile from "../pages/App/Account/Profile";
import DashboardRingkasan from "../pages/App/Dashboard/Ringkasan";
import DashboardBranch from "../pages/App/Dashboard/Branch";
import DashboardVendor from "../pages/App/Dashboard/Vendor";
import DashboardProduk from "../pages/App/Dashboard/Produk";
import DashboardPembeli from "../pages/App/Dashboard/Pembeli";
import Order from "../pages/App/Order";
import OrderShow from "../pages/App/Order/show";
import ManagementStock from "../pages/App/Stock/ManagementStock";
import MutationStock from "../pages/App/Stock/MutationStock";
import AdjustStock from "../pages/App/Stock/ManagementStock/AdjustStock";
import Promosi from "../pages/App/Promosi";
import AddPromosi from "../pages/App/Promosi/Create";
import DetailPromosi from "../pages/App/Promosi/Detail";
import PengaturanPeran from "../pages/App/Settings/Peran";
import TambahPeran from "../pages/App/Settings/Peran/create";
import PengaturanHakAkses from "../pages/App/Settings/HakAkses";
import TambahHakAkses from "../pages/App/Settings/HakAkses/createhakakses";
import PengaturanAkun from "../pages/App/Settings/Akun";
import TambahAkun from "../pages/App/Settings/Akun/createakun";
import PengaturanPengiriman from "../pages/App/Settings/Pengiriman";
import Product from "../pages/App/Product";
import ProductForm from "../pages/App/Product/Form";
import Stok from "../pages/App/Stock";
import StokForm from "../pages/App/Stock/Form";
import DashboardPenjualan from "../pages/App/Dashboard/Penjualan";

import Settings from "../pages/App/Settings";
import SettingBrand from "../pages/App/Settings/Brand";
import SettingVendor from "../pages/App/Settings/Vendor";
import SettingVendorForm from "../pages/App/Settings/Vendor/Form";
import SettingCategory from "../pages/App/Settings/Category";
import SettingCategoryForm from "../pages/App/Settings/Category/Form";
import SettingRole from "../pages/App/Settings/Role";
import SettingRoleForm from "../pages/App/Settings/Role/Form";
import SettingPermission from "../pages/App/Settings/Permission";
import SettingPermissionForm from "../pages/App/Settings/Permission/Form";
import SettingUser from "../pages/App/Settings/User";
import SettingUserForm from "../pages/App/Settings/User/Form";
import SettingVendorUserForm from "../pages/App/Settings/Vendor/UserForm";
import SettingBrandUserForm from "../pages/App/Settings/Brand/UserForm";
import Invoice from "../pages/App/Invoice";
import InvoiceShow from "../pages/App/Invoice/Show";
import Customer from "../pages/App/Customer";
import CustomerShow from "../pages/App/Customer/Show";
import SendPayment from "../pages/App/SendPayment";
import SendPaymentForm from "../pages/App/SendPayment/Form";
import SettingBank from "../pages/App/Settings/Bank";
import SettingBankForm from "../pages/App/Settings/Bank/Form";
import UpdateStockHistory from "../pages/App/Stock/UpdateStockHistory";

const appRoutes = [
  {
    path: "/",
    element: <LayoutApp />,
    children: [
      { path: "/", element: <Navigate to={"/dashboard"} /> },
      {
        path: "/dashboard",
        children: [
          {
            path: "/",
            element: <Dashboard />,
          },
          {
            path: "/ringkasan",
            element: <DashboardRingkasan />,
          },
          {
            path: "/penjualan",
            element: <DashboardPenjualan />,
          },
          {
            path: "/branch",
            element: <DashboardBranch />,
          },
          {
            path: "/vendor",
            element: <DashboardVendor />,
          },
          {
            path: "/produk",
            element: <DashboardProduk />,
          },
          {
            path: "/pembeli",
            element: <DashboardPembeli />,
          },
        ],
      },
      {
        path: "/invoice",
        children: [
          {
            path: "/",
            element: <Invoice />,
          },
          {
            path: "/:id",
            element: <InvoiceShow />,
          },
        ],
      },
      {
        path: "/order",
        children: [
          {
            path: "/",
            element: <Order />,
          },
          {
            path: "/:id",
            element: <OrderShow />,
          },
        ],
      },
      {
        path: "/send-payment",
        children: [
          {
            path: "/",
            element: <SendPayment />,
          },
          {
            path: "/create",
            element: <SendPaymentForm />,
          },
          {
            path: "/:id",
            element: <SendPaymentForm />,
          },
        ],
      },
      {
        path: "/product",
        children: [
          {
            path: "/",
            element: <Product />,
          },
          {
            path: "/create",
            element: <ProductForm />,
          },
          {
            path: "/:id",
            element: <ProductForm />,
          },
        ],
      },
      {
        path: "/stock",
        children: [
          {
            path: "/",
            element: <Stok />,
          },
          {
            path: "/create",
            element: <StokForm />,
          },
          {
            path: "/:id",
            element: <StokForm />,
          },
          {
            path: "/manajemen",
            element: <ManagementStock />,
          },
          {
            path: "/management/adjust",
            element: <AdjustStock />,
          },
          {
            path: "/mutation",
            element: <MutationStock />,
          },
          {
            path: "/update-stock-history/:id/:notificationId",
            element: <UpdateStockHistory />,
          },
        ],
      },
      {
        path: "/customer",
        children: [
          {
            path: "/",
            element: <Customer />,
          },
          {
            path: "/:name",
            element: <CustomerShow />,
          },
        ],
      },
      {
        path: "/promosi",
        children: [
          {
            path: "/",
            element: <Promosi />,
          },
          {
            path: "/create",
            element: <AddPromosi />,
          },
          {
            path: "/:name",
            element: <DetailPromosi />,
          },
        ],
      },
      {
        path: "/settings",
        children: [
          {
            path: "/",
            element: <Settings />,
          },
          {
            path: "/brand",
            children: [
              {
                path: "/",
                element: <SettingBrand />,
              },
              {
                path: "/user",
                children: [
                  {
                    path: "/create",
                    element: <SettingBrandUserForm />,
                  },
                  {
                    path: "/:id",
                    element: <SettingBrandUserForm />,
                  },
                ],
              },
            ],
          },
          {
            path: "/vendor",
            children: [
              {
                path: "/",
                element: <SettingVendor />,
              },
              {
                path: "/create",
                element: <SettingVendorForm />,
              },
              {
                path: "/:id",
                element: <SettingVendorForm />,
              },
              {
                path: "/:vendorId/user/create",
                element: <SettingVendorUserForm />,
              },
              {
                path: "/:vendorId/user/:id",
                element: <SettingVendorUserForm />,
              },
            ],
          },
          {
            path: "/category",
            children: [
              {
                path: "/",
                element: <SettingCategory />,
              },
              {
                path: "/create",
                element: <SettingCategoryForm />,
              },
              {
                path: "/:id",
                element: <SettingCategoryForm />,
              },
            ],
          },
          {
            path: "/user",
            children: [
              {
                path: "/",
                element: <SettingUser />,
              },
              {
                path: "/create",
                element: <SettingUserForm />,
              },
              {
                path: "/:id",
                element: <SettingUserForm />,
              },
            ],
          },
          {
            path: "/role",
            children: [
              {
                path: "/",
                element: <SettingRole />,
              },
              {
                path: "/create",
                element: <SettingRoleForm />,
              },
              {
                path: "/:id",
                element: <SettingRoleForm />,
              },
            ],
          },
          {
            path: "/permission",
            children: [
              {
                path: "/",
                element: <SettingPermission />,
              },
              {
                path: "/create",
                element: <SettingPermissionForm />,
              },
              {
                path: "/:id",
                element: <SettingPermissionForm />,
              },
            ],
          },
          {
            path: "/bank",
            children: [
              {
                path: "/",
                element: <SettingBank />,
              },
              {
                path: "/create",
                element: <SettingBankForm />,
              },
              {
                path: "/:id",
                element: <SettingBankForm />,
              },
            ],
          },
          // To Be Deleted
          {
            path: "/peran",
            children: [
              {
                path: "/",
                element: <PengaturanPeran />,
              },
              {
                path: "/create",
                element: <TambahPeran />,
              },
            ],
          },
          {
            path: "/hakakses",
            children: [
              {
                path: "/",
                element: <PengaturanHakAkses />,
              },
              {
                path: "/create",
                element: <TambahHakAkses />,
              },
            ],
          },
          {
            path: "/akun",
            children: [
              {
                path: "/",
                element: <PengaturanAkun />,
              },
              {
                path: "/create",
                element: <TambahAkun />,
              },
            ],
          },
          {
            path: "/pengiriman",
            children: [
              {
                path: "/",
                element: <PengaturanPengiriman />,
              },
            ],
          },
        ],
      },
      {
        path: "/account",
        children: [
          { path: "/", element: <Navigate to={"/account/profile"} /> },
          { path: "/profile", element: <AccountProfile /> },
        ],
      },
    ],
  },
];

export default appRoutes;
