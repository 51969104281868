import { Helmet } from "react-helmet-async";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import VuiDateRangePicker from "../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker";
import moment from "moment";
import { useState } from "@hookstate/core/dist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import MultipleCheckboxes from "../../../../components/MultipleCheckboxes";
import CustomerRepository from "../../../../repositories/Widget/CustomerRepository";
import ProductRepositories from "../../../../repositories/Widget/ProductRepositories";
import SalesRepository from "../../../../repositories/Widget/SalesRepository";
import VendorRepositories from "../../../../repositories/Widget/VendorRepositories";
import ChannelBrandRepositories from "../../../../repositories/Brand/ChannelRepository";
import BrandVendorRepositories from "../../../../repositories/Brand/VendorRepository";
import BrandCategoryRepositories from "../../../../repositories/Brand/CategoryRepository";
import useIsMounted from "../../../../vodea/utilities/hooks/useIsMounted";
import {
  convertToLocaleNumber,
  convertToType,
} from "../../../../vodea/utilities/helpers/global";
import { cardDefaultValues, CardInterface } from "../Penjualan/interface";
import { AxiosResponse } from "axios";
import VuiCard from "../../../../vodea/@vodea-ui/components/VuiCard";
import VuiLoader from "../../../../vodea/@vodea-ui/components/VuiLoader";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import clsx from "clsx";
import VuiNumberFormat from "../../../../vodea/@vodea-ui/components/VuiNumberFormat";
import * as htmlToImage from "html-to-image";
import { $clone } from "../../../../vodea/utilities";
import { VuiButton } from "../../../../vodea/@vodea-ui/components/VuiButton";
import { htmlToPdf } from "../../../../vodea/utilities/helpers/pdf.helper";

const DashboardRingkasan: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { brand } = useSelector((state: RootState) => state.brand);
  const [isFirstMount, setIsFirstMount] = React.useState<boolean>(false);

  //penjualan
  const [orderGmvCard, setOrderGmvCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [orderGmvCardLoading, setOrderGmvCardLoading] =
    React.useState<boolean>(false);
  const [newOrderCard, setNewOrderCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [newOrderCardLoading, setNewOrderCardLoading] =
    React.useState<boolean>(false);
  const [incomingOrderCard, setIncomingOrderCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [incomingOrderCardLoading, setIncomingOrderCardLoading] =
    React.useState<boolean>(false);
  const [soldUnit, setSoldUnit] =
    React.useState<CardInterface>(cardDefaultValues);
  const [soldUnitLoading, setSoldUnitLoading] = React.useState<boolean>(false);
  const [avgSalesPriceCard, setAvgSalesPriceCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [avgSalesPriceCardLoading, setAvgSalesPriceCardLoading] =
    React.useState<boolean>(false);

  //vendor
  const [gmvVendorCard, setGmvVendorCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [gmvVendorCardLoading, setGmvVendorCardLoading] =
    React.useState<boolean>(false);
  const [soldProductVendorCard, setSoldProductVendorCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [soldProductVendorCardLoading, setSoldProductVendorCardLoading] =
    React.useState<boolean>(false);
  const [inStockRateVendorCard, setInStockRateVendorCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [inStockRateVendorCardLoading, setInStockRateVendorCardLoading] =
    React.useState<boolean>(false);

  //product
  const [gmvProductCard, setGmvProductCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [gmvProductCardLoading, setGmvProductCardLoading] =
    React.useState<boolean>(false);
  const [soldProductCard, setSoldProductCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [soldProductCardLoading, setSoldProductCardLoading] =
    React.useState<boolean>(false);
  const [runRateProductCard, setRunRateProductCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [runRateProductCardLoading, setRunRateProductCardLoading] =
    React.useState<boolean>(false);
  const [weeksOfSupplyProductCard, setWeeksOfSupplyProductCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [weeksOfSupplyProductCardLoading, setWeeksOfSupplyProductCardLoading] =
    React.useState<boolean>(false);

  const [serviceLevelAgreementCard, setServiceLevelAgreementCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [
    serviceLevelAgreementCardLoading,
    setServiceLevelAgreementCardLoading,
  ] = React.useState<boolean>(false);

  //customer
  const [newCustomerCard, setNewCustomerCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [newCustomerCardLoading, setNewCustomerCardLoading] =
    React.useState<boolean>(false);
  const [regularCustomerCard, setRegularCustomerCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [regularCustomerCardLoading, setRegularCustomerCardLoading] =
    React.useState<boolean>(false);
  const [loyalCustomerCard, setLoyalCustomerCard] =
    React.useState<CardInterface>(cardDefaultValues);
  const [loyalCustomerCardLoading, setLoyalCustomerCardLoading] =
    React.useState<boolean>(false);

  const [totalCategoryOption, setTotalCategoryOption] =
    React.useState<number>(0);
  const [totalVendorOption, setTotalVendorOption] = React.useState<number>(0);
  const [daysDifference, setDaysDifference] = React.useState(0);
  const [channelOptions, setChannelOptions] = React.useState<any[]>([]);
  const [categoryOptions, setCategoryOptions] = React.useState<any[]>([]);
  const [selectedChannel, setSelectedChannel] = React.useState<any[]>([]);
  const [selectedVendor, setSelectedVendor] = React.useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<any[]>([]);
  const [vendorOptions, setVendorOptions] = React.useState<any[]>([]);

  const filterConfig = useState({
    date_from: moment().subtract(6, "days").format("YYYY-MM-DD"),
    date_to: moment().format("YYYY-MM-DD"),
  });

  const getChannelOptions = () => {
    ChannelBrandRepositories.all(brand.id)
      .then((response: any) => {
        const temp: any[] = [];
        response.data.data.forEach((item: any) => {
          temp.push(item.id);
        });
        if (isMounted.current) {
          setChannelOptions(response.data.data);
          setSelectedChannel(temp);
        }
      })
      .catch((err: any) => {});
  };

  const getCategoryOptions = () => {
    BrandCategoryRepositories.all(brand.id, {
      with: "children",
      only: "parent",
      per_page: 1000,
    })
      .then((response: any) => {
        let total = 0;
        const temp: any = [];
        response.data.data.forEach((item: any) => {
          if (item.children.length > 0) {
            item.children.forEach((child: any, index: number) => {
              temp.push(child.id);
              total += 1;
            });
          }
          temp.push(item.id);
          total += 1;
        });
        if (isMounted.current) {
          setTotalCategoryOption(total);
          setCategoryOptions(response.data.data);
          setSelectedCategory(temp);
        }
      })
      .catch((err: any) => {});
  };

  const getVendorOptions = () => {
    BrandVendorRepositories.all(brand.id, {
      per_page: 1000,
    })
      .then((response: any) => {
        if (isMounted.current) {
          const data = response.data.data;
          const temp: any = [];
          data.forEach((item: any) => {
            temp.push(item.id);
          });
          setVendorOptions(data);
          setTotalVendorOption(data.length);
          setSelectedVendor(temp);
        }
      })
      .catch((err: any) => {});
  };

  useEffect(() => {
    getChannelOptions();
    getCategoryOptions();
    getVendorOptions();
  }, []);

  useEffect(() => {
    if (isFirstMount) {
      getAllData();
    }
    if (
      selectedChannel.length > 0 &&
      selectedVendor.length > 0 &&
      selectedCategory.length > 0
    ) {
      setIsFirstMount(true);
    }
  }, [selectedCategory, selectedVendor, selectedChannel, isFirstMount]);

  const getGmvOrderCardData = (params: any) => {
    if (isMounted.current) setOrderGmvCardLoading(true);
    SalesRepository.getGrossMargin(brand.id, params)
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const temp: CardInterface = {
          title: "GMV",
          description:
            "Didapat dari pesanan yang masuk diluar pesanan yang dibatalkan.",
          value: data.current_period,
          compare: convertToLocaleNumber(data.last_period, data.current_period),
          type: convertToType(data.last_period, data.current_period),
        };
        if (isMounted.current) {
          setOrderGmvCard(temp);
          setOrderGmvCardLoading(false);
        }
      })
      .catch((err: any) => {
        if (isMounted.current) {
          setOrderGmvCardLoading(false);
        }
      });
  };

  const getNewOrderCardData = (params: any) => {
    if (isMounted.current) setNewOrderCardLoading(true);
    SalesRepository.getInvoiceCard(brand.id, {
      ...params,
      status: "Pesanan Baru",
    })
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const temp: CardInterface = {
          title: "Pesanan baru",
          description:
            "Jumlah pesanan yang baru masuk dan sudah dibayar oleh pembeli.",
          value: data.current_period,
          compare: convertToLocaleNumber(data.last_period, data.current_period),
          type: convertToType(data?.last_period, data?.current_period),
        };

        if (isMounted.current) {
          setNewOrderCard(temp);
          setNewOrderCardLoading(false);
        }
      })
      .catch((err: any) => {
        if (isMounted.current) {
          setNewOrderCardLoading(false);
        }
      });
  };

  const getInvoiceIncomingCard = (params: any) => {
    if (isMounted.current) setIncomingOrderCardLoading(true);
    SalesRepository.getIncomingInvoiceCard(brand.id, {
      ...params,
      status: [
        "Selesai",
        "Diproses Vendor",
        "Dalam Pengiriman",
        "Pesanan Sampai",
      ],
    })
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const temp: CardInterface = {
          title: "Pesanan masuk",
          description:
            "Jumlah pesanan masuk yang diambil dari invoice dimulai dari status pesanan diproses vendor sampai pesanan selesai.",
          value: data?.current_period,
          compare: convertToLocaleNumber(data.last_period, data.current_period),
          type: convertToType(data?.last_period, data?.current_period),
        };

        if (isMounted.current) {
          setIncomingOrderCard(temp);
          setIncomingOrderCardLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setIncomingOrderCardLoading(false);
        }
      });
  };

  const getSoldUnit = (params: any) => {
    if (isMounted.current) setSoldUnitLoading(true);
    SalesRepository.getOrderProductCard(brand.id, params)
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const temp: CardInterface = {
          title: "Unit terjual",
          description:
            "Jumlah pesanan yang baru masuk dan sudah dibayar oleh pembeli.",
          value: data.current_period,
          compare: convertToLocaleNumber(data.last_period, data.current_period),
          type: convertToType(data.last_period, data.current_period),
        };

        if (isMounted.current) {
          setSoldUnit(temp);
          setSoldUnitLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setSoldUnitLoading(false);
        }
      });
  };

  const getAvgSalesPriceData = (params: any) => {
    if (isMounted.current) setAvgSalesPriceCardLoading(true);
    SalesRepository.getAverageSalePrice(brand.id, params)
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const temp: CardInterface = {
          title: "ASP",
          description:
            "Berdasarkan Total GMV dibagi dengan Total Unit yang tersedia.",
          value: data.current_period,
          compare: convertToLocaleNumber(data.last_period, data.current_period),
          type: convertToType(data.last_period, data.current_period),
        };
        if (isMounted.current) {
          setAvgSalesPriceCard(temp);
          setAvgSalesPriceCardLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setAvgSalesPriceCardLoading(false);
        }
      });
  };

  const getServiceLevelAgreementData = (params: any) => {
    if (isMounted.current) setServiceLevelAgreementCardLoading(true);
    VendorRepositories.getServiceLevelAgreement(brand.id, params)
      .then((response: AxiosResponse) => {
        const temp: CardInterface = {
          title: "SLA",
          description:
            "Average dari semua transaksi awal saat vendor terima order s.d barang di terima customer.",
          value: response.data.data.current_period,
        };
        if (isMounted.current) {
          setServiceLevelAgreementCard(temp);
          setServiceLevelAgreementCardLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setServiceLevelAgreementCardLoading(false);
        }
      });
  };

  const getGmvVendorCardData = (params: any) => {
    if (isMounted.current) setGmvVendorCardLoading(true);
    VendorRepositories.getGrossMargin(brand.id, params)
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const temp: CardInterface = {
          title: "GMV",
          description:
            "Didapat dari pesanan yang masuk diluar dari pesanan yang dibatalkan.",
          value: data.current_period,
          compare: convertToLocaleNumber(data.last_period, data.current_period),
          type: convertToType(data.last_period, data.current_period),
        };
        if (isMounted.current) {
          setGmvVendorCard(temp);
          setGmvVendorCardLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setGmvVendorCardLoading(false);
        }
      });
  };

  const getSoldProductVendorCardData = (params: any) => {
    if (isMounted.current) setSoldProductVendorCardLoading(true);

    VendorRepositories.getSoldProduct(brand.id, params)
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const temp: CardInterface = {
          title: "Produk terjual",
          description: "Jumlah produk terjual",
          value: data.current_period,
          compare: convertToLocaleNumber(data.last_period, data.current_period),
          type: convertToType(data.last_period, data.current_period),
        };
        if (isMounted.current) {
          setSoldProductVendorCard(temp);
          setSoldProductVendorCardLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setSoldProductVendorCardLoading(false);
        }
      });
  };

  const getInStockRateVendorCardData = (params: any) => {
    if (isMounted.current) setInStockRateVendorCardLoading(true);
    VendorRepositories.getInStockRate(brand.id, params)
      .then((response: AxiosResponse) => {
        const temp: CardInterface = {
          title: "Average In Stock Rate",
          description: "Persentase ketersediaan stok.",
          value: response.data.data,
        };
        if (isMounted.current) {
          setInStockRateVendorCard(temp);
          setInStockRateVendorCardLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setInStockRateVendorCardLoading(false);
        }
      });
  };

  const getGmvProductCardData = (params: any) => {
    if (isMounted.current) setGmvProductCardLoading(true);
    ProductRepositories.getGrossMargin(brand.id, params)
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const temp: CardInterface = {
          title: "GMV",
          description:
            "Didapat dari pesanan yang masuk diluar dari pesanan yang dibatalkan.",
          value: data.current_period,
          compare: convertToLocaleNumber(data.last_period, data.current_period),
          type: convertToType(data.last_period, data.current_period),
        };

        if (isMounted.current) {
          setGmvProductCard(temp);
          setGmvProductCardLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setGmvProductCardLoading(false);
        }
      });
  };

  const getSoldProductCardData = (params: any) => {
    if (isMounted.current) setSoldProductCardLoading(true);
    ProductRepositories.getSoldProduct(brand.id, params)
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const temp: CardInterface = {
          title: "Produk terjual",
          description: "Jumlah produk terjual",
          value: data.current_period,
          compare: convertToLocaleNumber(data.last_period, data.current_period),
          type: convertToType(data.last_period, data.current_period),
        };

        if (isMounted.current) {
          setSoldProductCard(temp);
          setSoldProductCardLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setSoldProductCardLoading(false);
        }
      });
  };

  const getRunRateProductCardData = (params: any) => {
    if (isMounted.current) setRunRateProductCardLoading(true);
    ProductRepositories.getRunRate(brand.id, params)
      .then((response: any) => {
        const temp: CardInterface = {
          title: "Average Run Rate",
          description: "Unit terjual dari 7 hari kebelakang",
          value: response.data.data,
        };
        if (isMounted.current) {
          setRunRateProductCard(temp);
          setRunRateProductCardLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setRunRateProductCardLoading(false);
        }
      });
  };

  const getWeeksOfSupplyProductCardData = (params: any) => {
    if (isMounted.current) setWeeksOfSupplyProductCardLoading(true);
    ProductRepositories.getWOS(brand.id, params)
      .then((response: any) => {
        const temp: CardInterface = {
          title: "Average Weeks Of Supply",
          description: "Nilai total rata-rata dari item yang ada.",
          value: response.data.data,
        };
        if (isMounted.current) {
          setWeeksOfSupplyProductCard(temp);
          setWeeksOfSupplyProductCardLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setWeeksOfSupplyProductCardLoading(false);
        }
      });
  };

  const getNewCustomerCardData = (params: any) => {
    if (isMounted.current) setNewCustomerCardLoading(true);
    CustomerRepository.getNewCustomer(brand.id, params)
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const temp: CardInterface = {
          title: "Pembeli baru",
          description: "Pembeli yang baru pertama kali berbelanja di tokomu.",
          value: data.current_period,
          compare: convertToLocaleNumber(data.last_period, data.current_period),
          type: convertToType(data.last_period, data.current_period),
        };

        if (isMounted.current) {
          setNewCustomerCard(temp);
          setNewCustomerCardLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setNewCustomerCardLoading(false);
        }
      });
  };

  const getRegularCustomerCardData = (params: any) => {
    if (isMounted.current) setRegularCustomerCardLoading(true);
    CustomerRepository.getCustomerRegular(brand.id, params)
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const temp: CardInterface = {
          title: "Pembeli Reguler",
          description:
            "Pembeli yang kembali berbelanja di tokomu setelah lebih dari 30 hari semenjak transaksi terakhir.",
          value: data.current_period,
          compare: convertToLocaleNumber(data.last_period, data.current_period),
          type: convertToType(data.last_period, data.current_period),
        };
        if (isMounted.current) {
          setRegularCustomerCard(temp);
          setRegularCustomerCardLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setRegularCustomerCardLoading(false);
        }
      });
  };

  const getLoyalCustomerCardData = (params: any) => {
    if (isMounted.current) setLoyalCustomerCardLoading(true);
    CustomerRepository.getLoyalCustomer(brand.id, params)
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const temp: CardInterface = {
          title: "Pembeli setia",
          description:
            "Pembeli yang kembali berbelanja di tokomu dalam kurang dari 30 hari semenjak transaksi terakhir.",
          value: data.current_period,
          compare: convertToLocaleNumber(data.last_period, data.current_period),
          type: convertToType(data.last_period, data.current_period),
        };
        if (isMounted.current) {
          setLoyalCustomerCard(temp);
          setLoyalCustomerCardLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setLoyalCustomerCardLoading(false);
        }
      });
  };

  const getAllData = () => {
    const params = {
      "date-from": filterConfig.date_from.get(),
      "date-to": filterConfig.date_to.get(),
      channel: selectedChannel,
    };

    if (selectedCategory.length !== totalCategoryOption) {
      Object.assign(params, {
        category: selectedCategory,
      });
    }

    if (selectedVendor.length !== totalVendorOption) {
      Object.assign(params, {
        vendor: selectedVendor,
      });
    }

    const difference = moment(filterConfig.date_to.get()).diff(
      moment(filterConfig.date_from.get()),
      "days"
    );

    if (difference == 0 || difference == 1) {
      if (isMounted.current) setDaysDifference(1);
    } else {
      if (isMounted.current) setDaysDifference(difference + 1);
    }

    //penjualan
    getGmvOrderCardData(params);
    getNewOrderCardData(params);
    getInvoiceIncomingCard(params);
    getSoldUnit(params);
    getAvgSalesPriceData(params);

    //vendor
    getGmvVendorCardData(params);
    getSoldProductVendorCardData(params);
    getInStockRateVendorCardData(params);
    getServiceLevelAgreementData(params);

    //produk
    getGmvProductCardData(params);
    getSoldProductCardData(params);
    getRunRateProductCardData(params);
    getWeeksOfSupplyProductCardData(params);

    //customer
    getNewCustomerCardData(params);
    getRegularCustomerCardData(params);
    getLoyalCustomerCardData(params);
  };

  const handleChangeDate = (start: any, end: any) => {
    filterConfig.date_from.set(start.format("YYYY-MM-DD"));
    filterConfig.date_to.set(end.format("YYYY-MM-DD"));

    getAllData();
  };

  const [loadingExport, setLoadingExport] = React.useState<boolean>(false);
  const handleExport = useCallback(() => {
    if (isMounted.current) {
      setLoadingExport(true);
      const body: any = document.querySelector(".app-container");
      // htmlToImage
      //   .toPng(body)
      //   .then(function (dataUrl) {
      //     download(dataUrl, "dashboard-ringkasan", "png");
      //     if (isMounted.current) setLoadingExport(false);
      //   })
      //   .catch(function (error) {
      //     console.error("oops, something went wrong!", error);
      //   });
      if (body) {
        htmlToImage
          .toCanvas(body)
          .then(function (dataUrl) {
            const finish = htmlToPdf(dataUrl, "dashboard-ringkasan");
            if (finish) setLoadingExport(false);
          })
          .catch(function (error) {
            setLoadingExport(false);
          });
      }
    }
  }, [isMounted]);

  return (
    <>
      <Helmet>
        <title>{t("Dashboard Ringkasan")}</title>
      </Helmet>

      <div className={"page-header-component"}>
        <h3 className={"title"}>
          Dashboard | <strong>Ringkasan</strong>
        </h3>
        <VuiButton
          onClick={handleExport}
          variant="success"
          label={t("button.export")}
          loading={loadingExport}
        />
      </div>

      <div className="card-paper mb-4">
        <div className="card-content">
          <div className="card-filter-section">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label" style={{ display: "flex" }}>
                    Rentang Tanggal
                  </label>
                  <VuiDateRangePicker
                    startDate={$clone(filterConfig.date_from.get())}
                    endDate={$clone(filterConfig.date_to.get())}
                    callback={handleChangeDate}
                    useRanges={true}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">Kategori</label>
                  <MultipleCheckboxes
                    name={"kategori"}
                    options={categoryOptions}
                    labelOption="name"
                    valueOption="id"
                    values={selectedCategory}
                    onChange={(values) => {
                      setSelectedCategory(values);
                    }}
                    children={true}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">Channel</label>
                  <MultipleCheckboxes
                    name={"channel"}
                    labelOption="name"
                    options={channelOptions}
                    valueOption="id"
                    values={selectedChannel}
                    onChange={(values) => {
                      setSelectedChannel(values);
                    }}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">Vendor</label>
                  <MultipleCheckboxes
                    name={"vendor"}
                    options={vendorOptions}
                    labelOption="name"
                    valueOption="id"
                    values={selectedVendor}
                    onChange={(values) => {
                      setSelectedVendor(values);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-paper mb-4">
        <div className="card-header">
          <h6 className="card-header-title">Ringkasan penjualan</h6>

          <Link to={"/dashboard/penjualan"}>
            <FontAwesomeIcon icon={faAngleRight} />
          </Link>
        </div>
        <div className="card-content">
          <div className="card-chart-wrapper">
            <VuiCard
              title={orderGmvCard.title}
              description={orderGmvCard.description}
              loading={orderGmvCardLoading}
              type={orderGmvCard.type}
              compare={orderGmvCard.compare}
              value={orderGmvCard.value}
              days={daysDifference}
              isCurrency
              hasComparison
            />

            <VuiCard
              title={newOrderCard.title}
              description={newOrderCard.description}
              loading={newOrderCardLoading}
              type={newOrderCard.type}
              compare={newOrderCard.compare}
              value={newOrderCard.value}
              days={daysDifference}
              hasComparison
            />

            <VuiCard
              title={incomingOrderCard.title}
              description={incomingOrderCard.description}
              loading={incomingOrderCardLoading}
              type={incomingOrderCard.type}
              compare={incomingOrderCard.compare}
              value={incomingOrderCard.value}
              days={daysDifference}
              hasComparison
            />

            <VuiCard
              title={soldUnit.title}
              description={soldUnit.description}
              loading={soldUnitLoading}
              type={soldUnit.type}
              compare={soldUnit.compare}
              value={soldUnit.value}
              hasComparison
              days={daysDifference}
            />

            <VuiCard
              title={avgSalesPriceCard.title}
              description={avgSalesPriceCard.description}
              loading={avgSalesPriceCardLoading}
              type={avgSalesPriceCard.type}
              compare={avgSalesPriceCard.compare}
              value={avgSalesPriceCard.value}
              days={daysDifference}
              isCurrency
              hasComparison
            />
          </div>
        </div>
      </div>

      <div className="card-paper mb-4">
        <div className="card-header">
          <h6 className="card-header-title">Ringkasan Vendor</h6>

          <Link to={"/dashboard/vendor"}>
            <FontAwesomeIcon icon={faAngleRight} />
          </Link>
        </div>

        <div className="card-content">
          <div className="card-chart-wrapper">
            <VuiCard
              title={gmvVendorCard.title}
              description={gmvVendorCard.description}
              loading={gmvVendorCardLoading}
              type={gmvVendorCard.type}
              compare={gmvVendorCard.compare}
              value={gmvVendorCard.value}
              days={daysDifference}
              hasComparison
            />

            <VuiCard
              title={soldProductVendorCard.title}
              description={soldProductVendorCard.description}
              loading={soldProductVendorCardLoading}
              type={soldProductVendorCard.type}
              compare={soldProductVendorCard.compare}
              value={soldProductVendorCard.value}
              days={daysDifference}
              hasComparison
            />

            <VuiCard
              title={inStockRateVendorCard.title}
              description={inStockRateVendorCard.description}
              loading={inStockRateVendorCardLoading}
              value={inStockRateVendorCard.value}
              isPercentage
              days={daysDifference}
            />

            <div className={"card-chart"}>
              {serviceLevelAgreementCardLoading ? (
                <VuiLoader />
              ) : (
                <>
                  <label className="card-title">
                    {serviceLevelAgreementCard.title}
                    <OverlayTrigger
                      key={`tooltip-overlay-gmv`}
                      placement={"top"}
                      overlay={
                        <Tooltip id={`tooltip-gmv`}>
                          <i>{serviceLevelAgreementCard.description}</i>
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        className={"icon"}
                        icon={faExclamationCircle}
                      />
                    </OverlayTrigger>
                  </label>
                  <h3
                    className={clsx({
                      "card-value": true,
                      positive:
                        serviceLevelAgreementCard.value <= 24 &&
                        serviceLevelAgreementCard.value >= 1,
                      negative: serviceLevelAgreementCard.value > 24,
                    })}
                  >
                    <VuiNumberFormat
                      data={serviceLevelAgreementCard.value}
                      value={serviceLevelAgreementCard.value}
                    />
                  </h3>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="card-paper mb-4">
        <div className="card-header">
          <h6 className="card-header-title">Ringkasan Produk</h6>

          <Link to={"/dashboard/produk"}>
            <FontAwesomeIcon icon={faAngleRight} />
          </Link>
        </div>

        <div className="card-content">
          <div className="card-chart-wrapper">
            <VuiCard
              title={gmvProductCard.title}
              description={gmvProductCard.description}
              loading={gmvProductCardLoading}
              type={gmvProductCard.type}
              compare={gmvProductCard.compare}
              value={gmvProductCard.value}
              days={daysDifference}
              isCurrency
              hasComparison
            />

            <VuiCard
              title={soldProductCard.title}
              description={soldProductCard.description}
              loading={soldProductCardLoading}
              type={soldProductCard.type}
              compare={soldProductCard.compare}
              value={soldProductCard.value}
              days={daysDifference}
              hasComparison
            />

            <VuiCard
              title={runRateProductCard.title}
              description={runRateProductCard.description}
              loading={runRateProductCardLoading}
              value={runRateProductCard.value}
              days={daysDifference}
            />

            <VuiCard
              title={weeksOfSupplyProductCard.title}
              description={weeksOfSupplyProductCard.description}
              loading={weeksOfSupplyProductCardLoading}
              value={weeksOfSupplyProductCard.value}
              days={daysDifference}
            />
          </div>
        </div>
      </div>

      <div className="card-paper mb-4">
        <div className="card-header">
          <h6 className="card-header-title">Ringkasan Pembeli</h6>

          <Link to={"/dashboard/pembeli"}>
            <FontAwesomeIcon icon={faAngleRight} />
          </Link>
        </div>

        <div className="card-content">
          <div className="card-chart-wrapper">
            <VuiCard
              title={newCustomerCard.title}
              description={newCustomerCard.description}
              loading={newCustomerCardLoading}
              type={newCustomerCard.type}
              compare={newCustomerCard.compare}
              value={newCustomerCard.value}
              days={daysDifference}
              hasComparison
            />

            <VuiCard
              title={regularCustomerCard.title}
              description={regularCustomerCard.description}
              loading={regularCustomerCardLoading}
              type={regularCustomerCard.type}
              compare={regularCustomerCard.compare}
              value={regularCustomerCard.value}
              days={daysDifference}
              hasComparison
            />

            <VuiCard
              title={loyalCustomerCard.title}
              description={loyalCustomerCard.description}
              loading={loyalCustomerCardLoading}
              type={loyalCustomerCard.type}
              compare={loyalCustomerCard.compare}
              value={loyalCustomerCard.value}
              days={daysDifference}
              hasComparison
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardRingkasan;
