import { api, createCancelTokenHandler } from "../../vodea/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (brandId: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/brand/${brandId}/product`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  show: function (
    brandId: number | string,
    id: number | string,
    params: any = null
  ) {
    return api.get(`${endPoint()}/api/brand/${brandId}/product/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.show.name].handleRequestCancellation()
          .token,
    });
  },
  create: function (
    brandId: number | string,
    payload: any,
    params: any = null
  ) {
    return api.post(`${endPoint()}/api/brand/${brandId}/product`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
  update: function (
    brandId: number | string,
    id: number | string,
    payload: any,
    params: any = null
  ) {
    return api.put(
      `${endPoint()}/api/brand/${brandId}/product/${id}`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
            .token,
      }
    );
  },
  delete: function (
    brandId: number | string,
    id: number | string,
    params: any = null
  ) {
    return api.delete(`${endPoint()}/api/brand/${brandId}/product/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.delete.name].handleRequestCancellation()
          .token,
    });
  },
  allStockVendor: function (brandId: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/brand/${brandId}/product-stock`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.allStockVendor.name
        ].handleRequestCancellation().token,
    });
  },
  updateStockVendor: function (
    brandId: number | string,
    id: string,
    payload: any,
    params: any = null
  ) {
    return api.put(
      `${endPoint()}/api/brand/${brandId}/product/${id}/update-stock`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
            .token,
      }
    );
  },
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);
