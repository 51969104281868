import { api, createCancelTokenHandler } from "../../vodea/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (brandId: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/brand/${brandId}/widget/customer/card/`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  getNewCustomer: function (brandId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/customer/card/new-customer`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getNewCustomer.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getCustomerRegular: function (brandId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/customer/card/customer-regular`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getCustomerRegular.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getLoyalCustomer: function (brandId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/customer/card/loyal-customer`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getLoyalCustomer.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getNewCustomerChart: function (brandId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/customer/chart/new-customer-period`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getNewCustomerChart.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getRegularCustomerChart: function (
    brandId: number | string,
    params: any = null
  ) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/customer/chart/customer-regular-period`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getRegularCustomerChart.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getLoyalCustomerChart: function (
    brandId: number | string,
    params: any = null
  ) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/customer/chart/loyal-customer-period`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getLoyalCustomerChart.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getCustomerGenderChart: function (
    brandId: number | string,
    params: any = null
  ) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/customer/chart/customer-gender-period`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getCustomerGenderChart.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getTableData: function (brandId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/customer/table/customer-city`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getTableData.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getPaymentMethodChart: function (
    brandId: number | string,
    params: any = null
  ) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/customer/chart/customer-payment-method`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getPaymentMethodChart.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getCustomerCityChart: function (
    brandId: number | string,
    params: any = null
  ) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/customer/chart/customer-city`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getCustomerCityChart.name
          ].handleRequestCancellation().token,
      }
    );
  },
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);
