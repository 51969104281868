import { api, createCancelTokenHandler } from "../vodea/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (params: any = null) {
    return api.get(`${endPoint()}/api/vendor`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  show: function (id: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/vendor/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.show.name].handleRequestCancellation()
          .token,
    });
  },
  select: function (params: any = null) {
    return api.get(`${endPoint()}/api/select/vendor`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
          .token,
    });
  },
  create: function (payload: any, onUploadProgress: any, params: any = null) {
    return api.post(`${endPoint()}/api/vendor`, payload, {
      params,
      onUploadProgress,
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
  update: function (
    id: number | string,
    payload: any,
    onUploadProgress: any,
    params: any = null
  ) {
    return api.post(`${endPoint()}/api/vendor/${id}`, payload, {
      params,
      onUploadProgress,
      cancelToken:
        cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
          .token,
    });
  },
  delete: function (id: number | string, params: any = null) {
    return api.delete(`${endPoint()}/api/vendor/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.delete.name].handleRequestCancellation()
          .token,
    });
  },
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);
