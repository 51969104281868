import * as yup from 'yup';
import 'yup-phone';

export type InformationInputs = {
  name: string;
};

export const informationSchema = yup.object().shape({
  name: yup.string().required(),
});

export const InformationBaseModel: InformationInputs = {
  name: '',
};

