import DateRangePicker, {
  CallbackHandler,
  EventHandler,
} from "react-bootstrap-daterangepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useRef } from "react";
import moment from "moment";
import _ from "lodash";

type openTypes = "left" | "center" | "right";
type dropTypes = "up" | "down" | "auto";

interface VuiDateRangePickerProps {
  name?: string;
  single?: boolean;
  timePicker?: boolean;
  autoApply?: boolean;
  opens?: openTypes;
  drops?: dropTypes;
  useRanges?: boolean;
  charts?: boolean;
  format?: string;
  startDate?: any;
  endDate?: any;
  event?: EventHandler;
  callback?: CallbackHandler;
  onChange?: (val: any) => void;
}

const VuiDateRangePicker: React.FC<VuiDateRangePickerProps> = ({
  name,
  single = false,
  timePicker = false,
  autoApply = false,
  opens = "left",
  drops = "auto",
  useRanges,
  charts = false,
  format = "D MMMM YYYY",
  startDate = moment().format("YYYY-MM-DD"),
  endDate = moment().format("YYYY-MM-DD"),
  event,
  callback,
  onChange,
}) => {
  const ref = useRef<any>(null);

  const finalFormat = `${format} ${timePicker ? "h:mm A" : ""}`;

  useEffect(() => {
    const date = moment(startDate ? startDate : moment(), "YYYY-MM-DD");

    ref.current.setStartDate(date);
    if (single) {
      ref.current.setEndDate(date);
    }
  }, [startDate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (endDate) {
      ref.current.setEndDate(
        moment(endDate ? endDate : moment(), "YYYY-MM-DD").format(finalFormat)
      );
    }
  }, [endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const initSetting = {
    autoApply: autoApply,
    startDate: startDate,
    endDate: endDate,
    timePicker: timePicker,
    singleDatePicker: single,
    opens: opens,
    drops: drops,
    showDropdowns: true,
    locale: {
      format: finalFormat,
      fromLabel: "From",
      toLabel: "To",
    },
    ranges:
      useRanges === undefined
        ? undefined
        : useRanges
        ? {
            "Hari ini": [moment(), moment()],
            Kemarin: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days"),
            ],
            "7 hari terakhir": [moment().subtract(6, "days"), moment()],
            "30 hari terakhir": [moment().subtract(29, "days"), moment()],
            "Bulan ini": [moment().startOf("month"), moment().endOf("month")],
            "Bulan lalu": [
              moment().subtract(1, "month").startOf("month"),
              moment().subtract(1, "month").endOf("month"),
            ],
            "Tahun ini": [moment().startOf("year"), moment().endOf("year")],
          }
        : {
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "Last 90 Days": [moment().subtract(89, "days"), moment()],
            "This Year": [moment().startOf("year"), moment().endOf("year")],
          },
  };

  const datepickerEvent: EventHandler = (event, picker) => {
    if (_.indexOf(["apply", "hide"], event.type) !== -1) {
      const startDate = picker.startDate;
      const endDate = picker.endDate;

      if (onChange) {
        if (single) {
          onChange(startDate);
        } else if (!single) {
          onChange({
            start: startDate,
            end: endDate,
          });
        }
      }
    }
  };

  return (
    <>
      <div className="input-group suffix-group">
        <DateRangePicker
          ref={ref}
          onEvent={datepickerEvent}
          onCallback={callback}
          initialSettings={initSetting}
        >
          <input type="text" className="form-control" />
        </DateRangePicker>
        <span className="input-group-text">
          <FontAwesomeIcon className={"icon icon-prefix"} icon={faCalendar} />
        </span>
      </div>
    </>
  );
};

export default VuiDateRangePicker;
