import { api, createCancelTokenHandler } from "../../../vodea/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (vendorId: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/vendor/${vendorId}/widget/sales/card/`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  getGrossMargin: function (vendorId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/vendor/${vendorId}/widget/sales/card/gross-margin`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getGrossMargin.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getNewOrder: function (vendorId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/vendor/${vendorId}/widget/sales/card/new-order`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getNewOrder.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getNewOrderCard: function (vendorId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/vendor/${vendorId}/widget/sales/card/order`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getNewOrderCard.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getIncomingOrderCard: function (
    vendorId: number | string,
    params: any = null
  ) {
    return api.get(
      `${endPoint()}/api/vendor/${vendorId}/widget/sales/card/order`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getNewOrder.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getGrossMarginChart: function (
    vendorId: number | string,
    params: any = null
  ) {
    return api.get(
      `${endPoint()}/api/vendor/${vendorId}/widget/sales/chart/gross-margin-period`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getGrossMarginChart.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getOrderChart: function (vendorId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/vendor/${vendorId}/widget/sales/chart/order-period`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getOrderChart.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getIncomingOrderChart: function (
    vendorId: number | string,
    params: any = null
  ) {
    return api.get(
      `${endPoint()}/api/vendor/${vendorId}/widget/sales/chart/order-period`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getIncomingOrderChart.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getTableOrderData: function (vendorId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/vendor/${vendorId}/widget/sales/table/order`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getTableOrderData.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getOrderProduct: function (vendorId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/vendor/${vendorId}/widget/sales/card/order-product`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getOrderProduct.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getAverageSalePrice: function (
    vendorId: number | string,
    params: any = null
  ) {
    return api.get(
      `${endPoint()}/api/vendor/${vendorId}/widget/sales/card/average-sales-price`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getAverageSalePrice.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getAverageSalePriceChart: function (
    vendorId: number | string,
    params: any = null
  ) {
    return api.get(
      `${endPoint()}/api/vendor/${vendorId}/widget/sales/chart/average-sales-price`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getAverageSalePriceChart.name
          ].handleRequestCancellation().token,
      }
    );
  },
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);
