import { UPDATE_VENDOR, VendorActionTypes, VendorState } from './types';

const initialState: VendorState = {
  vendor: {}
};

export function vendorReducer(state = initialState, action: VendorActionTypes): VendorState {
  switch (action.type) {
    case UPDATE_VENDOR: {
      return Object.assign(state, {
        vendor: action.payload.vendor
      });
    }
    default:
      return state;
  }
}
