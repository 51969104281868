import React from 'react';
import NumberFormat from 'react-number-format';

interface INumber {
  data: string | number;
  value?: string | number;
  suffix?: string;
  prefix?: string;
  className?: string;
  decimalScale?: number;
  displayType?: any;
  onValueChange?: any;
}

const VuiNumberFormat: React.FC<INumber> = ({
  displayType = 'text',
  decimalScale = 0,
  className,
  data,
  prefix,
  suffix,
  onValueChange,
  value
}) => {
  const defaultValue = typeof data === 'number' ? data : parseFloat(data);
  return (
    <div className={className}>
      {prefix || ''}
      <NumberFormat
        value={value}
        defaultValue={defaultValue}
        displayType={displayType}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={decimalScale}
        onValueChange={onValueChange}
      />
      {suffix || ''}
    </div>
  );
};

export default VuiNumberFormat;
