import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faSearch} from "@fortawesome/free-solid-svg-icons";
import VuiDataTable from "../../../../vodea/@vodea-ui/components/VuiDataTable";
import UserRepository from "../../../../repositories/UserRepository";
import {AxiosError, AxiosResponse} from "axios";
import {showToast} from "../../../../vodea/utilities/helpers/global";
import {useState} from "@hookstate/core";
import moment from "moment";
import {$clone, defaultActionFormatter} from "../../../../vodea/utilities";
import _ from "lodash";
import {TableChangeHandler} from "react-bootstrap-table-next";
import {Link, useLocation, useParams} from "react-router-dom";
import useIsMounted from "../../../../vodea/utilities/hooks/useIsMounted";
import {useSelector} from "react-redux";
import {RootState} from "../../../../stores";
import VuiActionModal from "../../../../vodea/@vodea-ui/components/Modal/VuiActionModal";


const TabUser: React.FC<any> = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const isMounted = useIsMounted();
    const tableDeleteId = useState(0);
    const showActionModal = useState(false);
    const { brand } = useSelector((state: RootState) => state.brand);

    const tableOnClickDelete = (id: number) => {
        tableDeleteId.set(id);
        showActionModal.set(true);
    };

    const tableHandleOnContinue = (e: React.MouseEvent) => {
        UserRepository.delete(tableDeleteId.get())
            .then((response: AxiosResponse) => {
                showToast(response.data.message, response.data.success ? 'success' : 'error');
                showActionModal.set(false);
                getTableData(true);
            })
            .catch((e: AxiosError) => {
                showToast(e.message, 'error');
            });
    };

    const tableConfig = useState({
        search: '',
        page: 1,
        per_page: 10,
        sorted_by: 'asc',
        order_by: '',
        total: 0,
        loading: false
    });

    const tableData = useState([]);

    const tableColumns = [
        {
            dataField: 'name',
            text: t('table.name.label'),
            sort: true
        },
        {
            dataField: 'email',
            text: t('table.email.label'),
            sort: true
        },
        {
            dataField: 'mobile_phone',
            text: t('table.mobilePhone.label'),
            sort: true
        },
        {
            dataField: 'updated_at',
            text:  t('table.lastUpdated.label'),
            sort: true,
            formatter: (cell: any) => {
                return moment(cell).format('D MMMM YYYY h:m A');
            }
        },
        {
            dataField: 'action',
            text: 'Action',
            headerAlign: 'center',
            isDummyField: true,
            align: 'center',
            formatter: (cell: any, row: any, rowIndex: number) => {
                return (
                    <div className={'table-btn-wrapper'}>
                        <Link className={'btn btn-primary btn-small'} to={`${location.pathname}/user/${row.id}`}>
                            View
                        </Link>

                        {
                            (!row.is_special_account) ? (
                                <button type={'button'} className={'btn btn-danger btn-small'} onClick={() => tableOnClickDelete(row.id)}>
                                    Delete
                                </button>
                            ) : null
                        }
                    </div>
                );
            },
            formatExtraData: {
                location: location.pathname,
                actionDelete: tableOnClickDelete,
                permission: 'any',
            }
        }
    ];

    useEffect(() => {
        getTableData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleTableSearch = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        tableConfig.search.set(e.target.value);
        tableConfig.page.set(1);

        getTableData();
    }, 300);

    const onTableChange: TableChangeHandler<any> = (type, { page, sizePerPage, sortField, sortOrder }) => {
        tableConfig.page.set(page);
        tableConfig.per_page.set(sizePerPage);
        tableConfig.order_by.set(sortField);
        tableConfig.sorted_by.set(sortOrder);

        getTableData();
    };

    const changeTableLoading = (val: boolean) => {
        if (isMounted.current) {
            tableConfig.loading.set(val);
        }
    };

    const getTableData = (reset: boolean = false) => {
        if (reset) tableConfig.page.set(1);

        changeTableLoading(true);

        const conf = _.omit($clone(tableConfig.value), 'loading');

        let params: any = {};
        Object.keys(conf).forEach(prop => {
            if (conf[prop]) {
                params[prop] = conf[prop];
            }
        });

        Object.assign(params, {
            brand: brand.id,
        })

        tableData.set([]);

        UserRepository.all(params)
            .then((response: AxiosResponse) => {
                tableData.set(response.data.data);
                tableConfig.total.set(response.data.meta.total);

                changeTableLoading(false);
            })
            .catch((e: AxiosError) => {
                if (e.isAxiosError) showToast(e.message, 'error');

                changeTableLoading(false);
            });
    };

    return (
        <>
            <div className={'card-paper'}>
                <div className={'card-header'}>
                    <h6 className={'card-header-title'}>{t('settings.vendor.tabUser.title')}</h6>

                    <div className={'btn-wrapper'}>
                        <Link to={`/settings/brand/user/create`}>
                            <button type={'button'} className={'btn btn-primary btn-small'}>
                                <FontAwesomeIcon className={'icon icon-prefix'} icon={faPlus} />
                                {t('settings.vendor.tabUser.button.create')}
                            </button>
                        </Link>
                    </div>
                </div>

                <div className={'card-content'}>
                    <div className={'default-filter-section'}>
                        <div className={'filter-item filter-special type-search'}>
                            <div className='input-group prefix-group'>
                              <span className='input-group-text'>
                                <FontAwesomeIcon className={'icon icon-prefix'} icon={faSearch} />
                              </span>
                                <input
                                    defaultValue={tableConfig.search.get()}
                                    type='text'
                                    className='form-control'
                                    placeholder='Search'
                                    onChange={handleTableSearch}
                                />
                            </div>
                        </div>
                    </div>

                    <VuiDataTable
                        loading={tableConfig.loading.get()}
                        columns={tableColumns}
                        data={tableData.get()}
                        page={tableConfig.page.get()}
                        sizePerPage={tableConfig.per_page.get()}
                        totalSize={tableConfig.total.get()}
                        onTableChange={onTableChange}
                    />
                </div>
            </div>

            <VuiActionModal show={showActionModal.get()} onHide={() => showActionModal.set(false)} onContinue={tableHandleOnContinue} />
        </>
    )
}

export default TabUser
