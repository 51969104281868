export interface VendorState {
  vendor: any;
}

export const UPDATE_VENDOR = 'UPDATE_VENDOR';

interface UpdateVendorAction {
  type: typeof UPDATE_VENDOR;
  payload: VendorState;
}

export type VendorActionTypes = UpdateVendorAction;
