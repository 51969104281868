import OutsideClickHandler from 'react-outside-click-handler';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import React, { MouseEvent } from 'react';
import { useState } from '@hookstate/core/dist';
import { useTranslation } from 'react-i18next';
import { passportService } from '../../../../services';
import { useDispatch, useSelector } from 'react-redux';
import { updateSystem } from '../../../../../stores/system/actions';
import { RootState } from '../../../../../stores';
import { getAliasesName } from '../../../../utilities/helpers/global';
import {updateBrand} from "../../../../../stores/brand/actions";
import {updateVendor} from "../../../../../stores/vendor/actions";

interface VuiHeaderProfileProps {}

const VuiHeaderProfile: React.FC<VuiHeaderProfileProps> = () => {
  const { t } = useTranslation();
  const show = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => {
    return state.system.user;
  });

  const logout = async (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    await passportService
      .logout()
      .then(() => {})
      .catch(() => {});

    dispatch(
        updateSystem({
          loggedIn: false,
          user: null
        })
    );

    dispatch(
        updateVendor({
          vendor: {}
        })
    );

    dispatch(
        updateBrand({
         brand: {}
        })
    );
  };

  return (
    <div className={'dropdown-wrapper'}>
      <OutsideClickHandler
        onOutsideClick={() => {
          show.set(false);
        }}
      >
        <div
          className={'profile-wrapper'}
          onClick={() => {
            show.set(!show.get());
          }}
        >
          {getAliasesName(user.name)}
        </div>

        <div className={clsx(['dropdown-content', { show: show.get() }])}>
          <label className={'dropdown-title'}>{user.role_name}</label>
          <ul className={'dropdown-list'}>
            <li className={'dropdown-item'}>
              <Link className={'dropdown-link'} to={'/account/profile'} onClick={() => show.set(false)}>
                <span>{t('Profile')}</span>
              </Link>
            </li>
          </ul>

          <hr />

          <ul className={'dropdown-list'}>
            <li className={'dropdown-item'}>
              <span className={'dropdown-link'} onClick={logout}>
                <span>{t('Logout')}</span>
                <FontAwesomeIcon className={'dropdown-icon'} icon={faSignOutAlt} />
              </span>
            </li>
          </ul>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default VuiHeaderProfile;
