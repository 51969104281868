import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import VuiBreadcrumb from "../../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import { useState } from "@hookstate/core/dist";
import moment from "moment";
import VuiDateRangePicker from "../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faFileExcel,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { TableChangeHandler } from "react-bootstrap-table-next";
import { Link, Navigate, useLocation } from "react-router-dom";
import MultipleCheckboxes from "../../../../components/MultipleCheckboxes";
import { orderStatusConfig } from "../../../../constants";
import { $clone } from "../../../../vodea/utilities";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import _ from "lodash";
import useIsMounted from "../../../../vodea/utilities/hooks/useIsMounted";
import {
  getChannelConfig,
  getOrderStatusConfig,
  showToast,
} from "../../../../vodea/utilities/helpers/global";
import BrandOrderRepository from "../../../../repositories/Brand/OrderRepository";
import BrandVendorRepository from "../../../../repositories/Brand/VendorRepository";
import VendorRepository from "../../../../repositories/Brand/VendorRepository";
import BrandChannelRepository from "../../../../repositories/Brand/ChannelRepository";
import axios, { AxiosError, AxiosResponse } from "axios";
import VuiDataTable from "../../../../vodea/@vodea-ui/components/VuiDataTable";
import fileDownload from "js-file-download";
import ExportBrandRepository from "../../../../repositories/Export/Brand/ExportBrandRepositories";
import { Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import useRoleService from "../../../../vodea/utilities/hooks/useRoleService";
import OrderRepository from "../../../../repositories/OrderRepository";
import Modal from "react-bootstrap/Modal";
import VuiSelect from "../../../../vodea/@vodea-ui/components/Forms/VuiSelect";
import { VuiButton } from "../../../../vodea/@vodea-ui/components/VuiButton";
import Pusher from "pusher-js";
import { passportService } from "../../../../vodea/services";
import NumberFormat from "react-number-format";
import VuiLoader from "../../../../vodea/@vodea-ui/components/VuiLoader";
import VuiNumberFormat from "../../../../vodea/@vodea-ui/components/VuiNumberFormat";
import CategoryRepository from "../../../../repositories/Brand/CategoryRepository";
import SalesRepository from "../../../../repositories/Widget/SalesRepository";
import DateTime from "../../../../components/DateTime";
import ModalReplaceProduct from "../../../../components/ModalReplaceProduct";

const BrandOrder: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { brand } = useSelector((state: RootState) => state.brand);

  const statusSelected = useState<string>("");

  const selectedChannels = useState<any[]>([]);
  const channelOptions = useState<any[]>([]);
  const selectOption = useState(null);
  const [openChangeVendorModal, setOpenChangeVendorModal] =
    React.useState(false);
  const tableOrderID = useState("");
  const tableVendorId = useState("");
  const changeVendorOptions = useState<any[]>([]);
  const informationLoading = useState(false);
  const [productReturnId, setProductReturnId] = React.useState(null);

  const selectedVendors = useState<any[]>([]);
  const vendorOptions = useState<any[]>([]);
  const orderTabs = useState<boolean>(true);
  const barangTabs = useState<boolean>(false);
  const { isAdministrator } = useRoleService();
  const [showModalReplace, setShowModalReplace] =
    React.useState<boolean>(false);
  const [data, setData] = React.useState<any>({});

  const handleChangeDate = (start: any, end: any) => {
    tableConfig.date_from.set(start.format("YYYY-MM-DD"));
    tableConfig.date_to.set(end.format("YYYY-MM-DD"));

    getTableData();
  };

  const tableConfig = useState({
    search: "",
    page: 1,
    per_page: 10,
    sorted_by: "asc",
    order_by: "number",
    total: 0,
    loading: false,

    // additional
    channel: [],
    date_from: moment().format("YYYY-MM-DD"),
    date_to: moment().format("YYYY-MM-DD"),
  });

  const tableData = useState<any[]>([]);
  const categoryOptions = useState<any[]>([]);
  const filterConfigLoading = useState(false);
  const selectedCategory = useState<any[]>([]);
  const totalOrder = useState(0);

  const [echo, setEcho] = React.useState<any>();

  useEffect(() => {
    // @ts-ignore
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || "", {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      authorizer: (channel: any, options: any) => {
        return {
          authorize: (socketId: any, callback: any) => {
            axios
              .post(
                `${process.env.REACT_APP_PASSPORT_END_POINT}/api/broadcasting/auth`,
                {
                  socket_id: socketId,
                  channel_name: channel.name,
                },
                {
                  headers: {
                    Authorization: `Bearer ${passportService.getAccessToken()}`,
                  },
                }
              )
              .then((response: any) => {
                callback(false, response.data);
              })
              .catch((error: any) => {
                callback(true, error);
              });
          },
        };
      },
    });

    const channel = pusher.subscribe(`Order.Status`);

    setEcho(channel);

    return () => {
      channel.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (echo) {
      echo.bind("App\\Events\\OrderStatusChanged", (channelData: any) => {
        getTableData();
      });
    }
  }, [echo]);

  const handleClickReplaceProduct = (
    rowId: string | number,
    isFromOrder: boolean = true
  ) => {
    let params: any = {};
    Object.assign(params, {
      with: ["invoice", "orderProducts.invoiceProduct.product", "vendor"],
    });

    if (isFromOrder) {
      BrandOrderRepository.show(brand?.id, rowId, params)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            setData(response.data.data);
            setShowModalReplace(true);
          }
        })
        .catch((error: AxiosError) => {
          showToast(error.message, "error");
        });
    } else {
      BrandOrderRepository.show(brand?.id, rowId, params)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            setData(response.data.data);
            setShowModalReplace(true);
          }
        })
        .catch((error: AxiosError) => {
          showToast(error.message, "error");
        });
    }
  };

  const tableColumns = [
    {
      dataField: "number",
      text: t("table.orderNumber.label"),
      formatter: (cell: any, row: any) => (
        <Link to={`/order/${row.id}`}>
          <div className="">{cell}</div>
        </Link>
      ),
      sort: true,
    },

    {
      dataField: "created_at",
      text: t("table.createdAt.label"),
      sort: true,
      formatter: (cell: any) => {
        return moment(cell).format("D MMMM YYYY h:mm A");
      },
    },
    {
      dataField: "grand_total",
      text: t("table.total.label"),
      sort: true,
      formatter: (cell: any) => {
        return (
          <NumberFormat
            defaultValue={cell}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            decimalScale={0}
            prefix={"IDR "}
          />
        );
      },
    },
    {
      dataField: "status",
      text: t("table.status.label"),
      formatter: (cell: any, row: any) => (
        <span className={clsx(["badge", getOrderStatusConfig(cell)?.badge])}>
          {row.status}
        </span>
      ),
      sort: true,
    },
    {
      dataField: "updated_at",
      text: t("table.lastUpdated.label"),
      sort: true,
      formatter: (cell: any) => {
        return moment(cell).format("D MMMM YYYY h:mm A");
      },
    },
    {
      dataField: "external_number",
      text: t("table.invoiceNumber.label"),
      formatter: (cell: any, row: any) => (
        <Link to={`/invoice/${row.invoice_id}`}>
          <div className="">{cell}</div>
        </Link>
      ),
      sort: true,
    },
    {
      dataField: "vendor_name",
      text: t("table.vendor.label"),
      sort: true,
    },
    {
      dataField: "channel_name",
      text: t("table.channel.label"),
      formatter: (cell: any, row: any) => (
        <img
          className={"channel-icon"}
          src={getChannelConfig(cell)?.simpleLogo}
        />
      ),
      sort: true,
    },
    {
      dataField: "invoice_preorder_deadline",
      text: t("table.preorderDeadline.label"),
      formatter: (cell: any, row: any) => {
        return (
          <div className="display-flex align-items-center">
            {row.invoice_preorder_process_day ? (
              <>
                <DateTime data={cell} />
                <div className="status-wrapper for-status-po-detail type-grey ms-lg-3">
                  {`PO: ${row.invoice_preorder_process_day} Hari`}
                </div>
              </>
            ) : (
              "-"
            )}
          </div>
        );
      },
      sort: true,
    },
    {
      dataField: "",
      text: "",
      formatter: (cell: any, row: any) => {
        return (
          <div className={"table-btn-wrapper"}>
            {row.can_change_vendor ? (
              <button
                className={"btn btn-primary btn-small"}
                disabled={!row.can_change_vendor}
                onClick={() => {
                  // getSelectVendor($clone(row.id));
                  tableOrderID.set(row.id);
                  setOpenChangeVendorModal(true);
                }}
              >
                Change Vendor
              </button>
            ) : null}

            {row.can_replace_product && (
              <button
                className={clsx({
                  "btn btn-primary btn-small": true,
                  "ml-4": row?.can_change_vendor,
                })}
                onClick={() => handleClickReplaceProduct(row.id, true)}
              >
                Retur
              </button>
            )}
          </div>
        );
      },
    },
  ];

  const tableProductColumns = [
    {
      dataField: "number",
      text: t("table.orderNumber.label"),
      formatter: (cell: any, row: any) => (
        <Link to={`/order/${row.order_id}`}>
          <div>{cell}</div>
        </Link>
      ),
      sort: true,
    },
    {
      dataField: "product_name",
      text: t("table.productName.label"),
      sort: true,
    },
    {
      dataField: "total_price",
      text: t("table.total.label"),
      sort: true,
      formatter: (cell: any) => {
        return (
          <NumberFormat
            defaultValue={cell}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            decimalScale={0}
            prefix={"IDR "}
          />
        );
      },
    },
    {
      dataField: "category_name",
      text: t("table.category.label"),
      sort: true,
    },
    {
      dataField: "created_at",
      text: t("table.createdAt.label"),
      sort: true,
      formatter: (cell: any) => {
        return moment(cell).format("D MMMM YYYY h:mm A");
      },
    },
    {
      dataField: "order_status",
      text: t("table.status.label"),
      formatter: (cell: any, row: any) => (
        <span className={clsx(["badge", getOrderStatusConfig(cell)?.badge])}>
          {row.order_status}
        </span>
      ),
      sort: true,
    },
    {
      dataField: "updated_at",
      text: t("table.lastUpdated.label"),
      sort: true,
      formatter: (cell: any) => {
        return moment(cell).format("D MMMM YYYY h:mm A");
      },
    },
    {
      dataField: "external_number",
      text: t("table.invoiceNumber.label"),
      formatter: (cell: any, row: any) => (
        <Link to={`/invoice/${row.invoice_id}`}>
          <div className="">{cell}</div>
        </Link>
      ),
      sort: true,
    },
    {
      dataField: "vendor_name",
      text: t("table.vendor.label"),
      sort: true,
    },
    {
      dataField: "channel_name",
      text: t("table.channel.label"),
      formatter: (cell: any, row: any) => (
        <img
          className={"channel-icon"}
          src={getChannelConfig(cell)?.simpleLogo}
        />
      ),
      sort: true,
    },
    {
      dataField: "",
      text: "",
      formatter: (cell: any, row: any) => (
        <div className={"table-btn-wrapper"}>
          {row.can_change_vendor ? (
            <button
              className={"btn btn-primary btn-small"}
              disabled={!row.can_change_vendor}
              onClick={() => {
                // getSelectVendor($clone(row.id));
                tableOrderID.set(row.id);
                setOpenChangeVendorModal(true);
              }}
            >
              Change Vendor
            </button>
          ) : null}

          {row?.can_replace_product && (
            <button
              className={clsx({
                "btn btn-primary btn-small": true,
                "ml-4": row?.can_change_vendor,
              })}
              onClick={() => {
                setProductReturnId(row.product_id);
                handleClickReplaceProduct(row.order_id, false);
              }}
            >
              Retur
            </button>
          )}
        </div>
      ),
    },
  ];

  //todo: retur di barang masih belum selesai, minta yudi tambahin produk id di api order-product

  const loadingSelectVendor = useState(false);
  const getTotalOrder = (params: any) => {
    SalesRepository.getOrderCard(brand?.id, {
      ...params,
      status: params.status && encodeURI(params.status),
    }).then((resp: any) => {
      if (isMounted.current) {
        totalOrder.set(resp.data.data.current_period_value);
      }
    });
  };

  const getSelectVendor = async (id: any) => {
    loadingSelectVendor.set(true);
    await VendorRepository.all(brand.id, {
      for: "order-change-vendor",
      order: id,
    }).then((resp: any) => {
      loadingSelectVendor.set(false);
      changeVendorOptions.set(resp.data.data);
    });
  };

  useEffect(() => {
    (async () => {
      await Promise.all([
        getVendorOptions(),
        getChannelOptions(),
        getCategoryOptions(),
      ]).then(() => {
        getTableData();
      });
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getCategoryOptions = async () => {
    await CategoryRepository.all(brand.id, {
      with: "children",
      only: "parent",
      per_page: 1000,
    }).then((response: any) => {
      if (isMounted.current) {
        const fullData = response.data.data;
        // setCategoryOption(fullData);
        categoryOptions.set(fullData);
        const data: any = [];
        fullData.map((option: any) => {
          data.push(option.id);
          option.children.map((child: any) => {
            data.push(child.id);
          });
        });
        selectedCategory.set(data);
      }
    });
  };

  const getVendorOptions = async () => {
    await BrandVendorRepository.all(brand.id, {
      per_page: 1000,
    }).then((response: AxiosResponse) => {
      if (isMounted.current) {
        const options = response.data.data;
        vendorOptions.set(options);

        selectedVendors.set(
          options.map((item: any) => {
            return item.id;
          })
        );
      }
    });
  };

  const getChannelOptions = async () => {
    await BrandChannelRepository.all(brand.id, {
      per_page: 1000,
    }).then((response: AxiosResponse) => {
      if (isMounted.current) {
        const options = response.data.data;
        channelOptions.set(options);

        selectedChannels.set(
          options.map((item: any) => {
            return item.id;
          })
        );
      }
    });
  };

  const handleTableSearch = _.debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isMounted.current) {
        tableConfig.search.set(e.target.value);
        tableConfig.page.set(1);
      }

      getTableData();
    },
    300
  );

  const handleStatusTabChange = (val: string) => {
    statusSelected.set(val);

    getTableData();
  };

  const onTableChange: TableChangeHandler<any> = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    if (isMounted.current) {
      tableConfig.page.set(page);
      tableConfig.per_page.set(sizePerPage);
      tableConfig.order_by.set(sortField);
      tableConfig.sorted_by.set(sortOrder);
    }

    getTableData();
  };

  const changeTableLoading = (val: boolean) => {
    if (isMounted.current) {
      tableConfig.loading.set(val);
    }
  };

  const handleExport = () => {
    let params: any = {};
    Object.assign(params, {
      vendor: $clone(selectedVendors.get()),
      channel: $clone(selectedChannels.get()),
      "date-from": $clone(_.get(tableConfig.get(), "date_from")),
      "date-to": $clone(_.get(tableConfig.get(), "date_to")),
    });
    ExportBrandRepository.exportOrder(brand.id, params).then(
      (response: any) => {
        const fileName = `${moment().format("YYYY-MM-DD HH:mm:ss")} order.xlsx`;

        fileDownload(response.data, fileName);
      }
    );
  };

  const pathname = useLocation();
  const [notificationState, setNotificationState] = React.useState<any>(
    pathname?.state
  );

  const getTableData = (reset: boolean = false) => {
    if (reset) tableConfig.page.set(1);

    changeTableLoading(true);

    const conf = _.omit($clone(tableConfig.value), "loading");

    let params: any = {};
    Object.keys(conf).forEach((prop) => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    Object.assign(params, {
      "date-from": params.date_from,
      "date-to": params.date_to,
      vendor: $clone(selectedVendors.get()),
      channel: $clone(selectedChannels.get()),
    });

    if (
      statusSelected.get() === "" ||
      statusSelected.get() === "Dibatalkan" ||
      statusSelected.get() === "Dialihkan"
    ) {
      Object.assign(params, {
        "with-canceled": 1,
        "with-canceled-invoice": 1,
      });
    }

    if (statusSelected.get()) {
      Object.assign(params, {
        status: statusSelected.get(),
      });
    }

    if (barangTabs.get() && selectedCategory.get()) {
      Object.assign(params, {
        category: $clone(selectedCategory.get()),
      });
    }

    if (notificationState) {
      Object.assign(params, {
        notif: notificationState.notificationId,
      });
    }

    delete params.date_from;
    delete params.date_to;

    getTotalOrder(params);

    if (isMounted.current) tableData.set([]);

    if (orderTabs.get()) {
      BrandOrderRepository.all(brand.id, params)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            remapTableData(response.data.data).then(() => {
              tableConfig.total.set(response.data.meta.total);
              changeTableLoading(false);
            });
          }
        })
        .catch((e: AxiosError) => {
          if (isMounted.current) {
            if (e.isAxiosError) showToast(e?.response?.data.message, "error");

            changeTableLoading(false);
          }
        });
    } else {
      OrderRepository.all(brand?.id, {
        ...tableConfig.get(),
        ...params,
      })
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            remapTableData(response.data.data).then(() => {
              tableConfig.total.set(response.data.meta.total);
              changeTableLoading(false);
            });
          }
        })
        .catch((e: AxiosError) => {
          if (isMounted.current) {
            if (e.isAxiosError) showToast(e?.response?.data.message, "error");

            changeTableLoading(false);
          }
        });
    }
  };

  const remapTableData = async (data: any[]) => {
    const returnData = data.map((item) => {
      if (orderTabs.get()) {
        return {
          id: item.id,
          number: _.get(item, "number", ""),
          created_at: _.get(item, "created_at", ""),
          status: _.get(item, "status", ""),
          updated_at: _.get(item, "updated_at", ""),
          external_number: _.get(item, "invoice_external_number", ""),
          invoice_id: _.get(item, "invoice_id", ""),
          vendor_name: _.get(item, "vendor_name", ""),
          channel_name: _.get(item, "channel_name", ""),
          grand_total: _.get(item, "grand_total", 0),
          can_change_vendor: _.get(item, "can_change_vendor", false),
          can_replace_product: _.get(item, "can_replace_product", false),
          invoice_preorder_deadline: _.get(
            item,
            "invoice_preorder_deadline",
            null
          ),
          invoice_preorder_process_day: _.get(
            item,
            "invoice_preorder_process_day",
            null
          ),
          invoice_preorder_process_start: _.get(
            item,
            "invoice_preorder_process_start",
            null
          ),
        };
      } else {
        return {
          id: item.id,
          order_id: item.order_id,
          number: _.get(item, "order_number", ""),
          product_id: _.get(item, "product_id", ""),
          created_at: _.get(item, "created_at", ""),
          order_status: _.get(item, "order_status", ""),
          updated_at: _.get(item, "updated_at", ""),
          external_number: _.get(item, "invoice_external_number", ""),
          invoice_id: _.get(item, "invoice_id", ""),
          product_name: _.get(item, "product_name", ""),
          vendor_name: _.get(item, "vendor_name", ""),
          channel_name: _.get(item, "channel_name", ""),
          total_price: _.get(item, "total_price", 0),
          category_name: _.get(item, "category_name", ""),
          can_change_vendor: _.get(item, "can_change_vendor", false),
          can_replace_product: _.get(item, "can_replace_product", false),
        };
      }
    });

    if (isMounted.current) tableData.set(returnData);
  };

  const handleSelect = (eventKey: any) => {
    if (isMounted.current) {
      orderTabs.set(false);
      barangTabs.set(false);
      if (eventKey === "order") {
        orderTabs.set(true);
      } else {
        barangTabs.set(true);
      }
    }
    getTableData();
  };

  const breadcrumbList = [
    {
      label: t("order.title"),
      link: "/order",
    },
  ];

  if (!_.get(brand, "id", null)) {
    return <Navigate to={"/"} replace />;
  }

  const handleChangeVendor = () => {
    informationLoading.set(true);
    OrderRepository.changeVendor(brand?.id, tableOrderID.get(), {
      vendor_id: tableVendorId.get(),
    }).then((resp: any) => {
      if (isMounted.current) {
        informationLoading.set(false);
        setOpenChangeVendorModal(false);
      }
      showToast("Success change vendor", "success");
    });
  };

  return (
    <>
      <Helmet>
        <title>{t("order.title")}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <ModalReplaceProduct
        data={$clone(data)}
        open={showModalReplace}
        onClose={(withReloadData) => {
          setShowModalReplace(false);
          if (withReloadData) {
            getTableData();
          }
        }}
        productId={productReturnId}
      />

      <Modal
        show={openChangeVendorModal}
        onHide={() => setOpenChangeVendorModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VuiSelect
            selectRepository={VendorRepository}
            selectParams={{
              for: "order-change-vendor",
              order: $clone(tableOrderID.get()),
            }}
            selectParentId={brand.id}
            defaultValue={selectOption.get()}
            onChange={(value) => {
              tableVendorId.set(value.id);
              selectOption.set($clone(value));
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <VuiButton
            label={"Change"}
            loading={informationLoading.get()}
            onClick={handleChangeVendor}
          />
        </Modal.Footer>
      </Modal>

      <div className={"page-header-component"}>
        <h3 className={"title"}>{t("order.title")}</h3>
        <div className="btn-wrapper d-grid">
          <button
            type="button"
            className="btn btn-success"
            onClick={handleExport}
          >
            <FontAwesomeIcon icon={faFileExcel} className="icon icon-prefix" />
            {t("button.export")}
          </button>
        </div>
      </div>

      <div className="card-paper mb-4">
        <div className="card-content">
          <div className="card-filter-section">
            <div className="row">
              <div className={barangTabs.get() ? "col-md-3" : "col-md-4"}>
                <div className="form-group">
                  <label className="form-label">
                    {t("form.dateRange.label")}
                  </label>
                  <VuiDateRangePicker
                    startDate={tableConfig.date_from.get()}
                    endDate={tableConfig.date_to.get()}
                    callback={handleChangeDate}
                    useRanges={true}
                  />
                </div>
              </div>

              <div className={barangTabs.get() ? "col-md-3" : "col-md-4"}>
                <div className="form-group">
                  <label className="form-label">{t("form.vendor.label")}</label>
                  <MultipleCheckboxes
                    name={"vendors"}
                    options={$clone(vendorOptions.get())}
                    values={$clone(selectedVendors.get())}
                    labelOption={"name"}
                    valueOption={"id"}
                    onChange={(values) => {
                      selectedVendors.set(values);
                      getTableData(true);
                    }}
                  />
                </div>
              </div>

              {barangTabs.get() && (
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label">
                      {t("stock.index.option.kategori")}
                    </label>
                    <MultipleCheckboxes
                      name={"kategori"}
                      options={categoryOptions.get()}
                      labelOption="name"
                      valueOption="id"
                      values={$clone(selectedCategory.get())}
                      onChange={(values) => {
                        selectedCategory.set(values);
                        getTableData(true);
                        // fetchProductData(true);
                      }}
                      children={true}
                    />
                  </div>
                </div>
              )}

              <div className={barangTabs.get() ? "col-md-3" : "col-md-4"}>
                <div className="form-group">
                  <label className="form-label">
                    {t("form.channel.label")}
                  </label>
                  <MultipleCheckboxes
                    name={"channels"}
                    options={$clone(channelOptions.get())}
                    values={$clone(selectedChannels.get())}
                    labelOption={"name"}
                    valueOption={"id"}
                    onChange={(values) => {
                      selectedChannels.set(values);
                      getTableData(true);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={"card-chart-wrapper single"}>
        <div className={"card-chart mb-4"}>
          {filterConfigLoading.get() ? (
            <VuiLoader />
          ) : (
            <>
              <label className="card-title">
                Total Penjualan
                <OverlayTrigger
                  key={`tooltip-overlay-gmv`}
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-gmv`}>
                      <i>
                        Hasil dari total penjualan sesuai dengan order yang ada
                      </i>
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon
                    className={"icon"}
                    icon={faExclamationCircle}
                  />
                </OverlayTrigger>
              </label>

              <label className="card-note">
                <span className={"percentage-stock"}>
                  <VuiNumberFormat
                    data={totalOrder.get()}
                    value={totalOrder.get()}
                    prefix={"IDR "}
                  />
                </span>
              </label>
            </>
          )}
        </div>
      </div>

      {isAdministrator() && (
        <Nav variant="tabs" defaultActiveKey="order" onSelect={handleSelect}>
          <Nav.Item>
            <Nav.Link eventKey="order">Order</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="barang">Barang</Nav.Link>
          </Nav.Item>
        </Nav>
      )}

      <div className="card-paper">
        <ul className="status-options">
          <li
            key={"all"}
            className={clsx("status-item", {
              active: statusSelected.get() === "",
            })}
            onClick={() => handleStatusTabChange("")}
          >
            {t("general.all")}
          </li>
          {orderStatusConfig.map((item, key) => {
            return (
              <li
                key={key}
                className={clsx("status-item", {
                  active: $clone(statusSelected.get()) === item.key,
                })}
                onClick={() => handleStatusTabChange(item.key)}
              >
                {item.label}
              </li>
            );
          })}
        </ul>
        <div className="card-content">
          <div className={"default-filter-section"}>
            <div className={"filter-item filter-special type-search"}>
              <div className="input-group prefix-group">
                <span className="input-group-text">
                  <FontAwesomeIcon
                    className={"icon icon-prefix"}
                    icon={faSearch}
                  />
                </span>
                <input
                  defaultValue={""}
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={handleTableSearch}
                />
              </div>
            </div>
          </div>

          <VuiDataTable
            customClasses={"table-invoice"}
            loading={tableConfig.loading.get()}
            columns={orderTabs.get() ? tableColumns : tableProductColumns}
            data={tableData.get()}
            page={tableConfig.page.get()}
            sizePerPage={tableConfig.per_page.get()}
            totalSize={tableConfig.total.get()}
            onTableChange={onTableChange}
          />
        </div>
      </div>
    </>
  );
};

export default BrandOrder;
