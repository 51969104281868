import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useIsMounted from "../../../../../vodea/utilities/hooks/useIsMounted";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../stores";
import { Helmet } from "react-helmet-async";
import VuiBreadcrumb from "../../../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import { Link, useLocation, useParams } from "react-router-dom";
import { useState } from "@hookstate/core";
import BrandOrderRepository from "../../../../../repositories/Brand/OrderRepository";
import { AxiosError, AxiosResponse } from "axios";
import {
  getOrderStatusConfig,
  showToast,
} from "../../../../../vodea/utilities/helpers/global";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import moment from "moment";
import NumberFormat from "react-number-format";
import PrintOrderModal from "../../../../../components/Modal/PrintOrderModal";
import PrintInvoiceModal from "../../../../../components/Modal/PrintInvoiceModal";
import logo from "./logo/logo-fix-removebg-preview.png";
import { printDiv } from "../../../../../vodea/utilities/printScreen";
import VuiUploadImage from "../../../../../vodea/@vodea-ui/components/Forms/VuiUploadImage";
import useRoleService from "../../../../../vodea/utilities/hooks/useRoleService";
import DateTime from "../../../../../components/DateTime";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const BrandOrderShow: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const isMounted = useIsMounted();
  const { brand } = useSelector((state: RootState) => state.brand);

  const [orderData, setOrderData] = React.useState<any>([]);
  const [invoiceProducts, setInvoiceProducts] = React.useState([]);
  const { isAdministrator } = useRoleService();

  const [showModal, setShowModal] = React.useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = React.useState(false);

  const data = useState<any>(null);
  const products = useState<any[]>([]);

  const handlePrint = () => {
    printDiv("printable");
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const pathname = useLocation();
  const [notificationState, setNotificationState] = React.useState<any>(
    pathname?.state
  );

  const getData = () => {
    let params: any = {};
    Object.assign(params, {
      with: [
        "invoice",
        "orderProducts.invoiceProduct.product",
        "vendor",
        "brand",
        "orderDeliveryPhotos",
        "deliveryPhotos",
        "deliveredPhotos",
        "orderProducts.replacedOrderProducts",
      ],
    });

    if (notificationState) {
      Object.assign(params, {
        notif: notificationState.notificationId,
      });
    }

    BrandOrderRepository.show(brand.id, id, params)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          data.set(response.data.data);
          setOrderData({
            ...response.data.data,
            brand,
          });
          setInvoiceProducts(response.data.data.invoice.raw_data.products);

          if (response.data.data.order_products) {
            products.set(response.data.data.order_products);
          }
        }
      })
      .catch((e: AxiosError) => {
        showToast(e?.response?.data.message, "error");
      });
  };

  const handlePrintOrder = () => {
    printDiv("detailOrder");
  };

  const breadcrumbList = [
    {
      label: t("order.title"),
      link: "/order",
    },
    {
      label: _.get(data.get(), "number", ""),
      link: `/order/${id}`,
    },
  ];

  return (
    <>
      <Helmet>
        <title>{_.get(data.get(), "number", "")}</title>
      </Helmet>
      <PrintOrderModal
        show={showModal}
        setShow={setShowModal}
        data={orderData}
        logo={logo}
        handlePrint={handlePrint}
      />

      <PrintInvoiceModal
        show={showInvoiceModal}
        setShow={setShowInvoiceModal}
        data={orderData}
        logo={logo}
        handlePrint={handlePrint}
        products={invoiceProducts}
      />

      <div id="non-printable">
        <VuiBreadcrumb list={breadcrumbList} />

        <div className={"page-header-component"}>
          <h3 className={"title"}>{_.get(data.get(), "number", "")}</h3>
          <div className="d-flex justify-content-center align-items-center">
            {(orderData && orderData.status == "Siap Dikirim") ||
            "Dalam Pengiriman" ||
            "Sampai" ||
            "Selesai" ? (
              <div className="btn-wrapper">
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={() => handlePrintOrder()}
                >
                  <FontAwesomeIcon
                    icon={faPrint}
                    className="icon icon-prefix"
                  />
                  {/* {t("button.print.label")} */}
                  Cetak Penjualan
                </button>
              </div>
            ) : null}

            <div className="btn-wrapper ms-3">
              <button
                type="button"
                className="btn btn-warning"
                onClick={() => setShowInvoiceModal(true)}
              >
                <FontAwesomeIcon icon={faPrint} className="icon icon-prefix" />
                {/* {t("button.print.label")} */}
                Cetak Invoice
              </button>
            </div>
            <div className="btn-wrapper ms-3">
              <button
                type="button"
                className="btn btn-warning"
                onClick={() => setShowModal(true)}
              >
                <FontAwesomeIcon icon={faPrint} className="icon icon-prefix" />
                {t("button.print.label")}
              </button>
            </div>
          </div>
        </div>

        <div className="default-page-layout type-profile">
          <div className="information-section" id="detailOrder">
            <div className="card-paper mb-4">
              <div className="card-header">
                <h5 className="card-header-title">{t("order.orderDetail")}</h5>
                {_.get(data.get(), "invoice.preorder_process_day", null) && (
                  <div className="display-flex align-items-center">
                    <DateTime
                      data={_.get(
                        data.get(),
                        "invoice.preorder_deadline",
                        null
                      )}
                    />
                    <div className="status-wrapper for-status-po-detail type-grey ms-lg-3">
                      {`PO: ${_.get(
                        data.get(),
                        "invoice.preorder_process_day",
                        null
                      )} Hari`}
                    </div>
                  </div>
                )}
              </div>

              <div className="card-content">
                <div className="mb-3">
                  <div className="text-muted">
                    {t("form.orderNumber.label")}
                  </div>
                  <div className="">{_.get(data.get(), "number", "")}</div>
                </div>

                <div className="mb-3">
                  <div className="text-muted">
                    {t("form.invoiceNumber.label")}
                  </div>
                  <div>
                    <Link
                      to={`/invoice/${_.get(data.get(), "invoice_id", "")}`}
                    >
                      {_.get(data.get(), "invoice.external_number", "")}
                    </Link>
                  </div>
                </div>

                <div className="mb-3">
                  <div className="text-muted">{t("form.vendor.label")}</div>
                  <div>{_.get(data.get(), "vendor.name", "")}</div>
                </div>

                {_.get(data.get(), "invoice.vouchers", null) &&
                  _.get(data.get(), "invoice.vouchers", []).length > 0 && (
                    <div className="mb-3">
                      <div className="text-muted">
                        {t("form.vouchers.label")}
                      </div>
                      <div className="voucher-text">
                        {_.get(data.get(), "invoice.vouchers[0].name", "")}
                      </div>
                    </div>
                  )}

                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <div className="text-muted">{t("form.status.label")}</div>
                      <div>
                        <div
                          className={clsx([
                            "badge",
                            getOrderStatusConfig(
                              _.get(data.get(), "status", "")
                            )?.badge,
                          ])}
                        >
                          {_.get(data.get(), "status", "")}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-3">
                      <div className="text-muted">
                        {t("form.transactionDate.label")}
                      </div>
                      <div>
                        {moment(_.get(data.get(), "created_at", "")).format(
                          "D MMMM YYYY h:mm A"
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {isAdministrator() &&
                _.get(data.get(), "shipping_number", null) ? (
                  <>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <div className="text-muted">
                            {t("form.shippingNumber.label")}
                          </div>
                          <div className="">
                            {_.get(data.get(), "shipping_number", "")}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <div className="text-muted">
                            {t("form.receiverName.label")}
                          </div>
                          <div className="">
                            {_.get(data.get(), "receiver_name")}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="text-muted">
                        {t("form.shippingPhoto.label")}
                      </div>
                      <div className="">
                        <VuiUploadImage
                          className={"type-left"}
                          defaultValue={_.get(
                            data.get(),
                            "delivery_photos[0]",
                            null
                          )}
                          onChange={() => {}}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </>
                ) : null}

                <div className="table-responsive">
                  <table className="table table-bordered vendor-table">
                    <thead>
                      <tr>
                        <th>{t("table.product.label")}</th>
                        <th>{t("table.total.label")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {products
                        .get()
                        .map((product: any, productKey: number) => {
                          return (
                            <tr key={productKey}>
                              <td>
                                <div className="product-info">
                                  <div className="display-flex align-items-center mb-2">
                                    <p className="name">
                                      {_.get(
                                        product,
                                        "invoice_product.product.name",
                                        "-"
                                      )}
                                    </p>
                                    {product.quantity_return > 0 && (
                                      <>
                                        <span className="badge bg-secondary ml-4 mr-2">
                                          Retur
                                        </span>
                                        <OverlayTrigger
                                          key={`tooltip-description-retur`}
                                          placement={"top"}
                                          overlay={
                                            <Tooltip
                                              id={`tooltip-description-retur`}
                                            >
                                              <i>
                                                {product.replaced_order_products
                                                  .map((item: any) => item.note)
                                                  .filter((a: any) => a)
                                                  .map(
                                                    (x: any, index: number) => (
                                                      <div key={index}>
                                                        {`${index + 1}. ${x}`}
                                                      </div>
                                                    )
                                                  )}
                                              </i>
                                            </Tooltip>
                                          }
                                        >
                                          <FontAwesomeIcon
                                            className={"icon"}
                                            icon={faExclamationCircle}
                                          />
                                        </OverlayTrigger>
                                      </>
                                    )}
                                  </div>
                                  <div className="display-flex align-items-center font-size-3">
                                    <div className="mr-4">
                                      <div>{t("table.sku.label")}</div>
                                      <div>{t("table.quantity.label")}</div>
                                      <div>{t("table.price.label")}</div>
                                    </div>
                                    <div>
                                      <div>
                                        {` : ${_.get(
                                          product,
                                          "invoice_product.product.sku",
                                          "-"
                                        )}`}
                                      </div>
                                      <div>{` : ${product.quantity}`}</div>
                                      <div>
                                        {" : "}
                                        <NumberFormat
                                          value={_.get(product, "price", 0)}
                                          displayType={"text"}
                                          thousandSeparator={"."}
                                          decimalSeparator={","}
                                          decimalScale={0}
                                          prefix={"IDR "}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <NumberFormat
                                  value={
                                    product.quantity_return > 0
                                      ? 0
                                      : _.get(product, "total_price", 0)
                                  }
                                  displayType={"text"}
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  decimalScale={0}
                                  prefix={"IDR "}
                                />
                              </td>
                            </tr>
                          );
                        })}

                      <tr
                        style={{
                          verticalAlign: "middle",
                        }}
                      >
                        <td colSpan={1}>
                          <div className="total">{t("table.total.label")}</div>
                        </td>
                        <td>
                          <div className="fs-5">
                            <strong>
                              <NumberFormat
                                value={
                                  _.sumBy(products.get(), "total_price") -
                                  _.get(data.get(), "grand_total_return", 0)
                                }
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                decimalScale={0}
                                prefix={"IDR "}
                              />
                            </strong>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="customer-section">
            <div className="card-paper mb-4">
              <div className="card-header">
                <h5 className="card-header-title">
                  {t("order.shippingDetail")}
                </h5>
              </div>

              <div className="card-content">
                <div className="mb-4">
                  <div className="text-muted">
                    {t("form.recipientName.label")}
                  </div>
                  <div className="">
                    {_.get(data.get(), "invoice.recipient_name", "")}
                  </div>
                </div>
                <div className="mb-4">
                  <div className="text-muted">{t("form.telephone.label")}</div>
                  <div className="">
                    {_.get(data.get(), "invoice.recipient_mobile_phone", "")}
                  </div>
                </div>
                <div className="mb-4">
                  <div className="text-muted">
                    {t("form.recipientAddress.label")}
                  </div>
                  <div>
                    {_.get(data.get(), "invoice.recipient_address_full", "")}
                  </div>
                  <div>
                    {_.get(data.get(), "invoice.recipient_sub_district", "")},{" "}
                    {_.get(data.get(), "invoice.recipient_city", "")},{" "}
                    {_.get(data.get(), "invoice.recipient_province", "")},{" "}
                    {_.get(data.get(), "invoice.recipient_postal_code", "")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandOrderShow;
