import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import useIsMounted from "../../../../vodea/utilities/hooks/useIsMounted";
import { useLocation, useParams } from "react-router-dom";
import UpdateStockHistoryRepository from "../../../../repositories/Brand/UpdateStockHistoryRepository";
import { AxiosError, AxiosResponse } from "axios";
import { showToast } from "../../../../vodea/utilities/helpers/global";
import { axiosErrorLoadDataHandler } from "../../../../vodea/utilities/helpers/axios.helper";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import VuiDataTable from "../../../../vodea/@vodea-ui/components/VuiDataTable";
import { ColumnDescription } from "react-bootstrap-table-next";
import NumberTextField from "../../../../components/NumberTextField";

const UpdateStockHistory: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { id, notificationId } = useParams();
  const { brand } = useSelector((state: RootState) => state.brand);
  const [tableData, setTableData] = useState<any[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);

  const loadData = useCallback(
    async (brandId, id, notificationId) => {
      if (isMounted.current) setTableLoading(true);

      let params: any = {
        with: ["updateStockHistoryItems.product"],
      };

      if (notificationId) {
        Object.assign(params, {
          notif: notificationId,
        });
      }

      await UpdateStockHistoryRepository.show(brandId, id, params)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            const { data: responseData } = response.data;
            setTableData(responseData.update_stock_history_items);
            setTableLoading(false);
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) setTableLoading(false);
          axiosErrorLoadDataHandler(error, showToast);
        });
    },
    [isMounted]
  );

  useMemo(() => {
    (async () => {
      await loadData(brand?.id, id, notificationId);
    })();
  }, [notificationId, brand, id, loadData]);

  const tableColumns: ColumnDescription[] = [
    {
      dataField: "product.name",
      text: t("table.name.label"),
    },
    {
      dataField: "quantity",
      text: t("table.quantity.label"),
      formatter: (cell) => (
        <NumberTextField
          defaultValue={cell}
          isForm={false}
          displayType={"text"}
        />
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("stock.updateStockHistory.metaTitle")}</title>
      </Helmet>

      <div className={"page-header-component"}>
        <h3 className={"title"}>{t("stock.updateStockHistory.title")}</h3>
      </div>

      <div className={"card-paper"}>
        <div className={"card-content"}>
          <VuiDataTable
            loading={tableLoading}
            columns={tableColumns}
            data={tableData}
            withPagination={false}
          />
        </div>
      </div>
    </>
  );
};

export default UpdateStockHistory;
