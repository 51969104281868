import { Helmet } from 'react-helmet-async';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../stores';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import VuiDateRangePicker from "../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker";
import moment from "moment";
import {useState} from "@hookstate/core/dist";
import {VuiButton} from "../../../../vodea/@vodea-ui/components/VuiButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp, faExclamationCircle, faSearch} from "@fortawesome/free-solid-svg-icons";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import clsx from "clsx";
import {Line} from "react-chartjs-2";
import {$clone} from "../../../../vodea/utilities";
import VuiLoader from "../../../../vodea/@vodea-ui/components/VuiLoader";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from "react-bootstrap-table2-filter";
import Select from 'react-select';

const receiverOptions = [
    { value: '1', label: 'Maya' },
    { value: '2', label: 'John Doe' },
    { value: '3', label: 'John Maya' }
];


const dummyChartData = [
    [
        8750000,
        5150000,
        9300000,
        1100000,
        4700000,
        8300000,
        7000000
    ],
  [
    5300000,
    6675000,
    8900000,
    1050000,
    7005000,
    3110000,
    8900000
  ]
]

const colorChart = [
    '#346497',
    '#FDE700',
    '#5EAE3B',
    '#E76118'
]

const fulfillmentTableColumns = [
  {
    dataField: 'vendor',
    text: 'Vendor',
    sort: true
  },
  {
    dataField: 'area',
    text: 'Area',
    sort: true
  },
  {
    dataField: 'confirm',
    text: 'Confirm',
    sort: true
  },
  {
    dataField: 'sla',
    text: 'SLA',
    sort: true
  },
  {
    dataField: 'in_stock_rate',
    text: 'In-Stock Rate',
    sort: true
  },
  {
    dataField: 'installation',
    text: 'Installation',
    sort: true
  },
  {
    dataField: 'return',
    text: 'Return',
    sort: true
  },
  {
    dataField: 'cmm',
    text: 'CMM',
    sort: true
  },
  {
    dataField: 'trade_in',
    text: 'Trade In',
    sort: true
  },
  {
    dataField: 'average_rating',
    text: 'Avg. Rating',
    sort: true
  },
]

const fulfillmentTableData = [
  {
    'id': 1,
    'vendor': 'Toko A',
    'area': 'Jakarta Barat',
    'confirm': '93.5%',
    'sla': '95.22%',
    'in_stock_rate': '97.2%',
    'installation': '95.7%',
    'return': '-',
    'cmm': '99.65%',
    'trade_in': '-',
    'average_rating': '96.5%',
  },
  {
    'id': 2,
    'vendor': 'Toko B',
    'area': 'Jakarta Barat',
    'confirm': '97.5%',
    'sla': '94.22%',
    'in_stock_rate': '95.2%',
    'installation': '91.7%',
    'return': '-',
    'cmm': '96.65%',
    'trade_in': '-',
    'average_rating': '98.5%',
  },
  {
    'id': 3,
    'vendor': 'Toko C',
    'area': 'Jakarta Utara',
    'confirm': '95.5%',
    'sla': '98.22%',
    'in_stock_rate': '94.2%',
    'installation': '90.7%',
    'return': '10%',
    'cmm': '90%%',
    'trade_in': '-',
    'average_rating': '95.5%',
  },
  {
    'id': 4,
    'vendor': 'Toko D',
    'area': 'Jakarta Selatan',
    'confirm': '89.5%',
    'sla': '95%',
    'in_stock_rate': '94%',
    'installation': '-',
    'return': '15%',
    'cmm': '95%%',
    'trade_in': '-',
    'average_rating': '91%',
  },
  {
    'id': 5,
    'vendor': 'Toko E',
    'area': 'Jakarta Timur',
    'confirm': '95.5%',
    'sla': '98.22%',
    'in_stock_rate': '94.2%',
    'installation': '90.7%',
    'return': '10%',
    'cmm': '90%%',
    'trade_in': '-',
    'average_rating': '95.5%',
  }
]

const productTableData = [
  {
    'id': 1,
    'sku': 'A01',
    'page_visitor': 12.497,
    'bounce_rate': '23.15%',
    'return': '12',
    'complete_payment': 'Rp 1.158.518.000',
    'cash': '78.65%',
    'cc': '21.35%'
  },
  {
    'id': 2,
    'sku': 'A02',
    'page_visitor': 29.129,
    'bounce_rate': '12.40%',
    'return': '2',
    'complete_payment': 'Rp 3.918.244.000',
    'cash': '59.2%',
    'cc': '40.8%'
  },
  {
    'id': 3,
    'sku': 'A03',
    'page_visitor': 18.201,
    'bounce_rate': '16.12%',
    'return': '4',
    'complete_payment': 'Rp 958.518.000',
    'cash': '62,12%',
    'cc': '37,88%'
  },
  {
    'id': 4,
    'sku': 'A04',
    'page_visitor': 9.252,
    'bounce_rate': '63,4%',
    'return': '16',
    'complete_payment': 'Rp 858.112.000',
    'cash': '63,4%',
    'cc': '36,6%'
  },
  {
    'id': 5,
    'sku': 'A05',
    'page_visitor': 4.079,
    'bounce_rate': '97,2%',
    'return': '5',
    'complete_payment': 'Rp 1.200.129.299',
    'cash': '42,2%',
    'cc': '57,8%'
  }
]

const productTableColumns = [
  {
    dataField: 'sku',
    text: 'SKU',
    sort: true
  },
  {
    dataField: 'page_visitor',
    text: 'Page Visitor',
    sort: true,
    headerFormatter: (column: any, colIndex: any, { sortElement, filterElement } : any) => {
      return (
          <div className="header-info">
            Page Visitor
            <OverlayTrigger
                key={`tooltip-overlay-page_visitor`}
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-page_visitor`}>
                    <i>Page Visitor</i>
                  </Tooltip>
                }
            >
              <FontAwesomeIcon className={'icon'} icon={faExclamationCircle} />
            </OverlayTrigger>

            { sortElement }
          </div>
      );
    }
  },
  {
    dataField: 'bounce_rate',
    text: 'Bounce Rate',
    sort: true,
    headerFormatter: (column: any, colIndex: any, { sortElement, filterElement } : any) => {
      return (
          <div className="header-info">
            Bounce Rate
            <OverlayTrigger
                key={`tooltip-overlay-page_visitor`}
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-page_visitor`}>
                    <i>Bounce Rate</i>
                  </Tooltip>
                }
            >
              <FontAwesomeIcon className={'icon'} icon={faExclamationCircle} />
            </OverlayTrigger>

            { sortElement }
          </div>
      );
    }
  },
  {
    dataField: 'return',
    text: 'Return',
    sort: true,
    headerFormatter: (column: any, colIndex: any, { sortElement, filterElement } : any) => {
      return (
          <div className="header-info">
            Return
            <OverlayTrigger
                key={`tooltip-overlay-page_visitor`}
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-page_visitor`}>
                    <i>Return</i>
                  </Tooltip>
                }
            >
              <FontAwesomeIcon className={'icon'} icon={faExclamationCircle} />
            </OverlayTrigger>

            { sortElement }
          </div>
      );
    }
  },
  {
    dataField: 'complete_payment',
    text: 'Complete Payment',
    sort: true,
    headerFormatter: (column: any, colIndex: any, { sortElement, filterElement } : any) => {
      return (
          <div className="header-info">
            Complete Payment
            <OverlayTrigger
                key={`tooltip-overlay-page_visitor`}
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-page_visitor`}>
                    <i>Complete Payment</i>
                  </Tooltip>
                }
            >
              <FontAwesomeIcon className={'icon'} icon={faExclamationCircle} />
            </OverlayTrigger>

            { sortElement }
          </div>
      );
    }
  },
  {
    dataField: 'cash',
    text: 'Cash',
    sort: true,
    headerFormatter: (column: any, colIndex: any, { sortElement, filterElement } : any) => {
      return (
          <div className="header-info">
            Cash
            <OverlayTrigger
                key={`tooltip-overlay-page_visitor`}
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-page_visitor`}>
                    <i>Cash</i>
                  </Tooltip>
                }
            >
              <FontAwesomeIcon className={'icon'} icon={faExclamationCircle} />
            </OverlayTrigger>

            { sortElement }
          </div>
      );
    }
  },
  {
    dataField: 'cc',
    text: 'CC',
    sort: true,
    headerFormatter: (column: any, colIndex: any, { sortElement, filterElement } : any) => {
      return (
          <div className="header-info">
            CC
            <OverlayTrigger
                key={`tooltip-overlay-page_visitor`}
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-page_visitor`}>
                    <i>CC</i>
                  </Tooltip>
                }
            >
              <FontAwesomeIcon className={'icon'} icon={faExclamationCircle} />
            </OverlayTrigger>

            { sortElement }
          </div>
      );
    }
  },
]

const DashboardBranch: React.FC<any> = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => {
    return state.system;
  });
  const { t } = useTranslation();
  const chartSelection = useState(0);

  const filterConfig = useState({
    loading: false,

    date_from: moment().subtract(6, 'days').format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD'),

    date_compare_from: moment().subtract(14, 'days').format('YYYY-MM-DD'),
    date_compare_to: moment().subtract(7, 'days').format('YYYY-MM-DD'),
  })

  const handleChangeDate = (start: any, end: any) => {
    filterConfig.date_from.set(start.format('YYYY-MM-DD'));
    filterConfig.date_to.set(end.format('YYYY-MM-DD'));
  };

  const handleChangeCompareDate = (start: any, end: any) => {
    filterConfig.date_compare_from.set(start.format('YYYY-MM-DD'));
    filterConfig.date_compare_to.set(end.format('YYYY-MM-DD'));
  };

  const getData = () => {
    filterConfig.loading.set(true);

    setTimeout(() => {
      filterConfig.loading.set(false);
    }, 1000)
  }

  const changeChart = (key: number) => {
    chartLoading.set(true);

    chartSelection.set(key);
    chartColor.set(colorChart[key]);
    dataChart.set(dummyChartData[0]);
    dataChartCompare.set(dummyChartData[1])

    setTimeout(() => {
      chartLoading.set(false);
    }, 1500)
  }

  const chartLoading = useState(false);
  const chartLabel = useState<any[]>([
    '10 Mei',
    '11 Mei',
    '12 Mei',
    '13 Mei',
    '14 Mei',
    '15 Mei',
    '16 Mei',
  ])
  const chartColor = useState<string>(colorChart[0])
  const dataChart = useState<any[]>(dummyChartData[0])
  const dataChartCompare = useState<any[]>(dummyChartData[1])

  useEffect(() => {

  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Helmet>
        <title>{t('Dashboard')}</title>
      </Helmet>

        <div className={'page-header-component'}>
            <h3 className={'title'}>Dashboard | <strong>Branch</strong></h3>
        </div>

      <div className="card-paper mb-4">
        <div className="card-content">
          <div className="card-filter-section">
            <div className="row">
              <div className="col-md-10">
                <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">Branch</label>
                      <Select
                        isMulti
                        name="colors"
                        options={receiverOptions}
                        className="basic-multi-select"
                    
            />
                      
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">Rentang Tanggal</label>
                      <VuiDateRangePicker
                          startDate={filterConfig.date_from.get()}
                          endDate={filterConfig.date_to.get()}
                          callback={handleChangeDate}
                          useRanges={true}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">Bandingkan Dengan</label>

                      <VuiDateRangePicker
                          startDate={filterConfig.date_compare_from.get()}
                          endDate={filterConfig.date_compare_to.get()}
                          callback={handleChangeCompareDate}
                          useRanges={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card-footer">
          <div className="default-btn-action-wrapper">
            <button type={"button"} className="btn btn-secondary">Reset</button>
            <VuiButton forSubmit={false} label={'Terapkan'} loading={filterConfig.loading.get()} onClick={getData}/>
          </div>
        </div>
      </div>

      <div className="card-paper mb-4">
        <div className="card-content">
          <div className="card-chart-wrapper mb-4">
            <div className={clsx('card-chart', {
              'active': chartSelection.get() === 0
            })} onClick={() => changeChart(0)}>
              <label className="card-title">
                GMV
                <OverlayTrigger
                    key={`tooltip-overlay-gmv`}
                    placement={'top'}
                    overlay={
                      <Tooltip id={`tooltip-gmv`}>
                        <i>Gross Margin Value</i>
                      </Tooltip>
                    }
                >
                  <FontAwesomeIcon className={'icon'} icon={faExclamationCircle} />
                </OverlayTrigger>
              </label>
              <h3 className="card-value">
                Rp 52.53M
              </h3>

              <label className="card-note">
                <span className="percentage positive"><FontAwesomeIcon className={'icon'} icon={faArrowUp} /> 12.11%</span> vs 30 hari terakhir
              </label>
            </div>

            <div className={clsx('card-chart', {
              'active': chartSelection.get() === 1
            })} onClick={() => changeChart(1)}>
              <label className="card-title">
                Unit Terjual
                <OverlayTrigger
                    key={`tooltip-overlay-sold`}
                    placement={'top'}
                    overlay={
                      <Tooltip id={`tooltip-sold`}>
                        <i>Unit Terjual</i>
                      </Tooltip>
                    }
                >
                  <FontAwesomeIcon className={'icon'} icon={faExclamationCircle} />
                </OverlayTrigger>
              </label>
              <h3 className="card-value">
                16K
              </h3>

              <label className="card-note">
                <span className="percentage negative"><FontAwesomeIcon className={'icon'} icon={faArrowDown} /> 3.04%</span> vs 30 hari terakhir
              </label>
            </div>

            <div className={clsx('card-chart', {
              'active': chartSelection.get() === 2
            })} onClick={() => changeChart(2)}>
              <label className="card-title">
                Dibatalkan
                <OverlayTrigger
                    key={`tooltip-overlay-cancel`}
                    placement={'top'}
                    overlay={
                      <Tooltip id={`tooltip-cancel`}>
                        <i>Dibatalkan</i>
                      </Tooltip>
                    }
                >
                  <FontAwesomeIcon className={'icon'} icon={faExclamationCircle} />
                </OverlayTrigger>
              </label>
              <h3 className="card-value">
                1.23K
              </h3>

              <label className="card-note">
                <span className="percentage negative"><FontAwesomeIcon className={'icon'} icon={faArrowDown} /> 8.23%</span> vs 30 hari terakhir
              </label>
            </div>

            <div className={clsx('card-chart', {
              'active': chartSelection.get() === 3
            })} onClick={() => changeChart(3)}>
              <label className="card-title">
                Dikembalikan
                <OverlayTrigger
                    key={`tooltip-overlay-cancel`}
                    placement={'top'}
                    overlay={
                      <Tooltip id={`tooltip-cancel`}>
                        <i>Dikembalikan</i>
                      </Tooltip>
                    }
                >
                  <FontAwesomeIcon className={'icon'} icon={faExclamationCircle} />
                </OverlayTrigger>
              </label>
              <h3 className="card-value">
                261
              </h3>

              <label className="card-note">
                <span className="percentage positive"><FontAwesomeIcon className={'icon'} icon={faArrowUp} /> 2.3%</span> vs 30 hari terakhir
              </label>
            </div>
          </div>

          {chartLoading.get() ? (<VuiLoader/>) : (
              <div className="line-chart-wrapper">
                <Line
                    height={350}
                    data={{
                      labels: chartLabel.value,
                      datasets: [
                        {
                          label: 'Periode saat ini',
                          data: $clone(dataChart.get()),
                          fill: false,
                          backgroundColor: chartColor.get(),
                          borderColor: chartColor.get(),
                        },
                        {
                          label: 'Periode sebelumnya',
                          data: $clone(dataChartCompare.get()),
                          fill: false,
                          backgroundColor: chartColor.get(),
                          borderColor: chartColor.get(),
                          borderDash: [5, 5],
                        }
                      ]
                    }}
                    options={{
                      maintainAspectRatio: false,
                      responsive: true,
                      legend: {
                        display: true
                      }
                    }}
                />
              </div>
          )}
        </div>
      </div>

      <div className="card-paper mb-4">
        <div className="card-header">
          <h6 className="card-header-title">Performa Vendor</h6>
        </div>

        <div className="card-content">
          <div className={'default-filter-section'}>
            <div className={'filter-item filter-special type-search'}>
              <div className='input-group prefix-group'>
                  <span className='input-group-text'>
                    <FontAwesomeIcon className={'icon icon-prefix'} icon={faSearch} />
                  </span>
                <input
                    defaultValue={''}
                    type='text'
                    className='form-control'
                    placeholder='Search'
                />
              </div>
            </div>
          </div>

          <BootstrapTable
              wrapperClasses={'table-responsive'}
              keyField={'id'}
              data={fulfillmentTableData}
              columns={fulfillmentTableColumns}
              filter={filterFactory()}
              pagination={paginationFactory({
                page: 1,
                sizePerPage: 5,
                totalSize: 5,
                showTotal: true
              })}
          />
        </div>
      </div>

      <div className="card-paper">
        <div className="card-header">
          <h6 className="card-header-title">Analitik Performa Produk</h6>
        </div>

        <div className="card-content">
          <div className={'default-filter-section'}>
            <div className={'filter-item filter-special type-search'}>
              <div className='input-group prefix-group'>
                  <span className='input-group-text'>
                    <FontAwesomeIcon className={'icon icon-prefix'} icon={faSearch} />
                  </span>
                <input
                    defaultValue={''}
                    type='text'
                    className='form-control'
                    placeholder='Search'
                />
              </div>
            </div>
          </div>

          <BootstrapTable
              wrapperClasses={'table-responsive'}
              keyField={'id'}
              data={productTableData}
              columns={productTableColumns}
              filter={filterFactory()}
              pagination={paginationFactory({
                page: 1,
                sizePerPage: 5,
                totalSize: 5,
                showTotal: true
              })}
          />
        </div>
      </div>
    </>
  );
};

export default DashboardBranch;
