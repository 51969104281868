export interface CardInterface {
  title: string;
  value: string | number;
  compare?: string | number;
  description: string;
  type?: number | string;
}

export const cardDefaultValues: CardInterface = {
  title: "",
  description: "",
  compare: 0,
  value: 0,
  type: 0,
};
