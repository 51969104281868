import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import icon from "./icon1.svg";
import _ from "lodash";

interface ModalProps {
  show: boolean;
  setShow: any;
  data: any;
  logo: any;
  handlePrint: any;
}

function PrintOrderModal(props: ModalProps) {
  const { t } = useTranslation();

  const productData = props.data.order_products;

  return (
    <Modal
      size="lg"
      show={props.show}
      onHide={() => props.setShow(false)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton id="printInvoice" />
      <Modal.Body id="printable" className="p-0">
        {props.data.invoice && (
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center pe-3 ps-2 pt-2">
              {" "}
              <div>
                {" "}
                <img
                  alt={props.logo}
                  src={props.logo}
                  style={{ height: "100%", width: "15vw" }}
                />
              </div>
              <div className="fw-bold fs-2">Non Tunai</div>
            </div>
            <hr />
            <div className="row p-4">
              <div className="col-6">
                <div>{props.data.invoice.external_number}</div>
                <div className="d-flex justify-content-start align-items-center mt-3">
                  {/* <div>
                    {" "}
                    <img
                      src={props.logo}
                      style={{ height: "100%", width: "15vw" }}
                    ></img>
                  </div> */}
                  <div className="fw-bold fs-5">
                    {props.data.invoice.delivery_type}
                  </div>
                </div>
              </div>
            </div>

            <div className="row ps-4 pe-3 pb-4">
              <div className="col-12 col-md-6" />

              <div className="col-12 col-md-6">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div className="fst-italic">
                    Kode Booking ini Bukan No Resi Pengiriman
                  </div>
                  <div className="fs-2 fw-bold">{props.data.number}</div>
                </div>
              </div>
            </div>

            <div className="row ps-4 pe-4 pb-4">
              <div className="col-12 col-md-6">
                <div className="row">
                  <div className="d-flex flex-column col-6">
                    <div className="">Berat : </div>
                    <div className="fw-bold">{props.data.total_weight} kg</div>
                  </div>

                  <div className="d-flex flex-column col-6">
                    <div className="">Ongkir : </div>
                    <div className="fw-bold">
                      <s>
                        Rp {props.data.invoice.shipping_cost.toLocaleString()}
                      </s>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex p-4 m-4 justify-content-center align-items-center border">
              <div className="d-flex ">
                <div className="d-flex justify-content-center align-items-center">
                  <img alt="logo" src={icon} height={30} />
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="fst-italic ms-3 ">
                    Penjual <strong>tidak perlu</strong> bayar apapun ke kurir,
                    sudah dibayarkan otomatis.
                  </div>
                </div>
              </div>
            </div>

            <div className="row p-4">
              {props.data.invoice.recipient_name ? (
                <div className="col-12 col-md-6">
                  <div className="d-flex flex-column">
                    <div className="">Kepada : </div>
                    <div className="d-flex flex-column">
                      <div className="fw-bold">
                        <>{props.data.invoice.recipient_name}</>
                      </div>
                      <div>{props.data.invoice.recipient_address_full}</div>
                      <div>
                        {props.data.invoice.recipient_sub_district},{" "}
                        {props.data.invoice.recipient_city},{" "}
                        {props.data.invoice.recipient_province},{" "}
                        {props.data.invoice.recipient_postal_code}
                      </div>
                      <div>{props.data.invoice.recipient_mobile_phone}</div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="col-12 col-md-6">
                <div className="d-flex flex-column">
                  <div className="">Dari : </div>
                  <div className="d-flex flex-column">
                    <div className="fw-bold">{props.data.brand.name}</div>
                    <div>{props.data.brand.address}</div>
                    <div>{props.data.brand.telephone}</div>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div className="p-4">
              <div className="table-responsive w-100">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>{t("printOrder.table.productName")}</th>
                      <th>SKU</th>
                      <th>{t("printOrder.table.quantity")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productData.map((product: any, productKey: number) => {
                      return (
                        <tr key={productKey}>
                          <td>
                            <div className="product-info">
                              <p className="name">
                                {_.get(
                                  product,
                                  "invoice_product.product.name",
                                  "-"
                                )}
                              </p>
                            </div>
                          </td>
                          <td>
                            {_.get(product, "invoice_product.product.sku", "-")}
                          </td>
                          <td>{product.quantity} pcs</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer id="printInvoice">
        <div className="m-auto">
          <button className="btn btn-primary" onClick={props.handlePrint}>
            {t("printOrder.button.print")}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default PrintOrderModal;
