import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";
import useRoleService from "../../../vodea/utilities/hooks/useRoleService";
import _ from "lodash";
import { Navigate } from "react-router-dom";
import BrandOrderShow from "./Brand/Show";
import VendorOrderShow from "./Vendor/Show";

const OrderShow: React.FC<any> = () => {
  const { brand } = useSelector((state: RootState) => state.brand);
  const { isAdministrator, isKeyAccount, isVendor, isBrand } = useRoleService();

  if (!_.get(brand, "id", null)) {
    return <Navigate to={"/"} replace />;
  }

  if (isAdministrator() || isKeyAccount() || isBrand()) {
    return <BrandOrderShow />;
  } else if (isVendor()) {
    return <VendorOrderShow />;
  } else {
    return <></>;
  }
};

export default OrderShow;
