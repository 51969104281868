export default function printElement(elem: any, append: any, delimiter: any) {
  var domClone = elem.cloneNode(true);

  var $printSection = document.getElementById("printSection");

  if (!$printSection) {
    $printSection = document.createElement("div");
    $printSection.id = "printSection";
    document.body.appendChild($printSection);
  }

  if (append !== true) {
    $printSection.innerHTML = "";
  } else if (append === true) {
    if (typeof delimiter === "string") {
      $printSection.innerHTML += delimiter;
    } else if (typeof delimiter === "object") {
      $printSection.appendChild(delimiter);
    }
  }

  $printSection.appendChild(domClone);
  window.print();
}


export function printDiv(id: any) {
  var printContents = document.getElementById(id)!.innerHTML;
  document.body.innerHTML = printContents;
  window.print();
  window.location.reload()
}
