import React, { useEffect } from "react";
import { Button, Modal, ModalProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useState } from "@hookstate/core";
import { Controller, useForm } from "react-hook-form";
import { InformationInputs, informationSchema } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatFormData } from "../../../vodea/utilities/helpers/form";
import VendorStockProductRepository from "../../../repositories/Vendor/StokProductRepository";
import { AxiosError, AxiosResponse } from "axios";
import {
  mapHookErrors,
  showToast,
} from "../../../vodea/utilities/helpers/global";
import useIsMounted from "../../../vodea/utilities/hooks/useIsMounted";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";
import { VuiButton } from "../../../vodea/@vodea-ui/components/VuiButton";
import clsx from "clsx";
import _ from "lodash";
import NumberFormat from "react-number-format";

interface UpdateStockModalProps extends ModalProps {
  stock: any;
  show: boolean;
  onContinue: () => void;
}

const UpdateStockModal: React.FC<UpdateStockModalProps> = ({
  stock,
  show = false,
  onContinue,
  ...props
}) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { vendor } = useSelector((state: RootState) => state.vendor);

  useEffect(() => {
    if (stock) {
      informationSetValue("product_id", stock.id);
      informationSetValue("quantity", stock.stock);
    }
  }, [stock]);

  const informationLoading = useState(false);
  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    errors: informationErrors,
    setError: informationSetError,
    setValue: informationSetValue,
    control: informationControl,
  } = useForm<InformationInputs>({
    resolver: yupResolver(informationSchema),
  });

  const onInformationSubmit = informationHandleSubmit((data) => {
    informationLoading.set(true);

    const formData = formatFormData(data);

    VendorStockProductRepository.updateStock(vendor.id, [formData])
      .then((response: AxiosResponse) => {
        showToast(t("response.successUpdateData"), "success");
        informationLoading.set(false);
        onContinue();
      })
      .catch((e: AxiosError) => {
        if (isMounted.current && e?.response?.data?.errors) {
          const errors = mapHookErrors(e.response.data.errors);
          Object.keys(errors).forEach((key: any) => {
            informationSetError(key, errors[key]);
          });

          showToast(e.response.data.message, "error");
        }

        informationLoading.set(false);
      });
  });

  return (
    <>
      <Modal
        {...props}
        show={show}
        centered
        backdrop={"static"}
        className="modal-incoming-order"
      >
        <form className={"form-wrapper"} onSubmit={onInformationSubmit}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              type="hidden"
              name="product_id"
              className="form-control"
              ref={informationRegister}
            />

            <div className="mb-3">
              <div className="text-muted">{t("form.name.label")}</div>
              <div className="">{_.get(stock, "name", "")}</div>
            </div>

            <div className="mb-3">
              <div className="text-muted">{t("form.sku.label")}</div>
              <div className="">{_.get(stock, "sku", "")}</div>
            </div>

            <div
              className={clsx({
                "form-group": true,
                error: informationErrors.quantity,
              })}
            >
              <label className={"form-label"}>{t("form.quantity.label")}</label>
              <Controller
                name={"quantity"}
                control={informationControl}
                defaultValue={null}
                render={(props) => (
                  <NumberFormat
                    className="form-control"
                    value={props.value}
                    allowNegative={false}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={0}
                    placeholder={"0"}
                    onValueChange={({ value }) => {
                      props.onChange(value);
                    }}
                  />
                )}
              />
              <label className={"label-help"}>
                {informationErrors.quantity?.message}
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant={"light"} onClick={props.onHide}>
              {t("button.cancel")}
            </Button>
            <VuiButton
              label={t("button.update")}
              loading={informationLoading.get()}
              forSubmit={true}
            />
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default UpdateStockModal;
