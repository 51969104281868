import Autocomplete from "react-autocomplete";
import React from "react";

interface Props {
  onChange: (e: any) => void;
  onSelect: (e: any) => void;
  options: any;
  onOpen: () => void;
  defaultValue: any;
}

const VuiAutoComplete: React.FC<Props> = ({
  onSelect,
  onChange,
  options = [],
  onOpen,
  defaultValue,
}) => {
  return (
    <Autocomplete
      renderInput={(props) => {
        return <input {...props} className={"form-control"} />;
      }}
      wrapperStyle={{
        display: "block",
        position: "relative",
      }}
      menuStyle={{
        position: "absolute",
        zIndex: 5,
        padding: "0.375rem 0",
        boxShadow: `0 2px 12px rgba(0, 0, 0, 0.1)`,
        borderRadius: "0.25rem",
        top: 40,
        left: 0,
      }}
      items={options}
      onMenuVisibilityChange={(isOpen) => isOpen && onOpen()}
      getItemValue={(item) => item}
      value={defaultValue}
      onChange={onChange}
      onSelect={onSelect}
      renderItem={(item, isHighlighted) => (
        <div
          style={{
            padding: "0.375rem 0.75rem",
            zIndex: 5,
            background: isHighlighted ? "lightgray" : "white",
          }}
        >
          {item}
        </div>
      )}
    />
  );
};

export default VuiAutoComplete;
