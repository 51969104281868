import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import VuiBreadcrumb from "../../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import { VuiButton } from "../../../../vodea/@vodea-ui/components/VuiButton";
import { useState } from "@hookstate/core/dist";
import moment from "moment";
import VuiDateRangePicker from "../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker";
import Select from "react-select";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faSearch } from "@fortawesome/free-solid-svg-icons";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";

const statusOptions = [
  {
    key: 1,
    label: "Semua",
  },
  {
    key: 2,
    label: "Menunggu SendPayment",
  },
  {
    key: 3,
    label: "Lunas",
  },
  {
    key: 4,
    label: "Diproses",
  },
  {
    key: 5,
    label: "Dikirim",
  },
  {
    key: 6,
    label: "Sampai",
  },
  {
    key: 7,
    label: "Dibatalkan",
  },
  {
    key: 8,
    label: "Dikembalikan",
  },
  {
    key: 9,
    label: "Gagal",
  },
];

const tableColumns = [
  {
    dataField: "produk",
    text: "Produk",
    formatter: (cell: any, row: any) => (
      <div className="d-flex justify-content-start align-items-center">
        <img
          src="https://images.samsung.com/is/image/samsung/id-uhd-tu8500-ua65tu8500kxxd-Black-221111229?$720_576_PNG$"
          style={{ maxWidth: "30px" }}
          className="rounded-circle"
        ></img>
        <div className="ms-2" style={{ width: "75px" }}>
          {row.produk}
        </div>
      </div>
    ),

    sort: false,
  },
  {
    dataField: "sku",
    text: "SKU Produk",
  },
  {
    dataField: "stok_barang",
    text: "Stok Barang",
    sort: true,
  },
  {
    dataField: "stok_promosi",
    text: "Stok Promosi",
    formatter: (cell: any, row: any) => (
      <div>
        <input type="number" name="stok_promosi" min="0"></input>
      </div>
    ),

    sort: true,
  },
  {
    dataField: "batas_pembelian",
    formatter: (cell: any, row: any) => (
      <div>
        <input type="number" name="batas_pembelian" min="0"></input>
      </div>
    ),

    text: "Batas Pembelian",
  },
  {
    dataField: "harga",
    text: "Harga",
    sort: true,
  },
];

const tableData = [
  {
    id: 1,
    produk: "TV 12 Inch",
    sku: "A000111",
    stok_barang: "20",
    stok_promosi: "10",
    batas_pembelian: "2",
    harga: "20,000",
  },
  {
    id: 2,
    produk: "Kulkas",
    sku: "A000111",
    stok_barang: "24",
    stok_promosi: "9",
    batas_pembelian: "2",
    harga: "20,000",
  },
  {
    id: 3,
    produk: "Kipas Angin",
    sku: "A000111",
    stok_barang: "22",
    stok_promosi: "3",
    batas_pembelian: "2",
    harga: "20,000",
  },
  {
    id: 4,
    produk: "LED 32 Inch",
    sku: "A000111",
    stok_barang: "23",
    stok_promosi: "4",
    batas_pembelian: "2",
    harga: "20,000",
  },
  {
    id: 5,
    produk: "LED 20 Inch",
    sku: "A000111",
    stok_barang: "50",
    stok_promosi: "5",
    batas_pembelian: "2",
    harga: "20,000",
  },
];

const AddPromosi: React.FC<any> = () => {
  const { t } = useTranslation();
  const statusSelected = useState(1);
  const selectedValue = useState(0);

  const filterConfig = useState({
    loading: false,

    date_from: moment().subtract(6, "days").format("YYYY-MM-DD"),
    date_to: moment().format("YYYY-MM-DD"),

    date_compare_from: moment().subtract(14, "days").format("YYYY-MM-DD"),
    date_compare_to: moment().subtract(7, "days").format("YYYY-MM-DD"),
  });

  const handleChangeDate = (start: any, end: any) => {
    filterConfig.date_from.set(start.format("YYYY-MM-DD"));
    filterConfig.date_to.set(end.format("YYYY-MM-DD"));
  };

  const handleChangeCompareDate = (start: any, end: any) => {
    filterConfig.date_compare_from.set(start.format("YYYY-MM-DD"));
    filterConfig.date_compare_to.set(end.format("YYYY-MM-DD"));
  };

  const getData = () => {
    filterConfig.loading.set(true);

    setTimeout(() => {
      filterConfig.loading.set(false);
    }, 1000);
  };

  const breadcrumbList = [
    {
      label: t("Order"),
      link: "/order",
    },
  ];

  function changeDiv(e: any) {
    selectedValue.set(e!.value);
    let x = document.getElementById("value_1");
    x!.style.cssText = "width: 20px";
  }

  return (
    <>
      <Helmet>
        <title>{t("Order")}</title>
      </Helmet>

      <div className={"page-header type-form mb-4"}>
        <div className="d-flex justify-content-start align-items-center">
          <Link to={"/promosi"}>
            <div className={"icon-wrapper"}>
              <FontAwesomeIcon
                icon={faChevronLeft}
                className={"icon icon-prefix"}
              />
            </div>
          </Link>

          <div className="">
            <h4 className="title">Tambah Promosi</h4>
          </div>
        </div>
      </div>

      <div className="page-content">
        <div className="row">
          <div className="col-4 col-md-4">
            <div className="card-paper mb-4">
              <div className="card-header">
                <div className="card-header-title">
                  <h5>Informasi</h5>
                </div>
              </div>
              <div className="card-content">
                <div className={"form-group"}>
                  <label className={"form-label"}>Brand</label>
                  <input type="text" name="brand" className="form-control" />
                  <label className={"label-help"}></label>
                </div>
                <div className={"form-group"}>
                  <label className={"form-label"}>Nama</label>
                  <input type="text" name="nama" className="form-control" />
                  <label className={"label-help"}></label>
                </div>
                <div className={"form-group"}>
                  <label className={"form-label"}>Jenis</label>
                  <Select
                    name="vendor"
                    defaultValue={{
                      value: 1,
                      label: "Diskon",
                    }}
                    options={[
                      {
                        value: 1,
                        label: "Diskon",
                      },
                      {
                        value: 2,
                        label: "Potongan Harga",
                      },
                    ]}
                    onChange={changeDiv}
                  />

                  <label className={"label-help"}></label>
                </div>
                <div id="value_1" className={"form-group"}>
                  <input
                    id="value1"
                    type="text"
                    name="value"
                    className="form-control"
                  ></input>
                </div>
                <div className={"form-group"}>
                  <label className={"form-label"}>Channel</label>
                  <Select
                    name="vendor"
                    defaultValue={{
                      value: 1,
                      label: "Shopee",
                    }}
                    options={[
                      {
                        value: 1,
                        label: "Shopee",
                      },
                      {
                        value: 2,
                        label: "Tokopedia",
                      },
                    ]}
                  />
                  <label className={"label-help"}></label>
                </div>
                <div></div>
              </div>

              <div className="card-footer">
                <div className="default-btn-action-wrapper">
                  <button type={"button"} className="btn btn-secondary">
                    Reset
                  </button>
                  <VuiButton
                    forSubmit={false}
                    label={"Terapkan"}
                    loading={filterConfig.loading.get()}
                    onClick={getData}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-8 col-md-8">
            <div className="card-paper">
              <div className="card-header">
                <div className="card-header-title">
                  <h5>Produk</h5>
                </div>
              </div>
              <div className="card-content">
                <div className={"form-group"}>
                  <label className={"form-label"}>Produk</label>
                  <div className="row">
                    <div className="col-9">
                      <input type="text" name="nama" className="form-control" />
                    </div>
                    <div className="d-grid col-3">
                      <button
                        type="button"
                        className="btn btn-primary btn-block"
                      >
                        Tambah
                      </button>
                    </div>
                  </div>
                  <label className={"label-help"}></label>
                </div>

                <BootstrapTable
                  wrapperClasses={"table-responsive"}
                  keyField={"id"}
                  data={tableData}
                  columns={tableColumns}
                  filter={filterFactory()}
                  pagination={paginationFactory({
                    page: 1,
                    sizePerPage: 5,
                    totalSize: 5,
                    showTotal: true,
                  })}
                />
              </div>
            </div>
            <div className="card-paper mt-4">
              <div className="card-header">
                <div className="card-header-title">
                  <h5>Waktu</h5>
                </div>
              </div>
              <div className="card-content">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Tanggal Mulai</label>
                      <input
                        type="date"
                        id="start_date"
                        name="start_date"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Tanggal Berakhir</label>
                      <input
                        type="date"
                        id="end_date"
                        name="end_date"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Jam Mulai</label>
                      <input
                        type="time"
                        id="start_time"
                        name="start_time"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Jam Berakhir</label>
                      <input
                        type="time"
                        id="end_time"
                        name="end_time"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"card-paper mt-4"}>
          <div className={"card-content type-button"}>
            <div className={"button-submit-wrapper"}>
              <div className={"label-wrapper"}>
                <div className={"title"}>Simpan Data?</div>
              </div>
              <div className={"button-group-wrapper"}>
                <div className={"button-group"}>
                  <div className={"btn btn-sm"}>Batal</div>
                </div>
                <div className={"button-group"}>
                  <VuiButton
                    forSubmit={true}
                    label={"Simpan"}
                    loading={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPromosi;
