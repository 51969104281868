import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useIsMounted from "../../../../../vodea/utilities/hooks/useIsMounted";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../stores";
import { Helmet } from "react-helmet-async";
import VuiBreadcrumb from "../../../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import { Link, useParams } from "react-router-dom";
import { useState } from "@hookstate/core";
import VendorOrderRepository from "../../../../../repositories/Vendor/OrderRepository";
import { AxiosError, AxiosResponse } from "axios";
import {
  getOrderStatusConfig,
  showToast,
} from "../../../../../vodea/utilities/helpers/global";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faExclamationCircle,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import moment from "moment";
import NumberFormat from "react-number-format";
import { VuiButton } from "../../../../../vodea/@vodea-ui/components/VuiButton";
import { STATUS } from "../../../../../constants";
import {
  Accordion,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
  useAccordionToggle,
} from "react-bootstrap";
import VuiActionModal from "../../../../../vodea/@vodea-ui/components/Modal/VuiActionModal";
import { Controller, useForm } from "react-hook-form";
import {
  ModalDeliveredInputs,
  modalDeliveredSchema,
  ModalDeliveryInputs,
  modalDeliverySchema,
} from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatFormData } from "../../../../../vodea/utilities/helpers/form";
import VuiUploadImage from "../../../../../vodea/@vodea-ui/components/Forms/VuiUploadImage";
import { printDiv } from "../../../../../vodea/utilities/printScreen";
import PrintOrderModal from "../../../../../components/Modal/PrintOrderModal";
import logo from "./logo/logo-fix-removebg-preview.png";
import PrintInvoiceModal from "../../../../../components/Modal/PrintInvoiceModal";
import DateTime from "../../../../../components/DateTime";

const VendorOrderShow: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const isMounted = useIsMounted();
  const { vendor } = useSelector((state: RootState) => state.vendor);
  const [showModal, setShowModal] = React.useState(false);
  const [orderData, setOrderData] = React.useState<any>([]);
  const [showInvoiceModal, setShowInvoiceModal] = React.useState(false);
  const [invoiceProducts, setInvoiceProducts] = React.useState([]);

  const data = useState<any>(null);
  const products = useState<any[]>([]);
  const activities = useState<any[]>([]);

  const actionConfig = useState<any>({
    question: "",
    button: "",
    nextStatus: "",
    color: "",
    action: () => {},
  });

  const handleChangeStatus = (params = {}) => {
    actionLoading.set(true);

    VendorOrderRepository.update(
      vendor.id,
      id,
      actionConfig.get().nextStatus,
      params
    )
      .then((response: AxiosResponse) => {
        window.location.reload();
        actionLoading.set(false);
      })
      .catch((e: AxiosError) => {
        showToast(e.message, "error");
        actionLoading.set(false);
      });
  };

  const showActionModal = useState(false);
  const showOnDeliveryModal = useState(false);
  const showDeliveredModal = useState(false);

  const {
    register: modalDeliveryRegister,
    handleSubmit: modalDeliveryHandleSubmit,
    errors: modalDeliveryErrors,
    setError: modalDeliverySetError,
    setValue: modalDeliverySetValue,
    control: modalDeliveryControl,
  } = useForm<ModalDeliveryInputs>({
    resolver: yupResolver(modalDeliverySchema),
  });

  const onModalDeliverySubmit = modalDeliveryHandleSubmit((data) => {
    const formData = formatFormData(data);

    const params = {
      shipping_number: formData.shipping_number,
      photo_ids: [],
    };

    if (formData.photo_id) {
      Object.assign(params, {
        photo_ids: [formData.photo_id],
      });
    }

    handleChangeStatus(params);
  });

  const {
    register: modalDeliveredRegister,
    handleSubmit: modalDeliveredHandleSubmit,
    errors: modalDeliveredErrors,
    setError: modalDeliveredSetError,
    setValue: modalDeliveredSetValue,
    control: modalDeliveredControl,
  } = useForm<ModalDeliveredInputs>({
    resolver: yupResolver(modalDeliveredSchema),
  });

  const onModalDeliveredSubmit = modalDeliveredHandleSubmit((data) => {
    const formData = formatFormData(data);

    let params = {};

    if (formData.photo_id) {
      Object.assign(params, {
        photo_ids: [formData.photo_id],
        receiver_name: formData.receiver_name,
      });

      handleChangeStatus(params);
    }
  });

  const actionLoading = useState(false);

  const actionConfigOptions = [
    {
      status: STATUS.NEW_ORDER,
      config: {
        question: t("question.order.ready"),
        button: t("button.order.ready"),
        nextStatus: "ready-to-delivery",
        color: "info",
        action: () => {
          showActionModal.set(true);
        },
      },
    },
    {
      status: STATUS.READY_TO_SHIP,
      config: {
        question: t("question.order.shipping"),
        button: t("button.order.shipping"),
        nextStatus: "on-delivery",
        color: "warning",
        action: () => {
          showOnDeliveryModal.set(true);
        },
      },
    },
    {
      status: STATUS.ON_DELIVERY,
      config: {
        question: t("question.order.delivered"),
        button: t("button.order.delivered"),
        nextStatus: "delivered",
        color: "orange",
        action: () => {
          showDeliveredModal.set(true);
        },
      },
    },
  ];

  const handlePrint = () => {
    printDiv("printable");
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = () => {
    VendorOrderRepository.show(vendor.id, id, {
      with: [
        "invoice",
        "orderProducts.invoiceProduct.product",
        "orderActivities",
        "deliveryPhotos",
        "deliveredPhotos",
        "brand",
        "orderProducts.replacedOrderProducts",
      ],
    })
      .then((response: AxiosResponse) => {
        const tempData = response.data.data;

        if (isMounted.current) {
          setOrderData({
            ...response.data.data,
            vendor,
          });
          setInvoiceProducts(response.data.data.invoice.raw_data.products);
          data.set(tempData);
          if (tempData.order_products) {
            products.set(tempData.order_products);
          }
          activities.set(_.reverse(_.get(tempData, "order_activities", [])));
          const selectedActionConfig = _.find(
            actionConfigOptions,
            (item) => item.status === tempData.status
          );

          if (selectedActionConfig) {
            actionConfig.set(selectedActionConfig.config);
          }
        }
      })
      .catch((e: AxiosError) => {
        showToast(e.message, "error");
      });
  };

  const handlePrintOrder = () => {
    printDiv("detailOrder");
  };

  function CustomToggle({ children, eventKey }: any) {
    const decoratedOnClick = useAccordionToggle(eventKey, () => {});

    return <div onClick={decoratedOnClick}>{children}</div>;
  }

  const breadcrumbList = [
    {
      label: t("order.title"),
      link: "/order",
    },
    {
      label: _.get(data.get(), "number", ""),
      link: `/order/${id}`,
    },
  ];

  return (
    <>
      <Helmet>
        <title>{_.get(data.get(), "number", "")}</title>
      </Helmet>

      <PrintOrderModal
        show={showModal}
        setShow={setShowModal}
        data={orderData}
        logo={logo}
        handlePrint={handlePrint}
      />

      <PrintInvoiceModal
        show={showInvoiceModal}
        setShow={setShowInvoiceModal}
        data={orderData}
        logo={logo}
        handlePrint={handlePrint}
        products={invoiceProducts}
      />

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={"page-header-component"}>
        <h3 className={"title"}>{_.get(data.get(), "number", "")}</h3>
        <div className="d-flex justify-content-center align-items-center">
          {(orderData && orderData.status == "Siap Dikirim") ||
          "Dalam Pengiriman" ||
          "Sampai" ||
          "Selesai" ? (
            <div className="btn-wrapper">
              <button
                type="button"
                className="btn btn-warning"
                onClick={() => handlePrintOrder()}
              >
                <FontAwesomeIcon icon={faPrint} className="icon icon-prefix" />
                {/* {t("button.print.label")} */}
                Cetak Penjualan
              </button>
            </div>
          ) : null}

          <div className="btn-wrapper ms-3">
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => setShowInvoiceModal(true)}
            >
              <FontAwesomeIcon icon={faPrint} className="icon icon-prefix" />
              {/* {t("button.print.label")} */}
              Cetak Invoice
            </button>
          </div>
          <div className="btn-wrapper ms-3">
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => setShowModal(true)}
            >
              <FontAwesomeIcon icon={faPrint} className="icon icon-prefix" />
              {t("button.print.label")}
            </button>
          </div>
        </div>
      </div>

      <div className="default-page-layout type-profile">
        <div className="information-section" id="detailOrder">
          <div className="card-paper">
            <div className="card-header">
              <h5 className="card-header-title">{t("order.orderDetail")}</h5>
              {_.get(data.get(), "invoice.preorder_process_day", null) && (
                <div className="display-flex align-items-center">
                  <DateTime
                    data={_.get(data.get(), "invoice.preorder_deadline", null)}
                  />
                  <div className="status-wrapper for-status-po-detail type-grey ms-lg-3">
                    {`PO: ${_.get(
                      data.get(),
                      "invoice.preorder_process_day",
                      null
                    )} Hari`}
                  </div>
                </div>
              )}
            </div>

            <div className="card-content">
              <div className="mb-3">
                <div className="text-muted">{t("form.orderNumber.label")}</div>
                <div className="">{_.get(data.get(), "number", "")}</div>
              </div>

              {_.get(data.get(), "invoice.vouchers", null) &&
                _.get(data.get(), "invoice.vouchers", []).length > 0 && (
                  <div className="mb-3">
                    <div className="text-muted">{t("form.vouchers.label")}</div>
                    <div className="voucher-text">
                      {_.get(data.get(), "invoice.vouchers[0].name", "")}
                    </div>
                  </div>
                )}

              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <div className="text-muted">{t("form.status.label")}</div>
                    <div className="">
                      <div
                        className={clsx([
                          "badge",
                          getOrderStatusConfig(_.get(data.get(), "status", ""))
                            ?.badge,
                        ])}
                      >
                        {_.get(data.get(), "status", "")}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3">
                    <div className="text-muted">
                      {t("form.transactionDate.label")}
                    </div>
                    <div className="">
                      {moment(_.get(data.get(), "created_at", "")).format(
                        "D MMMM YYYY h:mm A"
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className={"row"}>
                {_.get(data.get(), "shipping_number", null) ? (
                  <div className="col-md-4">
                    <div className="mb-3">
                      <div className="text-muted">
                        {t("form.shippingNumber.label")}
                      </div>
                      <div className="">
                        {_.get(data.get(), "shipping_number", "")}
                      </div>
                    </div>
                  </div>
                ) : null}

                {_.get(data.get(), "receiver_name") && (
                  <div className="col-md-4">
                    <div className="mb-3">
                      <div className="text-muted">
                        {t("form.receiverName.label")}
                      </div>
                      <div className="">
                        {_.get(data.get(), "receiver_name")}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {_.get(data.get(), "shipping_number", null) && (
                <div className="mb-3">
                  <div className="text-muted">
                    {t("form.shippingPhoto.label")}
                  </div>
                  <div className="">
                    <VuiUploadImage
                      className={"type-left"}
                      defaultValue={_.get(
                        data.get(),
                        "delivery_photos[0]",
                        null
                      )}
                      onChange={() => {}}
                      disabled={true}
                    />
                  </div>
                </div>
              )}

              {_.get(data.get(), "delivered_photos", []).length ? (
                <div className="mb-3">
                  <div className="text-muted">
                    {t("form.deliveryProof.label")}
                  </div>
                  <div className="">
                    <VuiUploadImage
                      className={"type-left"}
                      defaultValue={_.get(
                        data.get(),
                        "delivered_photos[0]",
                        null
                      )}
                      onChange={() => {}}
                      disabled={true}
                    />
                  </div>
                </div>
              ) : null}

              <div className="table-responsive">
                <table className="table table-bordered vendor-table">
                  <thead>
                    <tr>
                      <th>{t("table.product.label")}</th>
                      <th>{t("table.total.label")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.get().map((product: any, productKey: number) => {
                      return (
                        <tr key={productKey}>
                          <td>
                            <div className="product-info">
                              <div className="display-flex align-items-center mb-2">
                                <p className="name">
                                  {product.invoice_product.product.name}
                                </p>
                                {product.quantity_return > 0 && (
                                  <>
                                    <span className="badge bg-secondary ml-4 mr-2">
                                      Retur
                                    </span>
                                    <OverlayTrigger
                                      key={`tooltip-description-retur`}
                                      placement={"top"}
                                      overlay={
                                        <Tooltip
                                          id={`tooltip-description-retur`}
                                        >
                                          <i>
                                            {product.replaced_order_products
                                              .map((item: any) => item.note)
                                              .filter((a: any) => a)
                                              .map((x: any, index: number) => (
                                                <div key={index}>
                                                  {`${index + 1}. ${x}`}
                                                </div>
                                              ))}
                                          </i>
                                        </Tooltip>
                                      }
                                    >
                                      <FontAwesomeIcon
                                        className={"icon"}
                                        icon={faExclamationCircle}
                                      />
                                    </OverlayTrigger>
                                  </>
                                )}
                              </div>
                              <div className="display-flex align-items-center font-size-3">
                                <div className="mr-4">
                                  <div>{t("table.sku.label")}</div>
                                  <div>{t("table.quantity.label")}</div>
                                  <div>{t("table.price.label")}</div>
                                </div>
                                <div>
                                  <div>
                                    {` : ${product.invoice_product.product.sku}`}
                                  </div>
                                  <div>{` : ${product.quantity}`}</div>
                                  <div>
                                    {" : "}
                                    <NumberFormat
                                      value={_.get(product, "price", 0)}
                                      displayType={"text"}
                                      thousandSeparator={"."}
                                      decimalSeparator={","}
                                      decimalScale={0}
                                      prefix={"IDR "}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <NumberFormat
                              value={
                                product.quantity_return > 0
                                  ? 0
                                  : _.get(product, "total_price", 0)
                              }
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              decimalScale={0}
                              prefix={"IDR "}
                            />
                          </td>
                        </tr>
                      );
                    })}

                    <tr
                      style={{
                        verticalAlign: "middle",
                      }}
                    >
                      <td colSpan={1}>
                        <div className="total">{t("table.total.label")}</div>
                      </td>
                      <td>
                        <div className="fs-5">
                          <strong>
                            <NumberFormat
                              value={
                                _.sumBy(products.get(), "total_price") -
                                _.get(data.get(), "grand_total_return", 0)
                              }
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              decimalScale={0}
                              prefix={"IDR "}
                            />
                          </strong>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="timeline-activity">
                <Accordion defaultActiveKey={`activity`}>
                  <CustomToggle eventKey={`activity`}>
                    <label className="header">
                      Aktifitas Pesanan{" "}
                      <FontAwesomeIcon icon={faAngleRight} className="icon" />
                    </label>
                  </CustomToggle>

                  <Accordion.Collapse eventKey={`activity`}>
                    <div className="timeline-wrapper">
                      {activities
                        .get()
                        .map((activity: any, activityKey: number) => {
                          return (
                            <div className="item" key={activityKey}>
                              <label className="date">
                                {moment(
                                  _.get(activity, "timestamp", "")
                                ).format("D MMMM YYYY")}
                                <br />
                                {moment(
                                  _.get(activity, "timestamp", "")
                                ).format("h:mm A")}
                              </label>
                              <span className="dot active" />
                              <label className="info">{activity.message}</label>
                            </div>
                          );
                        })}
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              </div>
            </div>
          </div>
        </div>

        <div className="customer-section">
          <div className="card-paper mb-4">
            <div className="card-header">
              <h5 className="card-header-title">{t("order.shippingDetail")}</h5>
            </div>

            <div className="card-content">
              <div className="mb-4">
                <div className="text-muted">
                  {t("form.recipientName.label")}
                </div>
                <div className="">
                  {_.get(data.get(), "invoice.recipient_name", "")}
                </div>
              </div>
              <div className="mb-4">
                <div className="text-muted">{t("form.telephone.label")}</div>
                <div className="">
                  {_.get(data.get(), "invoice.recipient_mobile_phone", "")}
                </div>
              </div>
              <div className="mb-4">
                <div className="text-muted">
                  {t("form.recipientAddress.label")}
                </div>
                <div>
                  {_.get(data.get(), "invoice.recipient_address_full", "")}
                </div>
                <div>
                  {_.get(data.get(), "invoice.recipient_sub_district", "")},{" "}
                  {_.get(data.get(), "invoice.recipient_city", "")},{" "}
                  {_.get(data.get(), "invoice.recipient_province", "")},{" "}
                  {_.get(data.get(), "invoice.recipient_postal_code", "")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {actionConfig.get().nextStatus ? (
        <div className={"card-action-form"}>
          <h6 className={"title"}>{actionConfig.get().question}</h6>

          <div className="btn-action-wrapper">
            <Link to={`/order`} className={"btn btn-sm btn-secondary"}>
              {t("general.back")}
            </Link>
            <VuiButton
              forSubmit={false}
              variant={actionConfig.get().color}
              label={actionConfig.get().button}
              loading={false}
              onClick={() => actionConfig.get().action()}
            />
          </div>
        </div>
      ) : null}

      <Modal
        centered
        backdrop={"static"}
        show={showActionModal.get()}
        onHide={() => showActionModal.set(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-action-wrapper">
            <p className="title">{t("modal.action.title")}</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={"light"} onClick={() => showActionModal.set(false)}>
            {t("button.cancel")}
          </Button>
          <VuiButton
            forSubmit={false}
            variant={"primary"}
            label={t("button.continue")}
            loading={actionLoading.get()}
            onClick={() => handleChangeStatus()}
          />
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        backdrop={"static"}
        show={showOnDeliveryModal.get()}
        onHide={() => showOnDeliveryModal.set(false)}
      >
        <form className={"form-wrapper"} onSubmit={onModalDeliverySubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{t("modal.title.inputShippingRef")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div
                className={clsx({
                  "form-group": true,
                  error: modalDeliveryErrors.shipping_number,
                })}
              >
                <label className={"form-label"}>
                  {t("form.shippingNumber.label")}
                </label>
                <input
                  type="text"
                  name="shipping_number"
                  className="form-control"
                  ref={modalDeliveryRegister}
                />
                <label className={"label-help"}>
                  {modalDeliveryErrors.shipping_number?.message}
                </label>
              </div>

              <div
                className={clsx({
                  "form-group": true,
                  error: modalDeliveryErrors.photo_id,
                })}
              >
                <label className={"form-label"}>
                  {t("form.shippingPhoto.label")}
                </label>

                <Controller
                  name="photo_id"
                  control={modalDeliveryControl}
                  defaultValue={null}
                  render={({ value, onChange }) => (
                    <VuiUploadImage defaultValue={value} onChange={onChange} />
                  )}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant={"light"}
              onClick={() => showOnDeliveryModal.set(false)}
            >
              {t("button.cancel")}
            </Button>
            <VuiButton
              forSubmit={true}
              label={t("button.continue")}
              loading={actionLoading.get()}
            />
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        centered
        backdrop={"static"}
        show={showDeliveredModal.get()}
        onHide={() => showDeliveredModal.set(false)}
      >
        <form className={"form-wrapper"} onSubmit={onModalDeliveredSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{t("modal.title.inputDelivered")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div
                className={clsx({
                  "form-group": true,
                  error: modalDeliveredErrors.receiver_name,
                })}
              >
                <label className={"form-label"}>
                  {t("form.receiverName.label")}
                </label>

                <Controller
                  name="receiver_name"
                  control={modalDeliveredControl}
                  defaultValue={""}
                  render={({ value, onChange, name }) => (
                    <input
                      type={"text"}
                      className={"form-control"}
                      name={name}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />

                <label className={"label-help"}>
                  {modalDeliveredErrors.receiver_name?.message}
                </label>
              </div>
              <div
                className={clsx({
                  "form-group": true,
                  error: modalDeliveredErrors.photo_id,
                })}
              >
                <label className={"form-label"}>
                  {t("form.shippingPhoto.label")}
                </label>

                <Controller
                  name="photo_id"
                  control={modalDeliveredControl}
                  defaultValue={null}
                  render={({ value, onChange }) => (
                    <VuiUploadImage defaultValue={value} onChange={onChange} />
                  )}
                />

                <label className={"label-help"}>
                  {modalDeliveredErrors.photo_id?.message}
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant={"light"}
              onClick={() => showDeliveredModal.set(false)}
            >
              {t("button.cancel")}
            </Button>
            <VuiButton
              forSubmit={true}
              label={t("button.continue")}
              loading={actionLoading.get()}
            />
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default VendorOrderShow;
