import { jsPDF } from "jspdf";

export const htmlToPdf = (dataUrl: any, fileName: string) => {
  setTimeout(() => {
    const pdf = new jsPDF({
      unit: "px",
    });
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    pdf.addImage(
      dataUrl.toDataURL("image/jpeg", 1),
      "JPEG",
      0,
      0,
      pdfWidth,
      pdfHeight
    );
    pdf.save(`${fileName}.pdf`);
  }, 100);

  return true;
};
