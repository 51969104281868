import {Autocomplete, GoogleMap, LoadScript, Marker} from "@react-google-maps/api";
import React, {useRef} from "react";
import {useState} from "@hookstate/core";
import _ from "lodash";
import useGeocode from "../../vodea/utilities/hooks/useGeocode";

interface PinAddressState {
    lat: string,
    lng: string,
    callBack: (e: any) => void;
}

const containerStyle = {
    width: '100%',
    height: '400px'
};

const googleApiKey = () => process.env.REACT_APP_GOOGLE_API_KEY;

const PinAddress: React.FC<PinAddressState> = ({
    lat,
    lng,
    callBack
}) => {
    const {fromLatLng} = useGeocode()

    const autocomplete = useState<any>(null)

    const onPlaceChanged = () => {
        const location = autocomplete.get().getPlace().geometry.location;

        onHandleCallback(location.lat(), location.lng())
    }

    const onHandleDragEnd = (position: any) => {
        onHandleCallback(position.latLng.lat(), position.latLng.lng())
    }

    const onHandleCallback = async (lat: string, lng: string) => {
        let res = {
            lat: lat,
            lng: lng,
            city: '',
            district: '',
            postal_code: '',
            address: ''
        }

        await fromLatLng(lat, lng).then((data) => {
            res.city = data.city;
            res.district = data.district;
            res.postal_code = data.postalCode;
            res.address = data.address;
        })

        callBack(res)
    }

    if (!lat || !lng) {
        return (
            <></>
        )
    }

    return (
        <>
            <LoadScript
                googleMapsApiKey={`${googleApiKey()}`}
                libraries={["places"]}
            >
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={{
                        lat: parseFloat(lat),
                        lng: parseFloat(lng)
                    }}
                    zoom={16}
                    options={{
                        mapTypeControl: false,
                        streetViewControl: false
                    }}
                >
                    <Marker
                        position={{
                            lat: parseFloat(lat),
                            lng: parseFloat(lng)
                        }}
                        draggable={true}
                        onDragEnd={onHandleDragEnd}
                    />
                    <Autocomplete
                        onLoad={(load) => autocomplete.set(load)}
                        onPlaceChanged={onPlaceChanged}
                        restrictions={{
                            country: 'id'
                        }}
                    >
                        <input
                            type="text"
                            placeholder="Masukkan nama tempat"
                            style={{
                                boxSizing: `border-box`,
                                border: `1px solid transparent`,
                                width: `240px`,
                                height: `32px`,
                                padding: `0 12px`,
                                borderRadius: `3px`,
                                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                fontSize: `14px`,
                                outline: `none`,
                                textOverflow: `ellipses`,
                                position: "absolute",
                                left: "50%",
                                marginLeft: "-120px"
                            }}
                        />
                    </Autocomplete>
                </GoogleMap>
            </LoadScript>
        </>
    )
}

export default PinAddress
