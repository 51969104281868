import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useIsMounted from "../../../../../vodea/utilities/hooks/useIsMounted";
import { Helmet } from "react-helmet-async";
import VuiBreadcrumb from "../../../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import { formatFormData } from "../../../../../vodea/utilities/helpers/form";
import { AxiosError, AxiosResponse } from "axios";
import { useForm } from "react-hook-form";
import { validationSchema } from "./validation";
import {
  mapHookErrors,
  showToast,
} from "../../../../../vodea/utilities/helpers/global";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import VuiActionForm from "../../../../../vodea/@vodea-ui/components/Forms/VuiActionForm";
import BankRepository from "../../../../../repositories/BankRepository";
import { IApiResource } from "../../../../../interfaces/ApiResource";

interface IBank {
  id?: number;
  name: string;
}

const defaultValues: IBank = {
  name: "",
};

const SettingBankForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { id } = useParams();
  const [informationLoading, setInformationLoading] = useState<boolean>(false);
  const title = `${id ? t("general.edit") : t("general.create")} ${t(
    "settings.bank.title"
  )}`;

  // const getData = () => {
  //   BankRepository.show(id)
  //     .then((response: AxiosResponse) => {
  //       const data = response.data.data;
  //
  //       const formattedData = formatSetValueForm(InformationBaseModel, data);
  //
  //       _.forEach(formattedData, (value, name: any) => {
  //         informationSetValue(name, value);
  //       });
  //     })
  //     .catch((e: AxiosError) => {
  //       showToast(e.message, "error");
  //     });
  // };

  const [data, setData] = useState<IBank>(defaultValues);

  const loadData = useCallback(async () => {
    if (!id) {
      return;
    }

    await BankRepository.show(id)
      .then((response: AxiosResponse<IApiResource<IBank>>) => {
        const { data: responseData } = response.data;
        if (isMounted.current) {
          setData(responseData);
        }
      })
      .catch((error: AxiosError) => {
        showToast(error.message, "error");
      });
  }, []);

  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    errors: informationErrors,
    setError: informationSetError,
    reset: informationReset,
  } = useForm<IBank>({
    defaultValues: useMemo(() => {
      (async () => {
        await loadData();
      })();
      return data;
    }, [id, loadData]),
    resolver: yupResolver(validationSchema()),
  });

  useEffect(() => {
    informationReset(data);
  }, [data]);

  const onInformationSubmit = informationHandleSubmit(async (data) => {
    if (isMounted.current) setInformationLoading(true);

    const formData = formatFormData(data);

    if (id) {
      await BankRepository.update(id, formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) setInformationLoading(false);
          showToast(t("response.successUpdateData"), "success");
          navigate("/settings/bank");
        })
        .catch((e: AxiosError) => {
          if (isMounted.current) setInformationLoading(false);
          if (isMounted.current && e?.response?.data?.errors) {
            const errors = mapHookErrors(e.response.data.errors);
            Object.keys(errors).forEach((key: any) => {
              informationSetError(key, errors[key]);
            });

            showToast(e.response.data.message, "error");
          }
        });
    } else {
      await BankRepository.create(formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) setInformationLoading(false);
          showToast(t("response.successCreateData"), "success");
          navigate("/settings/bank");
        })
        .catch((e: AxiosError) => {
          if (isMounted.current) setInformationLoading(false);
          if (isMounted.current && e?.response?.data?.errors) {
            const errors = mapHookErrors(e.response.data.errors);
            Object.keys(errors).forEach((key: any) => {
              informationSetError(key, errors[key]);
            });

            showToast(e.response.data.message, "error");
          }
        });
    }
  });

  const breadcrumbList = [
    {
      label: t("settings.title"),
      link: "/settings",
    },
    {
      label: t("settings.bank.title"),
      link: "/settings/bank",
    },
    {
      label: title,
      link: `/settings/bank/${id ? id : "create"}`,
    },
  ];

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={"page-header-component"}>
        <h3 className={"title"}>{title}</h3>
      </div>

      <form className={"form-wrapper"} onSubmit={onInformationSubmit}>
        <div className={"default-page-layout"}>
          <div className={"information-section"}>
            <div className={"card-paper"}>
              <div className={"card-header"}>
                <h6 className={"card-header-title"}>
                  {t("general.information")}
                </h6>
              </div>

              <div className={"card-content"}>
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className={clsx({
                        "form-group": true,
                        error: informationErrors.name,
                      })}
                    >
                      <label className={"form-label"}>
                        {t("form.name.label")}
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        ref={informationRegister}
                      />
                      <label className={"label-help"}>
                        {informationErrors.name?.message}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <VuiActionForm
          loading={informationLoading}
          cancelLink={"/settings/bank"}
        />
      </form>
    </>
  );
};

export default SettingBankForm;
