import { UPDATE_BRAND, BrandActionTypes, BrandState } from './types';

const initialState: BrandState = {
  brand: {}
};

export function brandReducer(state = initialState, action: BrandActionTypes): BrandState {
  switch (action.type) {
    case UPDATE_BRAND: {
      return Object.assign(state, {
        brand: action.payload.brand
      });
    }
    default:
      return state;
  }
}
