import React from "react";

export const defaultProductFormatter = (
  cell: any,
  row: any,
  rowIndex: number
) => {
  return (
    <div className="product-wrapper">
      <div className="product-image">
        <img src={row.image} alt={"image-product"} />
      </div>
      <div className="product-name">{row.product}</div>
    </div>
  );
};
