import { LOAD_PERMISSION, PermissionActionTypes, PermissionState } from './types';

const initialState: PermissionState = {
  permission: []
};

export function permissionReducer(state = initialState, action: PermissionActionTypes): PermissionState {
  switch (action.type) {
    case LOAD_PERMISSION: {
      return Object.assign(state, {
        permission: action.payload.permission
      });
    }
    default:
      return state;
  }
}
