import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../../stores";

interface DateTimeInterface {
  data: string;
  defaultValue?: string;
}

const DateTime: React.FC<DateTimeInterface> = ({
  data,
  defaultValue = "-",
}) => {
  const { datetime } = useSelector(({ formatter }: RootState) => ({
    datetime: formatter.datetime,
  }));

  return (
    <>{moment(data).isValid() ? moment(data).format(datetime) : defaultValue}</>
  );
};

export default DateTime;
