import { AsyncPaginate } from 'react-select-async-paginate';
import loadAsyncOptions from './loadAsyncOptions';
import React from 'react';
import _ from 'lodash';

interface VuiSelectProps {
  propKey?: any;
  isMulti?: boolean;
  defaultValue?: any;
  selectRepository?: object;
  selectParams?: object;
  selectParentId?: string | number;
  options?: Array<any>;
  max?: number;
  isDisabled?: boolean;
  optionKey?: string;
  labelKey?: string;
  clearable?: boolean;
  onChange?: (e: any) => void;
  placeholder?: string;
}

const VuiSelect: React.FC<VuiSelectProps> = ({
  propKey = '1',
  isMulti = false,
  defaultValue = null,
  selectRepository = null,
  selectParams = {},
  selectParentId = '',
  options = [],
  max = 0,
  isDisabled = false,
  optionKey = 'id',
  labelKey = 'name',
  clearable = false,
  onChange,
  placeholder = 'Select..'
}) => {
  const handleChange = (val: any) => {
    if (!Boolean(max) || (max ? _.get(defaultValue, 'length', 0) < max : true)) {
      if (onChange) {
        onChange(val);
      }
    }
  };

  const loadOptions = async (search: string, prevOptions: any, { page }: any) => {
    if (options?.length) {
      let filteredOptions;
      if (!search) {
        filteredOptions = options;
      } else {
        const searchLower = search.toLowerCase();

        filteredOptions = options.filter(({ label }) => label.toLowerCase().includes(searchLower));
      }

      return await {
        options: filteredOptions,
        hasMore: false
      };
    } else {
      return await loadAsyncOptions(search, prevOptions, page, selectRepository, selectParams, selectParentId);
    }
  };

  return (
    <div className={'form-select-wrapper'}>
      <AsyncPaginate
        key={JSON.stringify(propKey)}
        classNamePrefix='select'
        isClearable={clearable}
        isMulti={isMulti}
        value={defaultValue}
        placeholder={placeholder}
        getOptionValue={options => options[optionKey]}
        getOptionLabel={options => options[labelKey]}
        loadOptions={loadOptions}
        onChange={handleChange}
        isDisabled={isDisabled}
        additional={{
          page: 1
        }}
      />
    </div>
  );
};

export default VuiSelect;
