import MediaRepository from '../../../repositories/MediaRepository';
import { AxiosError, AxiosResponse } from 'axios';

export interface MediaServiceInterface {
  files: File[];
  path: string;
  disk: string;
}

const useMediaService = () => {
  const storeMedia = async ({ files, path, disk }: MediaServiceInterface) => {
    const formData = new FormData();

    for (let file of files) {
      formData.append('file', file);
    }

    formData.append('disk', disk);
    formData.append('path', path);

    return new Promise(async (resolve, reject) => {
      await MediaRepository.create(formData)
        .then((response: AxiosResponse) => {
          resolve(response.data.data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  };

  return {
    storeMedia
  };
};

export default useMediaService;
