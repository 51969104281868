import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import useIsMounted from "../../../../../vodea/utilities/hooks/useIsMounted";
import {Helmet} from "react-helmet-async";
import VuiBreadcrumb from "../../../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import BrandCategoryRepository from "../../../../../repositories/Brand/CategoryRepository"
import {formatFormData, formatSetValueForm} from "../../../../../vodea/utilities/helpers/form";
import {AxiosError, AxiosResponse} from "axios";
import {Controller, useForm} from "react-hook-form";
import {InformationBaseModel, InformationInputs, informationSchema} from "./validation";
import {mapHookErrors, showToast} from "../../../../../vodea/utilities/helpers/global";
import {useState} from "@hookstate/core";
import {yupResolver} from "@hookform/resolvers/yup";
import clsx from "clsx";
import VuiActionForm from "../../../../../vodea/@vodea-ui/components/Forms/VuiActionForm";
import _ from "lodash";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../stores";
import VuiSelect from "../../../../../vodea/@vodea-ui/components/Forms/VuiSelect";


const SettingCategoryForm: React.FC<any> = () => {
    const { t } = useTranslation();
    const isMounted = useIsMounted();
    const navigate = useNavigate();
    const { id } = useParams();
    const { brand } = useSelector((state: RootState) => state.brand);

    const title = `${(id) ? t('general.edit') : t('general.create')} ${t('settings.category.title')}`;

    useEffect(() => {
        if (id) {
            getData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getData = () => {
        BrandCategoryRepository.show(brand.id, id, {
            with: [
                'parent',
            ]
        })
            .then((response: AxiosResponse) => {
                const data = response.data.data;

                const formattedData = formatSetValueForm(InformationBaseModel, data);

                _.forEach(formattedData, (value, name: any) => {
                    informationSetValue(name, value);
                });
            })
            .catch((e: AxiosError) => {
                showToast(e.message, 'error');
            });
    };

    const informationLoading = useState(false);
    const {
        register: informationRegister,
        handleSubmit: informationHandleSubmit,
        errors: informationErrors,
        setError: informationSetError,
        setValue: informationSetValue,
        control: informationControl
    } = useForm<InformationInputs>({
        resolver: yupResolver(informationSchema),
    });

    const onInformationSubmit = informationHandleSubmit(data => {
        informationLoading.set(true);

        const formData = formatFormData(data);

        ((id) ? BrandCategoryRepository.update(brand.id, id, formData) : BrandCategoryRepository.create(brand.id, formData))
            .then((response: AxiosResponse) => {
                showToast(t('response.successUpdateData'), 'success');
                informationLoading.set(false);

                if (!id) navigate('/settings/category');
            })
            .catch((e: AxiosError) => {
                if (isMounted.current && e?.response?.data?.errors) {
                    const errors = mapHookErrors(e.response.data.errors);
                    Object.keys(errors).forEach((key: any) => {
                        informationSetError(key, errors[key]);
                    });

                    showToast(e.response.data.message, 'error');
                }

                informationLoading.set(false);
            });
    });

    const breadcrumbList = [
        {
            label: t("settings.title"),
            link: "/settings",
        },
        {
            label: t("settings.category.title"),
            link: "/settings/category",
        },
        {
            label: title,
            link: `/settings/category/${(id) ? id : 'create'}`,
        },
    ];

    if (!_.get(brand, 'id', null)) {
        return <Navigate to={'/settings'} replace />;
    }

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <VuiBreadcrumb list={breadcrumbList} />

            <div className={'page-header-component'}>
                <h3 className={'title'}>{title}</h3>
            </div>

            <form className={'form-wrapper'} onSubmit={onInformationSubmit}>
                <div className={'default-page-layout'}>
                    <div className={'information-section'}>
                        <div className={'card-paper'}>
                            <div className={'card-header'}>
                                <h6 className={'card-header-title'}>{t('general.information')}</h6>
                            </div>
                            <div className={'card-content'}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div
                                            className={clsx({
                                                'form-group': true,
                                                error: informationErrors.name
                                            })}
                                        >
                                            <label className={'form-label'}>{t('form.name.label')}</label>
                                            <input type='text' name='name' className='form-control' ref={informationRegister} />
                                            <label className={'label-help'}>{informationErrors.name?.message}</label>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div
                                            className={clsx({
                                                'form-group': true,
                                                error: informationErrors.parent_id
                                            })}
                                        >
                                            <label className={'form-label'}>{t('form.parentCategory.label')}</label>
                                            <Controller
                                                name={'parent_id'}
                                                control={informationControl}
                                                render={props => (
                                                    <VuiSelect
                                                        selectRepository={BrandCategoryRepository}
                                                        selectParams={{
                                                            for: 'category_parent'
                                                        }}
                                                        selectParentId={brand.id}
                                                        defaultValue={props.value}
                                                        onChange={props.onChange}
                                                        labelKey={'structure_name'}
                                                    />
                                                )}
                                            />
                                            <label className={'label-help'}>{informationErrors.parent_id?.message}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <VuiActionForm loading={informationLoading.get()} cancelLink={'/settings/category'} />
            </form>
        </>
    )
}

export default SettingCategoryForm
