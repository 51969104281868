import * as yup from "yup";
import "yup-phone";

export type ModalDeliveryInputs = {
  shipping_number: string;
  photo_id: number | null;
};

export const modalDeliverySchema = yup.object().shape({
  shipping_number: yup.string().required().label("shipping number"),
  photo_id: yup.mixed(),
});

export type ModalDeliveredInputs = {
  photo_id: number | null;
  receiver_name: string;
};

export const modalDeliveredSchema = yup.object().shape({
  photo_id: yup.mixed().required().label("photo"),
  receiver_name: yup.string().required().label("nama penerima"),
});
