import { api, createCancelTokenHandler } from "../../../vodea/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (brand_id: any, params: any = null) {
    return api.get(`${endPoint()}/api/brand/${brand_id}/tokopedia/showcase`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);
