import { Link } from 'react-router-dom';

interface ViuBreadcrumbListTypes {
  label: string;
  link: string;
}

interface VuiBreadcrumbProps {
  list: Array<ViuBreadcrumbListTypes>;
}

const VuiBreadcrumb: React.FC<VuiBreadcrumbProps> = ({ list }) => {
  return (
    <nav aria-label='breadcrumb'>
      <ol className='breadcrumb'>
        <li className='breadcrumb-item'>
          <Link to={'/'}>Dashboard</Link>
        </li>
        {list.map((item, idx) => {
          if (idx === list.length - 1) {
            return (
              <li key={idx} className='breadcrumb-item active'>
                {item.label}
              </li>
            );
          }

          return (
            <li key={idx} className='breadcrumb-item'>
              <Link to={item.link}>{item.label}</Link>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default VuiBreadcrumb;
