import React from "react";
import useRoleService from "../../../vodea/utilities/hooks/useRoleService";
import BrandOrder from "./Brand";
import VendorOrder from "./Vendor";

const Order: React.FC<any> = () => {
  const { isAdministrator, isKeyAccount, isVendor, isBrand } = useRoleService();

  if (isAdministrator() || isKeyAccount() || isBrand()) {
    return <BrandOrder />;
  } else if (isVendor()) {
    return <VendorOrder />;
  } else {
    return <></>;
  }
};

export default Order;
