export interface MediaProps {
  id: number;
  name: string;
  encoded_name: string;
  size: number;
  extension: string;
  path: string;
  disk: string;
  url: string;
  selected?: boolean;
}

export const instanceOfMedia = (object: any): object is MediaProps => {
  if ((object as MediaProps)?.id) {
    return true;
  }

  return false;
};

export const BaseModelMedia: MediaProps = {
  id: 0,
  name: '',
  encoded_name: '',
  size: 0,
  extension: '',
  path: '',
  disk: '',
  url: '',
  selected: false
};

export const ExampleListMedia = [
  {
    id: 1,
    name: 'media-image-1',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-1.jpg',
    disk: 's3',
    url: '/images/media/media-image-1.jpg'
  },
  {
    id: 2,
    name: 'media-image-2',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-2.jpg',
    disk: 's3',
    url: '/images/media/media-image-2.jpg'
  },
  {
    id: 3,
    name: 'media-image-3',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-3.jpg',
    disk: 's3',
    url: '/images/media/media-image-3.jpg'
  },
  {
    id: 4,
    name: 'media-image-4',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-4.jpg',
    disk: 's3',
    url: '/images/media/media-image-4.jpg'
  },
  {
    id: 5,
    name: 'media-image-5',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-5.jpg',
    disk: 's3',
    url: '/images/media/media-image-5.jpg'
  },
  {
    id: 6,
    name: 'media-image-6',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-6.jpg',
    disk: 's3',
    url: '/images/media/media-image-6.jpg'
  },
  {
    id: 7,
    name: 'media-image-1',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-1.jpg',
    disk: 's3',
    url: '/images/media/media-image-1.jpg'
  },
  {
    id: 8,
    name: 'media-image-2',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-2.jpg',
    disk: 's3',
    url: '/images/media/media-image-2.jpg'
  },
  {
    id: 9,
    name: 'media-image-3',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-3.jpg',
    disk: 's3',
    url: '/images/media/media-image-3.jpg'
  },
  {
    id: 10,
    name: 'media-image-4',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-4.jpg',
    disk: 's3',
    url: '/images/media/media-image-4.jpg'
  },
  {
    id: 11,
    name: 'media-image-5',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-5.jpg',
    disk: 's3',
    url: '/images/media/media-image-5.jpg'
  },
  {
    id: 12,
    name: 'media-image-6',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-6.jpg',
    disk: 's3',
    url: '/images/media/media-image-6.jpg'
  },
  {
    id: 13,
    name: 'media-image-1',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-1.jpg',
    disk: 's3',
    url: '/images/media/media-image-1.jpg'
  },
  {
    id: 14,
    name: 'media-image-2',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-2.jpg',
    disk: 's3',
    url: '/images/media/media-image-2.jpg'
  },
  {
    id: 15,
    name: 'media-image-3',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-3.jpg',
    disk: 's3',
    url: '/images/media/media-image-3.jpg'
  },
  {
    id: 16,
    name: 'media-image-4',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-4.jpg',
    disk: 's3',
    url: '/images/media/media-image-4.jpg'
  },
  {
    id: 17,
    name: 'media-image-5',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-5.jpg',
    disk: 's3',
    url: '/images/media/media-image-5.jpg'
  },
  {
    id: 18,
    name: 'media-image-6',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-6.jpg',
    disk: 's3',
    url: '/images/media/media-image-6.jpg'
  },
  {
    id: 19,
    name: 'media-image-1',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-1.jpg',
    disk: 's3',
    url: '/images/media/media-image-1.jpg'
  },
  {
    id: 20,
    name: 'media-image-2',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-2.jpg',
    disk: 's3',
    url: '/images/media/media-image-2.jpg'
  },
  {
    id: 21,
    name: 'media-image-3',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-3.jpg',
    disk: 's3',
    url: '/images/media/media-image-3.jpg'
  },
  {
    id: 22,
    name: 'media-image-4',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-4.jpg',
    disk: 's3',
    url: '/images/media/media-image-4.jpg'
  },
  {
    id: 23,
    name: 'media-image-5',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-5.jpg',
    disk: 's3',
    url: '/images/media/media-image-5.jpg'
  },
  {
    id: 24,
    name: 'media-image-6',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-6.jpg',
    disk: 's3',
    url: '/images/media/media-image-6.jpg'
  },
  {
    id: 25,
    name: 'media-image-1',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-1.jpg',
    disk: 's3',
    url: '/images/media/media-image-1.jpg'
  },
  {
    id: 26,
    name: 'media-image-2',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-2.jpg',
    disk: 's3',
    url: '/images/media/media-image-2.jpg'
  },
  {
    id: 27,
    name: 'media-image-3',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-3.jpg',
    disk: 's3',
    url: '/images/media/media-image-3.jpg'
  },
  {
    id: 28,
    name: 'media-image-4',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-4.jpg',
    disk: 's3',
    url: '/images/media/media-image-4.jpg'
  },
  {
    id: 29,
    name: 'media-image-5',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-5.jpg',
    disk: 's3',
    url: '/images/media/media-image-5.jpg'
  },
  {
    id: 30,
    name: 'media-image-6',
    encoded_name: '',
    size: 5000,
    extension: 'jpg',
    path: '/images/media/media-image-6.jpg',
    disk: 's3',
    url: '/images/media/media-image-6.jpg'
  }
];
