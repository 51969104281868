import { toast, ToastPosition, TypeOptions } from "react-toastify";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import XLSX from "xlsx";
import {
  channelConfig,
  invoiceStatusConfig,
  orderStatusConfig,
} from "../../../constants";

export const validateYoutubeUrl = (url: any) => {
  const re =
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
  return re.test(String(url).toLowerCase());
};

export const randomString = (
  length: number = 32,
  chars: string = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
) => {
  let result = "";
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
};

export const optionSelected = (option: any, value: any, key: string = "id") =>
  /*eslint-disable eqeqeq*/
  _.get(option, key) == _.get(value, key);

export const optionLabel = (option: any, key: string = "name") =>
  _.get(option, key);

export const showToast = (
  message: string,
  type: TypeOptions = toast.TYPE.DEFAULT,
  position: ToastPosition = toast.POSITION.BOTTOM_LEFT
) => {
  toast(message, {
    position: position,
    type: type,
  });
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export function abbreviateNumber(num: any, fixed: any) {
  if (num === null) {
    return null;
  } // terminate early
  if (num === 0) {
    return "0";
  } // terminate early
  fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
  var b = num.toPrecision(2).split("e"), // get power
    k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
    c =
      k < 1
        ? num.toFixed(0 + fixed)
        : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
    d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
    e = d + ["", "K", "M", "B", "T"][k]; // append power
  return e;
}

export const stringTransform = {
  removeDashUnderScore: (str = "") => {
    return str.replace(/[-_]/g, " ").replace(/\w\S*/g, (txt) => txt);
  },
  formatDate: (val = "", format = "DD MMMM YYYY") => {
    return moment(val).format(format);
  },
  formatTime: (val = "", format = "HH:mm") => {
    const fullDate = "1997-10-11 " + val;

    return moment(fullDate).format(format);
  },
  sumStringNumber: (value: any[]) => {
    return value.reduce((a, b) => parseInt(a) + parseInt(b), 0);
  },
};

export const mapHookErrors = (errors: any) => {
  let newErrors: any = {};

  Object.keys(errors).forEach((key) => {
    Object.assign(newErrors, {
      [key]: {
        message: errors[key][0]
          .replace(/_id/g, "")
          .replace(/ id/g, "")
          .replace(/\.\d{1,3}\./g, (match: string) => {
            return ` ${match
              .replace(/\./g, "")
              .replace(/\d{1,3}/g, (a) =>
                ordinalSuffixOf(parseFloat(a) + 1)
              )} `;
          })
          .replace(/\w\.\w/g, (match: string) => match.replace(/\./g, " "))
          .replace(/_/g, " "),
        type: "manual",
      },
    });
  });

  return newErrors;
};

export const ordinalSuffixOf = (value: any) => {
  if (!Number(value)) {
    return "";
  }

  const j = parseFloat(value) % 10;
  const k = parseFloat(value) % 100;

  if (j === 1 && k !== 11) {
    return value + "st";
  }

  if (j === 2 && k !== 12) {
    return value + "nd";
  }

  if (j === 3 && k !== 13) {
    return value + "rd";
  }

  return value + "th";
};

export const getAliasesName = (value: string) => {
  const arrayName = value?.split(" ");
  let aliasesName = value?.charAt(0) + value?.charAt(1);

  if (arrayName.length >= 2) {
    aliasesName = arrayName[0].charAt(0) + arrayName[1].charAt(0);
  }

  return aliasesName.toUpperCase();
};

export const padLeadingZeros = (num: number, size: number) => {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
};

export const mapParams = (parameters: any) => {
  let params: any = {};

  Object.keys(parameters).forEach((key: string) => {
    const parameter = parameters[key];
    if (parameter !== null) {
      if (Array.isArray(parameter)) {
        params[key] = parameter.map((a) => _.get(a, "id", a));
      } else if (_.has(parameter, "id")) {
        params[key] = parameter.id;
      } else {
        params[key] = parameter;
      }
    }
  });

  return params;
};

export const convertExcelToJson = async (file: File) => {
  return new Promise((resolve, reject) => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    let data: any[] = [];

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e?.target?.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      data = XLSX.utils.sheet_to_json(ws);
      /* Update state */

      resolve(data);
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  });
};

export const getInvoiceStatusConfig = (label: string) => {
  const statusList = invoiceStatusConfig;
  const selectedIndex = _.findIndex(
    invoiceStatusConfig,
    (item) => item.label === label
  );

  return statusList[selectedIndex];
};

export const getOrderStatusConfig = (label: string) => {
  const statusList = orderStatusConfig;
  const selectedIndex = _.findIndex(
    orderStatusConfig,
    (item) => item.label === label
  );

  return statusList[selectedIndex];
};

export const getChannelConfig = (label: string) => {
  const channelList = channelConfig;
  const selectedIndex = _.findIndex(
    channelList,
    (item) => item.label === label
  );

  return channelList[selectedIndex];
};

export const convertToLocaleNumber = (last: any, current: any) => {
  if (last > 0 && current > 0) {
    return `${Math.round(((last - current) / last) * 100)}%`;
  } else if (current == 0) {
    return "0%";
  } else if (last == 0 && current > 0) {
    return "100%";
  }
};

export const convertToType = (last: any, current: any) => {
  if (current / last >= 1 && current / last !== 1) {
    return 1;
  } else if (current / last === 1) {
    return 0;
  } else if (current / last <= 1 && current / last !== 0) {
    return 2;
  } else {
    return 0;
  }
};
