import React from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { Doughnut } from "react-chartjs-2";

interface ICard {
  total: number;
  lastTotal: number;
  days: number;
  label: Array<any>;
  data: Array<any>;
}

const DoughnutCard: React.FC<ICard> = ({
  total = 0,
  lastTotal = 0,
  days = 0,
  label,
  data,
}) => {
  return (
    <>
      <div className="card-info">
        <h3 className="card-value">{total} Pembeli</h3>

        <label className="card-note">
          <span
            className={clsx({
              percentage: true,
              positive: total > lastTotal,
              negative: total < lastTotal,
            })}
          >
            <FontAwesomeIcon
              className={"icon"}
              icon={lastTotal >= total ? faArrowDown : faArrowUp}
            />{" "}
            {lastTotal > 0
              ? `${Math.round((total / lastTotal) * 100)} %`
              : total == 0
              ? `0 %`
              : `100 %`}
          </span>{" "}
          vs {days} hari lalu
        </label>
      </div>
      <div className="line-chart-wrapper mt-4">
        <Doughnut
          height={200}
          data={{
            labels: label,
            datasets: [
              {
                data: data,
                fill: false,
                backgroundColor: [
                  "rgb(255, 99, 132)",
                  "rgb(54, 162, 235)",
                  "rgb(255, 205, 86)",
                ],
              },
            ],
          }}
          options={{
            maintainAspectRatio: true,
            responsive: true,
            legend: {
              display: true,
              position: "bottom",
            },
          }}
        />
      </div>
    </>
  );
};

export default DoughnutCard;
