import React, { useEffect, useLayoutEffect, useRef } from "react";
import _ from "lodash";
import { useState } from "@hookstate/core/dist";
import { useTranslation } from "react-i18next";
import { faCloudUploadAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDropzone } from "react-dropzone";
import clsx from "clsx";
import { BaseModelMedia, MediaProps } from "../../../../interfaces/media";
import { fileTypes } from "../../../../../types";
import useMediaService from "../../../../utilities/hooks/useMediaService";
import useIsMounted from "../../../../utilities/hooks/useIsMounted";
import VuiLoader from "../../VuiLoader";
import { SortableHandle } from "react-sortable-hoc";

interface VuiUploadImageProps {
  className?: string;
  defaultValue: MediaProps | null;
  accept?: fileTypes;
  onChange: (val: any) => void;
  onDelete?: () => void;
  disabled?: boolean;
  sizeUpdate?: boolean;
  dragHandle?: boolean;
}

const VuiUploadImage: React.FC<VuiUploadImageProps> = ({
  className = "",
  defaultValue = BaseModelMedia,
  accept = "image/*",
  onChange,
  onDelete,
  disabled = false,
  sizeUpdate = true,
  dragHandle = false,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const cardHeight = useState<number>(sizeUpdate ? 200 : 130);
  const scopedDefaultValue = useState<any>(defaultValue);
  const { storeMedia } = useMediaService();
  const isMounted = useIsMounted();

  const DragHandler = SortableHandle(() => {
    return (
      <div className="card-filled-wrapper">
        <img
          className="image"
          src={_.get(scopedDefaultValue.value, "url")}
          alt="media"
        />
      </div>
    );
  });

  const handleOnDrop = (acceptedFiles: any) => {
    const formData = {
      files: acceptedFiles,
      path: "file",
      disk: "public",
    };

    storeMedia(formData).then((response: any) => {
      scopedDefaultValue.set({
        ...BaseModelMedia,
        ...response,
      });

      onChange(response);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: accept,
    onDrop: handleOnDrop,
    onDropRejected: (event) => {},
    multiple: false,
    disabled: disabled,
  });

  const handleDelete = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    onChange(null);
    scopedDefaultValue.set(null);

    if (onDelete) {
      onDelete();
    }
  };

  const updateSize = () => {
    if (sizeUpdate && isMounted.current) {
      cardHeight.set(_.get(ref, "current.offsetWidth", 0));
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      scopedDefaultValue.set(defaultValue);
      updateSize();
    }
  }, [defaultValue]);

  useLayoutEffect(() => {
    if (isMounted.current) {
      window.addEventListener("resize", _.debounce(updateSize, 500));
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={`card-files-section single-file ${className}`}>
      <div
        className={clsx(["card-file"], {
          filled: _.get(scopedDefaultValue.value, "url", ""),
        })}
        ref={ref}
        style={{
          height: cardHeight.get(),
        }}
      >
        <div className="card-inner" {...getRootProps()}>
          <input {...getInputProps()} />
          {_.get(scopedDefaultValue.value, "url", "") ? (
            <div className="card-filled-wrapper">
              {dragHandle ? (
                <DragHandler />
              ) : (
                <img
                  className="image"
                  src={_.get(scopedDefaultValue.value, "url")}
                  alt="media"
                />
              )}
              {!disabled ? (
                <div className="action-wrapper">
                  <FontAwesomeIcon
                    className={"icon-delete-file"}
                    icon={faTrash}
                    onClick={handleDelete}
                  />
                </div>
              ) : null}
            </div>
          ) : (
            <>
              <p className="card-label">{t("Upload an Image")}</p>
              <FontAwesomeIcon className={"icon"} icon={faCloudUploadAlt} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default VuiUploadImage;
