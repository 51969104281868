import * as yup from 'yup';

export type Inputs = {
  email: string;
  password: string;
};

export const schema = yup.object().shape({
  email: yup.string().email().required().label('The email'),
  password: yup.string().min(8).required().label('The password')
});
