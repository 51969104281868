import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import VuiBreadcrumb from "../../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import { VuiButton } from "../../../../vodea/@vodea-ui/components/VuiButton";
import { useState } from "@hookstate/core/dist";
import moment from "moment";
import VuiDateRangePicker from "../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker";
import Select from "react-select";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faChevronLeft,
  faKey,
  faSearch,
  faSuitcase,
  faTruck,
  faUser,
  faUserCircle,
  faUserFriends,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";

const statusOptions = [
  {
    key: 1,
    label: "Semua",
  },
  {
    key: 2,
    label: "Menunggu SendPayment",
  },
  {
    key: 3,
    label: "Lunas",
  },
  {
    key: 4,
    label: "Diproses",
  },
  {
    key: 5,
    label: "Dikirim",
  },
  {
    key: 6,
    label: "Sampai",
  },
  {
    key: 7,
    label: "Dibatalkan",
  },
  {
    key: 8,
    label: "Dikembalikan",
  },
  {
    key: 9,
    label: "Gagal",
  },
];

const tableColumns = [
  {
    dataField: "name",
    text: "Nama Peran",
  },
  {
    dataField: "last_update",
    text: "Terakhir Diperbaharui",
  },
];

const tableData = [
  {
    id: 1,
    name: "Admin",
    last_update: "29 April 2021, 10:43 AM",
  },
  {
    id: 2,
    name: "Admin",
    last_update: "29 April 2021, 10:43 AM",
  },
  {
    id: 3,
    name: "Admin",
    last_update: "29 April 2021, 10:43 AM",
  },
  {
    id: 4,
    name: "Admin",
    last_update: "29 April 2021, 10:43 AM",
  },
  {
    id: 5,
    name: "Admin",
    last_update: "29 April 2021, 10:43 AM",
  },
];

const TambahPeran: React.FC<any> = () => {
  const { t } = useTranslation();
  const statusSelected = useState(1);

  const filterConfig = useState({
    loading: false,

    date_from: moment().subtract(6, "days").format("YYYY-MM-DD"),
    date_to: moment().format("YYYY-MM-DD"),

    date_compare_from: moment().subtract(14, "days").format("YYYY-MM-DD"),
    date_compare_to: moment().subtract(7, "days").format("YYYY-MM-DD"),
  });

  const handleChangeDate = (start: any, end: any) => {
    filterConfig.date_from.set(start.format("YYYY-MM-DD"));
    filterConfig.date_to.set(end.format("YYYY-MM-DD"));
  };

  const handleChangeCompareDate = (start: any, end: any) => {
    filterConfig.date_compare_from.set(start.format("YYYY-MM-DD"));
    filterConfig.date_compare_to.set(end.format("YYYY-MM-DD"));
  };

  const getData = () => {
    filterConfig.loading.set(true);

    setTimeout(() => {
      filterConfig.loading.set(false);
    }, 1000);
  };

  const breadcrumbList = [
    {
      label: t("Order"),
      link: "/order",
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("Order")}</title>
      </Helmet>

      <div className="page-header type-form mb-4">
        <div className="d-flex justify-content-start align-items-center">
          <Link to={"/setting/peran"}>
            <div className={"icon-wrapper"}>
              <FontAwesomeIcon
                icon={faChevronLeft}
                className={"icon icon-prefix"}
              />
            </div>
          </Link>

          <div className="">
            <h4 className="title">Tambah Peran</h4>
          </div>
        </div>
      </div>

      <div className="page-content">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <div className="card-paper mb-4">
              <div className="card-header">
                <div className="card-header-title">
                  <h5>Informasi</h5>
                </div>
              </div>

              <div className="card-content">
                <div className={"form-group"}>
                  <label className={"form-label"}>Nama Peran</label>
                  <input type="text" name="peran" className="form-control" />
                  <label className={"label-help"}></label>
                </div>
              </div>
            </div>
            <div className={"card-paper mt-4"}>
              <div className={"card-content type-button"}>
                <div className={"button-submit-wrapper"}>
                  <div className={"label-wrapper"}>
                    <div className={"title"}>Simpan Data?</div>
                  </div>
                  <div className={"button-group-wrapper"}>
                    <div className={"button-group"}>
                      <div className={"btn btn-sm"}>Batal</div>
                    </div>
                    <div className={"button-group"}>
                      <VuiButton
                        forSubmit={true}
                        label={"Simpan"}
                        loading={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TambahPeran;
