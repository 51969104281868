import ClipLoader from "react-spinners/ClipLoader";
import React from "react";

interface VuiLoaderProps {

}

const VuiLoader: React.FC<VuiLoaderProps> = () => {
    return (
        <div className="loader-overlay">
            <ClipLoader/>
        </div>
    )
}

export default VuiLoader
