/* eslint-disable-line react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import useIsMounted from "../../../../vodea/utilities/hooks/useIsMounted";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "@hookstate/core/dist";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import VuiBreadcrumb from "../../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import React, { useCallback, useEffect, useMemo } from "react";
import VuiActionForm from "../../../../vodea/@vodea-ui/components/Forms/VuiActionForm";
import clsx from "clsx";
import VuiSelect from "../../../../vodea/@vodea-ui/components/Forms/VuiSelect";
import SendPaymentRepository from "../../../../repositories/SendPaymentRepository";
import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import { $clone } from "../../../../vodea/utilities";
import VuiDateRangePicker from "../../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker";
import { formatFormData } from "../../../../vodea/utilities/helpers/form";
import { showToast } from "../../../../vodea/utilities/helpers/global";
import VuiNumberFormat from "../../../../vodea/@vodea-ui/components/VuiNumberFormat";
import VendorRepository from "../../../../repositories/VendorRepository";
import VuiAutoComplete from "../../../../vodea/@vodea-ui/components/VuiAutoComplete";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import Select from "react-select";
import { Col, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import SendPaymentModal from "../../../../components/Modal/SendPaymentModal";
import { printDiv } from "../../../../vodea/utilities/printScreen";
import BankRepository from "../../../../repositories/BankRepository";

const SendPaymentForm = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { id } = useParams();

  const title = id ? t("sendPayment.edit") : t("sendPayment.create");
  const vendorOptions = useState<any[]>([]);
  const summaryData = useState({
    total: 0,
  });
  const errors = useState({
    number: false,
    account_number: false,
    bank_id: false,
    vendor_id: false,
    account_holder_name: false,
  });
  const [paymentData, setPaymentData] = React.useState<any>({});
  const [showPaymentModal, setShowPaymentModal] =
    React.useState<boolean>(false);
  const historyAccountNameOptions = useState<any[]>([]);
  const { brand } = useSelector((state: RootState) => state.brand);
  const [showModalProduct, setShowModalProduct] =
    React.useState<boolean>(false);
  const orderProducts = useState<any[]>([]);
  const historyAccountNumberOptions = useState<any[]>([]);
  const orderOptions = useState<any[]>([]);
  const productData = useState<any[]>([]);

  const handleOpenPaymentModal = useCallback(
    () => setShowPaymentModal(true),
    []
  );

  const informationLoading = useState(false);
  const {
    handleSubmit: informationHandleSubmit,
    errors: informationErrors,
    control: informationControl,
    setValue: informationSetValue,
    watch: informationWatch,
    getValues: informationGetValues,
    register: informationRegister,
  } = useForm({
    defaultValues: {
      number: "",
      vendor_id: null,
      date: moment(),
      due_date: moment(),
      account_number: "",
      account_holder_name: "",
      order_ids: [],
      bank_id: null,
    },
  });

  const watchVendor = informationWatch("vendor_id");

  const getVendorOptions = async () => {
    await VendorRepository.all({
      per_page: 1000,
    }).then((response: any) => {
      vendorOptions.set(response.data.data);
    });
  };

  const getHistoryAccountNameOptions = async (searchValue: string = "") => {
    let params: any = {};
    const brandId = informationGetValues("brand_id");
    const vendorId = informationGetValues("vendor_id");
    const accountNumber = informationGetValues("account_number");
    Object.assign(params, {
      vendor: _.get(vendorId, "id", null),
      brand: _.get(brandId, "id", null),
      "account-number": accountNumber || null,
      search: searchValue || null,
    });
    await SendPaymentRepository.getHistoryAccountName(params).then(
      (response: any) => {
        historyAccountNameOptions.set(response.data.data);
      }
    );
  };

  const getHistoryAccountNumberOptions = async (searchValue: string = "") => {
    let params: any = {};
    const brandId = informationGetValues("brand_id");
    const vendorId = informationGetValues("vendor_id");
    const accountName = informationGetValues("account_holder_name");
    Object.assign(params, {
      vendor: _.get(vendorId, "id", null),
      brand: _.get(brandId, "id", null),
      "account-number": accountName || null,
      search: searchValue || null,
    });
    await SendPaymentRepository.getHistoryAccountNumber(params).then(
      (response: any) => {
        historyAccountNumberOptions.set(response.data.data);
      }
    );
  };

  useEffect(() => {
    getVendorOptions();
  }, []);

  const onInformationSubmit = informationHandleSubmit((data) => {
    informationLoading.set(true);
    const orderIds: any[] = [];
    const arrErrors: any[] = [];
    if (!data?.number) {
      arrErrors.push("number");
    }
    if (!data?.vendor_id) {
      arrErrors.push("vendor");
    }
    if (!data?.bank_id) {
      arrErrors.push("bank_id");
    }
    if (!data?.account_number) {
      arrErrors.push("account_number");
    }
    if (!data?.account_holder_name) {
      arrErrors.push("account_holder_name");
    }

    if (arrErrors.length > 0) {
      if (arrErrors.includes("number")) {
        errors.number.set(true);
      }
      if (arrErrors.includes("vendor")) {
        errors.vendor_id.set(true);
      }
      if (arrErrors.includes("bank_id")) {
        errors.bank_id.set(true);
      }
      if (arrErrors.includes("account_number")) {
        errors.account_number.set(true);
      }
      if (arrErrors.includes("account_holder_name")) {
        errors.account_holder_name.set(true);
      }
      if (isMounted.current) {
        informationLoading.set(false);
      }
      return;
    }

    const formData = formatFormData(data);
    formData.brand_id = brand?.id;
    itemFields.forEach((item: any) => orderIds.push(_.get(item, "order_id")));
    formData.order_ids = orderIds;

    if (id) {
      SendPaymentRepository.update(id, formData)
        .then((response: any) => {
          if (isMounted.current) {
            showToast(t("response.successUpdateData"), "success");
            navigate("/send-payment");
            informationLoading.set(false);
          }
        })
        .catch((error: any) => {
          if (isMounted.current) {
            showToast(error?.response?.data?.message, "error");
            informationLoading.set(false);
          }
        });
    } else {
      SendPaymentRepository.create(formData)
        .then((response: any) => {
          if (isMounted.current) {
            showToast(t("response.successCreateData"), "success");
            navigate("/send-payment");
            informationLoading.set(false);
          }
        })
        .catch((error: any) => {
          if (isMounted.current) {
            showToast(error?.response?.data?.message, "error");
            informationLoading.set(false);
          }
        });
    }
  });

  const {
    fields: itemFields,
    append: itemAppend,
    remove: itemRemove,
  } = useFieldArray({
    keyName: "key",
    name: "order_ids",
    control: informationControl,
  });

  const handleModalProduct = (data: any) => {
    setShowModalProduct(true);
    const tempData: any[] = [];
    if (data.length > 0) {
      data.forEach((item: any) => {
        tempData.push({
          id: _.get(item, "invoice_product.product.id"),
          name: _.get(item, "invoice_product.product.name"),
          quantity: _.get(item, "quantity"),
          amount: _.get(item, "price"),
          total_price: _.get(item, "total_price"),
        });
      });
    }
    orderProducts.set(tempData);
  };

  const getData = useCallback(async () => {
    if (!id) {
      return;
    }

    await SendPaymentRepository.show(id, {
      with: [
        "orders.invoice",
        "vendor",
        "prepares",
        "bank",
        "orders.orderProducts.invoiceProduct.product",
        "orders.orderProducts.invoiceProduct.invoice",
      ],
    }).then((response: any) => {
      const data = response.data.data;
      formattedData(data);
      setPaymentData(data);
      getOrderOptions(data.vendor_id);
      if (data?.orders.length > 0) {
        data.orders.forEach((order: any) => addItem(order));
      }
      _.forEach(data, (value, name) => {
        if (name === "vendor_id") {
          informationSetValue(name, data?.vendor);
        } else if (name === "bank_id") {
          informationSetValue(name, data?.bank);
        } else {
          informationSetValue(name, value);
        }
      });
    });
  }, []);

  const getOrderOptions = async (vendorId: string | number = "") => {
    const vendor = informationGetValues("vendor_id");
    await SendPaymentRepository.select({
      vendor: id ? vendorId : _.get(vendor, "id"),
      brand: brand?.id,
      "send-payment": id || null,
    }).then((response: any) => {
      orderOptions.set(response.data.data);
    });
  };

  const formattedData = (data: any = []) => {
    productData.set([]);
    const tempData: any[] = [];
    _.get(data, "orders", []).forEach((order: any) => {
      order.order_products.forEach((product: any) => {
        tempData.push({
          ...product,
          order_number: order?.number,
        });
      });
    });
    productData.set(tempData);
  };

  useMemo(() => {
    (async () => {
      await getData();
    })();
  }, [id, getData]);

  const orderProductColumns = [
    {
      dataField: "name",
      text: "Nama Produk",
    },
    {
      dataField: "quantity",
      text: "Qty",
    },
    {
      dataField: "amount",
      text: "Amount",
      formatter: (cell: any) => {
        return <VuiNumberFormat data={cell} value={cell} prefix={"IDR "} />;
      },
    },
    {
      dataField: "total_price",
      text: "Total",
      formatter: (cell: any) => {
        return <VuiNumberFormat data={cell} value={cell} prefix={"IDR "} />;
      },
    },
  ];

  const itemColumns = [
    {
      dataField: "number",
      text: "Nomor Pesanan",
    },
    {
      dataField: "amount",
      text: "Amount",
      formatter: (cell: any) => {
        return <VuiNumberFormat data={cell} prefix={"IDR "} />;
      },
    },
    {
      dataField: "action",
      text: "Action",
      headerAlign: "center",
      isDummyField: true,
      align: "center",
      formatter: (cell: any, row: any, rowIndex: number) => {
        return (
          <div className={"table-btn-wrapper"}>
            <button
              type={"button"}
              className={"btn btn-primary btn-small"}
              onClick={() => handleModalProduct(row?.order_products)}
            >
              View
            </button>
            <button
              type={"button"}
              className={"btn btn-danger btn-small"}
              onClick={() => {
                itemRemove(rowIndex);
              }}
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  const addItem = (val: any) => {
    const tempItems = $clone(itemFields);

    if (_.filter(tempItems, (o) => o.order_id == val.id).length === 0) {
      const tempItem = {
        order_id: _.get(val, "id"),
        number: _.get(val, "number"),
        amount: _.get(val, "grand_total"),
        order_products: _.get(val, "order_products"),
      };

      itemAppend(tempItem);
    }
  };

  useEffect(() => {
    calculateSummaryData();
  }, [itemFields.length]);

  const calculateSummaryData = () => {
    setTimeout(() => {
      let tempTotal = {
        total: 0,
      };

      itemFields.forEach((value: any) => {
        tempTotal.total += _.get(value, "amount", 0);
      });

      if (isMounted.current) {
        summaryData.set(tempTotal);
      }
    }, 100);
  };

  const handlePrint = () => {
    printDiv("printable");
  };

  const breadcrumbList = [
    {
      label: t("sendPayment.title"),
      link: "/send-payment",
    },
    {
      label: t("sendPayment.breadcrumb.create"),
      link: "/send-payment/create",
    },
  ];

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      {id && (
        <SendPaymentModal
          show={showPaymentModal}
          setShow={setShowPaymentModal}
          data={paymentData}
          productData={$clone(productData.get())}
          handlePrint={handlePrint}
          totalPrice={$clone(summaryData.total.get())}
        />
      )}

      <div id={"purchase-section"}>
        <div className={"page-header-component"}>
          <h3 className={"title"}>{title}</h3>
          {id && (
            <div className="btn-wrapper">
              <button
                type="button"
                className="btn btn-warning"
                onClick={handleOpenPaymentModal}
              >
                <FontAwesomeIcon icon={faPrint} className="icon icon-prefix" />
                Cetak Pembayaran
              </button>
            </div>
          )}
        </div>
      </div>

      <Modal show={showModalProduct} onHide={() => setShowModalProduct(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Detail Produk</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <BootstrapTable
            wrapperClasses={"table-responsive"}
            keyField="id"
            data={$clone(orderProducts.get())}
            columns={orderProductColumns}
          />
        </Modal.Body>
      </Modal>

      <form className={"form-wrapper"} onSubmit={onInformationSubmit}>
        <div className={"row"}>
          <div className={"col-12"}>
            <div className={"card-paper"}>
              <div className={"card-header"}>
                <h6 className={"card-header-title"}>{t("Information")}</h6>
              </div>
              <div className={"card-content"}>
                <Row>
                  <Col md={6} xs={12}>
                    <div
                      className={clsx({
                        "form-group": true,
                        error: errors.number.get(),
                      })}
                    >
                      <label className={"form-label"}>
                        {t("sendPayment.form.voucherNumber")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name={"number"}
                        ref={informationRegister()}
                      />
                      {errors.number.get() && (
                        <span className={"text-danger"}>
                          nomor voucher is required
                        </span>
                      )}
                    </div>
                  </Col>

                  <Col md={6} xs={12}>
                    <div
                      className={clsx({
                        "form-group": true,
                        error: errors.vendor_id.get(),
                      })}
                    >
                      <label className={"form-label"}>
                        {t("sendPayment.form.vendor")}
                      </label>
                      <Controller
                        name={"vendor_id"}
                        control={informationControl}
                        render={(props) => (
                          <Select
                            options={vendorOptions.get()}
                            getOptionValue={(options) => options["id"]}
                            getOptionLabel={(options) => options["name"]}
                            defaultValue={props.value}
                            value={props.value}
                            onChange={(value) => {
                              props.onChange(value);
                              getOrderOptions();
                              informationSetValue("order_ids", []);
                              informationSetValue("account_number", "");
                              informationSetValue("account_holder_name", "");
                            }}
                          />
                        )}
                      />
                      {errors.vendor_id.get() && (
                        <span className={"text-danger"}>
                          vendor is required
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col md={6} xs={12}>
                    <div
                      className={clsx({
                        "form-group": true,
                        error: informationErrors.date,
                      })}
                    >
                      <label className={"form-label"}>
                        {t("sendPayment.form.date")}
                      </label>
                      <Controller
                        name={"date"}
                        control={informationControl}
                        render={({ value, onChange }) => (
                          <VuiDateRangePicker
                            startDate={value}
                            onChange={onChange}
                            single={true}
                          />
                        )}
                      />
                      <label className={"label-help"}>
                        {_.get(informationErrors.date, "message")}
                      </label>
                    </div>
                  </Col>
                  <Col md={6} xs={12}>
                    <div
                      className={clsx({
                        "form-group": true,
                        error: informationErrors.due_date,
                      })}
                    >
                      <label className={"form-label"}>
                        {t("sendPayment.form.dueDate")}
                      </label>
                      <Controller
                        name={"due_date"}
                        control={informationControl}
                        render={({ value, onChange }) => (
                          <VuiDateRangePicker
                            startDate={value}
                            onChange={onChange}
                            single={true}
                          />
                        )}
                      />
                    </div>
                  </Col>
                  <Col md={6} xs={12}>
                    <div
                      className={clsx({
                        "form-group": true,
                        error: errors.bank_id.get(),
                      })}
                    >
                      <label className={"form-label"}>
                        {t("sendPayment.form.bank")}
                      </label>
                      <Controller
                        name={"bank_id"}
                        control={informationControl}
                        render={(props) => (
                          <VuiSelect
                            selectRepository={BankRepository}
                            defaultValue={props.value}
                            onChange={props.onChange}
                          />
                        )}
                      />
                      {errors.bank_id.get() && (
                        <span className={"text-danger"}>bank is required</span>
                      )}
                    </div>
                  </Col>
                  <Col md={6} xs={12}>
                    <div
                      className={clsx({
                        "form-group": true,
                        error: errors.account_number.get(),
                      })}
                    >
                      <label className={"form-label"}>
                        {t("sendPayment.form.bankAccount")}
                      </label>
                      <Controller
                        name={"account_number"}
                        control={informationControl}
                        render={({ value, onChange, name }) => (
                          <VuiAutoComplete
                            onOpen={getHistoryAccountNumberOptions}
                            options={historyAccountNumberOptions.get()}
                            defaultValue={value}
                            onChange={(e) => {
                              value = e.target.value;
                              onChange(e.target.value);
                              getHistoryAccountNumberOptions(e.target.value);
                            }}
                            onSelect={(val) => {
                              value = val;
                              onChange(val);
                            }}
                          />
                        )}
                      />
                      {errors.account_number.get() && (
                        <span className={"text-danger"}>
                          bank account is required
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col md={6} xs={12}>
                    <div
                      className={clsx({
                        "form-group": true,
                        error: errors.account_holder_name.get(),
                      })}
                    >
                      <label className={"form-label"}>
                        {t("sendPayment.form.bankName")}
                      </label>
                      <Controller
                        name={"account_holder_name"}
                        control={informationControl}
                        render={({ value, onChange, name }) => (
                          <VuiAutoComplete
                            onOpen={getHistoryAccountNameOptions}
                            options={historyAccountNameOptions.get()}
                            defaultValue={value}
                            onChange={(e) => {
                              value = e.target.value;
                              onChange(e.target.value);
                              getHistoryAccountNameOptions(e.target.value);
                            }}
                            onSelect={(val) => {
                              value = val;
                              onChange(val);
                            }}
                          />
                        )}
                      />
                      {errors.account_holder_name.get() && (
                        <span className={"text-danger"}>
                          nama bank is required
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col md={6} xs={12}>
                    <div
                      className={clsx({
                        "form-group": true,
                      })}
                    >
                      <label className={"form-label"}>
                        {t("sendPayment.form.checkNumber")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name={"payment_method_description"}
                        ref={informationRegister()}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className={"row mt-4"}>
          <div className={"col-12"}>
            <div className={"card-paper"}>
              <div className={"card-header"}>
                <h6 className={"card-header-title"}>
                  {t("sendPayment.form.orders")}
                </h6>
              </div>
              <div className={"card-content"}>
                {watchVendor !== null ? (
                  <>
                    <div className="form-group">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        options={$clone(orderOptions.get())}
                        getOptionValue={(options) => options["id"]}
                        getOptionLabel={(options) => options["number"]}
                        value={null}
                        onChange={addItem}
                      />
                    </div>

                    <BootstrapTable
                      wrapperClasses={"table-responsive"}
                      keyField="id"
                      data={$clone(itemFields)}
                      columns={itemColumns}
                    />

                    <div className="summary-data">
                      <h6>Total</h6>
                      <VuiNumberFormat
                        data={summaryData.total.get()}
                        value={summaryData.total.get()}
                      />
                    </div>

                    {/*<div className={"summary-data"}></div>*/}
                  </>
                ) : (
                  <div className="card-empty-content">
                    please select vendor first
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <VuiActionForm
          title={
            id
              ? t("sendPayment.form.textUpdate")
              : t("sendPayment.form.textCreate")
          }
          loading={informationLoading.get()}
          cancelLink={"/send-payment"}
        />
      </form>
    </>
  );
};

export default SendPaymentForm;
