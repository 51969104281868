import * as yup from 'yup';
import 'yup-phone';

export type InformationInputs = {
  role_id: any;
};

export const informationSchema = yup.object().shape({
  role_id: yup.mixed().required(),
});

export const InformationBaseModel: InformationInputs = {
  role_id: {},
};

