export interface SystemState {
  loggedIn: boolean;
  user: any;
}

export const UPDATE_SYSTEM = 'UPDATE_SYSTEM';

interface UpdateSystemAction {
  type: typeof UPDATE_SYSTEM;
  payload: SystemState;
}

export type SystemActionTypes = UpdateSystemAction;
