import * as yup from "yup";
import "yup-phone";

export type InformationInputs = {
  name: string;
  sku: string;
  category_id: string;
  photo_ids: Array<string>;
  sku_channel_ids: Array<string>;
  vendor_price: number;
};

export const informationSchema = yup.object().shape({
  name: yup.string().required(),
  sku: yup.string().required(),
  category_id: yup.mixed().required(),
  photo_ids: yup.mixed().notRequired(),
  sku_channel_ids: yup.mixed().notRequired(),
});
