import { Button, Modal, ModalProps } from "react-bootstrap";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BootstrapTable from "react-bootstrap-table-next";
import NumberFormat from "react-number-format";
import useIsMounted from "../../../vodea/utilities/hooks/useIsMounted";
import InvoiceOfferLogRepository from "../../../repositories/Vendor/InvoiceOfferLogRepository";
import { AxiosError, AxiosResponse } from "axios";
import { showToast } from "../../../vodea/utilities/helpers/global";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";
import { useState } from "@hookstate/core";
import _ from "lodash";
import { $clone } from "../../../vodea/utilities";
import { useNavigate } from "react-router-dom";

export const defaultProductFormatter = (
  cell: any,
  row: any,
  rowIndex: number
) => {
  return (
    <div className="product-wrapper">
      <div>
        <div className="product-name">{row.name}</div>
        <div className="product-label">{row.sku}</div>
      </div>
    </div>
  );
};

interface IncomingOrderModalProps extends ModalProps {
  invoiceLogId: string | number;
  notificationId: string | number;
  show: boolean;
  onContinue: () => void;
}

const IncomingOrderModal: React.FC<IncomingOrderModalProps> = ({
  invoiceLogId,
  notificationId,
  onContinue,
  show = false,
  ...props
}) => {
  const { t } = useTranslation();
  const { vendor } = useSelector((state: RootState) => state.vendor);
  const data = useState<any>({});
  const offerProducts = useState<any[]>([]);
  const totalPrice = useState(0);
  const showState = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (notificationId) {
      getData();
    } else {
      showState.set(false);
    }
  }, [notificationId]); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = () => {
    InvoiceOfferLogRepository.show(vendor.id, invoiceLogId, {
      with: ["invoice", "invoiceOfferProducts.invoiceProduct.product"],
      notif: notificationId,
    })
      .then((response: AxiosResponse) => {
        const resData = response.data.data;

        data.set(resData);

        if (resData.invoice_offer_products) {
          getProducts(resData.invoice_offer_products);
        }

        showState.set(true);
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, "error");

        showState.set(false);
      });
  };

  const getProducts = (products: any[]) => {
    let tempTotalPrice = 0;

    const tempProducts = products.map((item: any) => {
      const total_price = item.quantity * item.price;

      tempTotalPrice += total_price;

      return {
        id: item.id,
        name: item.invoice_product.product.name,
        sku: item.invoice_product.product.sku,
        quantity: item.quantity,
        price: item.price,
        total_price: total_price,
      };
    });

    totalPrice.set(tempTotalPrice);
    offerProducts.set(tempProducts);
  };

  const tableColumns = [
    {
      dataField: "name",
      text: "Produk",
      formatter: defaultProductFormatter,
    },
    {
      dataField: "quantity",
      text: "Jumlah",
      classes: "td-custom",
    },
    {
      dataField: "price",
      text: "Harga",
      classes: "td-custom",
      formatter: (cell: any, row: any, rowIndex: number) => {
        return (
          <NumberFormat
            defaultValue={cell}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            decimalScale={0}
            prefix={"IDR "}
          />
        );
      },
    },
    {
      dataField: "total_price",
      isDummyField: true,
      text: "Total",

      classes: "td-custom",
      formatter: (cell: any, row: any, rowIndex: number) => {
        const total = row.quantity * row.price;
        return (
          <NumberFormat
            defaultValue={total}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            decimalScale={0}
            prefix={"IDR "}
          />
        );
      },
    },
  ];

  const updateStatus = (status: string) => {
    InvoiceOfferLogRepository.updateStatus(vendor.id, invoiceLogId, status)
      .then((response: AxiosResponse) => {
        showToast(t("response.successUpdateData"), "success");
        onContinue();
        showState.set(false);
        if (status === "accept") {
          window.location.assign(`/order/${response.data.data.id}`);
        }
      })
      .catch((e: AxiosError) => {
        showState.set(false);
        if (e?.response?.data.errors) {
          const tempArr: any[] = Object.values(e?.response?.data.errors).flat();
          if (tempArr.length > 0) {
            showToast(tempArr[0], "error");
          }
        } else {
          showToast(e?.response?.data.message, "error");
        }
      });
  };

  return (
    <Modal
      {...props}
      show={showState.get()}
      centered
      backdrop={"static"}
      className="modal-incoming-order"
      size="lg"
    >
      <Modal.Header>
        <h3 className={"title"}>{t("Pesanan Masuk")}</h3>
        <div className="label">
          {_.get(data.get(), "invoice.external_number")}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body-wrapper">
          <div className="mb-4">
            <p className="label">Alamat Pengiriman</p>
            <hr />
            <p>
              {_.get(data.get(), "invoice.recipient_name")}(
              {_.get(data.get(), "invoice.recipient_mobile_phone")})
            </p>
            <p>{_.get(data.get(), "invoice.recipient_address_full")}</p>
          </div>
          <div>
            <p className="label">Item</p>
            <hr />
            <BootstrapTable
              wrapperClasses={"table-responsive"}
              classes={"table-custom"}
              keyField={"id"}
              data={$clone(offerProducts.get())}
              columns={tableColumns}
            />
          </div>
          <hr />
          <div className="footer">
            <div className="label mx-4">Total</div>
            <div className="price">
              <NumberFormat
                value={totalPrice.get()}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={0}
                prefix={"IDR "}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={"danger"} onClick={() => updateStatus("reject")}>
          {t("button.reject")}
        </Button>
        <Button variant={"primary"} onClick={() => updateStatus("accept")}>
          {t("button.accept")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default IncomingOrderModal;
