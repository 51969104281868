import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import VuiBreadcrumb from "../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookmark,
  faBoxes,
  faMoneyCheck,
  faUserLock,
  faUsers,
  faUserTag,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import useRoleService from "../../../vodea/utilities/hooks/useRoleService";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";

const Settings: React.FC<any> = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => {
    return state.system;
  });
  const { isAdministrator, isKeyAccount, isBrand, isVendor } = useRoleService();

  const settingItems = [
    {
      title: t("settings.items.brand.title"),
      subtitle: t("settings.items.brand.subtitle"),
      link: "/settings/brand",
      icon: faBookmark,
      show: isAdministrator() || isKeyAccount(),
    },
    {
      title: t("settings.items.category.title"),
      subtitle: t("settings.items.category.subtitle"),
      link: "/settings/category",
      icon: faBoxes,
      show: isAdministrator() || isKeyAccount(),
    },
    {
      title: t("settings.items.vendor.title"),
      subtitle: t("settings.items.vendor.subtitle"),
      link: "/settings/vendor",
      icon: faWarehouse,
      show: isAdministrator() || isKeyAccount() || isBrand(),
    },
    {
      title: t("settings.items.user.title"),
      subtitle: t("settings.items.user.subtitle"),
      link: "/settings/user",
      icon: faUsers,
      show: isAdministrator(),
    },
    {
      title: t("settings.items.user.title"),
      subtitle: t("settings.items.user.subtitle"),
      link: "/settings/user",
      icon: faUsers,
      show: isBrand() || isVendor(),
      permission: "vendor.user.index",
    },
    {
      title: t("settings.items.role.title"),
      subtitle: t("settings.items.role.subtitle"),
      link: "/settings/role",
      icon: faUserTag,
      show: isBrand() || isVendor(),
      permission: "vendor.role.index",
    },
    {
      title: t("settings.items.role.title"),
      subtitle: t("settings.items.role.subtitle"),
      link: "/settings/role",
      icon: faUserTag,
      show: isAdministrator() || isKeyAccount(),
      permission: "role.index",
    },
    {
      title: t("settings.items.permission.title"),
      subtitle: t("settings.items.permission.subtitle"),
      link: "/settings/permission",
      icon: faUserLock,
      show: isAdministrator() || isKeyAccount(),
      permission: "gate-setting.index",
    },
    {
      title: t("settings.items.permission.title"),
      subtitle: t("settings.items.permission.subtitle"),
      link: "/settings/permission",
      icon: faUserLock,
      show: isBrand() || isVendor(),
      permission: "vendor.gate-setting.index",
    },
    {
      title: t("settings.items.bank.title"),
      subtitle: t("settings.items.bank.subtitle"),
      link: "/settings/bank",
      icon: faMoneyCheck,
      show: isAdministrator(),
      permission: "bank.index",
    },
  ];

  const title = t("settings.title");

  const breadcrumbList = [
    {
      label: title,
      link: "/settings",
    },
  ];

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className="d-flex justify-content-between align-items-center mb-4 ">
        <h3 className={"title"}>{title}</h3>
      </div>

      <div className="page-content">
        <div className="card-setting-widget-wrapper">
          {settingItems.map((item, key) => {
            if (item.show) {
              if (isVendor()) {
                if (user.is_special_account == 0) {
                  if (user.permissions.includes(item.permission)) {
                    return (
                      <Link to={item.link} key={key}>
                        <div className="card-setting-widget">
                          <FontAwesomeIcon
                            icon={item.icon}
                            className="card-icon"
                          />
                          <div className="card-info">
                            <h4 className="card-title">{item.title}</h4>
                            <div className="card-subtitle">{item.subtitle}</div>
                          </div>
                        </div>
                      </Link>
                    );
                  }
                } else {
                  return (
                    <Link to={item.link} key={key}>
                      <div className="card-setting-widget">
                        <FontAwesomeIcon
                          icon={item.icon}
                          className="card-icon"
                        />
                        <div className="card-info">
                          <h4 className="card-title">{item.title}</h4>
                          <div className="card-subtitle">{item.subtitle}</div>
                        </div>
                      </div>
                    </Link>
                  );
                }
              } else {
                return (
                  <Link to={item.link} key={key}>
                    <div className="card-setting-widget">
                      <FontAwesomeIcon icon={item.icon} className="card-icon" />
                      <div className="card-info">
                        <h4 className="card-title">{item.title}</h4>
                        <div className="card-subtitle">{item.subtitle}</div>
                      </div>
                    </div>
                  </Link>
                );
              }
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </>
  );
};

export default Settings;
