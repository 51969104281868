import { api } from "../vodea/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (params: any = null) {
    return api.get(`${endPoint()}/api/account/notification`, {
      params,
    });
  },
  readOne: function (id: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/account/notification/read-one/${id}`, {
      params,
    });
  },
};

export default Repository;
