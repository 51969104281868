export interface BrandState {
  brand: any;
}

export const UPDATE_BRAND = 'UPDATE_BRAND';

interface UpdateBrandAction {
  type: typeof UPDATE_BRAND;
  payload: BrandState;
}

export type BrandActionTypes = UpdateBrandAction;
