import { api, createCancelTokenHandler } from "../../../vodea/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  getRunRate: function (vendorId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/vendor/${vendorId}/widget/product/card/average-run-rate`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getRunRate.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getWOS: function (vendorId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/vendor/${vendorId}/widget/product/card/average-weeks-of-supply`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[this.getWOS.name].handleRequestCancellation()
            .token,
      }
    );
  },
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);
