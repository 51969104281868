import React, { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { VuiButton } from "../../../../vodea/@vodea-ui/components/VuiButton";
import { Controller, useForm } from "react-hook-form";
import VuiSelect from "../../../../vodea/@vodea-ui/components/Forms/VuiSelect";
import clsx from "clsx";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./validation";
import NumberTextField from "../../../../components/NumberTextField";
import StockProductRepository from "../../../../repositories/Brand/StockProductRepository";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import {
  formatFormData,
  handleSaveResponseError,
} from "../../../../vodea/utilities/helpers/form";
import { AxiosError, AxiosResponse } from "axios";
import { IApiResource } from "../../../../interfaces/ApiResource";
import { showToast } from "../../../../vodea/utilities/helpers/global";
import useIsMounted from "../../../../vodea/utilities/hooks/useIsMounted";
import VendorRepository from "../../../../repositories/Brand/VendorRepository";

interface IModalUpdateStock {
  open: boolean;
  onClose: () => void;
  productId: string;
  callback: () => void;
}

interface IProductStock {
  vendor_id: any;
  quantity: number | string;
}

const defaultValues: IProductStock = {
  vendor_id: null,
  quantity: "",
};

const ModalUpdateStock: FC<IModalUpdateStock> = ({
  open,
  onClose,
  productId,
  callback,
}) => {
  const { t } = useTranslation();
  const { brand } = useSelector((state: RootState) => state.brand);
  const isMounted = useIsMounted();
  const [informationLoading, setInformationLoading] = useState<boolean>(false);
  const {
    control: informationControl,
    handleSubmit: informationHandleSubmit,
    reset: informationReset,
    errors: informationErrors,
    setError: informationSetError,
  } = useForm<IProductStock>({
    resolver: yupResolver(validationSchema()),
    defaultValues: defaultValues,
  });

  const onInformationSubmit = informationHandleSubmit(async (data) => {
    if (isMounted.current) setInformationLoading(true);
    const formData = formatFormData(data);
    const productStockData = {
      product_stocks: [formData],
    };

    await StockProductRepository.updateStockVendor(
      brand?.id,
      productId,
      productStockData
    )
      .then((response: AxiosResponse<IApiResource<IProductStock>>) => {
        showToast(t("response.successUpdateData"), "success");
        if (isMounted.current) {
          informationReset(defaultValues);
          setInformationLoading(false);
          if (onClose) onClose();
          if (callback) callback();
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) setInformationLoading(false);
        handleSaveResponseError(error, informationSetError, showToast);
      });
  });

  return (
    <Modal show={open} centered backdrop={"static"} className={"modal-md"}>
      <Modal.Header>
        <h5>{t("Update Stock")}</h5>
      </Modal.Header>
      <Modal.Body>
        <div
          className={clsx({
            "form-group": true,
            error: informationErrors.vendor_id,
          })}
        >
          <div className="form-label">Vendor</div>
          <Controller
            name={"vendor_id"}
            control={informationControl}
            render={(props) => (
              <VuiSelect
                selectRepository={VendorRepository}
                selectParentId={brand?.id}
                defaultValue={props.value}
                onChange={props.onChange}
              />
            )}
          />
          {informationErrors.vendor_id && (
            <span className={"text-danger"}>
              {informationErrors.vendor_id.message}
            </span>
          )}
        </div>

        <div
          className={clsx({
            "form-group": true,
            error: informationErrors.quantity,
          })}
        >
          <div className="form-label">Quantity</div>
          <Controller
            name={"quantity"}
            control={informationControl}
            render={({ name, value, onChange }) => (
              <NumberTextField
                defaultValue={value}
                onValueChange={({ value }) => onChange(value)}
                name={name}
                isError={informationErrors.quantity}
                errorMessage={informationErrors.quantity?.message}
              />
            )}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <VuiButton
          variant={"secondary"}
          customClass={"btn-sm-full-width"}
          label={t("button.cancel")}
          loading={false}
          onClick={onClose}
        />
        <VuiButton
          onClick={onInformationSubmit}
          label={t("button.submit")}
          loading={informationLoading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalUpdateStock;
