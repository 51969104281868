import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useIsMounted from "../../../../vodea/utilities/hooks/useIsMounted";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import { Helmet } from "react-helmet-async";
import VuiBreadcrumb from "../../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import { Link, useParams } from "react-router-dom";
import BrandInvoiceRepository from "../../../../repositories/Brand/InvoiceRepository";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import {
  getInvoiceStatusConfig,
  getOrderStatusConfig,
  showToast,
} from "../../../../vodea/utilities/helpers/global";
import { useState } from "@hookstate/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faPrint } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import moment from "moment";
import NumberFormat from "react-number-format";
import { Accordion, useAccordionToggle } from "react-bootstrap";
import { $clone } from "../../../../vodea/utilities";
import { useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";

const InvoiceShow: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { brand } = useSelector((state: RootState) => state.brand);
  const data = useState<any>(null);
  const orders = useState<any[]>([]);
  const offerLogs = useState<any[]>([]);

  const offerLogsColumn = [
    { dataField: "vendor.name", text: "Nama Vendor" },
    {
      dataField: "created_at",
      text: "Timestamp",
      formatter: (cell: any) => {
        return moment(cell).format("D MMMM YYYY h:mm A");
      },
    },
  ];

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = () => {
    BrandInvoiceRepository.show(brand.id, id, {
      with: [
        "channel",
        "orders.orderProducts.invoiceProduct.product",
        "orders.orderActivities",
        "orders.vendor",
        "invoiceOfferLogs.vendor",
      ],
    })
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          data.set(response.data.data);

          if (response.data.data.invoice_offer_logs.length > 0) {
            offerLogs.set(response.data.data.invoice_offer_logs);
          }

          if (response.data.data.orders) {
            orders.set(response.data.data.orders);
          }
        }
      })
      .catch((e: AxiosError) => {
        showToast(e.message, "error");
      });
  };

  function CustomToggle({ children, eventKey }: any) {
    const decoratedOnClick = useAccordionToggle(eventKey, () => {});

    return <div onClick={decoratedOnClick}>{children}</div>;
  }

  const breadcrumbList = [
    {
      label: t("invoice.title"),
      link: "/invoice",
    },
    {
      label: _.get(data.get(), "external_number", ""),
      link: `/invoice/${id}`,
    },
  ];

  return (
    <>
      <Helmet>
        <title>{_.get(data.get(), "external_number", "")}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={"page-header-component"}>
        <h3 className={"title"}>{_.get(data.get(), "external_number", "")}</h3>
      </div>

      <div className="default-page-layout type-profile">
        <div className="information-section">
          <div className="card-paper mb-4">
            <div className="card-header">
              <h5 className="card-header-title">
                {t("invoice.invoiceDetail")}
              </h5>
            </div>

            <div className="card-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <div className="text-muted">
                      {t("form.invoiceNumber.label")}
                    </div>
                    <div className="">
                      {_.get(data.get(), "external_number", "")}
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="mb-3">
                    <div className="text-muted">{t("form.customer.label")}</div>
                    <div className="">
                      <label
                        className="link-primary"
                        onClick={() => {
                          navigate(
                            `/customer/${_.get(
                              data.get(),
                              "customer_name",
                              ""
                            )}`,
                            {
                              state: {
                                customer_name: _.get(
                                  data.get(),
                                  "customer_name",
                                  ""
                                ),
                                customer_phone: _.get(
                                  data.get(),
                                  "customer_phone",
                                  ""
                                ),
                                customer_email: _.get(
                                  data.get(),
                                  "customer_email",
                                  ""
                                ),
                              },
                            }
                          );
                        }}
                      >
                        {_.get(data.get(), "customer_name", "")}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3">
                    <div className="text-muted">{t("form.status.label")}</div>
                    <div className="">
                      <div
                        className={clsx([
                          "badge",
                          getInvoiceStatusConfig(
                            _.get(data.get(), "status", "")
                          )?.badge,
                        ])}
                      >
                        {_.get(data.get(), "status", "")}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3">
                    <div className="text-muted">
                      {t("form.transactionDate.label")}
                    </div>
                    <div className="">
                      {moment(_.get(data.get(), "created_at", "")).format(
                        "D MMMM YYYY h:mm A"
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3">
                    <div className="text-muted">{t("form.channel.label")}</div>
                    <div className="">
                      {_.get(data.get(), "channel.name", "")}
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3">
                    <div className="text-muted">
                      {t("form.paymentMethod.label")}
                    </div>
                    <div>{_.get(data.get(), "payment_method", "")}</div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3">
                    <div className="text-muted">
                      {t("form.paymentDate.label")}
                    </div>
                    <div>
                      {moment(_.get(data.get(), "payment_date", "")).format(
                        "D MMMM YYYY h:mm A"
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <div className="text-muted">
                      {t("form.shippingMethod.label")}
                    </div>
                    <div>{_.get(data.get(), "delivery_type", "")}</div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3">
                    <div className="text-muted">
                      {t("form.shippingFee.label")}
                    </div>
                    <div>
                      <NumberFormat
                        value={_.get(data.get(), "shipping_cost", "")}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={0}
                        prefix={"IDR "}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <div className="text-muted">
                      {t("form.insuranceCost.label")}
                    </div>
                    <div>
                      <NumberFormat
                        value={_.get(data.get(), "insurance_cost", 0)}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={0}
                        prefix={"IDR "}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3">
                    <div className="text-muted">
                      {" "}
                      {t("form.totalItemPrice.label", {
                        number: _.get(data.get(), "total_product", ""),
                      })}
                    </div>
                    <div>
                      <NumberFormat
                        value={_.get(data.get(), "subtotal", "")}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={0}
                        prefix={"IDR "}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <div className="text-muted">Diskon</div>
                    <div>
                      <NumberFormat
                        value={_.get(data.get(), "voucher_amount", 0)}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={0}
                        prefix={"IDR "}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3">
                    <div className="text-muted">Cashback</div>
                    <div>
                      <NumberFormat
                        value={_.get(data.get(), "cashback_amount", "")}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={0}
                        prefix={"IDR "}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <div className="text-muted">{t("form.totalPrice.label")}</div>
                <div className="fs-4">
                  <strong>
                    <NumberFormat
                      value={_.get(data.get(), "grand_total", "")}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={0}
                      prefix={"IDR "}
                    />
                  </strong>
                </div>
              </div>
            </div>
          </div>

          {orders.get().length > 0 ? (
            <div>
              <h5>{t("invoice.listOrder")}</h5>
              <div className="border-bottom mb-3"></div>

              {$clone(orders.get()).map((order: any, key: number) => {
                const orderActivities = _.reverse(
                  _.get(order, "order_activities", [])
                );
                const orderProducts = _.get(order, "order_products", []);

                const totalProductPrice = _.sumBy(orderProducts, "total_price");

                return (
                  <div className="card-paper mb-4" key={key}>
                    <div className="card-header">
                      <Link to={`/order/${order.id}`}>
                        <h5 className="card-header-title">
                          {_.get(order, "number")}
                        </h5>
                      </Link>
                    </div>

                    <div className="card-content">
                      <div className={"row"}>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <div className="text-muted">
                              {t("form.vendor.label")}
                            </div>
                            <div>{_.get(order, "vendor.name", "")}</div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-3">
                            <div className="text-muted">
                              {t("form.status.label")}
                            </div>
                            <div>
                              <div
                                className={clsx([
                                  "badge",
                                  getOrderStatusConfig(
                                    _.get(order, "status", "")
                                  )?.badge,
                                ])}
                              >
                                {_.get(order, "status", "")}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="mb-3">
                            <div className="text-muted">
                              {t("form.transactionDate.label")}
                            </div>
                            <div>
                              {moment(_.get(order, "created_at", "")).format(
                                "D MMMM YYYY h:mm A"
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="table-responsive">
                        <table className="table table-bordered vendor-table">
                          <thead>
                            <tr>
                              <th>{t("table.product.label")}</th>
                              <th>{t("table.total.label")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderProducts.map(
                              (product: any, productKey: number) => {
                                const productDetail = _.get(
                                  product,
                                  "invoice_product.product"
                                );

                                return (
                                  <tr key={productKey}>
                                    <td>
                                      <div className="product-info">
                                        <p className="name">
                                          {_.get(productDetail, "name")}
                                        </p>
                                        <table>
                                          <tbody>
                                            <tr>
                                              <td>{t("table.sku.label")}</td>
                                              <td>:</td>
                                              <td>
                                                {_.get(productDetail, "sku")}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                {t("table.quantity.label")}
                                              </td>
                                              <td>:</td>
                                              <td>
                                                {_.get(product, "quantity")}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>{t("table.price.label")}</td>
                                              <td>:</td>
                                              <td>
                                                <NumberFormat
                                                  value={_.get(
                                                    product,
                                                    "price",
                                                    0
                                                  )}
                                                  displayType={"text"}
                                                  thousandSeparator={"."}
                                                  decimalSeparator={","}
                                                  decimalScale={0}
                                                  prefix={"IDR "}
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                    <td>
                                      <NumberFormat
                                        value={_.get(product, "total_price", 0)}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        decimalScale={0}
                                        prefix={"IDR "}
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            )}

                            <tr
                              style={{
                                verticalAlign: "middle",
                              }}
                            >
                              <td colSpan={1}>
                                <div className="total">
                                  {t("table.total.label")}
                                </div>
                              </td>
                              <td>
                                <div className="fs-5">
                                  <strong>
                                    <NumberFormat
                                      value={totalProductPrice}
                                      displayType={"text"}
                                      thousandSeparator={"."}
                                      decimalSeparator={","}
                                      decimalScale={0}
                                      prefix={"IDR "}
                                    />
                                  </strong>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="timeline-activity">
                        <Accordion defaultActiveKey={`"${key}"`}>
                          <CustomToggle eventKey={`"${key}"`}>
                            <label className="header">
                              Aktifitas Pesanan{" "}
                              <FontAwesomeIcon
                                icon={faAngleRight}
                                className="icon"
                              />
                            </label>
                          </CustomToggle>

                          <Accordion.Collapse eventKey={`"${key}"`}>
                            <div className="timeline-wrapper">
                              {orderActivities.map(
                                (activity: any, activityKey: number) => {
                                  return (
                                    <div className="item" key={activityKey}>
                                      <label className="date">
                                        {moment(
                                          _.get(activity, "timestamp", "")
                                        ).format("D MMMM YYYY")}
                                        <br />
                                        {moment(
                                          _.get(activity, "timestamp", "")
                                        ).format("h:mm A")}
                                      </label>
                                      <span className="dot active" />
                                      <label className="info">
                                        {activity.message}
                                      </label>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </Accordion.Collapse>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              <h5>{t("invoice.listOffer")}</h5>
              <div className="border-bottom mb-3"></div>

              <div className={"card-paper"}>
                <div className={"card-content"}>
                  <BootstrapTable
                    wrapperClasses={"table-responsive"}
                    classes={"table-custom mb-unset"}
                    keyField={"id"}
                    data={$clone(offerLogs.get())}
                    columns={offerLogsColumn}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="customer-section">
          <div className="card-paper mb-4">
            <div className="card-header">
              <h5 className="card-header-title">
                {t("invoice.shippingDetail")}
              </h5>
            </div>

            <div className="card-content">
              <div className="mb-4">
                <div className="text-muted">
                  {t("form.recipientName.label")}
                </div>
                <div>{_.get(data.get(), "recipient_name", "")}</div>
              </div>
              <div className="mb-4">
                <div className="text-muted">{t("form.telephone.label")}</div>
                <div>{_.get(data.get(), "recipient_mobile_phone", "")}</div>
              </div>
              <div className="mb-4">
                <div className="text-muted">
                  {t("form.recipientAddress.label")}
                </div>
                <div>{_.get(data.get(), "recipient_address_full", "")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceShow;
