/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../stores";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "@hookstate/core/dist";
import VuiHeaderNotification from "../../vodea/@vodea-ui/components/Header/VuiHeaderNotification";
import VuiHeaderProfile from "../../vodea/@vodea-ui/components/Header/VuiHeaderProfile";
import VuiSidebar from "../../vodea/@vodea-ui/components/VuiSidebar";
import VuiHeaderSearch from "../../vodea/@vodea-ui/components/Header/VuiHeaderSearch";
import { usePassportService } from "../../vodea/utilities/hooks";
import VuiLoadingScreen from "../../vodea/@vodea-ui/components/VuiLoadingScreen";
import _ from "lodash";
import BrandSelector from "../../components/BrandSelector";
import useRoleService from "../../vodea/utilities/hooks/useRoleService";

const LayoutApp: React.FC<any> = () => {
  const { isOnFetchingUser } = usePassportService();
  const { loggedIn, user } = useSelector((state: RootState) => state.system);
  const { isAdministrator, isKeyAccount, isVendor } = useRoleService();

  const showSidebar = useState(false);

  if (isOnFetchingUser) return <VuiLoadingScreen />;

  if (!loggedIn) {
    return <Navigate to={"/auth/login"} replace />;
  }

  return (
    <section id={"app-layout"}>
      <div className={"app-header"}>
        <div className={"brand-wrapper"}>
          <div
            className={"mobile-menu"}
            onClick={() => {
              showSidebar.set(!showSidebar.get());
            }}
          >
            <FontAwesomeIcon className={"menu-icon"} icon={faBars} />
          </div>

          <img className={"logo"} src={"/logo-full.png"} alt={"logo"} />

          {isVendor() ? (
            <label className="brand-vendor">
              {_.get(user, "vendor_name", "")}
            </label>
          ) : (
            ""
          )}
        </div>

        <div className={"navigation-wrapper"}>
          {isAdministrator() || isKeyAccount() ? (
            <div className="navigation-item">
              <div className="brand-selector-wrapper hide-sm">
                <BrandSelector />
              </div>
            </div>
          ) : null}

          {/*<div className={"navigation-item"}>
            <VuiHeaderSearch />
          </div>*/}

          <div className={"navigation-item"}>
            <VuiHeaderNotification />
          </div>

          <div className={"navigation-item"}>
            <VuiHeaderProfile />
          </div>
        </div>
      </div>

      <div className={"app-header-separator"} />

      <VuiSidebar
        show={showSidebar.get()}
        onClose={() => {
          showSidebar.set(false);
        }}
      />

      <div className={"app-content"}>
        <div className={"app-container"}>
          <Outlet />
        </div>
      </div>
    </section>
  );
};

export default LayoutApp;
