import * as yup from "yup";
import "yup-phone";

export type InformationInputs = {
  name: string;
  telephone: string;
  fax: string;
  city: string;
  postal_code: string;
  address: string;
  lat: string;
  lng: string;
  channels: ChannelInputs[];
};

export type ChannelInputs = {
  channel_id: string | number;
  channel_meta: IChannelMeta;
};

export interface IChannelMeta {
  is_tkpd_official: boolean;
}

export const informationSchema = yup.object().shape({
  name: yup.string().required(),
  telephone: yup.string().phone("ID", true).required().label("phone number"),
  fax: yup.string().phone("ID", true).required().label("fax number"),
  city: yup.string().required(),
  postal_code: yup
    .string()
    .matches(/^\d+$/, {
      message: "Postal Code must match number only",
    })
    .length(5)
    .required()
    .label("Postal Code"),
  lat: yup.string().required(),
  lng: yup.string().required(),
  address: yup.string(),
});

export const InformationBaseModel: InformationInputs = {
  name: "",
  telephone: "",
  fax: "",
  city: "",
  postal_code: "",
  lat: "",
  lng: "",
  address: "",
  channels: [],
};
