import { Helmet } from "react-helmet-async";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExcel,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { TableChangeHandler } from "react-bootstrap-table-next";
import moment from "moment";
import { $clone } from "../../../vodea/utilities";
import { useState } from "@hookstate/core/dist";
import VuiBreadcrumb from "../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import { Link, useNavigate } from "react-router-dom";
import VuiActionModal from "../../../vodea/@vodea-ui/components/Modal/VuiActionModal";
import { AxiosError, AxiosResponse } from "axios";
import { showToast } from "../../../vodea/utilities/helpers/global";
import _ from "lodash";
import useIsMounted from "../../../vodea/utilities/hooks/useIsMounted";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";
import VuiDataTable from "../../../vodea/@vodea-ui/components/VuiDataTable";
import SendPaymentRepository from "../../../repositories/SendPaymentRepository";
import VuiDateRangePicker from "../../../vodea/@vodea-ui/components/Forms/VuiDateRangePicker";
import BrandVendorRepository from "../../../repositories/Brand/VendorRepository";
import MultipleCheckboxes from "../../../components/MultipleCheckboxes";
import BrandRepository from "../../../repositories/BrandRepository";
import ExportBrandRepository from "../../../repositories/Export/Brand/ExportBrandRepositories";
import fileDownload from "js-file-download";
import BrandChannelRepository from "../../../repositories/Brand/ChannelRepository";

const SendPayment: React.FC<any> = () => {
  const { t } = useTranslation();
  const showActionModal = useState(false);
  const isMounted = useIsMounted();
  const navigate = useNavigate();

  const tableDeleteId = useState(0);
  const selectedChannels = useState<any[]>([]);
  const selectedVendors = useState<any[]>([]);
  const vendorOptions = useState<any[]>([]);
  const channelOptions = useState<any[]>([]);
  const selectedBrands = useState<any[]>([]);
  const brandOptions = useState<any[]>([]);
  const tableData = useState<any[]>([]);

  const { brand } = useSelector((state: RootState) => state.brand);

  const tableConfig = useState({
    search: "",
    page: 1,
    per_page: 10,
    sorted_by: "desc",
    order_by: "created_at",
    total: 0,
    loading: false,

    date_from: moment().format("YYYY-MM-DD"),
    date_to: moment().format("YYYY-MM-DD"),
  });

  const tableHandleOnContinue = (e: React.MouseEvent) => {
    SendPaymentRepository.delete(tableDeleteId.get(), brand?.id)
      .then((response: AxiosResponse) => {
        showToast(
          response.data.message,
          response.data.success ? "success" : "error"
        );
        showActionModal.set(false);
        getTableData();
      })
      .catch((e: AxiosError) => {
        showToast(e?.response?.data.message, "error");
      });
  };

  const changeTableLoading = (val: boolean) => {
    if (isMounted.current) {
      tableConfig.loading.set(val);
    }
  };

  const getTableData = (reset: boolean = false) => {
    if (reset) tableConfig.page.set(1);

    changeTableLoading(true);

    const conf = _.omit($clone(tableConfig.value), "loading");

    let params: any = {};
    Object.keys(conf).forEach((prop) => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    Object.assign(params, {
      "date-from": params.date_from,
      "date-to": params.date_to,
      vendor: $clone(selectedVendors.get()),
      brand: $clone(selectedBrands.get()),
    });

    delete params.date_from;
    delete params.date_to;

    tableData.set([]);

    SendPaymentRepository.all(params)
      .then((response: any) => {
        if (isMounted.current) {
          tableData.set(response.data.data);
          tableConfig.total.set(response.data.meta.total);
          changeTableLoading(false);
        }
      })
      .catch((e: AxiosError) => {
        if (isMounted.current) {
          if (e.isAxiosError) showToast(e.message, "error");

          changeTableLoading(false);
        }
      });
  };

  const getVendorOptions = async () => {
    await BrandVendorRepository.all(brand.id, {
      per_page: 1000,
    }).then((response: AxiosResponse) => {
      if (isMounted.current) {
        const options = response.data.data;
        vendorOptions.set(options);

        selectedVendors.set(
          options.map((item: any) => {
            return item.id;
          })
        );
      }
    });
  };

  const getBrandOptions = async () => {
    await BrandRepository.all({
      per_page: 1000,
    }).then((response: AxiosResponse) => {
      if (isMounted.current) {
        const options = response.data.data;
        brandOptions.set(options);

        selectedBrands.set(
          options.map((item: any) => {
            return item.id;
          })
        );
      }
    });
  };

  const getChannelOptions = async () => {
    await BrandChannelRepository.all(brand.id, {
      per_page: 1000,
    }).then((response: AxiosResponse) => {
      if (isMounted.current) {
        const options = response.data.data;
        channelOptions.set(options);

        selectedChannels.set(
          options.map((item: any) => {
            return item.id;
          })
        );
      }
    });
  };

  const handleExport = () => {
    let params: any = {};
    Object.assign(params, {
      vendor: $clone(selectedVendors.get()),
      brand: $clone(selectedBrands.get()),
      "date-from": $clone(_.get(tableConfig.get(), "date_from")),
      "date-to": $clone(_.get(tableConfig.get(), "date_to")),
    });
    SendPaymentRepository.export(params).then((response: any) => {
      const fileName = `${moment().format(
        "YYYY-MM-DD HH:mm:ss"
      )} pembayaran.xlsx`;
      fileDownload(response.data, fileName);
    });
  };

  const tableOnClickDelete = (id: number) => {
    tableDeleteId.set(id);
    showActionModal.set(true);
  };

  const tableColumns = [
    {
      dataField: "number",
      text: "Nomor Voucher",
      classes: "valign-middle",
    },
    {
      dataField: "vendor_name",
      text: "Vendor",
      classes: "valign-middle",
      formatter: (cell: any) => {
        return <div>{cell || "-"}</div>;
      },
    },
    {
      dataField: "brand_name",
      text: "Brand",
      classes: "valign-middle",
      formatter: (cell: any) => {
        return <div>{cell || "-"}</div>;
      },
    },
    {
      dataField: "created_at",
      text: "Date",
      classes: "valign-middle",
      formatter: (cell: any) => {
        return moment(cell).format("DD MMMM YYYY, HH:mm");
      },
    },
    {
      dataField: "action",
      text: "Action",
      headerAlign: "center",
      isDummyField: true,
      align: "center",
      classes: "valign-middle",
      formatter: (cell: any, row: any) => {
        return (
          <div className={"table-btn-wrapper"}>
            <button
              className={"btn btn-primary btn-small"}
              onClick={() => {
                navigate(row.id);
              }}
            >
              View
            </button>
            <button
              className={"btn btn-danger btn-small"}
              onClick={() => tableOnClickDelete(row.id)}
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  const onTableChange: TableChangeHandler<any> = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    tableConfig.page.set(page);
    tableConfig.per_page.set(sizePerPage);
    tableConfig.order_by.set(sortField);
    tableConfig.sorted_by.set(sortOrder);

    getTableData();
  };

  const handleTableSearch = _.debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      tableConfig.search.set(e.target.value);
      tableConfig.page.set(1);

      setTimeout(() => {
        changeTableLoading(true);
      }, 400);

      getTableData();
    },
    300
  );

  const handleChangeDate = (start: any, end: any) => {
    tableConfig.date_from.set(start.format("YYYY-MM-DD"));
    tableConfig.date_to.set(end.format("YYYY-MM-DD"));

    getTableData();
  };

  useEffect(() => {
    getVendorOptions();
    getBrandOptions();
    getChannelOptions();
    getTableData();
  }, []);

  const breadcrumbList = [
    {
      label: t("sendPayment.title"),
      link: "/send-payment",
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("sendPayment.title")}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={"page-header-component"}>
        <div className="title-action-wrapper">
          <h3 className={"title"}>{t("sendPayment.title")}</h3>
        </div>

        <div className="action-wrapper">
          <button
            type="button"
            className="btn btn-success action-item"
            onClick={handleExport}
          >
            <FontAwesomeIcon icon={faFileExcel} className="icon icon-prefix" />
            {t("button.export")}
          </button>
          <Link
            to={"/send-payment/create"}
            type={"button"}
            className={"btn btn-primary action-item"}
          >
            <FontAwesomeIcon className={"icon icon-prefix"} icon={faPlus} />
            {t("sendPayment.create")}
          </Link>
        </div>
      </div>

      <div className="card-paper mb-4">
        <div className="card-content">
          <div
            className={
              "default-filter-section justify-content-between mb-unset"
            }
          >
            <div className={"filter-item filter-special type-search"}>
              <div className="input-group prefix-group">
                <span className="input-group-text">
                  <FontAwesomeIcon
                    className={"icon icon-prefix"}
                    icon={faSearch}
                  />
                </span>
                <input
                  defaultValue={tableConfig.search.get()}
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={handleTableSearch}
                />
              </div>
            </div>
            <div className={"filter-item filter-special"}>
              <VuiDateRangePicker
                startDate={tableConfig.date_from.get()}
                endDate={tableConfig.date_to.get()}
                callback={handleChangeDate}
                useRanges={true}
              />
            </div>
          </div>

          <div className={"default-filter-section"}>
            <div className={"filter-item filter-special"}>
              <div className={"form-group mb-unset"}>
                <MultipleCheckboxes
                  name={"vendors"}
                  options={$clone(vendorOptions.get())}
                  values={$clone(selectedVendors.get())}
                  labelOption={"name"}
                  valueOption={"id"}
                  onChange={(values) => {
                    selectedVendors.set(values);
                    getTableData();
                  }}
                />
              </div>
            </div>

            <div className={"filter-item filter-special"}>
              <div className={"form-group mb-unset"}>
                <MultipleCheckboxes
                  name={"brands"}
                  options={$clone(brandOptions.get())}
                  values={$clone(selectedBrands.get())}
                  labelOption={"name"}
                  valueOption={"id"}
                  onChange={(values) => {
                    selectedBrands.set(values);
                    getTableData();
                  }}
                />
              </div>
            </div>

            <div className={"filter-item filter-special"}>
              <div className={"form-group mb-unset"}>
                <MultipleCheckboxes
                  name={"channels"}
                  options={$clone(channelOptions.get())}
                  values={$clone(selectedChannels.get())}
                  labelOption={"name"}
                  valueOption={"id"}
                  onChange={(values) => {
                    selectedChannels.set(values);
                    getTableData(true);
                  }}
                />
              </div>
            </div>
          </div>

          <VuiDataTable
            loading={tableConfig.loading.get()}
            columns={tableColumns}
            data={tableData.get()}
            page={tableConfig.page.get()}
            sizePerPage={tableConfig.per_page.get()}
            totalSize={tableConfig.total.get()}
            onTableChange={onTableChange}
          />
        </div>
      </div>

      <VuiActionModal
        show={showActionModal.get()}
        onHide={() => showActionModal.set(false)}
        onContinue={tableHandleOnContinue}
      />
    </>
  );
};

export default SendPayment;
