import React, {useEffect} from "react";
import VuiSelect from "../../vodea/@vodea-ui/components/Forms/VuiSelect";
import BrandRepository from "../../repositories/BrandRepository";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../stores";
import {useState} from "@hookstate/core";
import {updateBrand} from "../../stores/brand/actions";
import AccountRepository from "../../repositories/AccountRepository"
import {AxiosError, AxiosResponse} from "axios";


const BrandSelector: React.FC<any> = () => {
    const dispatch = useDispatch();
    const {brand} = useSelector((state: RootState) => state.brand);
    const selectedBrand = useState(brand)

    useEffect(() => {
        selectedBrand.set(brand)
    }, [brand])

    const handleChange = async (value: any) => {
        await AccountRepository.changeBrand({
            brand_id: value.id
        }).then((res: AxiosResponse) => {
            selectedBrand.set(value);

            dispatch(
                updateBrand({
                    brand: value
                })
            )

            window.location.reload()
        }).catch((err: AxiosError) => {

        })
    }

    return (
        <>
            <VuiSelect selectRepository={BrandRepository} defaultValue={selectedBrand.get()} onChange={handleChange}/>
        </>
    )
}

export default BrandSelector
