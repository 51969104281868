import authRoutes from './auth';
import appRoutes from './app';
import NotFound from '../pages/404';

const routes = [
  ...authRoutes,
  ...appRoutes,
  {
    path: '*',
    element: <NotFound />
  }
];

export default routes;
