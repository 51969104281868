import _ from "lodash";
import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import DateTime from "../../DateTime";

interface ModalProps {
  show: boolean;
  setShow: any;
  data: any;
  logo: any;
  handlePrint: any;
  products: any;
}

function PrintOrderModal(props: ModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      size="lg"
      show={props.show}
      onHide={() => props.setShow(false)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton id="printInvoice" />
      <Modal.Body id="printable" className="p-0">
        {props.data.invoice && (
          <div className="d-flex flex-column">
            <div className="row p-4">
              <div className="col-6">
                <div>
                  {" "}
                  <img
                    alt={props.logo}
                    src={props.logo}
                    style={{ height: "100%", width: "15vw" }}
                  />
                </div>
                <div className="mb-2 mt-2">
                  <strong className="fs-6 ">Nomor Invoice</strong> :{" "}
                  {props.data.invoice.external_number}
                </div>

                <div className="d-flex flex-column">
                  <div className="mb-2">
                    <strong>Diterbitkan atas nama :</strong>{" "}
                  </div>
                  <div className="row">
                    <div className="col-5">
                      <div>
                        <strong>Penjual</strong>
                      </div>
                    </div>
                    <div className="col-7">
                      <div className="fw-bold">{props.data.brand?.name}</div>
                    </div>
                    <div className="col-5">
                      <div>
                        <strong>Tanggal</strong>
                      </div>
                    </div>
                    <div className="col-7">
                      <div className="fw-bold">
                        {moment(props.data.date).format("DD MMMM YYYY")}
                      </div>
                    </div>
                    {props.data?.invoice?.preorder_process_day && (
                      <>
                        <div className="col-5">
                          <div>
                            <strong>Batas Waktu</strong>
                          </div>
                        </div>
                        <div className="col-7">
                          <div className="fw-bold">
                            <DateTime
                              data={props.data?.invoice?.preorder_deadline}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="d-flex flex-column">
                  <div className="mb-2 fw-bold fs-5">Tujuan Pengiriman : </div>
                  <div className="d-flex flex-column">
                    <div className="fw-bold">
                      <>{props.data.invoice.recipient_name}</>
                    </div>
                    <div className="fw-bold">
                      {props.data.invoice.recipient_address_full}
                    </div>
                    <div className="fw-bold">
                      {props.data.invoice.recipient_mobile_phone}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row p-4">
              {props.data.invoice.recipient_name ? (
                <div className="col-12 col-md-6" />
              ) : (
                ""
              )}
              <div className="col-12 col-md-6" />
            </div>

            <hr />

            <div className="p-4">
              <div className="table-responsive w-100">
                <table className="table table-bordered vendor-table">
                  <thead>
                    <tr>
                      <th>{t("printOrder.table.productName")}</th>
                      <th>{t("printOrder.table.quantity")}</th>

                      <th>{t("printOrder.table.weight")}</th>
                      <th>{t("printOrder.table.price")}</th>

                      <th>{t("printOrder.table.subtotal")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.data?.order_products.length > 0 &&
                      props.data?.order_products.map(
                        (product: any, productKey: number) => {
                          return (
                            <tr key={productKey}>
                              <td>
                                <div className="product-info">
                                  <p className="name">
                                    {_.get(
                                      product,
                                      "invoice_product.product.name",
                                      "-"
                                    )}
                                  </p>
                                  <p className="text-muted">
                                    SKU -{" "}
                                    {_.get(
                                      product,
                                      "invoice_product.product.sku",
                                      "-"
                                    )}
                                  </p>
                                </div>
                              </td>
                              <td>{`${product.quantity} pcs`}</td>
                              <td>{`${product.total_weight} kg `} </td>
                              <td>
                                <NumberFormat
                                  value={product.price}
                                  displayType={"text"}
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  decimalScale={0}
                                  prefix={`${t("printOrder.table.currency")} `}
                                />
                              </td>{" "}
                              <td>
                                <NumberFormat
                                  value={product.total_price}
                                  displayType={"text"}
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  decimalScale={0}
                                  prefix={`${t("printOrder.table.currency")} `}
                                />
                              </td>
                            </tr>
                          );
                        }
                      )}

                    <tr
                      style={{
                        verticalAlign: "middle",
                      }}
                    >
                      <td colSpan={4}>
                        <div className="total me-5">
                          {t("printOrder.table.subtotal2")}
                        </div>
                      </td>
                      <td>
                        <div className="fs-6">
                          <strong>
                            <NumberFormat
                              value={_.sumBy(props.products, "total_price")}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              decimalScale={0}
                              prefix={`${t("printOrder.table.currency")} `}
                            />
                          </strong>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row p-4">
              <div className="col-4" />
              <div className="col-12 col-md-8 border border-2 p-3">
                <div className="row">
                  <div className="col-8 col-md-8">
                    {props.data.invoice.delivery_type} (Berat{" "}
                    {props.data.total_weight} kg)
                  </div>
                  <div className="col-4 col-md-4">
                    {`${t("printOrder.table.currency")} `}{" "}
                    {props.data.invoice.shipping_cost.toLocaleString()}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-8 col-md-8">Cashback</div>
                  <div className="col-4 col-md-4">
                    {`${t("printOrder.table.currency")} `}{" "}
                    {props.data.invoice.cashback_amount.toLocaleString()}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-8 col-md-8">Diskon</div>
                  <div className="col-4 col-md-4">
                    {`${t("printOrder.table.currency")} `}{" "}
                    {props.data.invoice.voucher_amount.toLocaleString()}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-8 col-md-8 fw-bold fs-6">
                    {t("printOrder.table.subtotal_delivery")}
                  </div>
                  <div className="col-4 col-md-4 fw-bold fs-6">
                    {`${t("printOrder.table.currency")} `}{" "}
                    {props.data.invoice.shipping_cost.toLocaleString()}
                  </div>
                </div>
              </div>
            </div>

            <div className="row p-4">
              <div className="col-4" />
              <div className="col-12 col-md-8 border border-2 p-3">
                <div className="row">
                  <div className="col-8 col-md-8 fw-bold fs-5">
                    {t("printOrder.table.total")}
                  </div>
                  <div className="col-4 col-md-4 fw-bold fs-5">
                    {`${t("printOrder.table.currency")} `}{" "}
                    {props.data.invoice.grand_total.toLocaleString()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer id="printInvoice">
        <div className="m-auto">
          <button className="btn btn-primary" onClick={props.handlePrint}>
            {t("printOrder.button.print")}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default PrintOrderModal;
