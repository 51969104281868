import * as yup from 'yup';
import 'yup-phone';

export type InformationInputs = {
  name: string;
  email: string;
  mobile_phone: string;
  password: string | null;
};

export const informationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  mobile_phone: yup.string().phone('ID', true).required().label('phone number'),
  password: yup.string().min(8).required().label('password')
});

export const informationSchemaUpdate = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  mobile_phone: yup.string().phone('ID', true).required().label('phone number'),
  password: yup.string().label('password').nullable(true)
});

export const InformationBaseModel: InformationInputs = {
  name: '',
  email: '',
  mobile_phone: '',
  password: null
};

