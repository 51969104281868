import { api, createCancelTokenHandler } from "../../vodea/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (brandId: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/brand/${brandId}/widget/vendor/card/`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  getGrossMargin: function (brandId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/vendor/card/gross-margin`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getGrossMargin.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getSoldProduct: function (brandId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/vendor/card/sold-product`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getSoldProduct.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getGrossMarginChart: function (brandId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/vendor/chart/gross-margin-period`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getGrossMarginChart.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getSoldProductChart: function (brandId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/vendor/chart/sold-product-period`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getSoldProductChart.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getTableData: function (brandId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/vendor/table/performance`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getTableData.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getInStockRate: function (brandId: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/vendor/card/average-in-stock-rate`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getInStockRate.name
          ].handleRequestCancellation().token,
      }
    );
  },
  getServiceLevelAgreement: function (
    brandId: number | string,
    params: any = null
  ) {
    return api.get(
      `${endPoint()}/api/brand/${brandId}/widget/vendor/card/average-sla`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.getServiceLevelAgreement.name
          ].handleRequestCancellation().token,
      }
    );
  },
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);
