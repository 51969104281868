import { api, createCancelTokenHandler } from "../../../vodea/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (params: any = null, brand_id: any) {
    return api.get(`${endPoint()}/api/brand/${brand_id}/tokopedia/product`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  show: function (id: number | string, brand_id: any, params: any = null) {
    return api.get(
      `${endPoint()}/api/brand/${brand_id}/tokopedia/v2/product/${id}`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[this.show.name].handleRequestCancellation()
            .token,
      }
    );
  },
  showWithVariant: function (
    id: number | string,
    brand_id: any,
    params: any = null
  ) {
    return api.get(
      `${endPoint()}/api/brand/${brand_id}/tokopedia/v2/product/${id}/variant`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.showWithVariant.name
          ].handleRequestCancellation().token,
      }
    );
  },
  select: function (brand_id: any, params: any = null) {
    return api.get(`${endPoint()}/api/brand/${brand_id}/tokopedia/product`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
          .token,
    });
  },
  create: function (brand_id: any, payload: any, params: any = null) {
    return api.post(
      `${endPoint()}/api/brand/${brand_id}/tokopedia/product`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
            .token,
      }
    );
  },
  update: function (brand_id: any, payload: any, params: any = null) {
    return api.put(
      `${endPoint()}/api/brand/${brand_id}/tokopedia/product/update`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
            .token,
      }
    );
  },
  delete: function (brand_id: any, payload: any, params: any = null) {
    return api.post(
      `${endPoint()}/api/brand/${brand_id}/tokopedia/product/delete`,
      {
        ...payload,
        _method: "DELETE",
      },
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[this.delete.name].handleRequestCancellation()
            .token,
      }
    );
  },
  setStatusInActive: function (
    brand_id: any,
    payload: any,
    params: any = null
  ) {
    return api.post(
      `${endPoint()}/api/brand/${brand_id}/tokopedia/product/status/set-inactive`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.setStatusInActive.name
          ].handleRequestCancellation().token,
      }
    );
  },
  setStatusActive: function (brand_id: any, payload: any, params: any = null) {
    return api.post(
      `${endPoint()}/api/brand/${brand_id}/tokopedia/product/status/set-active`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.setStatusActive.name
          ].handleRequestCancellation().token,
      }
    );
  },
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);
