import React, { ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import useIsMounted from "../../../../../vodea/utilities/hooks/useIsMounted";
import { Helmet } from "react-helmet-async";
import VuiBreadcrumb from "../../../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import BrandGateSettingRepository from "../../../../../repositories/Brand/GateSettingRepository";
import BrandRoleRepository from "../../../../../repositories/Brand/RoleRepository";
import VendorGateSettingRepository from "../../../../../repositories/Vendor/GateSettingRepository";
import VendorRoleRepository from "../../../../../repositories/Vendor/RoleRepository";
import {
  formatFormData,
  formatSetValueForm,
} from "../../../../../vodea/utilities/helpers/form";
import { AxiosError, AxiosResponse } from "axios";
import { Controller, useForm } from "react-hook-form";
import {
  InformationBaseModel,
  InformationInputs,
  informationSchema,
} from "./validation";
import {
  mapHookErrors,
  showToast,
  stringTransform,
} from "../../../../../vodea/utilities/helpers/global";
import { none, useState } from "@hookstate/core";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import VuiActionForm from "../../../../../vodea/@vodea-ui/components/Forms/VuiActionForm";
import _ from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../stores";
import useRoleService from "../../../../../vodea/utilities/hooks/useRoleService";
import VuiSelect from "../../../../../vodea/@vodea-ui/components/Forms/VuiSelect";
import { Form } from "react-bootstrap";
import { $clone } from "../../../../../vodea/utilities";
import GateSettingRepository from "../../../../../repositories/GateSettingRepository";
import RoleRepository from "../../../../../repositories/RoleRepository";
import { Capitalize } from "../../../../../vodea/utilities/helpers/string.helper";

const SettingPermissionForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { id } = useParams();
  const { brand } = useSelector((state: RootState) => state.brand);
  const { vendor } = useSelector((state: RootState) => state.vendor);
  const { isAdministrator, isKeyAccount, isVendor, isBrand } = useRoleService();

  const selectedPermissions = useState<any[]>([]);
  const permissionOptions = useState<any[]>([]);

  const title = `${id ? t("general.edit") : t("general.create")} ${t(
    "settings.permission.title"
  )}`;

  useEffect(() => {
    if (isVendor())
      VendorRoleRepository.all(vendor.id).then((response: any) => {});
    getPermissionData();
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = () => {
    (isVendor()
      ? VendorGateSettingRepository.show(vendor.id, id, {
          with: ["permissions", "role"],
        })
      : isBrand()
      ? BrandGateSettingRepository.show(brand.id, id)
      : GateSettingRepository.show(id, {
          with: ["permissions", "role"],
        })
    )
      .then((response: AxiosResponse) => {
        const data = response.data.data;

        const arrSelectedPermissions: any = [];

        for (var i = 0; i < data.permissions.length; i++) {
          arrSelectedPermissions.push(data.permissions[i].id);
        }

        selectedPermissions.set(arrSelectedPermissions);

        informationSetValue("role_id", data.role);

        const formattedData = formatSetValueForm(InformationBaseModel, data);

        // _.forEach(formattedData, (value, name: any) => {
        //   informationSetValue(name, value);
        // });

        // selectedPermissions.set(_.get(data, "permission_ids", []));
      })
      .catch((e: AxiosError) => {
        showToast(e.message, "error");
      });
  };

  const getPermissionData = () => {
    (isVendor()
      ? VendorGateSettingRepository.permission(vendor.id)
      : isBrand()
      ? BrandGateSettingRepository.permission(brand.id)
      : GateSettingRepository.permission()
    )
      .then((response: AxiosResponse) => {
        convertToPermissionOptions(response.data.data);
      })
      .catch((e: AxiosError) => {
        showToast(e.message, "error");
      });
  };

  const checkCrudPermissionName = (name: string) => {
    if (name === "index") {
      return "List";
    } else if (name === "store") {
      return "Create";
    } else if (name === "destroy") {
      return "Delete";
    } else {
      return name;
    }
  };

  const convertToPermissionOptions = (permissions: any[]) => {
    let options: any[] = [];
    const isAdminOrKeyAccount = isAdministrator() || isKeyAccount();

    permissions.forEach((permission) => {
      const arrayKey = permission.name.split(".");
      let index = _.findIndex(
        options,
        (opt) => opt?.key === arrayKey[isAdminOrKeyAccount ? 0 : 1]
      );
      const key = arrayKey[isAdminOrKeyAccount ? 0 : 1];

      if (index === -1) {
        options.push({
          key: key,
          title:
            arrayKey.length < 3
              ? arrayKey[0].replaceAll("-", " ")
              : key.replaceAll("-", " "),
          items: [],
        });
      }

      index = _.findIndex(
        options,
        (opt) => opt?.key === arrayKey[isAdminOrKeyAccount ? 0 : 1]
      );

      if (arrayKey.length < 3) {
        options[index].items.push({
          id: permission.id,
          label: arrayKey[1].replaceAll("-", " "),
        });
      } else if (arrayKey.length > 3 && arrayKey.length < 5) {
        options[index].items.push({
          id: permission.id,
          label: `${Capitalize(
            arrayKey[2].replaceAll("-", " ")
          )} ${checkCrudPermissionName(arrayKey[3].replaceAll("-", " "))}`,
        });
      } else {
        options[index].items.push({
          id: permission.id,
          label: checkCrudPermissionName(arrayKey[2].replaceAll("-", " ")),
        });
      }
    });

    if (isMounted.current) permissionOptions.set(options);
  };

  const informationLoading = useState(false);
  const {
    handleSubmit: informationHandleSubmit,
    errors: informationErrors,
    setError: informationSetError,
    setValue: informationSetValue,
    control: informationControl,
  } = useForm<InformationInputs>({
    resolver: yupResolver(informationSchema),
  });

  const onInformationSubmit = informationHandleSubmit((data) => {
    informationLoading.set(true);

    const finalData = {
      permission_ids: $clone(selectedPermissions.value),
    };

    let formData = formatFormData(data);

    formData = Object.assign(formData, {
      permission_ids: $clone(selectedPermissions.value),
    });

    id
      ? (isVendor()
          ? VendorGateSettingRepository.update(vendor.id, id, finalData)
          : isBrand()
          ? BrandGateSettingRepository.update(brand.id, id, finalData)
          : GateSettingRepository.update(id, finalData)
        )
          .then((response: AxiosResponse) => {
            showToast(t("response.successUpdateData"), "success");
            informationLoading.set(false);

            if (!id) navigate("/settings/permission");
          })
          .catch((e: AxiosError) => {
            if (isMounted.current && e?.response?.data?.errors) {
              const errors = mapHookErrors(e.response.data.errors);
              Object.keys(errors).forEach((key: any) => {
                informationSetError(key, errors[key]);
              });

              showToast(e.response.data.message, "error");
            }

            informationLoading.set(false);
          })
      : (isVendor()
          ? VendorGateSettingRepository.create(vendor.id, formData)
          : isBrand()
          ? BrandGateSettingRepository.create(brand.id, formData)
          : GateSettingRepository.create(formData)
        )
          .then((response: AxiosResponse) => {
            showToast(t("response.successUpdateData"), "success");
            informationLoading.set(false);

            if (!id) navigate("/settings/permission");
          })
          .catch((e: AxiosError) => {
            if (isMounted.current && e?.response?.data?.errors) {
              const errors = mapHookErrors(e.response.data.errors);
              Object.keys(errors).forEach((key: any) => {
                informationSetError(key, errors[key]);
              });

              showToast(e.response.data.message, "error");
            }

            informationLoading.set(false);
          });
  });

  const handleOnChangePermission = (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    const tempSelected = $clone(selectedPermissions.get());
    const index = _.indexOf(tempSelected, value);

    if (index === -1) {
      selectedPermissions.merge([value]);
    } else {
      selectedPermissions[index].set(none);
    }
  };

  const breadcrumbList = [
    {
      label: t("settings.title"),
      link: "/settings",
    },
    {
      label: t("settings.permission.title"),
      link: "/settings/permission",
    },
    {
      label: title,
      link: `/settings/permission/${id ? id : "create"}`,
    },
  ];

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={"page-header-component"}>
        <h3 className={"title"}>{title}</h3>
      </div>

      <form className={"form-wrapper"} onSubmit={onInformationSubmit}>
        <div className={"default-page-layout"}>
          <div className={"information-section"}>
            <div className={"card-paper"}>
              <div className={"card-header"}>
                <h6 className={"card-header-title"}>
                  {t("general.information")}
                </h6>
              </div>
              <div className={"card-content"}>
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className={clsx({
                        "form-group": true,
                        error: informationErrors.role_id,
                      })}
                    >
                      <label className={"form-label"}>
                        {t("form.role.label")}
                      </label>

                      {isAdministrator() ? (
                        <Controller
                          name={"role_id"}
                          control={informationControl}
                          render={(props) => (
                            <VuiSelect
                              selectRepository={RoleRepository}
                              defaultValue={props.value}
                              onChange={props.onChange}
                              isDisabled={!!id}
                            />
                          )}
                        />
                      ) : (
                        <Controller
                          name={"role_id"}
                          control={informationControl}
                          render={(props) => (
                            <VuiSelect
                              selectRepository={
                                isVendor()
                                  ? VendorRoleRepository
                                  : BrandRoleRepository
                              }
                              selectParentId={isVendor() ? vendor.id : brand.id}
                              defaultValue={props.value}
                              onChange={props.onChange}
                              isDisabled={!!id}
                            />
                          )}
                        />
                      )}

                      <label className={"label-help"}>
                        {informationErrors.role_id?.message}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"card-paper mt-3"}>
          <div className={"card-header"}>
            <h6 className={"card-header-title"}>
              {t("general.addPermission")}
            </h6>
          </div>

          <div className={"card-content"}>
            <div className="card-permission-wrapper">
              {permissionOptions.get().map((permission: any, key) => {
                return (
                  <div className="card-permission" key={key}>
                    <h6 className="card-title text-capitalize">
                      {permission.title} :
                    </h6>

                    {permission.items.map((item: any, itemKey: number) => {
                      return (
                        <Form.Check
                          key={itemKey}
                          type={"checkbox"}
                          id={`${permission.title}-${item.label}`}
                          defaultValue={item.id}
                          label={item.label}
                          className={"text-capitalize"}
                          onChange={handleOnChangePermission}
                          checked={
                            _.indexOf(
                              $clone(selectedPermissions.get()),
                              item.id
                            ) !== -1
                          }
                        />
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <VuiActionForm
          loading={informationLoading.get()}
          cancelLink={"/settings/permission"}
        />
      </form>
    </>
  );
};

export default SettingPermissionForm;
